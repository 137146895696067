import {
  IonCheckbox,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonNote,
} from '@ionic/react';
import { pricetagsOutline as tasksIcon } from 'ionicons/icons';
import { TasksState } from 'pages/WorkLog/CreateInvoice';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { formatCurrency } from 'utils/formatCurrency';

export interface TaskListProps {
  tasks: TasksState;
  tasksTotal: number;
  onSelectItem: (id: string) => void;
}

const useStyles = createUseStyles({
  list: {
    border: '1px solid var(--ion-color-light)',
    paddingTop: 0,
    paddingBottom: 0,

    '& > ion-item:last-child': {
      '--inner-border-width': 0,
    },

    '& ion-checkbox': {
      // vertically align with item divider icon and text
      marginInlineStart: '3px',
      marginInlineEnd: '35px',
    },
  },
  note: {
    fontSize: '0.875rem',
    padding: 0,
    margin: 'auto',
  },
});

const TaskList: React.FC<TaskListProps> = ({ tasks, tasksTotal, onSelectItem }) => {
  const classes = useStyles();
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('amountDue', tasksTotal);
  }, [tasksTotal, setValue]);

  const taskIds = Object.keys(tasks);

  return (
    <IonList className={classes.list}>
      <IonItemDivider mode="md" sticky={true}>
        <IonIcon icon={tasksIcon} slot="start" />
        <IonLabel color="dark">
          <h2>Tasks</h2>
        </IonLabel>
        {taskIds.length > 0 && (
          <IonLabel color="secondary" slot="end">
            {formatCurrency(tasksTotal)}
          </IonLabel>
        )}
      </IonItemDivider>
      {taskIds.length ? (
        taskIds.map((taskId) => {
          const { disabled, invoiceNumber, name, totalCost } = tasks[taskId];
          return (
            <IonItem key={taskId} disabled={disabled}>
              <IonCheckbox slot="start" value={taskId} onIonChange={() => onSelectItem(taskId)} />
              <IonLabel>
                <h3>
                  {name}
                  {invoiceNumber && <span>{` · Invoice ${invoiceNumber}`}</span>}
                </h3>
              </IonLabel>
              <IonNote className={classes.note} slot="end">
                {formatCurrency(totalCost)}
              </IonNote>
            </IonItem>
          );
        })
      ) : (
        <IonItem lines="none">
          <IonLabel style={{ fontSize: '0.875rem' }}>No Tasks</IonLabel>
        </IonItem>
      )}
    </IonList>
  );
};

export default TaskList;
