import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

export interface TableHeaderItemProps {
  nameLabel?: string;
  mileageLabel?: string;
  costLabel?: string;
}

const useStyles = createUseStyles({
  col: {
    '@media screen and (max-width: 576px)': {
      display: 'none',
    },
  },
  grid: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
});

const TableHeaderItem: React.FC<TableHeaderItemProps> = ({
  nameLabel = '',
  mileageLabel = 'Mileage',
  costLabel = 'Cost',
}) => {
  const classes = useStyles();

  return (
    <IonItem style={{ fontSize: '0.875rem' }}>
      <IonGrid className={classes.grid}>
        <IonRow className="ion-align-items-center">
          <IonCol sizeSm="4" sizeXs="5" style={{ paddingLeft: 0 }}>
            <IonLabel>{nameLabel}</IonLabel>
          </IonCol>

          <IonCol className="ion-text-center" sizeSm="3" sizeXs="4">
            <IonLabel>{mileageLabel}</IonLabel>
          </IonCol>

          <IonCol className="ion-text-center" sizeSm="2" sizeXs="3">
            <IonLabel>{costLabel}</IonLabel>
          </IonCol>

          <IonCol className={classes.col} size="3" />
        </IonRow>
      </IonGrid>
      <IonIcon icon="undefined" slot="end" />
    </IonItem>
  );
};

export default TableHeaderItem;
