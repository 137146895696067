import { useEffect, useState } from 'react';

import { useMounted } from './useMounted';

interface UseLocalStorageResult<T> {
  value?: T;
  setValue: (value: T) => void;
  clearValue: () => void;
}

const clearStorage = (key: string): void => window.localStorage.removeItem(key);

const setStorage = <T>(key: string, value: T): void =>
  window.localStorage.setItem(key, JSON.stringify(value));

const getStorage = <T>(key: string): T | undefined => {
  try {
    const storageValue = window.localStorage.getItem(key);
    return storageValue !== null ? JSON.parse(storageValue) : undefined;
  } catch (_) {
    return undefined;
  }
};

/**
 * Maintain state synced to local storage.
 * @param key Local storage key. Key can change and value will be stored at the new key.
 * @param initialValue Optional value to initialize key if it does not existing in storage.
 */
export const useLocalStorage = <T>(key: string, initialValue?: T): UseLocalStorageResult<T> => {
  const isStateAvailable = useMounted();
  const [state, setState] = useState<T | undefined>(() => getStorage(key) || initialValue);

  const setValue = (value?: T) =>
    // `isStateAvailable` resolves issue trying to set state after component has unmounted.
    // If state is not available (component is no longer mounted), update in storage directly.
    // Same logic applies to `clearValue`.
    isStateAvailable.current ? setState(value) : setStorage(key, value);

  const clearValue = () => (isStateAvailable.current ? setState(undefined) : clearStorage(key));

  // Effect to persist state changes to storage
  useEffect(() => {
    state ? setStorage<T>(key, state) : clearStorage(key);
  }, [key, state]);

  return {
    value: state,
    setValue,
    clearValue,
  };
};
