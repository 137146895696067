import { IonCard, IonCardContent, IonCardHeader, IonIcon, IonItem, IonLabel } from '@ionic/react';
import Avatar from 'components/Avatar';
import { Account } from 'interfaces/Account';
import { AccountKind } from 'interfaces/AccountKind';
import {
  atOutline as emailIcon,
  peopleOutline as teamIcon,
  phonePortraitOutline as phoneIcon,
} from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { prettyDate } from 'utils/prettyDate';
import { prettyPhone } from 'utils/prettyPhone';

export interface AccountDisplayProps {
  account: Pick<
    Account,
    'kind' | 'activatedAt' | 'createdAt' | 'email' | 'isEmailVerified' | 'name' | 'phone' | 'team'
  >;
  className?: string;
  routerLink?: string;
}

const useStyles = createUseStyles({
  header: {
    '--inner-padding-end': 0,
    '--background': 'transparent',
  },
  dataLabel: {
    '--min-height': 'auto',
    fontSize: '0.875rem',
    '& ion-icon, & ion-label': {
      marginTop: 4,
      marginBottom: 4,
    },
    '--background': 'transparent',
  },
});

const AccountDisplay: React.FC<AccountDisplayProps> = ({ account, className, routerLink }) => {
  const classes = useStyles();

  const { kind, activatedAt, createdAt, email, isEmailVerified, name, phone, team } = account;

  return (
    <IonCard className={className} button={true} routerLink={routerLink}>
      <IonCardHeader>
        <IonItem className={classes.header} lines="none">
          <Avatar alt={name} slot="start" />
          <IonLabel style={{ marginTop: 0, marginBottom: 0 }}>
            <h2>{name}</h2>
            {activatedAt ? (
              <p style={{ marginTop: 6 }}>Joined {prettyDate(activatedAt)}</p>
            ) : kind === AccountKind.User ? (
              <p style={{ marginTop: 6 }}>Invited {prettyDate(createdAt)}</p>
            ) : (
              <p style={{ marginTop: 6 }}>Created {prettyDate(createdAt)}</p>
            )}
          </IonLabel>
        </IonItem>
      </IonCardHeader>

      <IonCardContent>
        {kind === AccountKind.User && (
          <>
            <IonItem className={classes.dataLabel} lines="none">
              <IonIcon icon={emailIcon} slot="start" />
              <IonLabel>
                {email}
                {isEmailVerified && <slot> (verified)</slot>}
              </IonLabel>
            </IonItem>
            {phone && (
              <IonItem className={classes.dataLabel} lines="none">
                <IonIcon icon={phoneIcon} slot="start" />
                <IonLabel>{prettyPhone(phone)}</IonLabel>
              </IonItem>
            )}
          </>
        )}
        <IonItem className={classes.dataLabel} lines="none">
          <IonIcon icon={teamIcon} slot="start" />
          <IonLabel>{team.name}</IonLabel>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default AccountDisplay;
