import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import UnsafeArea from 'components/UnsafeArea';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React, { useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { NotificationModalProps } from './NotificationModalProps';

const NotificationModal: React.FC<NotificationModalProps> = ({
  isItemLoaded,
  itemCount,
  loadMoreItems,
  popupState,
  row,
  onClose,
}) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const headerHeight = isPlatform('ios') ? 44 : 56;
  const height = window.innerHeight - headerHeight;

  const onCloseClick = () => {
    onClose();
    modalRef.current?.dismiss();
  };

  return (
    <IonModal isOpen={popupState.showPopup} onDidDismiss={onClose} ref={modalRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCloseClick}>
              <IonIcon icon={closeIcon} color="primary" slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        threshold={0}
      >
        {({ onItemsRendered, ref }) => (
          <List
            height={height}
            itemCount={itemCount}
            itemSize={138}
            onItemsRendered={onItemsRendered}
            itemData={true}
            ref={ref}
            width="100%"
          >
            {row}
          </List>
        )}
      </InfiniteLoader>
      <UnsafeArea position="bottom" />
    </IonModal>
  );
};

export default NotificationModal;
