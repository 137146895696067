import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { useMessages } from 'hooks/useMessages';
import { Status } from 'interfaces/Status';
import { WorkOrder } from 'interfaces/WorkOrder';
import { useEffect, useState } from 'react';

export const GET_WORKORDERS = gql`
  query GetWorkOrders($query: WorkOrdersQuery!, $filter: WorkOrdersFilter) {
    workorders(query: $query, filter: $filter) {
      nodes {
        id
        title
        description
        workorderNumber
        titleDisplay @client
        hasAlert @client
        location {
          address
          latitude
          longitude
        }
        category {
          id
          name
        }
        status
        reason
        createdAt
        team {
          id
          name
        }
        assignee {
          id
          name
        }
        comments {
          id
          content
        }
        attachments {
          id
          mimetype
          name
          attachmentType @client
        }
      }
    }
  }
`;

export interface WorkOrderFilter {
  statuses?: Status[];
}

export interface WorkOrderConfig {
  organizationId: string;
  assigneeId?: string;
  active?: boolean;
  filter?: WorkOrderFilter;
}

export const useWorkOrders = (config?: WorkOrderConfig) => {
  const { filter } = { ...config };

  const [workorders, setWorkorders] = useState<WorkOrder[]>([]);

  const { addMessage } = useMessages();

  const { data, networkStatus, refetch } = useQuery(GET_WORKORDERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      query: {
        organizationId: config?.organizationId,
        assigneeId: config?.assigneeId,
        active: config?.active,
      },
      filter,
    },
    onError: (err: any) => {
      // tslint:disable-next-line: no-console
      console.error(err);
      addMessage('Unable to load work orders.', 'danger');
    },
  });

  const findById = (workorderId: string) => {
    return workorders.find((workorder: WorkOrder) => workorder.id === workorderId);
  };

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && data?.workorders?.nodes) {
      const records: WorkOrder[] = data.workorders.nodes;
      const sorted = [...records].sort((a, b) => b.createdAt - a.createdAt);
      setWorkorders(sorted);
    }
  }, [data, networkStatus]);

  return {
    loading: !networkStatus || networkStatus === NetworkStatus.loading,
    workorders,
    refetch,
    findById,
  };
};
