import { IonIcon } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface IIconBadge {
  icon: string;
  badgeContent?: number;
  slot?: string;
  color?: string;
}

const useStyles = createUseStyles({
  iconBadge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& ion-icon': {
      fontSize: 24,
    },
  },
  badge: {
    marginLeft: -6,
    marginTop: '-50%',
    fontSize: '0.625rem',
    borderRadius: 9,
    zIndex: 2,
    width: 18,
    height: 18,
    backgroundColor: (props: any) => `var(--ion-color-${props.color})`,
    color: (props: any) => `var(--ion-color-${props.color}-contrast)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const IconBadge: React.FC<IIconBadge> = ({
  icon,
  badgeContent = 0,
  slot = 'end',
  color = 'primary',
}) => {
  const classes = useStyles({ color });

  return (
    <span className={classes.iconBadge}>
      <IonIcon icon={icon} slot={slot} size="small" color="medium" />
      <span className={classes.badge}>{badgeContent > 99 ? '99+' : badgeContent}</span>
    </span>
  );
};

export default IconBadge;
