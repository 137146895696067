import { MutationUpdaterFn, useMutation, useQuery } from '@apollo/client';
import { Plugins } from '@capacitor/core';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import clsx from 'clsx';
import BackButton from 'components/BackButton';
import { MagicField, MagicForm } from 'components/MagicForm';
import UnsafeArea from 'components/UnsafeArea';
import { useMessages } from 'hooks/useMessages';
import { useWorkOrderDetails } from 'hooks/useWorkOrderDetails';
import { useWorkspace } from 'hooks/useWorkspace';
import { Invoice, Task } from 'interfaces/Worklog';
import moment from 'moment';
import {
  CREATE_INVOICE,
  CreateInvoiceInput,
  CreateInvoiceResponse,
  GET_AFTER_CREATE_INVOICE_UPDATES,
  GET_ORGANIZATION_INVOICE_TEMPLATES,
  GetAfterCreateInvoiceUpdates,
  GetOrganizationInvoiceTemplatesResponse,
} from 'pages/WorkLog/graphql';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';

import TaskList from './components/TaskList';

const { Browser } = Plugins;

export type TasksState = Record<
  string,
  {
    checked: boolean;
    disabled: boolean;
    invoiceNumber: string;
    name: string;
    totalCost: number;
  }
>;

interface State {
  tasks: TasksState;
  tasksTotal: number;
}

export interface InvoiceFormData {
  description: string;
  invoiceNumber: string;
  invoiceDate: number;
  amountDue: number;
  name: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
}

const cacheUpdate: MutationUpdaterFn<CreateInvoiceResponse> = (cache, { data }) => {
  if (!data) {
    return;
  }

  const { createWorklogInvoice } = data;

  const workorderId = createWorklogInvoice.invoice.workorder.id;

  const queryData: any = cache.readQuery({
    query: GET_AFTER_CREATE_INVOICE_UPDATES,
    variables: { workorderId },
  });

  cache.writeQuery({
    query: GET_AFTER_CREATE_INVOICE_UPDATES,
    variables: { workorderId },
    data: {
      workorder: {
        ...queryData.workorder,
        invoices: [...queryData.workorder.invoices, createWorklogInvoice.invoice],
      },
    },
  });
};

const init = ({
  worklog,
}: {
  worklog?: Pick<Task, 'id' | 'name' | 'invoice' | 'totalCost'>[];
}): State => {
  const tasks = (worklog || []).reduce(
    (acc, task) => ({
      ...acc,
      [task.id]: {
        checked: false,
        disabled: !!task.invoice?.id,
        invoiceNumber: task.invoice?.invoiceNumber,
        name: task.name,
        totalCost: task.totalCost,
      },
    }),
    {}
  );

  return {
    tasks,
    tasksTotal: 0,
  };
};

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'TOGGLE_CHECKED':
      const { taskId } = action.payload;
      const tasks: TasksState = {
        ...state.tasks,
        [taskId]: {
          ...state.tasks[taskId],
          checked: !state.tasks[taskId].checked,
        },
      };
      const tasksTotal = Object.keys(tasks).reduce(
        (acc, key) =>
          tasks[key].checked ? acc + parseFloat(tasks[key].totalCost.toFixed(2)) : acc,
        0
      );

      return {
        ...state,
        tasks,
        tasksTotal,
      };
    case 'RESET': {
      return init(action.payload);
    }
    default:
      return state;
  }
};

const useStyles = createUseStyles({
  itemDivider: {
    marginTop: 'var(--ion-margin, 16px)',
    '--padding-start': 0,
    '--background': 'var(--ion-background-color, #fff)',
  },
  fieldNote: {
    fontSize: '0.875rem',
    lineHeight: 1.6,
  },
});

const CreateInvoice: React.FC = () => {
  const { workorderId } = useParams<{ workorderId: string }>();

  const history = useHistory();

  const classes = useStyles();

  const { workspace } = useWorkspace();

  const { addMessage } = useMessages();
  const { workorder } = useWorkOrderDetails(workorderId);

  const [loading, setLoading] = useState(false);

  // State to load toast with button to open the invoice PDF on success
  const [successToast, setSuccessToast] = useState<{
    success: boolean;
    invoiceNumber?: string;
    url?: string;
  }>({
    success: false,
  });

  const suggestedInvoiceNumber = useMemo(() => {
    if (!workorder) return;

    const { workorderNumber, invoices } = workorder;

    return `${workorderNumber}-${invoices.length + 1}`;
  }, [workorder]);

  const [{ tasks, tasksTotal }, dispatch] = useReducer<
    (state: State, actions: any) => State,
    { worklog?: Pick<Task, 'id' | 'name' | 'invoice' | 'totalCost'>[] }
  >(reducer, { worklog: workorder?.worklog }, init);

  const { data: invoiceTemplatesData } = useQuery<GetOrganizationInvoiceTemplatesResponse>(
    GET_ORGANIZATION_INVOICE_TEMPLATES,
    {
      displayName: 'CreateInvoice',
      variables: { organizationId: workspace.organizationId },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      partialRefetch: true,
      onError: () => addMessage('Unable to load invoice template.', 'danger'),
    }
  );
  // Always use first template for an org. Should only be 1 per org.
  // We may include features later to allow configuration of multiple templates.
  const templateId = invoiceTemplatesData?.organization.invoiceTemplates[0]?.id || '';

  const [create] = useMutation(CREATE_INVOICE, { update: cacheUpdate });

  // Network only query just for refetching after successful creation.
  // `skip` always true to allow lazy refetching as needed
  const { refetch: refetchInvoices } = useQuery<GetAfterCreateInvoiceUpdates>(
    GET_AFTER_CREATE_INVOICE_UPDATES,
    {
      displayName: 'CreateInvoice',
      variables: { workorderId },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      partialRefetch: true,
      skip: true,
    }
  );

  useEffect(() => {
    dispatch({ type: 'RESET', payload: { worklog: workorder?.worklog } });
  }, [workorder]);

  const isChecked = (taskId: string) => {
    return tasks[taskId].checked;
  };

  const onSubmit = async ({
    description,
    invoiceNumber,
    invoiceDate,
    amountDue,
    name,
    email,
    addressLine1,
    addressLine2,
    addressLine3,
  }: InvoiceFormData) => {
    if (!templateId) {
      addMessage('Unable to load invoice templates.', 'danger');
      return;
    }

    const taskIds = Object.keys(tasks).filter((taskId) => isChecked(taskId));

    const variables: CreateInvoiceInput = {
      workorderId,
      templateId,
      invoiceNumber,
      invoiceDate: moment(invoiceDate, 'YYYY-MM-DD').valueOf(),
      description,
      amountDue,
      taskIds,
    };

    // Only use recipient properties that are not undefined
    const recipient = { name, addressLine1, addressLine2, addressLine3, email };
    (Object.keys(recipient) as (keyof typeof recipient)[]).forEach((key) => {
      recipient[key] === undefined && delete recipient[key];
    });
    variables.recipient = recipient;

    setLoading(true);

    let invoiceId: string | undefined;

    try {
      const createResponse = await create({ variables });
      invoiceId = createResponse.data?.createWorklogInvoice.invoice.id;
    } catch ({ graphQLErrors }) {
      setLoading(false);
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to create invoice', ...messages].join('. ')}.`, 'danger');
      return;
    }

    setTimeout(async () => {
      // Provide time for projections to rebuild before navigating
      // Using 3000 (longer than typical) because invoice PDFs take longer to generate

      let refetchedInvoice: Pick<Invoice, 'invoiceNumber' | 'url'> | undefined;

      try {
        // Invoice PDFs are created asynchronously.
        // After wait, refetch workorder for the url.
        // Refetching also ensures the full invoice with URL will be available on the worklog.
        const refetchWorkOrderResponse = await refetchInvoices();
        refetchedInvoice = (refetchWorkOrderResponse.data?.workorder.invoices || []).find(
          ({ id }: Pick<Invoice, 'id'>) => id === invoiceId
        );
      } catch (_err) {
        // swallow errors and continue
      }

      setSuccessToast({
        success: true,
        invoiceNumber: refetchedInvoice?.invoiceNumber,
        url: refetchedInvoice?.url,
      });

      setLoading(false);
      history.replace(`/workorders/details/${workorderId}`);
    }, 3000);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref={`/workorders/details/${workorderId}`} />
          </IonButtons>
          <IonTitle>Create Invoice</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={loading} />

        {workorder && (
          <MagicForm
            style={{ height: '100%' }}
            submitText="Create"
            onSubmit={onSubmit}
            defaultValues={{
              invoiceNumber: suggestedInvoiceNumber,
            }}
          >
            <MagicField
              name="description"
              type="text"
              fieldType="text"
              inputMode="text"
              label="Description of Work"
              rules={{
                required: 'Description is required',
              }}
            />

            <MagicField
              name="invoiceNumber"
              placeholder={suggestedInvoiceNumber}
              type="text"
              fieldType="text"
              inputMode="text"
              label="Invoice Number"
              rules={{
                required: 'Invoice Number is required',
              }}
            />

            <MagicField
              name="invoiceDate"
              type="date"
              inputMode="text"
              label="Invoice Date"
              rules={{
                required: 'Invoice Date is required',
              }}
            />

            <div className="ion-margin-top">
              <TaskList
                tasks={tasks}
                tasksTotal={tasksTotal}
                onSelectItem={(taskId) => dispatch({ type: 'TOGGLE_CHECKED', payload: { taskId } })}
              />
            </div>

            <div className={clsx('ion-padding-top', 'ion-padding-horizontal')}>
              <IonNote className={classes.fieldNote}>
                <p className="ion-no-margin">
                  The amount due is the total from all selected tasks.
                </p>
                <p className="ion-no-margin">
                  If no tasks are selected, the amount due can be manually set to any value.
                </p>
              </IonNote>
            </div>

            <MagicField
              name="amountDue"
              type="number"
              step="1"
              inputMode="decimal"
              label="Amount Due"
              disabled={Object.keys(tasks).some((taskId) => isChecked(taskId))}
              rules={{
                required: 'Amount due is required',
              }}
            />

            <IonItem className={classes.itemDivider} lines="none" mode="md">
              <IonLabel className="ion-margin-horizontal">
                <h2>Invoice Recipient</h2>
              </IonLabel>
            </IonItem>

            <MagicField
              name="name"
              type="text"
              fieldType="text"
              inputMode="text"
              label="Name"
              rules={{
                required: 'Name is required',
              }}
            />

            <MagicField
              name="addressLine1"
              type="text"
              fieldType="text"
              inputMode="text"
              label="Address Line 1"
              style={{ marginBottom: 0, borderBottom: 0 }}
            />

            <MagicField
              name="addressLine2"
              type="text"
              fieldType="text"
              inputMode="text"
              label="Address Line 2"
              style={{ marginTop: 0, marginBottom: 0, borderBottom: 0, borderTop: 0 }}
            />

            <MagicField
              name="addressLine3"
              type="text"
              fieldType="text"
              inputMode="text"
              label="Address Line 3"
              style={{ marginTop: 0, borderTop: 0 }}
            />

            <MagicField name="email" type="email" inputMode="email" label="Email" />
          </MagicForm>
        )}

        {/* Not using `useMessages` because we need a custom "Open" button */}
        <IonToast
          isOpen={successToast.success}
          color="dark"
          message={`Invoice ${successToast.invoiceNumber} created`}
          position="bottom"
          buttons={
            successToast.url
              ? [{ text: 'Open', handler: () => Browser.open({ url: successToast.url! }) }]
              : undefined
          }
          duration={10000}
          // Toast will not open a second time without resetting on dismiss
          onDidDismiss={() => setSuccessToast({ success: false })}
        />
      </IonContent>
      <UnsafeArea color="primary" position="bottom" />
    </IonPage>
  );
};

export default CreateInvoice;
