import { IonItem, IonLabel, IonList } from '@ionic/react';
import usePortal from 'hooks/usePortal';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { PropsWithChildren, ReactNode, RefObject, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { createUseStyles } from 'react-jss';
import { animated, useTransition } from 'react-spring';

interface RenderProps<T> {
  value: T;
  onClick: (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => void;
  isFirst: boolean;
  isLast: boolean;
}

interface SearchResults<T> {
  show?: boolean;
  results: T[];
  inputRef: RefObject<HTMLIonSearchbarElement>;
  onSelect: (props: T) => void;
  renderSearchItem: (props: RenderProps<T>) => ReactNode;
  renderNoItems?: () => ReactNode;
}

const useStyles = createUseStyles({
  results: {
    lineHeight: 1.6,
    fontSize: '0.875rem',
    '& ion-label p': {
      marginTop: 6,
    },
    '& ion-list': {
      padding: 0,
    },
    '& ion-item': {
      '--min-height': '67px',
    },
  },
});

const SearchResults = <T, >({
  show = true,
  results,
  inputRef,
  onSelect,
  renderSearchItem,
  renderNoItems,
}: PropsWithChildren<SearchResults<T>>) => {
  const classes = useStyles();

  const target = usePortal('search-results');

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  // Input render may be delayed from animations
  // Use width as dependency for memo to account for size/position changes
  const inputRect = inputRef?.current?.getBoundingClientRect();

  const resultsPosition = useMemo(() => {
    if (!(inputRef.current && inputRect && windowHeight && windowWidth)) {
      return { top: 100, bottom: 0, left: 0, right: 0 };
    }

    const { top, bottom, left, right, height } = inputRect;
    const { offsetLeft } = inputRef.current;

    return {
      top: top + height + 11,
      bottom: windowHeight - bottom,
      left: left - offsetLeft + 16,
      right: windowWidth - right - offsetLeft + 16,
    };
  }, [inputRect, inputRef, windowHeight, windowWidth]);

  const listAnimationOptions = {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  };

  const transitionList = useTransition(show, null, listAnimationOptions);

  const itemsAnimationOptions = {
    enter: {
      opacity: 1,
      height: 68, // min-height from CSS + 1px border
      transform: 'translate3d(0px,0px,0px)',
    },
    leave: {
      opacity: 0,
      height: 0,
      transform: 'translate3d(0px,20px,0px)',
    },
    from: {
      opacity: 0,
      height: 0,
      transform: 'translate3d(0px,-20px,0px)',
    },
    trail: 10,
    unique: true,
  };

  const items = useMemo<any[]>(() => results.map((result, index) => ({ key: index, ...result })), [
    results,
  ]);

  const transitionItems = useTransition(items, (item) => item.key, itemsAnimationOptions);

  const list = transitionList.map(
    ({ item: i, key: k, props: p }) =>
      i && (
        <animated.div
          key={k}
          style={{ position: 'absolute', ...resultsPosition, ...p }}
          className={classes.results}
        >
          <IonList>
            {!!transitionItems.length ? (
              transitionItems.map(({ item, props, key }, index, array) => (
                <animated.div key={key} style={props}>
                  {renderSearchItem({
                    value: item,
                    onClick: () => onSelect(item),
                    isFirst: index === 0,
                    isLast: index === array.length - 1,
                  })}
                </animated.div>
              ))
            ) : (
              <animated.div key="no-results">
                {!!renderNoItems ? (
                  renderNoItems()
                ) : (
                  <IonItem>
                    <IonLabel class="ion-text-wrap">
                      <h3>No Results</h3>
                    </IonLabel>
                  </IonItem>
                )}
              </animated.div>
            )}
          </IonList>
        </animated.div>
      )
  );

  return createPortal(list, target);
};

export default SearchResults;
