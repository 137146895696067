import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from '@ionic/react';
import centerOfMass from '@turf/center-of-mass';
import InteractiveMap from 'components/InteractiveMap/InteractiveMap';
import * as assetSymbology from 'components/InteractiveMap/assetSymbology';
import { Asset } from 'interfaces/Asset';
import { MapStyles } from 'interfaces/Basemaps';
import {
  arrowForwardCircleOutline as navCircle,
  closeCircleOutline as closeCircle,
} from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';

export interface WorkOrderDetailsAssetCardProps {
  asset: Pick<Asset, 'id' | 'name' | 'assetType' | 'location'>;
  checked?: boolean;
  onCheckChange?: (id: string, checked: boolean) => void;
  createWorkOrder?: boolean; // if true, then checked & onCheckChange are not needed
  removeTag?: (id: string) => void; // required if createWorkOrder is true
  mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
}

const useStyles = createUseStyles({
  titleStyle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    whiteSpace: 'pre-wrap',
  },
  labelStyle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    whiteSpace: 'pre-wrap',
  },
  topRightStyle: {
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: 1,
    paddingTop: 'calc(var(--ion-padding, 16px) * 0.5)',
    paddingRight: 'calc(var(--ion-padding, 16px) * 0.5)',
  },
  navBtnStyle: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    zIndex: 1,
    paddingBottom: 'calc(var(--ion-padding, 16px) * 0.45)',
    paddingRight: 'calc(var(--ion-padding, 16px) * 0.25)',
  },
  hideLogo: {
    '& .mapboxgl-ctrl-logo': { display: 'none' },
  },
});

const WorkOrderDetailsAssetCard: React.FC<WorkOrderDetailsAssetCardProps> = ({
  asset,
  checked,
  onCheckChange,
  createWorkOrder,
  removeTag,
  mapStyles,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const handleCheckChange = () => {
    if (onCheckChange) {
      onCheckChange(asset.id, !checked);
    }
  };

  // No need to re-render the map.
  const interactiveMap = React.useMemo(() => {
    // no matter the shape of the asset, we want to position the map at the center of it
    const center = centerOfMass(JSON.parse(asset.location.geometry));

    return (
      <InteractiveMap
        style={{ width: '100%', height: '100%' }}
        className={classes.hideLogo}
        mapStyles={mapStyles}
        isMoveable={false}
        initialPosition={{
          center: [center.geometry.coordinates[0], center.geometry.coordinates[1]],
          zoom: 14,
        }}
        geoJSON={{
          features: [
            {
              type: 'Feature',
              properties: asset,
              geometry: JSON.parse(asset.location.geometry),
            },
          ],
          ...assetSymbology,
          selectable: false,
        }}
      />
    );
  }, [mapStyles, asset, classes.hideLogo]);

  return (
    <IonCard style={{ background: 'var(--ion-color-light-tint)', boxShadow: 'none' }}>
      {createWorkOrder && removeTag ? (
        <div className={classes.topRightStyle}>
          <IonIcon
            color="primary"
            icon={closeCircle}
            onClick={() => removeTag(asset.id)}
            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
          />
        </div>
      ) : (
        <div className={classes.topRightStyle} style={{ opacity: checked ? 1 : 0.5 }}>
          <IonCheckbox
            mode="md"
            className="ion-no-margin"
            checked={checked}
            onClick={handleCheckChange}
          />
        </div>
      )}
      <IonCardContent className="ion-no-padding">
        <IonGrid className="ion-no-padding">
          <IonRow style={{ height: '120px' }}>
            <IonCol size="5" style={{ height: '100%' }}>
              {interactiveMap}
            </IonCol>
            <IonCol
              size="7"
              className="ion-padding ion-align-items-center"
              style={{ display: 'flex' }}
            >
              <div style={{ width: '100%' }}>
                <IonRow>
                  <IonCol>
                    <IonLabel color="dark" className={classes.titleStyle}>
                      {asset.name}
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel className={classes.labelStyle} style={{ fontSize: '0.8rem' }}>
                      {asset.assetType}
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel className={classes.labelStyle} style={{ fontSize: '0.8rem' }}>
                      {asset.location?.description}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
      {!createWorkOrder && (
        <div className={classes.navBtnStyle}>
          <IonIcon
            color="primary"
            icon={navCircle}
            onClick={() => history.push(`/assets/${asset.id}`)}
            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
          />
        </div>
      )}
    </IonCard>
  );
};

export default WorkOrderDetailsAssetCard;
