import { gql } from '@apollo/client';
import { Account } from 'interfaces/Account';
import { AreaOfInterest } from 'interfaces/AreaOfInterest';
import { Category } from 'interfaces/Category';
import { Status } from 'interfaces/Status';
import { Team } from 'interfaces/Team';
import { WorkOrder } from 'interfaces/WorkOrder';

export type WorkOrderSearchResult = Pick<
  WorkOrder,
  | 'id'
  | 'titleDisplay'
  | 'location'
  | 'category'
  | 'status'
  | 'team'
  | 'assignee'
  | 'createdAt'
  | 'tags'
  | 'assets'
>;

export interface WorkOrderAggregations {
  categories?: {
    nodes: {
      category: Pick<Category, 'id' | 'name'>;
      count: number;
    }[];
  };
  statuses?: {
    nodes: {
      status: Status;
      count: number;
    }[];
  };
  areasOfInterest?: {
    nodes: {
      areaOfInterest: Pick<AreaOfInterest, 'id' | 'name'>;
      count: number;
    }[];
  };
  teams?: {
    nodes: {
      team: Pick<Team, 'id' | 'name'>;
      count: number;
    }[];
  };
  assignees?: {
    nodes: {
      assignee: Pick<Account, 'id' | 'name'>;
      count: number;
    }[];
  };
  tags?: {
    nodes: {
      tag: string;
      count: number;
    }[];
  };
}

export interface GetWorkOrderFiltersData {
  organization: {
    tags: {
      nodes: string[];
    };
    workorders: {
      aggs: WorkOrderAggregations;
    };
  };
}

export interface GetWorkOrderFiltersVariables {
  organizationId: string;
  query?: string;
}

export interface GetWorkOrdersData {
  organization: {
    workorders: {
      nodes: WorkOrderSearchResult[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
      };
    };
  };
}

export interface GetWorkOrdersVariables {
  organizationId: string;
  query?: string;
  categories?: string;
  statuses?: string;
  areasOfInterest?: string;
  street?: string;
  teams?: string;
  assignees?: string;
  after?: string;
  size?: number;
  createdFrom?: number;
  createdTo?: number;
  tags?: string;
}

export const GET_WORK_ORDER_FILTERS = gql`
  query GetWorkOrderFilters($organizationId: ID!, $query: String) {
    organization(id: $organizationId) {
      workorders(params: { text: $query }) {
        aggs {
          tags {
            nodes {
              tag
              count
            }
          }
          categories {
            nodes {
              category {
                id
                name
              }
              count
            }
          }
          statuses {
            nodes {
              status
              count
            }
          }
          areasOfInterest {
            nodes {
              areaOfInterest {
                id
                name
              }
              count
            }
          }
          teams {
            nodes {
              team {
                id
                name
              }
              count
            }
          }
          assignees {
            nodes {
              assignee {
                id
                name
              }
              count
            }
          }
        }
      }
    }
  }
`;

export const workOrderSearchResultFragment = gql`
  fragment WorkOrderSearchResult on WorkOrder {
    id
    title
    workorderNumber
    titleDisplay @client
    location {
      address
      latitude
      longitude
    }
    category {
      id
      name
    }
    status
    team {
      id
      name
    }
    assignee {
      id
      name
    }
    createdAt
  }
`;

export const GET_WORK_ORDERS = gql`
  query GetWorkOrders(
    $organizationId: ID!
    $query: String
    $categories: String
    $statuses: String
    $areasOfInterest: String
    $street: String
    $teams: String
    $assignees: String
    $after: String
    $size: Int
    $createdFrom: Float
    $createdTo: Float
    $tags: String
  ) {
    __typename
    organization(id: $organizationId) {
      workorders(
        params: {
          createdFrom: $createdFrom
          createdTo: $createdTo
          text: $query
          categories: $categories
          statuses: $statuses
          areasOfInterest: $areasOfInterest
          street: $street
          teams: $teams
          assignees: $assignees
          after: $after
          size: $size
          tags: $tags
        }
      ) {
        nodes {
          ...WorkOrderSearchResult
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${workOrderSearchResultFragment}
`;
