import {
  IonActionSheet,
  IonAlert,
  IonButton,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonList,
  isPlatform,
} from '@ionic/react';
import AddNewItemButton from 'components/AddNewItemButton';
import { MapStyles } from 'interfaces/Basemaps';
import { locationOutline as assetIcon } from 'ionicons/icons';
import React, { useState } from 'react';

import WorkOrderDetailsAssetCardGroup, {
  WorkOrderDetailsAssetCardGroupProps,
} from './WorkOrderDetailsAssetCardGroup';
import WorkOrderDetailsAssetCardGroupSkeleton from './WorkOrderDetailsAssetCardGroupSkeleton';

export interface WorkOrderDetailsAssetsProps {
  assets: WorkOrderDetailsAssetCardGroupProps['assets'];
  mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
  loading?: boolean;
  onUntag: (assetIds: string[]) => void;
  setAddAssetOpen: (open: boolean) => void;
}

const isMobile = isPlatform('mobile');

const WorkOrderDetailsAssets: React.FC<WorkOrderDetailsAssetsProps> = ({
  assets,
  mapStyles,
  loading = false,
  setAddAssetOpen,
  onUntag,
}) => {
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const deleteHeader = `Would you like to permanently untag ${
    selectedAssets.length > 1 ? 'these assets' : 'this asset'
  }?`;

  const handleAssetSelectionChange = (assetId: string, checked: boolean) => {
    if (checked) {
      setSelectedAssets((prevSelected) => [...prevSelected, assetId]);
    } else {
      setSelectedAssets((prevSelected) => prevSelected.filter((id) => id !== assetId));
    }
  };

  const deleteDismiss = () => {
    setShowConfirmDelete(false);
  };

  const deleteButtons = [
    { text: 'Cancel', role: 'cancel' },
    { text: 'Delete', role: 'destructive', handler: () => untagSelectedAssets() },
  ];

  const untagSelectedAssets = () => {
    onUntag(selectedAssets);
    setSelectedAssets([]);
  };

  const openTagAssetModal = () => {
    setAddAssetOpen(true);
  };

  return (
    <>
      <IonList className="ion-no-padding">
        <IonItemDivider
          mode="md"
          sticky={true}
          style={{
            '--padding-end': 0,
            '--inner-padding-end': 'calc(var(--ion-margin, 16px) * 0.25)',
          }}
        >
          <IonIcon icon={assetIcon} slot="start" />
          <IonLabel>
            <h2>Assets</h2>
          </IonLabel>
          {selectedAssets.length > 0 && (
            <IonButton
              slot="end"
              size="default"
              color="danger"
              fill="clear"
              onClick={() => setShowConfirmDelete(true)}
              style={{ fontSize: '0.8125rem' }}
            >
              Remove Selected
            </IonButton>
          )}
        </IonItemDivider>
        {loading ? (
          <WorkOrderDetailsAssetCardGroupSkeleton />
        ) : (
          <WorkOrderDetailsAssetCardGroup
            assets={assets}
            selectedAssets={selectedAssets}
            handleAssetSelectionChange={handleAssetSelectionChange}
            mapStyles={mapStyles}
          />
        )}
      </IonList>

      {/* TODO. Only show if asset URL is available */}
      <AddNewItemButton
        title="Tag an Asset"
        disabled={loading}
        onClick={() => openTagAssetModal()}
      />

      <IonAlert
        isOpen={showConfirmDelete && !isMobile}
        onDidDismiss={deleteDismiss}
        header="Delete"
        message={deleteHeader}
        buttons={deleteButtons}
      />
      <IonActionSheet
        isOpen={showConfirmDelete && isMobile}
        header={deleteHeader}
        onDidDismiss={deleteDismiss}
        buttons={deleteButtons}
      />
    </>
  );
};

export default WorkOrderDetailsAssets;
