import { gql, useQuery } from '@apollo/client';
import { useMessages } from 'hooks/useMessages';
import { Asset } from 'interfaces/Asset';

export type AssetInsightsAsset = Pick<
  Asset,
  'id' | 'name' | 'assetType' | 'location' | 'timeline' | 'photos'
>;

export const QUERY_ASSET = gql`
  query QueryAsset($assetId: ID!) {
    asset(id: $assetId) {
      id
      name
      assetType
      location {
        description
        geometry
      }
      timeline {
        workorders {
          nodes {
            id
            createdAt
            closedAt
            workorderNumber
            title
            status
            team {
              id
              name
            }
            assignee {
              id
              name
            }
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const useAssetInsights = (assetId: string) => {
  const { addMessage } = useMessages();

  const { data, loading, refetch } = useQuery<{ asset: AssetInsightsAsset }, { assetId: string }>(
    QUERY_ASSET,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { assetId },
      onError: () => addMessage('Unable to load asset.', 'danger'),
    }
  );

  return { asset: data?.asset || null, loading, refetch };
};
