import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import Settings from './Settings';

const SettingsRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      <Route path={`${path}`} component={Settings} exact={true} />
    </IonRouterOutlet>
  );
};

export { SettingsRoutes, Settings };
