import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AssetIcon from 'components/AssetIcon';
import Avatar from 'components/Avatar';
import InteractiveMap from 'components/InteractiveMap';
import { images, layers } from 'components/InteractiveMap/assetSymbology';
import ListSeparator from 'components/ListSeparator';
import SelectableAssetItem from 'components/SelectableAssetItem';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import { Asset } from 'interfaces/Asset';
import { MapStyles } from 'interfaces/Basemaps';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React, {
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';

import useTagAssetsModal, { GISProperties } from './useTagAssetsModal';

export type SelectedAsset = Pick<Asset, 'id' | 'name' | 'assetType'> & {
  location: Pick<Asset['location'], 'description' | 'geometry'>;
};

export interface TagAssetsModalProps {
  workorderId: string;
  mapStyles: MapStyles;
  open: boolean;
  /** Use ref to page element to get card style modals in iOS */
  pageRef?: RefObject<HTMLElement>;
  setOpen: (open: boolean) => void;
  onTag: (asset: SelectedAsset) => Promise<void>;
}

const useStyles = createUseStyles({
  segment: {
    '--background': 'var(--ion-item-background, var(--ion-background-color, #fff))',
  },
  footer: {
    '--padding-top': 'var(--ion-padding, 8px)',
    '--padding-bottom': 'var(--ion-padding, 8px)',
    '--padding-start': 'var(--ion-padding, 8px)',
    '--padding-end': 'var(--ion-padding, 8px)',
    '--background': 'var(--ion-item-background, var(--ion-background-color, #fff))',
  },
});

const TagAssetsModal = ({
  workorderId,
  mapStyles,
  open,
  pageRef,
  setOpen,
  onTag,
}: PropsWithChildren<TagAssetsModalProps>) => {
  const classes = useStyles();

  const [view, setView] = useState('list');

  const { workorderLocation, availableAssets, loading } = useTagAssetsModal({ workorderId });

  const [selectedAsset, setSelectedAsset] = useState<SelectedAsset | null>(null);

  const { results, query, setQuery } = useFuzzySearch({
    list: availableAssets,
    options: { keys: ['properties.name', 'properties.assetType'], threshold: 0.2 },
  });

  const listResults = useMemo(
    () => results.filter((asset) => asset.properties.distance && asset.properties.distance < 600),
    [results]
  );

  const handleOnSelectionChange = useCallback((selectedGeoJSON: GeoJSON.Feature | null) => {
    if (!selectedGeoJSON) {
      setSelectedAsset(null);
      return;
    }

    const { geometry, properties } = selectedGeoJSON as GeoJSON.Feature<
      GeoJSON.Geometry,
      GISProperties
    >;

    setSelectedAsset({
      id: properties.gisId,
      name: properties.name || '',
      assetType: properties.assetType || 'Other',
      location: {
        geometry: JSON.stringify(geometry),
        description: properties.location || '',
      },
    });
  }, []);

  const interactiveMap = useMemo(() => {
    if (!workorderLocation) return null;

    return (
      <InteractiveMap
        mapStyles={mapStyles}
        style={{ height: '100%', width: '100%', visibility: 'visible' }}
        isMoveable={true}
        initialPosition={{
          center: [workorderLocation.longitude, workorderLocation.latitude],
          zoom: 16,
        }}
        focusLocation={{
          center: [workorderLocation.longitude, workorderLocation.latitude],
        }}
        geoJSON={{
          features: results,
          layers,
          images,
          selectable: true,
          onGeoJSONSelectionChange: handleOnSelectionChange,
        }}
      />
    );
  }, [mapStyles, workorderLocation, results, handleOnSelectionChange]);

  useEffect(() => {
    setSelectedAsset(null);
  }, [view]);

  return (
    <>
      <IonModal
        isOpen={open}
        swipeToClose={true}
        onDidDismiss={() => setOpen(false)}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>

            <IonTitle>Select Asset</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar
              mode="md"
              debounce={500}
              onIonChange={({ target }: any) => {
                setQuery(target.value);
              }}
            />
          </IonToolbar>
          <IonSegment
            className={classes.segment}
            scrollable={false}
            mode="md"
            value={view}
            onIonChange={(e) => setView(e.detail.value!)}
          >
            <IonSegmentButton value="list">Nearby</IonSegmentButton>
            <IonSegmentButton value="map">Map</IonSegmentButton>
          </IonSegment>
          <ListSeparator height="1" />
        </IonHeader>
        <IonContent scrollY={view !== 'map'}>
          {(loading || !availableAssets) && (
            <IonLoading isOpen={true} message={'Loading GIS database...'} duration={0} />
          )}
          {view === 'list' && (
            <IonList lines="full" className="ion-no-padding">
              {!loading && !listResults.length && (
                <IonLabel className="ion-margin">
                  <h3 className="ion-text-center">
                    No nearby assets{query ? ` matching search "${query}"` : ''}
                  </h3>
                </IonLabel>
              )}
              {listResults.map((asset) => {
                const isSelected = selectedAsset?.id === asset.properties.gisId;

                return (
                  <SelectableAssetItem
                    key={asset.properties.gisId}
                    title={asset.properties.assetType || 'Other'}
                    subtitle={asset.properties.name}
                    StartSlot={(props) => (
                      <Avatar {...props}>
                        <AssetIcon
                          assetType={asset.properties.assetType || 'Other'}
                          iconStyle="light"
                          color={isSelected ? '#1AC7BB' : '#BD975E'}
                        />
                      </Avatar>
                    )}
                    EndSlot={
                      asset.properties.distance
                        ? (props) => (
                            <IonLabel {...props}>
                              <p>{Math.round(asset.properties.distance!)} feet</p>
                            </IonLabel>
                          )
                        : undefined
                    }
                    selected={isSelected}
                    className={isSelected ? '--background-hover' : undefined}
                    onClick={() => {
                      setSelectedAsset(
                        isSelected
                          ? null
                          : {
                              id: asset.properties.gisId,
                              name: asset.properties.name || '',
                              assetType: asset.properties.assetType || 'Other',
                              location: {
                                geometry: JSON.stringify(asset.geometry),
                                description: asset.properties.location || '',
                              },
                            }
                      );
                    }}
                  />
                );
              })}
            </IonList>
          )}
          {view === 'map' && workorderLocation && availableAssets && (
            <IonSlide className="swiper-no-swiping">{interactiveMap}</IonSlide>
          )}
        </IonContent>

        <IonFooter>
          {Boolean(selectedAsset) && (
            <IonToolbar style={{ '--background': '#15A299' }}>
              <p className="ion-text-center" style={{ fontSize: '0.9375rem', color: '#FFF' }}>
                {[selectedAsset?.assetType, selectedAsset?.name].filter(Boolean).join(' - ')}
              </p>
            </IonToolbar>
          )}
          <IonToolbar color="primary">
            <IonButton
              color="primary"
              expand="full"
              size="large"
              className="ion-no-margin"
              disabled={!selectedAsset}
              onClick={async () => {
                if (!selectedAsset) return;

                try {
                  await onTag(selectedAsset);
                } catch (_err) {
                  // NOOP
                }

                setOpen(false);
              }}
              style={{ fontSize: '1.2rem' }}
            >
              Tag
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default TagAssetsModal;
