import {
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import clsx from 'clsx';
import IconBadge from 'components/IconBadge';
import Indicator from 'components/Indicator';
import StaticMap from 'components/StaticMap';
import StatusTracker from 'components/StatusTracker';
import { parseAddress } from 'hooks/useLocation';
import { useStatus } from 'hooks/useStatus';
import { useWorkOrderTracking } from 'hooks/useWorkOrderTracking';
import { Account } from 'interfaces/Account';
import { Attachment } from 'interfaces/Attachment';
import { Category } from 'interfaces/Category';
import { WorkOrder } from 'interfaces/WorkOrder';
import {
  chatboxEllipsesOutline as commentsIcon,
  imageOutline as imageIcon,
  personCircleOutline as personIcon,
  timeOutline as timeIcon,
} from 'ionicons/icons';
import React, { RefObject, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { prettyDate } from 'utils/prettyDate';

const textOverflow = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const gutter = {
  marginBottom: 'calc(var(--ion-margin, 16px) * 0.5) !important',
};

const extendedGutter = {
  marginBottom: 'var(--ion-margin, 16px) !important',
};

const leadingIcon = {
  display: 'flex',
  alignItems: 'center',
  '& ion-icon': {
    flex: '0 0 auto',
    marginRight: 6,
    fontSize: 20,
  },
};

export const useStyles = createUseStyles({
  root: {
    position: 'relative',
  },
  card: {
    minWidth: 250,
  },
  item: {
    '--padding-start': '0px',
    '--inner-padding-end	': '8px',
    '& ion-label': {
      margin: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: { top: 10, bottom: 10, left: 10, right: 0 },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    extend: [textOverflow],
  },
  title: {
    extend: [textOverflow, gutter],
    'p&': {
      fontSize: '1rem',
    },
  },
  address: {
    extend: [textOverflow, extendedGutter],
    color: 'var(--ion-color-medium)',
  },
  assignee: {
    extend: [gutter, leadingIcon],
    margin: 0,
  },
  timestamp: {
    extend: leadingIcon,
    flex: '0 0 auto',
    margin: 0,
  },
  category: {
    marginLeft: '8px !important',
    flex: '1 1 0%',
  },
  status: (props: any) => ({
    extend: leadingIcon,
    margin: 0,
    color: props.statusColor,
  }),
  badges: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginLeft: 8,
    },
  },
  noSpace: {
    padding: 0,
    margin: 0,
  },
});

type WorkOrderProps = Pick<
  WorkOrder,
  'id' | 'createdAt' | 'location' | 'status' | 'titleDisplay'
> & {
  assignee?: Pick<Account, 'name'>;
  category?: Pick<Category, 'name'>;
  attachments?: Pick<Attachment, 'attachmentType'>[];
} & Partial<Pick<WorkOrder, 'comments' | 'hasAlert'>>;

export interface WorkOrderItemProps {
  workorder: WorkOrderProps;
  disableSliding?: boolean;
  showMap?: boolean;
  pageRef?: RefObject<HTMLElement>;
}

const WorkOrderItem: React.FC<WorkOrderItemProps> = ({
  workorder,
  disableSliding = false,
  showMap = true,
  pageRef,
}) => {
  const {
    id,
    assignee,
    attachments,
    category,
    comments,
    createdAt,
    hasAlert,
    location,
    status,
    titleDisplay,
  } = workorder;
  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);

  const { color: statusColor, label: statusLabel, icon: statusIcon } = useStatus(status);
  const classes = useStyles({ statusColor, hasAlert });

  const { changeStatus } = useWorkOrderTracking({ workorderId: id });

  const slideAction = (callback: (id: string) => void) => {
    callback(id);
    if (itemSlidingRef.current) {
      itemSlidingRef.current.close();
    }
  };

  return (
    <div className={classes.root}>
      <IonCard key={id} className={classes.card}>
        <IonItemSliding ref={itemSlidingRef} disabled={disableSliding}>
          <StatusTracker
            pageRef={pageRef}
            onStatusChange={changeStatus}
            status={status}
            renderStatusItem={({ onClick }) => (
              <IonItemOptions side="start" onIonSwipe={() => slideAction(onClick)}>
                <IonItemOption
                  style={{ '--background': statusColor }}
                  expandable={true}
                  onClick={() => slideAction(onClick)}
                >
                  Update Status
                </IonItemOption>
              </IonItemOptions>
            )}
          />
          <IonItem
            lines="none"
            className={classes.item}
            routerLink={`/workorders/details/${id}`}
            detail={false}
          >
            <Indicator color={statusColor} size={4} isVertical={true} />
            <IonLabel>
              <IonGrid className={classes.noSpace}>
                <IonRow>
                  {showMap && (
                    <IonCol size="3" className={classes.noSpace}>
                      {location && (
                        <StaticMap
                          latitude={location.latitude}
                          longitude={location.longitude}
                          height={200}
                          width={180}
                          markerColor={statusColor.substring(1)}
                          zoom={10}
                          style={{ height: '100%' }}
                        />
                      )}
                    </IonCol>
                  )}
                  <IonCol size={showMap ? '9' : '12'}>
                    <div className={classes.content}>
                      <div className={classes.info}>
                        <p className={classes.title}>{titleDisplay}</p>
                        {location && (
                          <p className={classes.address}>{parseAddress(location.address)}</p>
                        )}
                        <p className={classes.assignee}>
                          <IonIcon icon={personIcon} slot="start" />
                          {assignee ? assignee.name : 'Not Assigned'}
                        </p>
                      </div>
                      <div
                        className={clsx(classes.flexRow, 'ion-margin-bottom')}
                        style={textOverflow}
                      >
                        <p className={classes.timestamp}>
                          <IonIcon icon={timeIcon} slot="start" />
                          {createdAt ? prettyDate(createdAt) : 'Unavailable'}
                        </p>
                        {category && (
                          <p className={clsx('ion-text-nowrap', 'ion-text-end', classes.category)}>
                            <IonText color="medium">{category.name}</IonText>
                          </p>
                        )}
                      </div>
                      <div className={classes.flexRow}>
                        <p className={classes.status}>
                          <IonIcon icon={statusIcon} slot="start" /> {statusLabel}
                        </p>
                        <div className={classes.badges}>
                          {comments && (
                            <IconBadge
                              icon={commentsIcon}
                              badgeContent={comments.length}
                              color="light"
                            />
                          )}
                          {attachments && (
                            <IconBadge
                              icon={imageIcon}
                              badgeContent={
                                (attachments || []).filter((a) => a.attachmentType === 'photo')
                                  .length
                              }
                              color="light"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonLabel>
          </IonItem>
        </IonItemSliding>
      </IonCard>
    </div>
  );
};

export default WorkOrderItem;
