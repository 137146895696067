import 'mapbox-gl/dist/mapbox-gl.css';

import { MapStyles } from 'interfaces/Basemaps';
import mapboxgl, {
  EventData,
  GeoJSONSource,
  LngLat,
  MapMouseEvent,
  MapTouchEvent,
  MapboxOptions,
} from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import { v4 as uuidv4 } from 'uuid';

import layers from './layers';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const { REACT_APP_MAPBOX_TOKEN: accessToken } = process.env;
const heatMapSourceID = `heatmap-${uuidv4()}`;

type RootElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface ReactResizeDetectorDimensions {
  /**
   * Map height as determined by the resize detector.
   * Do not use to set component height. Use `style` or `className` instead.
   */
  height: number;
  /**
   * Map width as determined by the resize detector.
   * Do not use to set component width. Use `style` or `className` instead.
   */
  width: number;
}

export interface FeatureProperties {
  /** Numeric value greater than 0 to apply relative feature weight in heat map.  */
  value: number;
}

export interface HeatMapProps extends RootElementProps, Partial<ReactResizeDetectorDimensions> {
  readonly mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
  readonly initialPosition?: Partial<
    Pick<MapboxOptions, 'center' | 'zoom' | 'bearing' | 'pitch' | 'bounds'>
  >;
  readonly features: GeoJSON.FeatureCollection<GeoJSON.Point, FeatureProperties>;
  readonly minZoom?: number;
  readonly maxZoom?: number;
  /** Callback when map position has moved (map zoomed or panned) */
  onMapMoved?: (position: {
    center: { lng: number; lat: number };
    zoom: number;
    centerChanged: boolean;
  }) => void;
}

const mapStyle = ({ lightUrl, darkUrl }: Pick<MapStyles, 'lightUrl' | 'darkUrl'>, dark = false) =>
  dark ? darkUrl : lightUrl;

const HeatMap: React.FC<HeatMapProps> = ({
  mapStyles,
  initialPosition = {},
  features,
  minZoom,
  maxZoom,
  height,
  width,
  onMapMoved,
  ...rootElementProps
}) => {
  const readOnlyProps = useRef<Partial<HeatMapProps>>({
    mapStyles,
    initialPosition,
    minZoom,
    maxZoom,
  });
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const mapInstance = useRef<mapboxgl.Map>();
  const lastMovedCoords = useRef<LngLat>();
  const [loading, setLoading] = useState(true);

  // Heat map data source is dynamically added to the map style when the component mounts.
  // `sourceLoaded` is `null` if source has never loaded and updated with ticks at time of load
  // for each successive load (allows for effects to run on each load).
  const [sourceLoaded, setSourceLoaded] = useState<number | null>(null);

  useEffect(() => {
    if (!mapContainer.current) {
      throw new Error('Ref to map container element must be assigned before rendering');
    }

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    const map = (mapInstance.current = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken,
      style: readOnlyProps.current.mapStyles
        ? mapStyle(readOnlyProps.current.mapStyles, prefersDark.matches)
        : undefined,
      minZoom: readOnlyProps.current.minZoom,
      maxZoom: readOnlyProps.current.maxZoom,
      attributionControl: false,
      scrollZoom: { around: 'center' } as any, // Incorrectly typed in @types/mapbox-gl
      touchZoomRotate: { around: 'center' } as any, // Incorrectly typed in @types/mapbox-gl
      ...readOnlyProps.current.initialPosition,
    }));

    map.on('load', () => setLoading(false));
    map.on('style.load', () => {
      const emptyGeoJSONFeature: GeoJSON.Feature<GeoJSON.Point> = {
        type: 'Feature',
        properties: null,
        geometry: { type: 'Point', coordinates: [] },
      };

      // Create source with empty data to prevent any dependencies on the data in this effect.
      // Data can be set on the source (ie. `source.setData(data)`) elsewhere once `sourceLoaded` is `true`.
      map.addSource(heatMapSourceID, { type: 'geojson', data: emptyGeoJSONFeature });
      layers.forEach((layer) => map.addLayer({ ...layer, source: heatMapSourceID }));
      setSourceLoaded(new Date().valueOf());
    });

    const onMediaChange = (e: any) =>
      readOnlyProps.current.mapStyles &&
      map.setStyle(mapStyle(readOnlyProps.current.mapStyles, e.matches));
    prefersDark.addListener(onMediaChange);

    return () => {
      setSourceLoaded(null);
      prefersDark.removeListener(onMediaChange);
      map.remove();
    };
  }, []);

  useEffect(() => {
    const heatMapSource =
      sourceLoaded && (mapInstance.current?.getSource(heatMapSourceID) as GeoJSONSource);
    if (!heatMapSource) return;

    // Weight features from 0 - 1 based on `value`
    const maxValue = features.features.reduce(
      (max, { properties: { value } }) => (value > max ? value : max),
      0
    );
    const weightedFeatures = features.features.map((feature) => ({
      ...feature,
      properties: {
        ...feature.properties,
        weight: feature.properties.value / maxValue,
      },
    }));

    heatMapSource.setData({ type: 'FeatureCollection', features: weightedFeatures });
  }, [sourceLoaded, features]);

  // Hook `onMapMoved` callback to Mapbox `Map` 'moveend' events
  useEffect(() => {
    if (loading || !(mapInstance.current && onMapMoved)) return;

    const onMoveEnd = ({
      target,
      avoidSideEffects = false, // Internally triggered events that should not invoke `onMapMoved`
    }: (MapMouseEvent | MapTouchEvent) & EventData) => {
      if (avoidSideEffects) return;
      const center = target.getCenter();
      const zoom = target.getZoom();
      const centerChanged =
        !!lastMovedCoords.current && center.distanceTo(lastMovedCoords.current) > 0;
      lastMovedCoords.current = center;
      onMapMoved({ center, zoom, centerChanged });
    };

    const map = mapInstance.current.on('moveend', onMoveEnd);

    return () => {
      map.off('moveend', onMoveEnd);
    };
  }, [loading, onMapMoved]);

  // Resize Mapbox `Map` on container size changes
  // `height` and `width` changes provided by `withResizeDetector`
  // https://docs.mapbox.com/mapbox-gl-js/api/map/#map#resize
  useEffect(() => {
    if (!(mapInstance.current && height && width)) return;
    mapInstance.current.resize();
  }, [height, width]);

  // Remove `targetRef` added by resize detector. Creates React warning if bound to `div` el.
  const { targetRef, ...divProps } = rootElementProps as any;

  return <div {...divProps} ref={mapContainer} />;
};

export default withResizeDetector(HeatMap);
