import {
  IonCol,
  IonItem,
  IonListHeader,
  IonNote,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import Notifications from 'components/Notifications';
import { useIdentityUser } from 'hooks/useIdentityUser';
import { useWorkspace } from 'hooks/useWorkspace';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  email: {
    display: 'block',
    marginTop: 4,
    'ion-menu.md &': {
      lineHeight: '24px',
    },
  },
  workspace: {
    marginTop: 'calc(var(--ion-margin, 16px) * 0.5)',
    marginBottom: 'calc(var(--ion-margin, 16px) * 0.5)',
  },
  select: {
    maxWidth: 'fit-content',
    '&::part(icon)': {
      marginLeft: 'var(--ion-margin, 16px)',
    },
    '&.select-disabled': {
      // Disabled workspace select looks like enabled but without the icon and hover styles
      opacity: 1,
      '&::part(icon)': {
        display: 'none',
      },
    },
  },
  popover: {
    '& ion-list': {
      padding: 0,
    },
  },
  notifications: {
    '.ios &': { marginTop: 'var(--ion-margin, 16px)' },
    marginRight: 'var(--ion-margin, 16px)',
  },
});

const MenuTopPanel: React.FC = () => {
  const classes = useStyles();
  const user = useIdentityUser();
  const { workspaces, workspace, activateWorkspace } = useWorkspace();

  return (
    <>
      <IonRow>
        <IonCol size="10" style={{ padding: 0 }}>
          <IonListHeader>{user.name}</IonListHeader>
          <IonNote className={classes.email}>{user.email}</IonNote>
        </IonCol>
        <IonCol size="2" style={{ padding: 0 }}>
          <Notifications className={classes.notifications} />
        </IonCol>
      </IonRow>
      <IonItem className={classes.workspace} lines="none">
        <IonSelect
          interface="popover"
          interfaceOptions={{ cssClass: classes.popover }}
          value={workspace.id}
          onIonChange={(e) => activateWorkspace(e.detail.value)}
          disabled={!(workspaces.length > 1)}
          className={classes.select}
        >
          {workspaces.map((w) => (
            <IonSelectOption key={w.id} value={w.id}>
              {w.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </>
  );
};

export default MenuTopPanel;
