import { JSX } from '@ionic/core/components';
import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import { checkmarkOutline as selectedIcon } from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';

export interface SelectionItemProps extends React.HTMLAttributes<HTMLIonItemElement> {
  title: string;
  subtitle?: string;
  selected?: boolean;
}

const useStyles = createUseStyles({
  selectionItem: {
    '--inner-padding-top': '12px',
    '--inner-padding-bottom': '12px',
    '&:not(.in-list)': {
      margin: {
        top: 15,
        bottom: 15,
      },
    },
    cursor: 'pointer',
  },
});

const SelectionItem: React.FC<SelectionItemProps & JSX.IonItem> = ({
  title,
  subtitle,
  className,
  lines = 'full',
  selected = false,
  ...inputProps
}) => {
  const classes = useStyles();

  return (
    <IonItem
      {...inputProps}
      lines={lines}
      className={clsx(classes.selectionItem, className)}
      button={!(inputProps.disabled || false)}
    >
      <Avatar alt={title} slot="start" />
      <IonLabel>
        <h2>{title}</h2>
        {subtitle && <h3>{subtitle}</h3>}
      </IonLabel>
      {selected && <IonIcon slot="end" icon={selectedIcon} />}
    </IonItem>
  );
};

export default SelectionItem;
