import { InputChangeEventDetail } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Avatar from 'components/Avatar';
import { MagicField, MagicForm } from 'components/MagicForm';
import UnsafeArea from 'components/UnsafeArea';
import { useReporters } from 'hooks/useReporters';
import { useWorkspace } from 'hooks/useWorkspace';
import { Reporter } from 'interfaces/Reporter';
import {
  atOutline as emailIcon,
  closeOutline as closeIcon,
  personCircleOutline as nameIcon,
  phonePortraitOutline as phoneIcon,
} from 'ionicons/icons';
import React, { PropsWithChildren, RefObject, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { prettyPhone } from 'utils/prettyPhone';

export interface RenderProps<T> {
  onClick?: any;
  value?: T;
}

export interface ReporterModalProps {
  value?: Reporter;
  label?: string;
  /** Use ref to page element to get card style modals in iOS */
  pageRef?: RefObject<HTMLElement>;
  onSelect: (props: Reporter) => void;
  renderDisplayItem?: (props: RenderProps<Reporter>) => JSX.Element;
}

const useStyles = createUseStyles({
  form: {
    height: '100%',
    '& ion-searchbar': {
      padding: '0 !important',
      marginTop: 'var(--ion-margin, 16px)',
    },
  },
});

const ReporterModal = ({
  value,
  pageRef,
  onSelect,
  renderDisplayItem,
}: PropsWithChildren<ReporterModalProps>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState<Reporter>();
  const { workspace } = useWorkspace();
  const classes = useStyles();
  const { organizationId } = workspace;
  const [query, setQuery] = useState<string>(value?.name || '');
  const { reporters } = useReporters(organizationId, {
    query,
    size: 3,
    skip: !query,
  });
  const [popoverState, setPopoverState] = React.useState<{
    showPopup: boolean;
    event: CustomEvent<InputChangeEventDetail> | undefined;
  }>({
    showPopup: false,
    event: undefined,
  });

  const onSubmit = (reporter: Reporter) => {
    onSelect({ ...reporter, name: query });
    setIsModalOpen(false);
  };

  const onSearchSelect = (reporter: Reporter) => {
    setPopoverState({ showPopup: false, event: undefined });
    setSelectedSearch(reporter);
    setQuery(reporter.name);
  };

  const defaultValues = selectedSearch || value;
  const reporterSearchResults = reporters || [];

  return (
    <>
      {renderDisplayItem &&
        renderDisplayItem({
          onClick: setIsModalOpen.bind(null, true),
          value,
        })}

      <IonModal
        onDidDismiss={() => {
          setIsModalOpen(false);
          setSelectedSearch(undefined);
        }}
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={setIsModalOpen.bind(null, false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>Resident</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <MagicForm
            className={classes.form}
            submitText="Save"
            onSubmit={onSubmit}
            // Replace `null` phone values with undefined. Magic phone field only accepts string or undefined.
            defaultValues={{
              ...defaultValues,
              name: defaultValues?.name || query,
              phone: defaultValues?.phone || undefined,
              email: defaultValues?.email || undefined,
              secondaryPhone: defaultValues?.secondaryPhone || undefined,
            }}
            subtitle="Resident that initially reported the work order"
          >
            <MagicField
              name="name"
              icon={nameIcon}
              value={query}
              onIonChange={(e: any) => {
                setQuery(e.target.value);
                setPopoverState({ showPopup: true, event: e });
              }}
              onIonBlur={() => setPopoverState({ showPopup: false, event: undefined })}
              // Popover when dismissed reverts focus to the top input bug in ionic framework
              placeholder="Resident Name"
              rules={{
                required: 'You must provide a resident name.',
              }}
            />
            <IonPopover
              mode="md"
              showBackdrop={false}
              keyboardClose={false}
              event={popoverState.event}
              isOpen={popoverState.showPopup}
              onDidDismiss={() => setPopoverState({ showPopup: false, event: undefined })}
            >
              {reporterSearchResults.map((reporter) => (
                <IonItem
                  key={reporter.id}
                  button={true}
                  onClick={() => onSearchSelect(reporter)}
                  // Prevent ionBlur event from firing until after the reporter is selected
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <Avatar alt={reporter.name} slot="start" />
                  <IonLabel>
                    <h3>{reporter.name}</h3>
                    <p>
                      {reporter.email}
                      {reporter.phone && ` ・ ${prettyPhone(reporter.phone)}`}
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
            </IonPopover>
            <MagicField
              name="email"
              type="email"
              inputmode="text"
              icon={emailIcon}
              placeholder="Email Address"
            />
            <MagicField
              name="phone"
              fieldType="phone"
              icon={phoneIcon}
              placeholder="Phone Number"
            />
            <MagicField
              name="secondaryPhone"
              fieldType="phone"
              icon={phoneIcon}
              placeholder="Secondary Phone Number"
            />
          </MagicForm>
        </IonContent>
        <UnsafeArea position="bottom" color="primary" />
      </IonModal>
    </>
  );
};

export default ReporterModal;
