import { useWorkspace } from 'hooks/useWorkspace';
import React from 'react';

import { useInAppNotifications } from './useInAppNotifications';

/**
 * Does not render any UI.
 * Displays toast for incoming notifications for workspace account (GraphQL subscription).
 * Toast dismisses after 10 sec.
 * Notifications can be navigated to if they include a navigable reference.
 * Notification marked as read on toast dismiss.
 */
const InAppNotifications: React.FC = () => {
  const { workspace } = useWorkspace();
  useInAppNotifications({ accountId: workspace.operatorId });
  return null;
};

export default InAppNotifications;
