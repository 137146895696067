import { IonFooter, IonHeader, IonToolbar } from '@ionic/react';
import React from 'react';

export interface UnsafeAreaProps {
  position: 'top' | 'bottom';
  color?: string;
}

/**
 * Provide spacer for top or bottom unsafe areas.
 * Unsafe areas are notch areas on iOS where content should not be rendered.
 */
const UnsafeArea: React.FC<UnsafeAreaProps> = ({ position, color }) => {
  switch (position) {
    case 'top':
      return (
        <IonHeader>
          <IonToolbar
            color={color}
            style={{
              '--min-height': 0,
              '--padding-top': 0,
              '--padding-bottom': 0,
              '--border-width': 0,
            }}
          />
        </IonHeader>
      );
    case 'bottom':
      return (
        <IonFooter>
          <IonToolbar
            color={color}
            style={{
              '--min-height': 0,
              '--padding-top': 0,
              '--padding-bottom': 0,
              '--border-width': 0,
            }}
          />
        </IonFooter>
      );
    default:
      return null;
  }
};

export default UnsafeArea;
