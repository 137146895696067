import { IonToast, ToastButton } from '@ionic/react';
import { useMessages } from 'hooks/useMessages';
import { Message } from 'interfaces/Message';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React from 'react';

const toastButtons: ToastButton[] = [
  {
    role: 'cancel',
    icon: closeIcon,
  },
];

const MessagesDisplay: React.FC = () => {
  const { messages, clearMessage, display } = useMessages();

  return (
    <>
      {messages.map((message: Message) => (
        <IonToast
          key={message.timestamp}
          color={message.color || 'toast'}
          isOpen={display}
          onDidDismiss={() => clearMessage(message.timestamp)}
          message={message.content}
          position="bottom"
          mode="md"
          translucent={true}
          buttons={toastButtons}
          duration={10000}
        />
      ))}
    </>
  );
};

export default MessagesDisplay;
