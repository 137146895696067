import { AccountAction } from 'interfaces/AccountAction';
import { barChartOutline, buildOutline, carOutline, peopleOutline } from 'ionicons/icons';

export const navigation = [
  {
    title: 'Work Orders',
    url: '/workorders',
    iosIcon: buildOutline,
    mdIcon: buildOutline,
    requiredActions: [AccountAction.ListWorkOrders, AccountAction.GetWorkOrder],
  },
  {
    title: 'Equipment',
    url: '/equipment',
    iosIcon: carOutline,
    mdIcon: carOutline,
  },
  {
    title: 'Accounts',
    url: '/accounts',
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
    requiredActions: [AccountAction.ListAccounts, AccountAction.GetAccount],
  },
  {
    title: 'Reports',
    url: '/reports',
    iosIcon: barChartOutline,
    mdIcon: barChartOutline,
  },
  // {
  //   title: 'Settings',
  //   url: '/settings',
  //   iosIcon: settingsOutline,
  //   mdIcon: settingsOutline,
  // },
];
