import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AddressSearchBar from 'components/AddressSearchBar';
import GeolocateButton from 'components/GeolocateButton';
import InteractiveMap, { MapMarker } from 'components/InteractiveMap';
import MapPopup from 'components/MapPopup';
import WorkOrderItem from 'components/WorkOrderItem';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { useMapStyles } from 'hooks/useMapStyles';
import { useMessages } from 'hooks/useMessages';
import { useStatus as fetchStatus } from 'hooks/useStatus';
import { useWorkOrders } from 'hooks/useWorkOrders';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { MapContext } from 'interfaces/Basemaps';
import { Location } from 'interfaces/Location';
import { WorkOrder } from 'interfaces/WorkOrder';
import { add } from 'ionicons/icons';
import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Map: React.FC = () => {
  const pageRef = useRef<HTMLElement>(null);

  const history = useHistory();

  const [activeItem, setActiveItem] = useState<WorkOrder | null>(null);
  const { workspace } = useWorkspace();
  const { center, zoom } = workspace.geography;

  const mapStyles = useMapStyles({
    organizationId: workspace.organizationId,
    context: MapContext.WorkOrdersMap,
  });

  const { addMessage } = useMessages();

  const { workorders } = useWorkOrders({
    organizationId: workspace.organizationId,
    active: true,
  });

  const [addressMatchLocation, setAddressMatchLocation] = useState<Location | null>(null);

  const onSelectedMarker = (marker: MapMarker | null) => {
    setAddressMatchLocation(null);

    if (!marker) {
      setActiveItem(null);
      return;
    }
    const selected = workorders.find((workorder) => workorder.id === marker.id);
    setActiveItem(selected || null);
  };

  const markers: MapMarker[] = useMemo(() => {
    return workorders.reduce<MapMarker[]>(
      (acc, workorder) =>
        workorder.location
          ? [
              ...acc,
              {
                id: workorder.id,
                location: [workorder.location.longitude, workorder.location.latitude],
                color:
                  activeItem?.id === workorder.id
                    ? 'var(--ion-color-highlight)'
                    : fetchStatus(workorder.status).color,
              },
            ]
          : acc,
      []
    );
  }, [activeItem, workorders]);

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Work Orders</IonTitle>
        </IonToolbar>
        <AddressSearchBar
          proximity={center}
          onSelectResult={(loc) => {
            setActiveItem(null);
            setAddressMatchLocation({ zoom: 16, ...loc });
          }}
        />
      </IonHeader>

      <IonContent scrollY={false}>
        <AuthorizeRequired required={[AccountAction.GetWorkOrder, AccountAction.ListWorkOrders]}>
          <>
            <GeolocateButton
              vertical="top"
              horizontal="end"
              slot="fixed"
              onGeolocate={(loc) =>
                setAddressMatchLocation({ zoom: 16, ...loc, address: 'Current Location' })
              }
              onError={({ message }) => addMessage(message, 'danger')}
              renderLoadingDisplay={(isLoading) => <IonLoading isOpen={isLoading} />}
            />
            {activeItem && (
              <MapPopup>
                <WorkOrderItem workorder={activeItem} showMap={false} pageRef={pageRef} />
              </MapPopup>
            )}
            <InteractiveMap
              mapStyles={mapStyles}
              style={{ height: '100%' }}
              initialPosition={{ center, zoom }}
              isMoveable={true}
              markers={markers}
              focusLocation={
                addressMatchLocation
                  ? {
                      center: [addressMatchLocation.longitude, addressMatchLocation.latitude],
                      zoom: addressMatchLocation.zoom,
                      title: addressMatchLocation.address,
                    }
                  : undefined
              }
              moveFocusLocationWithMap={false}
              onSelectedMarker={onSelectedMarker}
            />
          </>
        </AuthorizeRequired>

        <AuthorizeRequired required={[AccountAction.CreateWorkOrder]}>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => history.push(`/workorders/create`)}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </AuthorizeRequired>
      </IonContent>
    </IonPage>
  );
};

export default Map;
