import { useMutation } from '@apollo/client';
import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonNote,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { MagicField, MagicForm } from 'components/MagicForm';
import SearchModal from 'components/SearchModal';
import SelectionItem from 'components/SelectionItem';
import UnsafeArea from 'components/UnsafeArea';
import { usePermissions } from 'containers/AuthorizeRequired';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { Account } from 'interfaces/Account';
import { AccountAction } from 'interfaces/AccountAction';
import { AccountKind } from 'interfaces/AccountKind';
import { Team } from 'interfaces/Team';
import {
  atOutline as emailIcon,
  closeOutline as closeIcon,
  logoUsd as rateIcon,
  peopleCircleOutline as teamIcon,
  phonePortraitOutline as phoneIcon,
} from 'ionicons/icons';
import { TRANSFER_TEAM, TransferTeamInput, TransferTeamResponse } from 'pages/Accounts/graphql';
import React, { RefObject, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { logException } from 'services/logger';
import { prettyDate } from 'utils/prettyDate';
import { prettyPhone } from 'utils/prettyPhone';

type TeamAssignment = Pick<Team, 'id' | 'name'>;

const useStyles = createUseStyles({
  root: {
    '--padding-top': 'var(--ion-margin, 16px)',
    '--padding-bottom': 0,
    '--ion-grid-padding': 0,
    '& ion-grid': {
      paddingBottom: 'calc(var(--ion-margin, 16px) * 0.5)',
      borderBottom:
        'dashed 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))',
    },
    '& ion-col': {
      paddingLeft: 0,
    },
  },
  iconColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 'var(--ion-margin, 16px)',
    fontSize: '0.875rem',
    '& ion-icon': {
      marginRight: 'var(--ion-margin, 16px)',
      fontSize: '1.2rem',
      color: 'rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)',
    },
  },
  chips: {
    paddingTop: 'calc(var(--ion-margin, 16px) * 0.5)',
    paddingBottom: 'calc(var(--ion-margin, 16px) * 0.5)',
    '& ion-chip:first-child': {
      marginInlineStart: 0,
    },
  },
  searchModalItem: {
    border: '1px solid var(--ion-color-light)',
  },
  form: {
    height: '100%',
  },
});

export interface AccountSummaryProps {
  account: Pick<
    Account,
    'kind' | 'activatedAt' | 'createdAt' | 'email' | 'phone' | 'team' | 'rate' | 'id'
  >;
  disabled?: boolean;
  /** Use ref to page element to get card style modals in iOS */
  pageRef?: RefObject<HTMLElement>;
  onSetRate: (value: number) => Promise<any>;
}

export const useTeam = (accountId: string) => {
  const { addMessage } = useMessages();
  const [mutating, setMutating] = useState(false);
  const [error, setError] = React.useState('');
  const [transfer, { loading: transferTeamLoading }] = useMutation<TransferTeamResponse>(
    TRANSFER_TEAM
  );

  const transferTeam = async (teamId: string): Promise<TransferTeamResponse | undefined> => {
    const transferTeamInput: TransferTeamInput = {
      accountId,
      teamId,
    };
    setMutating(true);
    try {
      const r = await transfer({ variables: { input: transferTeamInput } });
      setMutating(false);
      return r.data || undefined;
    } catch (err) {
      onError(err as Error, 'Unable to transfer team');
      setMutating(false);
    }
  };

  const onError = (err: Error, alertMessage: string) => {
    addMessage(alertMessage, 'danger');
    setError(err.message);
    logException(err);
  };

  return {
    error,
    mutating,
    transferTeam,
    transferTeamLoading,
  };
};

const AccountSummary: React.FC<AccountSummaryProps> = ({
  account,
  disabled = false,
  pageRef,
  onSetRate,
}) => {
  const classes = useStyles();
  const { workspace } = useWorkspace();
  const [isOpen, setIsOpen] = useState(false);
  const [mutating, setMutating] = useState(false);
  const { teams } = workspace;
  const { kind, activatedAt, createdAt, email, phone, team, rate, id } = account;
  const { transferTeam, mutating: transferTeamLoading } = useTeam(id);

  const canTransferTeam = usePermissions({ required: [AccountAction.TransferTeam] }) && !disabled;
  const canSetAccountRate =
    usePermissions({ required: [AccountAction.SetAccountRate] }) && !disabled;

  const onSubmit = async (data: any) => {
    setMutating(true);
    try {
      await onSetRate(data.rate);
      setMutating(false);
      setIsOpen(false);
    } catch (_err) {
      setMutating(false);
    }
  };

  return (
    <>
      <IonItem className={classes.root} lines="full">
        <div style={{ flex: '1 1 0%' }}>
          <IonGrid>
            <IonRow>
              <IonCol style={{ padding: 0 }}>
                <IonNote style={{ fontSize: '0.875rem' }}>
                  {kind === AccountKind.User &&
                    (activatedAt
                      ? `Joined ${prettyDate(activatedAt)}`
                      : `Invited ${prettyDate(createdAt)}`)}
                  {kind === AccountKind.Virtual && `Created ${prettyDate(createdAt)}`}
                </IonNote>
              </IonCol>
            </IonRow>
            <IonRow style={{ paddingTop: 'calc(var(--ion-margin, 16px) * 0.5)' }}>
              {kind === AccountKind.User && (
                <>
                  <IonCol className={classes.iconColumn} size="auto">
                    <IonIcon icon={emailIcon} />
                    <IonLabel>{email}</IonLabel>
                  </IonCol>
                  {phone && (
                    <IonCol className={classes.iconColumn} size="auto">
                      <IonIcon icon={phoneIcon} />
                      <IonLabel>{prettyPhone(phone)}</IonLabel>
                    </IonCol>
                  )}
                </>
              )}
            </IonRow>
          </IonGrid>
          <div className={classes.chips}>
            <SearchModal<TeamAssignment>
              pageRef={pageRef}
              keys={['name']}
              label="Select Team"
              items={teams}
              onSelect={({ id: teamId }) => transferTeam(teamId)}
              value={team}
              renderDisplayItem={({ onClick }) => (
                <IonChip
                  color="primary"
                  disabled={!canTransferTeam}
                  outline={true}
                  onClick={onClick}
                >
                  <IonIcon icon={teamIcon} />
                  <IonLabel>{team.name}</IonLabel>
                </IonChip>
              )}
              renderSearchItem={({ value, onClick }) => (
                <SelectionItem title={value.name} onClick={onClick} />
              )}
            />

            <IonChip
              color="primary"
              disabled={!canSetAccountRate}
              outline={true}
              onClick={() => setIsOpen(true)}
            >
              <IonLabel>{rate ? `$ ${rate}/hr` : 'No Rate'}</IonLabel>
            </IonChip>
          </div>
        </div>
      </IonItem>

      <IonLoading isOpen={mutating || transferTeamLoading} />

      <IonModal
        isOpen={isOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
        onDidDismiss={() => setIsOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>Account Rate</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <MagicForm
            className={classes.form}
            subtitle="Default billing rate when logging labor by this account"
            submitText="Save"
            onSubmit={onSubmit}
            defaultValues={{ rate }}
          >
            <MagicField
              name="rate"
              type="number"
              step="0.01"
              inputMode="decimal"
              icon={rateIcon}
              placeholder="Rate ($/hr)"
              rules={{
                required: 'Rate is required',
              }}
            />
          </MagicForm>
        </IonContent>
        <UnsafeArea position="bottom" color="primary" />
      </IonModal>
    </>
  );
};

export default AccountSummary;
