import { ApolloError, gql, useMutation } from '@apollo/client';
import { Location } from 'interfaces/Location';
import { Reporter } from 'interfaces/Reporter';
import { logException } from 'services/logger';
import { formatPhone } from 'utils/formatPhone';

import { useMessages } from './useMessages';

export const ASSIGN_LOCATION = gql`
  mutation assignLocation(
    $workorderId: ID!
    $address: String!
    $latitude: Float!
    $longitude: Float!
  ) {
    assignWorkOrderLocation(
      input: {
        workorderId: $workorderId
        location: { address: $address, latitude: $latitude, longitude: $longitude }
      }
    ) {
      workorder {
        id
        location {
          address
          longitude
          latitude
        }
      }
    }
  }
`;

export const ASSIGN_ASSIGNEE = gql`
  mutation assignAssignee($workorderId: ID!, $assigneeId: ID!) {
    assignWorkOrderAssignee(input: { workorderId: $workorderId, assigneeId: $assigneeId }) {
      workorder {
        id
        assignee {
          id
          name
          email
        }
      }
    }
  }
`;

export const ASSIGN_ORGANIZATION = gql`
  mutation assignOrganization($workorderId: ID!, $organizationId: ID!) {
    assignWorkOrderOrganization(
      input: { workorderId: $workorderId, organizationId: $organizationId }
    ) {
      workorder {
        id
        organization {
          id
          name
        }
        category {
          id
          name
        }
        team {
          id
          name
        }
        assignee {
          id
          name
          email
        }
      }
    }
  }
`;

export const ASSIGN_TEAM = gql`
  mutation assignTeam($workorderId: ID!, $teamId: ID!) {
    assignWorkOrderTeam(input: { workorderId: $workorderId, teamId: $teamId }) {
      workorder {
        id
        team {
          id
          name
        }
      }
    }
  }
`;

export const ASSIGN_CATEGORY = gql`
  mutation assignCategory($workorderId: ID!, $categoryId: ID!) {
    assignWorkOrderCategory(input: { workorderId: $workorderId, categoryId: $categoryId }) {
      workorder {
        id
        category {
          id
          name
        }
      }
    }
  }
`;

export const ASSIGN_REPORTER = gql`
  mutation assignReporter(
    $workorderId: ID!
    $name: String!
    $email: String
    $phone: String
    $secondaryPhone: String
  ) {
    assignWorkOrderReporter(
      input: {
        workorderId: $workorderId
        name: $name
        email: $email
        phone: $phone
        secondaryPhone: $secondaryPhone
      }
    ) {
      workorder {
        id
        reporter {
          name
          email
          phone
          secondaryPhone
        }
      }
    }
  }
`;

interface WorkOrderAssignmentConfig {
  workorderId: string;
}

export const useWorkOrderAssignment = ({ workorderId }: WorkOrderAssignmentConfig) => {
  const { addMessage } = useMessages();

  const onError = ({ graphQLErrors }: ApolloError) => {
    const messages = [...(graphQLErrors || [])].map((err) => err.message);
    addMessage(`${['Unable to save', ...messages].join('. ')}.`, 'danger');
  };

  const [locationMutation, { loading: locationLoading }] = useMutation(ASSIGN_LOCATION, {
    onError,
  });
  const [organizationMutation, { loading: organizationLoading }] = useMutation(
    ASSIGN_ORGANIZATION,
    {
      onError,
    }
  );
  const [teamMutation, { loading: teamLoading }] = useMutation(ASSIGN_TEAM, {
    onError,
  });
  const [assigneeMutation, { loading: assigneeLoading }] = useMutation(ASSIGN_ASSIGNEE, {
    onError,
  });
  const [categoryMutation, { loading: categoryLoading }] = useMutation(ASSIGN_CATEGORY, {
    onError,
  });
  const [reporterMutation, { loading: reporterLoading }] = useMutation(ASSIGN_REPORTER, {
    onError,
  });

  const assignCategory = async (categoryId: string) => {
    try {
      const result = await categoryMutation({ variables: { workorderId, categoryId } });
      return result;
    } catch (err) {
      logException(err);
    }
  };

  const assignOrganization = async (organizationId: string) => {
    try {
      const result = await organizationMutation({ variables: { workorderId, organizationId } });
      return result;
    } catch (err) {
      logException(err);
    }
  };

  const assignTeam = async (teamId: string) => {
    try {
      const result = await teamMutation({ variables: { workorderId, teamId } });
      return result;
    } catch (err) {
      logException(err);
    }
  };

  const assignAssignee = async (assigneeId: string) => {
    try {
      const result = await assigneeMutation({ variables: { workorderId, assigneeId } });
      return result;
    } catch (err) {
      logException(err);
    }
  };

  const assignLocation = async (location: Location) => {
    try {
      const result = await locationMutation({ variables: { workorderId, ...location } });
      return result;
    } catch (err) {
      logException(err);
    }
  };

  const assignReporter = async (reporter: Reporter) => {
    if (reporter.phone) {
      reporter.phone = formatPhone(reporter.phone);
    }
    if (reporter.secondaryPhone) {
      reporter.secondaryPhone = formatPhone(reporter.secondaryPhone);
    }

    try {
      const result = await reporterMutation({ variables: { workorderId, ...reporter } });
      return result;
    } catch (err) {
      logException(err);
    }
  };

  return {
    assignLocation,
    assignCategory,
    assignOrganization,
    assignTeam,
    assignAssignee,
    assignReporter,
    loading:
      organizationLoading ||
      teamLoading ||
      assigneeLoading ||
      locationLoading ||
      categoryLoading ||
      reporterLoading,
  };
};
