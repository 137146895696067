import { useMutation, useQuery } from '@apollo/client';
import { ToggleChangeEventDetail } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonModal,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import InfiniteList from 'components/InfiniteList';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import { TableHeaderItem, TableItem } from 'components/TaskTable';
import UnsafeArea from 'components/UnsafeArea';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import MaintenanceLog from 'containers/EquipmentMaintenance';
import { GraphQLError } from 'graphql';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { EquipmentUtilization } from 'interfaces/EquipmentUtilization';
import { Vehicle } from 'interfaces/Vehicle';
import {
  carOutline as vehicleIcon,
  closeOutline as closeIcon,
  createOutline as editIcon,
  pricetagsOutline as utilizationSummaryIcon,
} from 'ionicons/icons';
import moment from 'moment';
import EquipmentForm, { EquipmentFormData } from 'pages/Equipment/components/EquipmentForm';
import React, { useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { formatCurrency } from 'utils/formatCurrency';
import { isEqual } from 'utils/isEqual';

import {
  ACTIVATE_EQUIPMENT,
  DEACTIVATE_EQUIPMENT,
  GET_EQUIPMENT,
  GetEquipmentResponse,
  SET_EQUIPMENT_NAME,
  SET_EQUIPMENT_RATE,
  SET_EQUIPMENT_VEHICLE,
} from '../graphql';

const useStyles = createUseStyles({
  root: {
    '--padding-top': 'var(--ion-margin, 16px)',
    '--padding-bottom': 0,
    '--ion-grid-padding': 0,
    '& ion-grid': {
      paddingBottom: 'calc(var(--ion-margin, 16px) * 0.5)',
      borderBottom:
        'dashed 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))',
    },
    '& ion-col': {
      paddingLeft: 0,
    },
  },
  iconColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 'var(--ion-margin, 16px)',
    fontSize: '0.875rem',
    '& ion-icon': {
      marginRight: 'var(--ion-margin, 16px)',
      fontSize: '1.2rem',
      color: 'rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)',
    },
  },
});

const EquipmentDetails: React.FC = () => {
  const { id: equipmentId } = useParams<any>();

  const classes = useStyles();

  const pageRef = useRef<HTMLElement>(null);

  const { workspace } = useWorkspace();
  const { actions } = workspace;

  const { addMessage } = useMessages();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [mutating, setMutating] = useState(false);

  const [setName] = useMutation(SET_EQUIPMENT_NAME);
  const [setRate] = useMutation(SET_EQUIPMENT_RATE);
  const [setVehicle] = useMutation(SET_EQUIPMENT_VEHICLE);
  const [activateMutation] = useMutation(ACTIVATE_EQUIPMENT);
  const [deactivateMutation] = useMutation(DEACTIVATE_EQUIPMENT);

  const { data, loading, refetch } = useQuery<GetEquipmentResponse>(GET_EQUIPMENT, {
    variables: { equipmentId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: () => addMessage('Unable to load equipment details.', 'danger'),
  });
  const equipment = data?.equipment;

  const vehicle = equipment?.vehicle;
  const vehicleLine1 = [vehicle?.year, vehicle?.make, vehicle?.model].filter((v) => v).join(' ');
  const vehicleLine2 = [
    vehicle?.vinNumber && `VIN: ${vehicle.vinNumber}`,
    vehicle?.licenseNumber && `Plate: ${vehicle?.licenseNumber}`,
  ]
    .filter((v) => v)
    .join(' ・ ');

  const canActivateDeactivate = [
    AccountAction.ActivateEquipment,
    AccountAction.DeactivateEquipment,
  ].every((ra) => actions.includes(ra));

  const onSubmit = async (formData: EquipmentFormData) => {
    const { name, primaryRate, make, model, year, vinNumber, licenseNumber } = formData;

    setMutating(true);

    try {
      const requests = [];

      if (name !== equipment?.name) {
        requests.push(setName({ variables: { equipmentId, name } }));
      }

      if (primaryRate !== equipment?.rate.primary) {
        requests.push(setRate({ variables: { equipmentId, primary: primaryRate } }));
      }

      if (
        !isEqual<Partial<Vehicle>>(equipment?.vehicle || {}, formData, {
          nullAndUndefinedAreEquivalent: true,
          keys: ['licenseNumber', 'make', 'model', 'vinNumber', 'year'],
        })
      ) {
        requests.push(
          setVehicle({ variables: { equipmentId, make, model, year, vinNumber, licenseNumber } })
        );
      }

      await Promise.all(requests);
    } catch ({ graphQLErrors }) {
      const messages = [...((graphQLErrors as ReadonlyArray<GraphQLError>) || [])].map(
        (err) => err.message
      );
      addMessage(`${['Unable to save changes', ...messages].join('. ')}.`, 'danger');
    }

    setIsEditModalOpen(false);
    setMutating(false);
  };

  const onToggleActivate = async (e: CustomEvent<ToggleChangeEventDetail>) => {
    const activate = e.detail.checked;

    if (mutating || loading || !equipment || activate === equipment.isActive) return;

    setMutating(true);
    try {
      const mutation = activate ? activateMutation : deactivateMutation;
      await mutation({ variables: { equipmentId: equipment.id } });
    } catch ({ graphQLErrors }) {
      const messages = [...((graphQLErrors as ReadonlyArray<GraphQLError>) || [])].map(
        (err) => err.message
      );
      addMessage(
        `${[`Unable to ${activate ? 'activate' : 'deactivate'}`, ...messages].join('. ')}.`,
        'danger'
      );
    }
    setMutating(false);
  };

  const { equipmentUtilizations, totalEquipmentUtilizationCost } = useMemo(() => {
    let utilizations: EquipmentUtilization[] = [];
    let totalCost = 0;

    if (equipment?.utilization.nodes.length) {
      utilizations = equipment.utilization.nodes;
      totalCost = equipment.utilization.nodes.reduce((total, item) => total + item.itemCost, 0);
    }

    return {
      equipmentUtilizations: utilizations,
      totalEquipmentUtilizationCost: totalCost,
    };
  }, [equipment]);

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref="/equipment" />
          </IonButtons>
          {!loading && equipment && (
            <>
              <IonTitle>{equipment.name}</IonTitle>
              <AuthorizeRequired
                required={[
                  AccountAction.SetEquipmentName,
                  AccountAction.SetEquipmentRate,
                  AccountAction.SetEquipmentVehicle,
                ]}
              />
            </>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PullToRefresh onRefresh={refetch} />

        {!loading && equipment && (
          <>
            <ListSeparator />

            <IonItem className={classes.root} lines="full">
              <div style={{ flex: '1 1 0%', paddingBottom: 'calc(var(--ion-margin, 16px) * 0.5)' }}>
                <IonRow>
                  <IonCol style={{ padding: 0, display: 'flex', justifyContent: 'space-between' }}>
                    <IonNote style={{ fontSize: '0.875rem' }}>
                      Rate: ${equipment.rate.primary}
                    </IonNote>
                    <IonButtons>
                      <IonButton onClick={() => setIsEditModalOpen(true)}>
                        <IonIcon icon={editIcon} onClick={() => setIsEditModalOpen(true)} />
                      </IonButton>
                    </IonButtons>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {vehicleLine1 && (
                    <IonCol className={classes.iconColumn} size="auto">
                      <IonIcon icon={vehicleIcon} />
                      <IonLabel>{vehicleLine1}</IonLabel>
                    </IonCol>
                  )}
                  {vehicleLine2 && (
                    <IonCol className={classes.iconColumn} size="auto">
                      <IonLabel className="ion-text-wrap">{vehicleLine2}</IonLabel>
                    </IonCol>
                  )}
                </IonRow>
              </div>
            </IonItem>

            <ListSeparator />

            <IonItem lines="full">
              <IonLabel>Active</IonLabel>
              <IonToggle
                slot="end"
                disabled={!canActivateDeactivate}
                checked={equipment.isActive}
                onIonChange={onToggleActivate}
              />
            </IonItem>

            <ListSeparator />

            <IonItemDivider mode="md" sticky={true}>
              <IonIcon icon={utilizationSummaryIcon} slot="start" />
              <IonLabel>
                <h2>{isPlatform('mobile') ? 'Utilization' : 'Utilization Summary'}</h2>
              </IonLabel>
              <IonLabel color="secondary" slot="end">
                {formatCurrency(totalEquipmentUtilizationCost)}
              </IonLabel>
            </IonItemDivider>

            <InfiniteList
              title="Utilization Summary"
              pageRef={pageRef}
              searchKeys={['task.name', 'task.workorder.workorderNumber', 'task.workorder.title']}
              items={equipmentUtilizations}
              itemCount={5}
              itemSize={isPlatform('ios') ? 63 : 65}
              renderListHeader={() => <TableHeaderItem quantityLabel="Hours" />}
              renderListItem={({ task, rate, hours, itemCost }, index, items) => (
                <TableItem
                  key={task.id}
                  lines={index === items.length - 1 ? 'full' : 'inset'}
                  name={`${moment(task.taskDate).format('l')} ・ ${task.name}`}
                  note={`Work Order - ${task.workorder.titleDisplay}`}
                  rate={rate}
                  quantity={hours}
                  total={itemCost}
                  routerLink={`/worklog/tasks/details/${task.id}`}
                />
              )}
            />

            <ListSeparator />

            <MaintenanceLog pageRef={pageRef} equipmentId={equipmentId} />

            <IonLoading isOpen={mutating} />

            <IonModal
              swipeToClose={true}
              isOpen={isEditModalOpen}
              presentingElement={pageRef?.current || undefined}
              onDidDismiss={() => setIsEditModalOpen(false)}
            >
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton onClick={() => setIsEditModalOpen(false)}>
                      <IonIcon slot="icon-only" icon={closeIcon} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Edit Equipment</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <EquipmentForm equipment={equipment} onSubmit={onSubmit} />
              </IonContent>
              <UnsafeArea position="bottom" color="primary" />
            </IonModal>

            <ListSeparator hideBorder={true} />
          </>
        )}
      </IonContent>
      <UnsafeArea position="bottom" />
    </IonPage>
  );
};

export default EquipmentDetails;
