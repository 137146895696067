import { ApolloQueryResult, NetworkStatus, gql, useMutation, useQuery } from '@apollo/client';
import { useMessages } from 'hooks/useMessages';
import { Account } from 'interfaces/Account';
import { AccountStatus } from 'interfaces/AccountStatus';
import { formatPhone } from 'utils/formatPhone';

interface AccountConfig {
  organizationId?: string;
  identityId?: string;
  statuses?: AccountStatus[];
}

interface AccountResult {
  accounts: Account[];
  createAccount: (input: CreateAccountInput) => Promise<any>;
  loading: boolean;
  refetch: (args?: any) => Promise<ApolloQueryResult<any>>;
}

interface CreateAccountInput {
  name: string;
  phone: string;
  email: string;
  teamId: string;
}

const ORGANIZATION_FIELDS = gql`
  fragment OrganizationFields on Organization {
    id
    name
    geography {
      center
      zoom
    }
    teams {
      id
      name
    }
    categories {
      id
      name
      isEnabled
      type
    }
    accounts(query: { statuses: $statuses }) {
      id
      status
      name
      email
      kind
    }
  }
`;

export const ACCOUNT_FIELDS = gql`
  fragment AccountFields on Account {
    name
    phone
    email
    rate
    actions
    isPhoneVerified
    isEmailVerified
    organization {
      id
      ...OrganizationFields
    }
    team {
      id
      name
    }
    activatedAt
    createdAt
  }
  ${ORGANIZATION_FIELDS}
`;

export const GET_ACCOUNT = gql`
  query Account($accountId: ID!) {
    account(id: $accountId) {
      id
      ...AccountFields
    }
  }
  ${ACCOUNT_FIELDS}
`;

const GET_ACCOUNTS_BY_ORGANIZATION = gql`
  query GetAccountsByOrganization($organizationId: ID!, $statuses: [AccountStatus!]) {
    accounts(query: { organizationId: $organizationId, statuses: $statuses }) {
      nodes {
        id
        ...AccountFields
      }
    }
  }
  ${ACCOUNT_FIELDS}
`;

const GET_ACCOUNTS_BY_IDENTITY = gql`
  query GetAccountsByIdentity($identityId: ID!, $statuses: [AccountStatus!]) {
    accounts(query: { identityId: $identityId }) {
      nodes {
        id
        ...AccountFields
      }
    }
  }
  ${ACCOUNT_FIELDS}
`;

const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountInput) {
    createAccount(input: $input) {
      account {
        id
        ...AccountFields
      }
    }
  }
  ${ACCOUNT_FIELDS}
`;

export const useAccounts = ({
  organizationId,
  identityId,
  statuses,
}: AccountConfig): AccountResult => {
  if (organizationId && identityId) {
    // tslint:disable-next-line: no-console
    console.error(`You should not provide both identityId and organizationId`);
  }

  const { addMessage } = useMessages();

  const [create] = useMutation(CREATE_ACCOUNT, {
    update: (cache, { data: mutationData }) => {
      const queryData: any = cache.readQuery({
        query: identityId ? GET_ACCOUNTS_BY_IDENTITY : GET_ACCOUNTS_BY_ORGANIZATION,
        variables: { organizationId, identityId },
      });

      cache.writeQuery({
        query: identityId ? GET_ACCOUNTS_BY_IDENTITY : GET_ACCOUNTS_BY_ORGANIZATION,
        variables: { organizationId, identityId },
        data: {
          accounts: {
            ...queryData.accounts,
            nodes: [...queryData.accounts.nodes, mutationData.createAccount.account],
          },
        },
      });

      cache.writeQuery({
        query: GET_ACCOUNT,
        variables: { accountId: mutationData.createAccount.account.id },
        data: { account: mutationData.createAccount.account },
      });
    },
  });

  const { data, networkStatus, refetch } = useQuery(
    identityId ? GET_ACCOUNTS_BY_IDENTITY : GET_ACCOUNTS_BY_ORGANIZATION,
    {
      displayName: 'useAccounts',
      variables: { organizationId, identityId, statuses },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      partialRefetch: true,
      onError: () => addMessage('Unable to load accounts.', 'danger'),
    }
  );

  const createAccount = async (input: CreateAccountInput) => {
    const phone = formatPhone(input.phone);

    const { data: record } = await create({
      variables: {
        input: {
          ...input,
          phone,
          organizationId,
        },
      },
    });

    return record.createAccount.account as Account;
  };

  return {
    accounts: data?.accounts?.nodes || [],
    refetch,
    createAccount,
    loading: networkStatus === NetworkStatus.loading,
  };
};
