import { NumberFormat, parsePhoneNumberFromString } from 'libphonenumber-js';

interface FormatConfig {
  format: NumberFormat;
}

const defaultConfig = {
  format: 'E.164',
};

export const formatPhone = (value: string, config?: FormatConfig): string => {
  const options = { ...defaultConfig, ...config };

  const phoneNumber = parsePhoneNumberFromString(value, 'US');

  if (phoneNumber) {
    return String(phoneNumber.format(options.format as NumberFormat));
  }

  return value;
};
