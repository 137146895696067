import { gql, useMutation } from '@apollo/client';
import * as React from 'react';
import { logException } from 'services/logger';

import { useMessages } from './useMessages';

export interface GetOrganizationTagsVariables {
  organizationId: string;
  after?: string;
  text: string;
  size?: number;
}

export interface GetOrganizationTagsData {
  organization: {
    tags: {
      nodes: string[];
    };
  };
}

export const GET_ORGANIZATION_TAGS = gql`
  query organization($organizationId: ID!, $text: String!, $size: Int) {
    organization(id: $organizationId) {
      tags(params: { size: $size, text: $text }) {
        nodes
      }
    }
  }
`;

export const ADD_TAG = gql`
  mutation addWorkOrderTag($workorderId: ID!, $label: String!) {
    addWorkOrderTag(input: { workorderId: $workorderId, label: $label }) {
      workOrder {
        id
        tags {
          nodes
        }
      }
    }
  }
`;

export const REMOVE_TAG = gql`
  mutation removeWorkOrderTag($workorderId: ID!, $label: String!) {
    removeWorkOrderTag(input: { workorderId: $workorderId, label: $label }) {
      workOrder {
        id
        tags {
          nodes
        }
      }
    }
  }
`;

export const useTags = (workorderId: string) => {
  const { addMessage } = useMessages();
  const [error, setError] = React.useState('');
  const [add, { loading: addTagLoading }] = useMutation(ADD_TAG);
  const [remove, { loading: removeTagLoading }] = useMutation(REMOVE_TAG);

  const addTag = async (label: string): Promise<any> => {
    try {
      const r = await add({ variables: { workorderId, label } });
      return r;
    } catch (err) {
      onError(err as Error, 'Unable to add tag');
    }
  };

  const deleteTag = async (tag: string): Promise<any> => {
    try {
      const r = await remove({ variables: { workorderId, label: tag } });
      return r.data.deleteWorkOrderComment;
    } catch (err) {
      onError(err as Error, 'Unable to delete tag');
    }
  };

  const onError = (err: Error, alertMessage: string) => {
    addMessage(alertMessage, 'danger');
    setError(err.message);
    logException(err);
  };

  return {
    error,
    addTag,
    deleteTag,
    addTagLoading,
    removeTagLoading,
  };
};
