import React, { useEffect, useState } from 'react';

const { REACT_APP_MAPBOX_TOKEN = '' } = process.env;

type IDivElement = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface IStaticMap extends IDivElement {
  latitude: number;
  longitude: number;
  zoom?: number;
  bearing?: number;
  pitch?: number;
  width?: number;
  height?: number;
  autoSize?: boolean;
  markerColor?: string;
}

const primary = getComputedStyle(document.documentElement)
  .getPropertyValue('--ion-color-primary')
  .replace('#', '')
  .trim();

const tint = getComputedStyle(document.documentElement)
  .getPropertyValue('--ion-color-primary-tint')
  .replace('#', '')
  .trim();

const mapStyle = (dark: boolean): { style: string; marker: string } =>
  dark
    ? { style: 'mapbox/dark-v10', marker: primary }
    : { style: 'ljagis/ckfywoifl008619p4l84jrj2h', marker: tint };

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

const StaticMap = React.memo(
  ({
    latitude,
    longitude,
    zoom = 16,
    bearing = 0,
    pitch = 0,
    width = 320,
    height = 200,
    autoSize = false,
    markerColor,
    ...divElementProps
  }: IStaticMap) => {
    const [style, setStyle] = useState(mapStyle(prefersDark.matches));

    useEffect(() => {
      const onMediaChange = (e: any) => setStyle(mapStyle(e.matches));
      prefersDark.addListener(onMediaChange);
      return () => prefersDark.removeListener(onMediaChange);
    }, []);

    const view = autoSize ? `auto` : `${longitude},${latitude},${zoom},${bearing},${pitch}`;
    const marker = `pin-s+${markerColor || style.marker}(${longitude},${latitude})`;
    const size = `${width}x${height}@2x`;
    const src = `https://api.mapbox.com/styles/v1/${style.style}/static/${marker}/${view}/${size}?access_token=${REACT_APP_MAPBOX_TOKEN}&attribution=false&logo=false`;
    return (
      <div
        {...divElementProps}
        style={{
          backgroundPosition: 'center center',
          backgroundSize: `${width}px ${height}px`,
          backgroundRepeat: 'no-repeat',
          ...(divElementProps.style || {}),
          backgroundImage: `url("${src}")`,
        }}
      />
    );
  }
);

export default StaticMap;
