import { FilesystemDirectory, Plugins } from '@capacitor/core';
import { FileOpener } from '@ionic-native/file-opener';
import { isPlatform } from '@ionic/core/components';
import { createPdf } from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

const { Filesystem } = Plugins;

export interface PdfOpenOptions {
  docDefinition: TDocumentDefinitions;
  fileName: string;
  onComplete: (success: boolean, error?: string) => void;
}

export const open = ({ docDefinition, fileName, onComplete }: PdfOpenOptions) => {
  try {
    const pdf = createPdf(docDefinition);

    if (isPlatform('capacitor')) {
      pdf.getBase64(async (data) => {
        try {
          const { uri } = await Filesystem.writeFile({
            path: `${new Date().valueOf()}/${fileName}`,
            data,
            directory: FilesystemDirectory.Cache,
            recursive: true,
          });
          FileOpener.open(uri, 'application/pdf')
            .then(() => onComplete(true))
            .catch((error) => onComplete(false, error.message));
        } catch (error) {
          onComplete(false, error);
        }
      });
    } else {
      pdf.download(fileName, () => onComplete(true));
    }
  } catch (error) {
    onComplete(false, error);
  }
};

export default {
  open,
};
