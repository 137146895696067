import { gql, useMutation } from '@apollo/client';
import { Attachment } from 'interfaces/Attachment';
import { Comment } from 'interfaces/Comment';
import { useState } from 'react';
import { logException } from 'services/logger';
import { useMessages } from './useMessages';

const attachmentFragment = {
  attachment: gql`
    fragment WorkOrderAttachment on Attachment {
      id
      name
      mimetype
      urls {
        raw
        thumb2X
        small
        small2X
        large2X
      }
      createdAt
      operator {
        id
        name
      }
      attachmentType @client
    }
  `,
};

const commentFragment = {
  comment: gql`
    fragment WorkOrderComment on Comment {
      id
      content
      createdAt
      operator {
        id
        name
      }
    }
  `,
};

export const ATTACH_ATTACHMENT = gql`
  mutation attachWorkOrderAttachment(
    $workorderId: ID!
    $uploadId: ID!
    $name: String!
    $mimetype: String!
    $storageKey: String!
  ) {
    attachWorkOrderAttachment(
      input: {
        workorderId: $workorderId
        uploadId: $uploadId
        name: $name
        mimetype: $mimetype
        storageKey: $storageKey
      }
    ) {
      workorder {
        id
        attachments {
          ...WorkOrderAttachment
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const DETACH_ATTACHMENT = gql`
  mutation detachWorkOrderAttachment($workorderId: ID!, $attachmentId: ID!) {
    detachWorkOrderAttachment(input: { workorderId: $workorderId, attachmentId: $attachmentId }) {
      workorder {
        id
        attachments {
          ...WorkOrderAttachment
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const ADD_COMMENT = gql`
  mutation addWorkOrderComment($workorderId: ID!, $content: String!) {
    addWorkOrderComment(input: { workorderId: $workorderId, content: $content }) {
      workorder {
        id
        comments {
          ...WorkOrderComment
        }
      }
    }
  }
  ${commentFragment.comment}
`;

export const EDIT_COMMENT = gql`
  mutation editWorkOrderComment($workorderId: ID!, $commentId: ID!, $content: String!) {
    editWorkOrderComment(
      input: { workorderId: $workorderId, commentId: $commentId, content: $content }
    ) {
      workorder {
        id
        comments {
          ...WorkOrderComment
        }
      }
    }
  }
  ${commentFragment.comment}
`;

export const DELETE_COMMENT = gql`
  mutation deleteWorkOrderComment($workorderId: ID!, $commentId: ID!) {
    deleteWorkOrderComment(input: { workorderId: $workorderId, commentId: $commentId }) {
      workorder {
        id
        comments {
          ...WorkOrderComment
        }
      }
    }
  }
  ${commentFragment.comment}
`;

interface AttachAttachmentInput {
  uploadId: string;
  name: string;
  mimetype: string;
  storageKey: string;
}

export const useWorkOrderDocumentation = (workorderId: string) => {
  const { addMessage } = useMessages();
  const [error, setError] = useState('');
  const [attach, { loading: attachmentLoading }] = useMutation(ATTACH_ATTACHMENT);
  const [detach, { loading: detachmentLoading }] = useMutation(DETACH_ATTACHMENT);
  const [comment, { loading: commentLoading }] = useMutation(ADD_COMMENT);
  const [edit, { loading: editLoading }] = useMutation(EDIT_COMMENT);
  const [remove, { loading: removeLoading }] = useMutation(DELETE_COMMENT);

  const attachAttachment = async (input: AttachAttachmentInput): Promise<Attachment> => {
    try {
      const attached = await attach({ variables: { workorderId, ...input } });
      const { attachment } = attached.data.attachWorkOrderAttachment;
      return attachment;
    } catch (err) {
      return onError(err, 'Unable to add attachment');
    }
  };

  const detachAttachment = async (attachmentId: string) => {
    try {
      const r = await detach({ variables: { workorderId, attachmentId } });
      return r.data.detachWorkOrderAttachment;
    } catch (err) {
      onError(err, 'Unable to delete attachment');
    }
  };

  const addComment = async (content: string): Promise<Comment> => {
    try {
      const r = await comment({ variables: { workorderId, content } });
      return r.data.addWorkOrderComment.comment;
    } catch (err) {
      return onError(err, 'Unable to add comment');
    }
  };

  const editComment = async (commentId: string, content: string) => {
    try {
      const r = await edit({ variables: { workorderId, commentId, content } });
      return r.data.editWorkOrderComment.comment;
    } catch (err) {
      onError(err, 'Unable to edit comment');
    }
  };

  const deleteComment = async (commentId: string) => {
    try {
      const r = await remove({ variables: { workorderId, commentId } });
      return r.data.deleteWorkOrderComment;
    } catch (err) {
      onError(err, 'Unable to delete comment');
    }
  };

  const onError = (err: Error, alertMessage: string) => {
    addMessage(alertMessage, 'danger');
    setError(err.message);
    logException(err);
    throw err;
  };

  return {
    error,
    loading:
      attachmentLoading || detachmentLoading || commentLoading || editLoading || removeLoading,
    attachAttachment,
    detachAttachment,
    addComment,
    editComment,
    deleteComment,
  };
};
