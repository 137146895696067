import { gql, useMutation } from '@apollo/client';
import { Status } from 'interfaces/Status';

export const GET_STATUS = gql`
  query GetStatus($workorderId: ID!) {
    workorder(workorderId: $workorderId) {
      id
      status
    }
  }
`;

export const START_WORK_ORDER = gql`
  mutation startWorkOrder($workorderId: ID!, $reason: String) {
    startWorkOrder(input: { workorderId: $workorderId, reason: $reason }) {
      workorder {
        id
        status
        reason
      }
    }
  }
`;

export const COMPLETE_WORK_ORDER = gql`
  mutation completeWorkOrder($workorderId: ID!, $reason: String, $date: String) {
    completeWorkOrder(
      input: { workorderId: $workorderId, reason: $reason, workCompletedDate: $date }
    ) {
      workorder {
        id
        hasAlert @client
        status
        reason
        closedAt
      }
    }
  }
`;

export const DENY_WORK_ORDER = gql`
  mutation denyWorkOrder($workorderId: ID!, $reason: String) {
    denyWorkOrder(input: { workorderId: $workorderId, reason: $reason }) {
      workorder {
        id
        hasAlert @client
        status
        reason
      }
    }
  }
`;

export const CANCEL_WORK_ORDER = gql`
  mutation cancelWorkOrder($workorderId: ID!, $reason: String) {
    cancelWorkOrder(input: { workorderId: $workorderId, reason: $reason }) {
      workorder {
        id
        hasAlert @client
        status
        reason
      }
    }
  }
`;

export const REOPEN_WORK_ORDER = gql`
  mutation reopenWorkOrder($workorderId: ID!, $reason: String) {
    reopenWorkOrder(input: { workorderId: $workorderId, reason: $reason }) {
      workorder {
        id
        hasAlert @client
        status
        reason
      }
    }
  }
`;

export const HOLD_WORK_ORDER = gql`
  mutation holdWorkOrder($workorderId: ID!, $reason: String) {
    holdWorkOrder(input: { workorderId: $workorderId, reason: $reason }) {
      workorder {
        id
        hasAlert @client
        status
        reason
      }
    }
  }
`;

interface WorkOrderConfig {
  workorderId: string;
}

export const useWorkOrderTracking = ({ workorderId }: WorkOrderConfig) => {
  const [complete, { loading: completeLoading }] = useMutation(COMPLETE_WORK_ORDER);
  const [start, { loading: startLoading }] = useMutation(START_WORK_ORDER);
  const [deny, { loading: denyLoading }] = useMutation(DENY_WORK_ORDER);
  const [cancel, { loading: cancelLoading }] = useMutation(CANCEL_WORK_ORDER);
  const [reopen, { loading: reopenLoading }] = useMutation(REOPEN_WORK_ORDER);
  const [hold, { loading: holdLoading }] = useMutation(HOLD_WORK_ORDER);

  const changeStatus = async (value: Status, reason?: string, date?: string) => {
    switch (value) {
      case Status.Completed:
        try {
          const completed = await complete({ variables: { workorderId, reason, date } });
          return completed;
        } catch (err) {
          break;
        }
      case Status.Started:
        try {
          const started = await start({ variables: { workorderId, reason } });
          return started;
        } catch (err) {
          break;
        }
      case Status.Denied:
        try {
          const denied = await deny({ variables: { workorderId, reason } });
          return denied;
        } catch (err) {
          break;
        }
      case Status.Cancelled:
        try {
          const cancelled = await cancel({ variables: { workorderId, reason } });
          return cancelled;
        } catch (err) {
          break;
        }
      case Status.Reopened:
        try {
          const reopened = await reopen({ variables: { workorderId, reason } });
          return reopened;
        } catch (err) {
          break;
        }
      case Status.OnHold:
        try {
          const placedOnHold = await hold({ variables: { workorderId, reason } });
          return placedOnHold;
        } catch (err) {
          break;
        }
    }
  };

  return {
    changeStatus,
    loading:
      completeLoading ||
      startLoading ||
      denyLoading ||
      cancelLoading ||
      reopenLoading ||
      holdLoading,
  };
};
