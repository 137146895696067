import { IonItem, IonLabel } from '@ionic/react';
import AssetIcon from 'components/AssetIcon';
import { Asset } from 'interfaces/Asset';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  item: {
    '--min-height': '36px',
    '--background': 'var(--luke-asset-item-background)',
  },
  label: {
    paddingLeft: 'calc(var(--ion-padding, 16px) * 4.5)',
    '@media screen and (max-width: 576px)': {
      paddingLeft: 'var(--ion-padding, 16px)',
    },
    fontSize: '0.875rem !important',
    color: 'var(--ion-color-dark-tint) !important',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
  },
  asterisk: {
    padding: '0 calc(var(--ion-padding, 16px) * 0.5)',
  },
  assetName: {
    paddingLeft: 'calc(var(--ion-padding, 16px) * 0.25)',
  },
});

interface WorkOrderAssetItemProps {
  assets: Asset[];
  lines?: 'full' | 'inset' | 'none';
}

const WorkOrderAssetItem: React.FC<WorkOrderAssetItemProps> = ({ assets, lines }) => {
  const classes = useStyles();

  return (
    <IonItem lines={lines} className={`ion-no-padding ${classes.item}`} detail={false}>
      {assets.length === 0 ? (
        <IonLabel className={classes.label}>
          <span className={classes.assetName} style={{ opacity: 0.5 }}>
            No Assets
          </span>
        </IonLabel>
      ) : (
        <IonLabel className={classes.label}>
          {assets.map((asset, index) => (
            <React.Fragment key={asset.id}>
              {index !== 0 && <span className={classes.asterisk}>·</span>}
              <AssetIcon assetType={asset.assetType} size="small" iconStyle="light" />{' '}
              <span className={classes.assetName}>
                {[asset.assetType, asset.name].filter(Boolean).join(' - ')}
              </span>
            </React.Fragment>
          ))}
        </IonLabel>
      )}
    </IonItem>
  );
};

export default WorkOrderAssetItem;
