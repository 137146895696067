import { IonAlert, IonContent, IonGrid } from '@ionic/react';
import MessageBar from 'components/CommentBar';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { Account } from 'interfaces/Account';
import { AccountAction } from 'interfaces/AccountAction';
import { Message } from 'interfaces/Chat/Message';
import { MessageAuthor } from 'interfaces/Chat/MessageAuthor';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

import MessageBubble from './MessageBubble';

interface AlertState {
  showAlert: boolean;
  selectedMessage?: string;
}

interface MessageProps
  extends Pick<
    Message,
    'id' | 'content' | 'createdAt' | 'isCurrentUser' | 'isEdited' | 'modifiedAt'
  > {
  author: Pick<MessageAuthor, 'name' | 'email' | 'phone'>;
  createdBy?: Pick<Account, 'id'>;
}

interface ChatDialogueProps {
  messages?: MessageProps[];
  onPostMessage: (content: string) => Promise<any>;
  onEditMessage: (messageId: string, content: string) => Promise<any>;
  onDeleteMessage: (messageId: string) => Promise<any>;
}

const useStyles = createUseStyles({
  alert: {
    '--backdrop-opacity': 0.32,
  },
  menuContent: {
    padding: 0,
  },
});

const ChatDialogue: React.FC<ChatDialogueProps> = ({
  messages = [],
  onPostMessage,
  onEditMessage,
  onDeleteMessage,
}) => {
  const classes = useStyles();

  const [{ showAlert, selectedMessage }, setAlertState] = useState<AlertState>({
    showAlert: false,
  });

  const onInitDelete = (messageId: string) => {
    setAlertState({ showAlert: true, selectedMessage: messageId });
  };

  const onCancelDelete = () => {
    setAlertState({ showAlert: false });
  };

  const onConfirmDelete = (messageId?: string) => async () => {
    if (!messageId) {
      return;
    }

    await onDeleteMessage(messageId);
  };

  return (
    <>
      <IonContent>
        <IonGrid className={classes.menuContent}>
          {messages.map((message) => (
            <MessageBubble
              key={message.id}
              message={message}
              onEditMessage={onEditMessage}
              onInitDelete={onInitDelete}
            />
          ))}
        </IonGrid>
      </IonContent>

      <AuthorizeRequired required={[AccountAction.PostChatMessage]}>
        <MessageBar
          allowMentions={false}
          placeholder="Add a message..."
          onAddComment={onPostMessage}
        />
      </AuthorizeRequired>

      <IonAlert
        cssClass={classes.alert}
        isOpen={showAlert}
        header="Delete Message"
        subHeader="Are you sure you want to delete this message? This cannot be undone."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Confirm',
            handler: onConfirmDelete(selectedMessage),
          },
        ]}
        onDidDismiss={onCancelDelete}
      />
    </>
  );
};

export default ChatDialogue;
