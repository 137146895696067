import Storage from '@aws-amplify/storage';
import { IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonProgressBar } from '@ionic/react';
import clsx from 'clsx';
import AddNewItemButton from 'components/AddNewItemButton';
import { MagicField, MagicForm } from 'components/MagicForm';
import SearchModal from 'components/SearchModal';
import SelectionItem from 'components/SelectionItem';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { Account } from 'interfaces/Account';
import { Attachment } from 'interfaces/Attachment';
import { MaintenanceEntry } from 'interfaces/EquipmentMaintenance';
import {
  closeOutline as removeAttachmentIcon,
  documentAttachOutline as attachmentsIcon,
  trashBinOutline as deleteMaintenanceIcon,
} from 'ionicons/icons';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { v4 as uuidv4 } from 'uuid';

const { REACT_APP_EQUIPMENT_S3_BUCKET: bucket } = process.env;

interface FileUpload {
  uploadId: string;
  name: string;
  mimetype: string;
  storageKey: string;
}

type AccountSearchResult = Pick<Account, 'id' | 'name'> & Partial<Pick<Account, 'email'>>;

type AttachmentProps = Pick<Attachment, 'id' | 'uploadId' | 'name' | 'mimetype'>;

export interface FormInputs {
  date: number;
  service: string;
  performedBy: string;
  mileage?: number;
  cost?: number;
  notes?: string;
  attachments: FileUpload[];
}

interface MaintenanceProps
  extends Pick<MaintenanceEntry, 'id' | 'service' | 'date' | 'mileage' | 'cost' | 'notes'> {
  performedBy: Pick<Account, 'id' | 'name'>;
  attachments: AttachmentProps[];
}

export interface MaintenanceEntryFormProps {
  accounts: AccountSearchResult[];
  equipmentId: string;
  deleteAllowed?: boolean;
  maintenance?: MaintenanceProps;
  pageRef?: React.RefObject<HTMLElement>;
  onError: (message: string) => void;
  onSubmit: (mode: 'create' | 'edit', input: FormInputs, entryId?: string) => void;
  onDeleteEntry: (entryId: string) => void;
}

const useStyles = createUseStyles({
  root: {
    height: '100%',
  },
  attachments: {
    border: '1px solid var(--ion-color-light)',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 'var(--ion-margin, 16px)',
  },
  hasBorder: {
    border: '1px solid var(--ion-color-light)',
  },
});

const initUploads = (equipmentId: string, attachments: AttachmentProps[] = []) => {
  return attachments.map((attachment) => ({
    uploadId: attachment.uploadId,
    name: attachment.name,
    mimetype: attachment.mimetype,
    storageKey: `public/${equipmentId}/${attachment.uploadId}`,
  }));
};

const accountPlaceholder: any = {
  name: 'Select Technician',
};

const MaintenanceEntryForm: React.FC<MaintenanceEntryFormProps> = ({
  maintenance,
  accounts,
  equipmentId,
  deleteAllowed,
  pageRef,
  onError,
  onSubmit,
  onDeleteEntry,
}) => {
  const classes = useStyles();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedAccount, setSelectedAccount] = useState<AccountSearchResult | undefined>(
    maintenance?.performedBy
  );
  const [uploads, setUploads] = useState<FileUpload[]>(() =>
    initUploads(equipmentId, maintenance?.attachments)
  );

  const [presentConfirmAction] = useConfirmAction({
    header: 'Delete Maintenance',
    message: 'Are you sure you want to permanently delete this maintenance log entry?',
    confirmText: 'Delete',
    destructive: true,
    handler: () => {
      maintenance && onDeleteEntry(maintenance.id);
    },
  });

  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});

  const setUploadPercentage = (uploadId: string, percentage: number) => {
    setUploadProgress((prevState) => ({
      ...prevState,
      [uploadId]: percentage,
    }));
  };

  const browseFiles = () => {
    fileInputRef.current?.click();
  };

  const removeUpload = (target: string) => {
    setUploads(uploads.filter(({ uploadId }) => uploadId !== target));
  };

  const uploadFile = async () => {
    if (!fileInputRef.current?.files?.length) return;

    const [file] = fileInputRef.current.files;
    const uploadId = uuidv4();
    const key = `${equipmentId}/${uploadId}`;

    const upload: FileUpload = {
      uploadId,
      name: file.name,
      mimetype: file.type,
      storageKey: `public/${key}`,
    };

    setUploads([...uploads, upload]);

    try {
      await Storage.put(key, file, {
        bucket,
        contentDisposition: `inline; filename="${file.name}"`,
        contentType: file.type,
        uploadId,
        progressCallback: (progress: any) =>
          setUploadPercentage(uploadId, progress.loaded / progress.total),
      });
    } catch (err) {
      removeUpload(uploadId);
    }
  };

  const handleSubmit = (input: Omit<FormInputs, 'performedBy' | 'attachments'>) => {
    /**
     * MagicForm does not currently handle SearchModal type inputs
     * If a user tries to submit the form without first selecting a technician,
     * an error message, in the form of an ion-chip, appears at the bottom of the screen,
     * the mutation is skipped, and the form remains open
     */
    if (!selectedAccount?.id) {
      onError('Technician is required');
      return;
    }

    maintenance
      ? onSubmit(
          'edit',
          {
            date: new Date(input.date).valueOf(),
            service: input.service,
            mileage: input.mileage || undefined,
            cost: input.cost || undefined,
            notes: input.notes || undefined,
            performedBy: selectedAccount.id,
            attachments: uploads,
          },
          maintenance.id
        )
      : onSubmit('create', {
          date: new Date(input.date).valueOf(),
          service: input.service,
          mileage: input.mileage || undefined,
          cost: input.cost || undefined,
          notes: input.notes || undefined,
          performedBy: selectedAccount.id,
          attachments: uploads,
        });
  };

  const defaultValues = {
    ...maintenance,
    date: maintenance?.date
      ? moment(maintenance.date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
  };

  return (
    <MagicForm
      className={classes.root}
      defaultValues={defaultValues}
      submitText={maintenance ? 'Update' : 'Create'}
      onSubmit={handleSubmit}
    >
      <MagicField
        name="date"
        type="date"
        label="Service Date"
        rules={{ required: 'Service Date is required' }}
      />

      <MagicField
        name="service"
        type="text"
        label="Type of Service"
        rules={{ required: 'Type of Service is required' }}
      />

      <SearchModal<AccountSearchResult>
        pageRef={pageRef}
        label="Select Technician"
        items={accounts}
        keys={['name', 'email']}
        value={selectedAccount || accountPlaceholder}
        onSelect={(result) => setSelectedAccount(result)}
        renderDisplayItem={(props) => (
          <SelectionItem
            className={classes.hasBorder}
            lines="none"
            title={props.value?.name}
            onClick={props.onClick}
          />
        )}
        renderSearchItem={(props) => (
          <SelectionItem
            title={props.value?.name}
            subtitle={props.value?.email}
            onClick={props.onClick}
          />
        )}
      />

      <MagicField name="mileage" type="number" inputMode="decimal" step="1" label="Mileage" />

      <MagicField name="cost" type="number" inputMode="decimal" step="1" label="Cost" />

      <MagicField name="notes" type="text" fieldType="text" multiline={true} label="Notes" />

      <IonList className={classes.attachments}>
        <IonItemDivider mode="md" sticky={true}>
          <IonIcon icon={attachmentsIcon} slot="start" />
          <IonLabel color="dark">
            <h2>Attachments</h2>
          </IonLabel>
        </IonItemDivider>

        {uploads.length ? (
          uploads.map((file, index) => (
            <IonItem key={file.uploadId} lines={index === uploads.length - 1 ? 'full' : 'inset'}>
              <IonLabel>
                <h3>{file.name}</h3>
              </IonLabel>

              {uploadProgress[file.uploadId] < 1 && (
                <IonProgressBar
                  slot="end"
                  style={{ width: '100px' }}
                  type="determinate"
                  value={uploadProgress[file.uploadId]}
                />
              )}

              <IonIcon
                icon={removeAttachmentIcon}
                size="small"
                slot="end"
                onClick={() => removeUpload(file.uploadId)}
              />
            </IonItem>
          ))
        ) : (
          <IonItem lines="full">
            <IonLabel>
              <p>No added attachments</p>
            </IonLabel>
          </IonItem>
        )}

        <AddNewItemButton title="Add Attachment" onClick={browseFiles} />
      </IonList>

      {!!maintenance && (
        <IonItem
          button={true}
          className={clsx(classes.hasBorder, 'ion-margin-top')}
          disabled={!deleteAllowed}
          lines="none"
          onClick={presentConfirmAction}
        >
          <IonIcon color="danger" icon={deleteMaintenanceIcon} slot="start" />
          <IonLabel color="danger">Delete Maintenance Entry</IonLabel>
        </IonItem>
      )}

      <input ref={fileInputRef} className="ion-hide" type="file" onChange={uploadFile} />
    </MagicForm>
  );
};

export default MaintenanceEntryForm;
