import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import { Reporter as Resident } from 'interfaces/Reporter';
import { closeOutline as closeIcon, cogOutline as optionsIcon } from 'ionicons/icons';
import React, { RefObject, useReducer } from 'react';
import PdfService from 'services/PdfService';

import { CreateDocumentConfig, createDocument } from './createDocument';
import { init, reducer } from './reducer';

export enum Section {
  WorkOrdersMap = 'WORKORDERS_MAP',
  WorkOrdersList = 'WORKORDERS_LIST',
}

export interface PdfGeneratorProps extends Pick<CreateDocumentConfig, 'workorders'> {
  isOpen: boolean;
  pageRef?: RefObject<HTMLElement>;
  residents: Pick<Resident, 'name'>[];
  onDismiss: () => void;
  onWillDownload: () => void;
  onDidDownload: () => void;
  onFailure: (error: string) => void;
}

const PdfGenerator: React.FC<PdfGeneratorProps> = ({
  isOpen,
  workorders,
  residents,
  pageRef,
  onDismiss,
  onWillDownload,
  onDidDownload,
  onFailure,
}) => {
  const [{ options }, dispatch] = useReducer(reducer, init());

  const isVisible = (section: Section) => {
    return options[section].visible;
  };

  const generatePdf = () => {
    onWillDownload();

    const uniqueNames = [...new Set(residents.map((resident) => resident.name))];

    const docDefinition = createDocument({
      options,
      workorders,
      residents: uniqueNames,
    });

    PdfService.open({
      docDefinition,
      fileName: 'Resident Report',
      onComplete: (success, error) => {
        onDidDownload();
        !success && onFailure(error as string);
      },
    });

    onDismiss();
  };

  return (
    <IonModal
      isOpen={isOpen}
      presentingElement={pageRef?.current || undefined}
      swipeToClose={true}
      onDidDismiss={onDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" onClick={onDismiss}>
            <IonButton>
              <IonIcon icon={closeIcon} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Resident Report Download</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <ListSeparator />

        <IonList className="ion-no-padding">
          <IonItemDivider mode="md" sticky={true}>
            <IonIcon icon={optionsIcon} slot="start" />
            <IonLabel>
              <h2>PDF Options</h2>
            </IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>Include Work Orders Map</IonLabel>
            <IonToggle
              checked={isVisible(Section.WorkOrdersMap)}
              onIonChange={() =>
                dispatch({ type: 'TOGGLE_VISIBILITY', payload: { section: Section.WorkOrdersMap } })
              }
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>Include Work Orders List</IonLabel>
            <IonToggle
              checked={isVisible(Section.WorkOrdersList)}
              onIonChange={() =>
                dispatch({
                  type: 'TOGGLE_VISIBILITY',
                  payload: { section: Section.WorkOrdersList },
                })
              }
            />
          </IonItem>
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar className="ion-no-padding">
          <IonButton
            className="ion-no-margin"
            expand="full"
            color="primary"
            size="large"
            onClick={generatePdf}
          >
            Download
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default PdfGenerator;
