import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AddressSearchBar from 'components/AddressSearchBar';
import GeolocateButton from 'components/GeolocateButton';
import InteractiveMap from 'components/InteractiveMap';
import UnsafeArea from 'components/UnsafeArea';
import { usePermissions } from 'containers/AuthorizeRequired';
import { useGeocoder } from 'hooks/useGeocoder';
import { useLocation } from 'hooks/useLocation';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { MapStyles } from 'interfaces/Basemaps';
import { Location } from 'interfaces/Location';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React, { PropsWithChildren, RefObject, useCallback, useState } from 'react';

export interface RenderProps<T> {
  disabled: boolean;
  value: T;
  onClick?: () => any;
}

export interface ILocationTracker {
  onSelect: (props: Location) => void;
  value?: Location;
  label?: string;
  mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
  /** Use ref to page element to get card style modals in iOS */
  pageRef?: RefObject<HTMLElement>;
  renderDisplayItem?: (props: RenderProps<Location>) => JSX.Element;
}

const LocationTracker = ({
  onSelect,
  value,
  label = 'Select Location',
  mapStyles,
  pageRef,
  renderDisplayItem,
}: PropsWithChildren<ILocationTracker>) => {
  const { workspace } = useWorkspace();
  const { center } = workspace.geography;

  const { addMessage } = useMessages();
  const { reverseGeocode } = useGeocoder();
  const { location, updateLocation } = useLocation({ value });

  const authorized = usePermissions({ required: [AccountAction.AssignWorkOrderLocation] });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateLocationWithAddress = useCallback(
    async (lng: number, lat: number) => {
      const geocode = await reverseGeocode(lng, lat);
      updateLocation({ zoom: undefined, longitude: lng, latitude: lat, address: geocode.address });
    },
    [reverseGeocode, updateLocation]
  );

  const onConfirmChange = () => {
    onSelect(location);
    setIsModalOpen(false);
  };

  const { address, latitude, longitude } = location || {};

  return (
    <>
      {renderDisplayItem &&
        renderDisplayItem({
          onClick: () => setIsModalOpen(true),
          value: location,
          disabled: !authorized,
        })}

      <IonModal
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
        onDidDismiss={() => setIsModalOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>{label}</IonTitle>
          </IonToolbar>
          {value && (
            <AddressSearchBar
              proximity={center}
              onSelectResult={(loc) => updateLocation({ zoom: 16, ...loc })}
            />
          )}
        </IonHeader>
        <IonContent scrollY={false}>
          <GeolocateButton
            vertical="top"
            horizontal="end"
            slot="fixed"
            onGeolocate={updateLocation}
            onError={({ message }) => addMessage(message, 'danger')}
            renderLoadingDisplay={(isLoading) => <IonLoading isOpen={isLoading} />}
          />
          <InteractiveMap
            mapStyles={mapStyles}
            style={{ height: '100%' }}
            initialPosition={{ center: [longitude, latitude], zoom: 14 }}
            isMoveable={true}
            focusLocation={{
              title: address
                ? address
                : 'Address not found! Drag the map around or use current location.',
              center: [longitude, latitude],
            }}
            moveFocusLocationWithMap={true}
            onMapMoved={({ center: { lng, lat }, centerChanged }) =>
              centerChanged && updateLocationWithAddress(lng, lat)
            }
            renderLoadingDisplay={(isLoading) => <IonLoading isOpen={isLoading} />}
          />
        </IonContent>
        <IonFooter>
          <IonButton
            color="primary"
            expand="full"
            size="large"
            className="ion-no-margin"
            onClick={onConfirmChange}
          >
            Select Location
          </IonButton>
        </IonFooter>
        <UnsafeArea position="bottom" color="primary" />
      </IonModal>
    </>
  );
};

export default LocationTracker;
