import { IonIcon, IonItem, IonLabel, IonSkeletonText, IonText } from '@ionic/react';
import { Notification } from 'interfaces/Notification';
import { ellipse as unreadIcon } from 'ionicons/icons';
import React, { CSSProperties } from 'react';
import { prettyDate } from 'utils/prettyDate';

interface NotificationItemProps {
  data: Pick<Notification, 'id' | 'message' | 'createdAt' | 'url' | 'unread' | 'subject'>;
  style: CSSProperties;
}

/**
 * Ionic item to display a single notification.
 * Item is always height of 138px to allow rendering in a fixed height virtualized list.
 * Subject and message text are capped at 2 lines.
 * If either subject or message is less than 2 lines, content is center with extra space above and below.
 */
const NotificationItem: React.FC<NotificationItemProps> = ({ data, style }) => {
  if (!data?.id) {
    return (
      <IonItem lines="full" mode="md" style={style}>
        <IonLabel
          className="ion-text-wrap"
          style={{
            height: 138 - 1 - 10 - 11,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <IonSkeletonText animated={true} style={{ width: '70%' }} />
          <IonSkeletonText
            animated={true}
            style={{ width: '90%', marginTop: 'calc(var(--ion-margin, 16px) * 0.25)' }}
          />
          <IonSkeletonText
            animated={true}
            style={{ width: '40%', marginTop: 'calc(var(--ion-margin, 16px) * 0.5)' }}
          />
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <IonItem lines="full" mode="md" style={style} routerLink={data.url} detail={false}>
      {data.unread && (
        <IonIcon
          icon={data.unread ? unreadIcon : undefined}
          color="primary"
          slot="end"
          style={{ fontSize: '14px' }}
        />
      )}
      <IonLabel
        className="ion-text-wrap"
        style={{
          height: 138 - 1 - 10 - 11,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <h3
          style={{
            lineHeight: '20px',
            maxHeight: 20 * 2,
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          <strong>{data.subject}</strong>
        </h3>
        <p
          style={{
            lineHeight: '20px',
            maxHeight: 20 * 2,
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {data.message}
        </p>
        <p style={{ marginTop: 'calc(var(--ion-margin, 16px) * 0.25)', fontSize: '0.75rem' }}>
          <IonText color="medium">{prettyDate(data.createdAt)}</IonText>
        </p>
      </IonLabel>
    </IonItem>
  );
};

export default NotificationItem;
