import { Plugins } from '@capacitor/core';
import { IonChip, IonIcon, IonLabel, isPlatform } from '@ionic/react';
import LocationTracker from 'components/LocationTracker';
import StatusTracker from 'components/StatusTracker';
import TagsModal from 'components/Tags/TagsModal';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { parseAddress } from 'hooks/useLocation';
import { useStatus } from 'hooks/useStatus';
import { AccountAction } from 'interfaces/AccountAction';
import { MapStyles } from 'interfaces/Basemaps';
import { Location } from 'interfaces/Location';
import { Status } from 'interfaces/Status';
import { WorkOrder } from 'interfaces/WorkOrder';
import {
  navigateCircleOutline as directionsIcon,
  scanCircleOutline as locationIcon,
} from 'ionicons/icons';
import moment from 'moment';
import React, { RefObject, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { prettyDateTime } from 'utils/prettyDate';

type WorkOrderProps = Pick<
  WorkOrder,
  'createdAt' | 'closedAt' | 'location' | 'modifiedAt' | 'status' | 'id' | 'tags'
>;

export interface DetailsHeaderProps {
  pageRef: RefObject<HTMLElement>;
  workOrder: WorkOrderProps;
  mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
  onSelectLocation: (value: Location) => Promise<any>;
  onSelectStatus: (value: Status, reason?: string, date?: string) => Promise<any>;
}

const { Browser } = Plugins;

const useStyles = createUseStyles({
  chips: {
    margin: 'calc(var(--ion-margin, 16px) * 0.5) 0',

    '& > ion-chip': {
      marginLeft: 0,
      marginRight: 'calc(var(--ion-margin, 16px) * 0.5)',
    },
  },
  timestamps: {
    marginLeft: 'calc(var(--ion-margin, 16px) * 0.25)',
  },
});

const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  pageRef,
  workOrder: { createdAt, closedAt, location, modifiedAt, status, id, tags },
  mapStyles,
  onSelectLocation,
  onSelectStatus,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { circleIcon: statusIcon, label: statusLabel, options: statusOptions } = useStatus(status);

  const workCompletedDate = useMemo(() => {
    return new Date(closedAt).toISOString().split('T')[0];
  }, [closedAt]);

  const onDirections = () => {
    if (!location) return;

    const destination = `${location.latitude},${location.longitude}`;

    if (isPlatform('ios')) {
      window.open(`maps://?daddr=${destination}&dirflg=d`, '_system');
    } else if (isPlatform('android')) {
      // See https://developers.google.com/maps/documentation/urls/android-intents
      window.open(`google.navigation:q=${destination}&mode=d`, '_system');
    } else {
      Browser.open({
        url: `https://www.google.com/maps/dir/?api=1&destination=${destination}&dir_action=navigate`,
      });
    }
  };

  return (
    <section>
      <div className={classes.chips}>
        <AuthorizeRequired minRequired={statusOptions.map((o) => o.actions).flat()}>
          <StatusTracker
            pageRef={pageRef}
            status={status}
            onStatusChange={onSelectStatus}
            renderStatusItem={(props) => (
              <AuthorizeRequired required={props.value.actions}>
                <IonChip color="primary" outline={true} onClick={props.onClick}>
                  {statusIcon && <IonIcon icon={statusIcon} />}
                  <IonLabel>{statusLabel}</IonLabel>
                </IonChip>
              </AuthorizeRequired>
            )}
          />
        </AuthorizeRequired>

        <LocationTracker
          pageRef={pageRef}
          value={location}
          mapStyles={mapStyles}
          onSelect={onSelectLocation}
          renderDisplayItem={(props) => (
            <IonChip
              color="primary"
              disabled={props.disabled}
              outline={true}
              onClick={props.onClick}
            >
              <IonIcon icon={locationIcon} />
              <IonLabel>{parseAddress(location?.address)}</IonLabel>
            </IonChip>
          )}
        />

        {location !== undefined && (
          <IonChip color="primary" outline={true} onClick={onDirections}>
            <IonIcon icon={directionsIcon} />
            <IonLabel>Directions</IonLabel>
          </IonChip>
        )}

        {tags?.nodes.map((tag, index) => (
          <IonChip
            color="primary"
            outline={true}
            key={index}
            onClick={() => history.push(`/workorders/list/search?tags=${tag}`)}
          >
            <IonLabel>{tag}</IonLabel>
          </IonChip>
        ))}
        <AuthorizeRequired
          required={[AccountAction.AddWorkOrderTag, AccountAction.RemoveWorkOrderTag]}
        >
          <TagsModal tags={tags.nodes} workOrder={id} />
        </AuthorizeRequired>
      </div>

      <IonLabel className={classes.timestamps}>
        <p className="ion-text-wrap">
          {[
            `Created ${prettyDateTime(createdAt)}`,
            `Updated ${prettyDateTime(modifiedAt)}`,
            status === Status.Completed && `Completed ${moment(workCompletedDate).format('l')}`,
          ]
            .filter(Boolean)
            .join(' · ')}
        </p>
      </IonLabel>
    </section>
  );
};

export default DetailsHeader;
