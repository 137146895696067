import { gql } from '@apollo/client';
import { Account } from 'interfaces/Account';
import { Organization } from 'interfaces/Organization';
import { Team } from 'interfaces/Team';

/** Account fields required to display list */
const listAccountFragment = {
  listAccount: gql`
    fragment ListAccount on Account {
      id
      kind
      status
      activatedAt
      createdAt
      email
      isEmailVerified
      phone
      name
      team {
        id
        name
      }
    }
  `,
};

export type ListAccount = Pick<
  Account,
  | 'id'
  | 'kind'
  | 'status'
  | 'activatedAt'
  | 'createdAt'
  | 'email'
  | 'isEmailVerified'
  | 'phone'
  | 'name'
  | 'team'
>;

export interface GetOrganizationAccountsResponse {
  accounts: {
    nodes: ListAccount[];
  };
}

export const GET_ORGANIZATION_ACCOUNTS = gql`
  query GetOrganizationAccounts($organizationId: ID!, $statuses: [AccountStatus!]) {
    accounts(query: { organizationId: $organizationId, statuses: $statuses }) {
      nodes {
        ...ListAccount
      }
    }
  }
  ${listAccountFragment.listAccount}
`;

export interface GetAccountResponse {
  account: Pick<
    Account,
    | 'id'
    | 'kind'
    | 'status'
    | 'activatedAt'
    | 'actions'
    | 'createdAt'
    | 'email'
    | 'name'
    | 'phone'
    | 'pastIdentity'
    | 'team'
    | 'rate'
    | 'utilization'
  >;
}

export const GET_ACCOUNT = gql`
  query Account($accountId: ID!) {
    account(id: $accountId) {
      id
      kind
      status
      activatedAt
      actions
      createdAt
      email
      name
      phone
      pastIdentity
      team {
        id
        name
      }
      rate
      utilization {
        nodes {
          task {
            id
            name
            taskDate
            workorder {
              id
              title
              workorderNumber
              titleDisplay @client
            }
          }
          rate
          hours
          itemCost
        }
      }
    }
  }
`;

export interface CreateAccountInput {
  organizationId: string;
  teamId: string;
  name: string;
  phone: string;
  email: string;
}

export interface CreateAccountResponse {
  createAccount: {
    account: ListAccount & { organization: Pick<Organization, 'id'> };
  };
}

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountInput) {
    createAccount(input: $input) {
      account {
        organization {
          id
        }
        ...ListAccount
      }
    }
  }
  ${listAccountFragment.listAccount}
`;

export interface CreateVirtualAccountInput {
  organizationId: string;
  teamId: string;
  name: string;
}
export interface CreateVirtualAccountResponse {
  createVirtualAccount: {
    account: ListAccount & { organization: Pick<Organization, 'id'> };
  };
}

export const CREATE_VIRTUAL_ACCOUNT = gql`
  mutation CreateAccount($input: CreateVirtualAccountInput) {
    createVirtualAccount(input: $input) {
      account {
        organization {
          id
        }
        ...ListAccount
      }
    }
  }
  ${listAccountFragment.listAccount}
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($input: ActivateAccountInput) {
    activateAccount(input: $input) {
      account {
        id
        activatedAt
      }
    }
  }
`;

export const ATTACH_PERMISSIONS = gql`
  mutation AttachPermissions($input: AttachPermissionsInput) {
    attachPermissions(input: $input) {
      account {
        id
        actions
      }
    }
  }
`;

export const DETACH_PERMISSIONS = gql`
  mutation DetachPermissions($input: DetachPermissionsInput) {
    detachPermissions(input: $input) {
      account {
        id
        actions
      }
    }
  }
`;

export const SET_ACCOUNT_RATE = gql`
  mutation SetAccountRate($accountId: ID!, $rate: Float!) {
    setAccountRate(input: { accountId: $accountId, rate: $rate }) {
      account {
        id
        rate
      }
    }
  }
`;

export const DEACTIVATE_ACCOUNT = gql`
  mutation DeactivateAccount($accountId: ID!) {
    deactivateAccount(input: { accountId: $accountId }) {
      account {
        id
        status
        pastIdentity
      }
    }
  }
`;

export const REACTIVATE_ACCOUNT = gql`
  mutation ReactivateAccount($accountId: ID!) {
    reactivateAccount(input: { accountId: $accountId }) {
      account {
        id
        status
      }
    }
  }
`;

export const REINVITE_ACCOUNT = gql`
  mutation ReinviteAccount($accountId: ID!) {
    reinviteAccount(input: { accountId: $accountId }) {
      account {
        id
        status
      }
    }
  }
`;

export interface TransferTeamInput {
  accountId: string;
  teamId: string;
}

export interface TransferTeamResponse {
  account: Pick<Account, 'id' | 'name'> & { team: Pick<Team, 'id' | 'name'> };
}

export const TRANSFER_TEAM = gql`
  mutation transferTeam($input: TransferTeamInput) {
    transferTeam(input: $input) {
      account {
        id
        name
        team {
          id
          name
        }
      }
    }
  }
`;
