import { AccountAction } from 'interfaces/AccountAction';
import { Status } from 'interfaces/Status';
import {
  alertCircleOutline as unknownCircleIcon,
  alertOutline as unknownIcon,
  arrowRedoCircleOutline as submittedCircleIcon,
  arrowRedoOutline as submittedIcon,
  arrowUndoCircleOutline as cancelledCircleIcon,
  arrowUndoOutline as cancelledIcon,
  checkmarkCircleOutline as completedCircleIcon,
  checkmarkOutline as completedIcon,
  closeCircleOutline as deniedCircleIcon,
  closeOutline as deniedIcon,
  infiniteOutline as reopenedIcon,
  pauseCircleOutline as placedOnHoldCircleIcon,
  pauseOutline as placedOnHoldIcon,
  syncCircleOutline as startedCircleIcon,
  syncOutline as startedIcon,
} from 'ionicons/icons';

export interface StatusOption {
  actions: AccountAction[];
  color: string;
  circleIcon: string;
  description: string;
  icon: string;
  label: string;
  value: Status;
}

export interface IStatus extends StatusOption {
  options: StatusOption[];
}

export const statusMap: Record<Status, StatusOption> = {
  [Status.Submitted]: {
    actions: [],
    circleIcon: submittedCircleIcon,
    color: `#01BAEF`, // Blue
    description: 'Newly submitted work order. Waiting to start work.',
    icon: submittedIcon,
    label: 'Submitted',
    value: Status.Submitted,
  },
  [Status.Denied]: {
    actions: [AccountAction.DenyWorkOrder],
    circleIcon: deniedCircleIcon,
    color: `#CD533B`, // Red
    description:
      'Work order is closed and will not be worked on. The request for work is not valid.',
    icon: deniedIcon,
    label: 'Denied',
    value: Status.Denied,
  },
  [Status.Cancelled]: {
    actions: [AccountAction.CancelWorkOrder],
    circleIcon: cancelledCircleIcon,
    color: `#DF78DB`, // Red
    description:
      'Work order is closed and will not be completed. Initially described work is no longer needed.',
    icon: cancelledIcon,
    label: 'Canceled',
    value: Status.Cancelled,
  },
  [Status.Completed]: {
    actions: [AccountAction.CompleteWorkOrder],
    circleIcon: completedCircleIcon,
    color: `#70B77E`, // Green
    description: 'All work has been completed. Work order is closed.',
    icon: completedIcon,
    label: 'Completed',
    value: Status.Completed,
  },
  [Status.Started]: {
    actions: [AccountAction.StartWorkOrder],
    circleIcon: startedCircleIcon,
    color: `#FFBA08`, // Orange
    description: 'Work order is actively being worked on.',
    icon: startedIcon,
    label: 'Started',
    value: Status.Started,
  },
  [Status.Reopened]: {
    actions: [AccountAction.ReopenWorkOrder],
    circleIcon: reopenedIcon,
    color: `#01BAEF`, // Blue
    description: 'Work order was previously closed, but is reopened to continue work.',
    icon: reopenedIcon,
    label: 'Reopened',
    value: Status.Reopened,
  },
  [Status.OnHold]: {
    actions: [AccountAction.HoldWorkOrder],
    circleIcon: placedOnHoldCircleIcon,
    color: `#CECE53`, // Orange
    description: 'Place work order on hold while waiting for outside work like contractors.',
    icon: placedOnHoldIcon,
    label: 'On Hold',
    value: Status.OnHold,
  },
};

export const useStatus = (status: Status): IStatus => {
  switch (status) {
    case Status.Submitted:
      return {
        ...statusMap[Status.Submitted],
        options: [
          statusMap[Status.Denied],
          statusMap[Status.Started],
          statusMap[Status.Cancelled],
          statusMap[Status.Completed],
          statusMap[Status.OnHold],
        ],
      };
    case Status.Denied:
      return {
        ...statusMap[Status.Denied],
        options: [statusMap[Status.Reopened]],
        value: status,
      };
    case Status.Completed:
      return {
        ...statusMap[Status.Completed],
        options: [statusMap[Status.Reopened]],
      };
    case Status.Cancelled:
      return {
        ...statusMap[Status.Cancelled],
        options: [statusMap[Status.Reopened]],
      };
    case Status.Started:
      return {
        ...statusMap[Status.Started],
        options: [
          statusMap[Status.Cancelled],
          statusMap[Status.Completed],
          statusMap[Status.OnHold],
        ],
      };
    case Status.Reopened:
      return {
        ...statusMap[Status.Reopened],
        options: [
          statusMap[Status.Started],
          statusMap[Status.Cancelled],
          statusMap[Status.Completed],
          statusMap[Status.OnHold],
        ],
      };
    case Status.OnHold:
      return {
        ...statusMap[Status.OnHold],
        options: [
          statusMap[Status.Started],
          statusMap[Status.Cancelled],
          statusMap[Status.Completed],
        ],
      };
    default:
      return {
        actions: [],
        color: '#989aa2',
        circleIcon: unknownCircleIcon,
        icon: unknownIcon,
        label: 'Unknown Status',
        description: '',
        options: [],
        value: status,
      };
  }
};
