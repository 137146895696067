import { IonLabel, IonRow } from '@ionic/react';
import { AssetTimelineNode } from 'interfaces/Asset';
import moment from 'moment';
import React from 'react';

export interface YearNoYearSeparatorProps {
  timelineItems: Pick<AssetTimelineNode, 'id' | 'createdAt' | 'closedAt'>[];
  index: number;
}

const hasYearChanged = (
  timelineItems: YearNoYearSeparatorProps['timelineItems'],
  currentIndex: number
): boolean => {
  if (currentIndex === timelineItems.length - 1) return false; // Last element, no next entry to compare

  const currentEntry = timelineItems[currentIndex];
  const nextEntry = timelineItems[currentIndex + 1];

  return !moment(currentEntry.closedAt || currentEntry.createdAt).isSame(
    nextEntry.closedAt || nextEntry.createdAt,
    'year'
  );
};

const YearNoYearSeparator: React.FC<YearNoYearSeparatorProps> = ({ timelineItems, index }) => {
  const yearChanged = hasYearChanged(timelineItems, index);

  return (
    <>
      {index !== timelineItems.length - 1 && (
        <IonRow className={yearChanged ? 'yearSeparator' : 'separator'}>
          {yearChanged && (
            <IonLabel className="ion-padding" color="primary" style={{ position: 'absolute' }}>
              {moment(
                timelineItems[index + 1].closedAt || timelineItems[index + 1].createdAt
              ).year()}
            </IonLabel>
          )}
        </IonRow>
      )}
    </>
  );
};

export default YearNoYearSeparator;
