import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import GridList from 'components/GridList';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import WorkOrderItem, { WorkOrderItemSkeleton } from 'components/WorkOrderItem';
import { WorkOrderItemProps } from 'components/WorkOrderItem/WorkOrderItem';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { useWorkOrders } from 'hooks/useWorkOrders';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { add } from 'ionicons/icons';
import React, { Fragment, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useMaintainScrollPosition } from './useMaintainScrollPosition';

const isMobile = isPlatform('mobile');
const showMap = !isMobile;

/**
 * Others list limited count to prevent issues rendering large lists,
 * especially on mobile.
 * Virtualization is difficult because of the grid and sticky item dividers.
 * The active list isn't intended to display hundreds of items.
 * The search page is a better solution. User is directed there if limit is exceeded.
 */
const othersLimit = isMobile ? 50 : 300;

const ActiveList: React.FC = () => {
  const pageRef = useRef<HTMLElement>(null);

  const history = useHistory();

  const { workspace } = useWorkspace();

  const { contentRef, handleScrollEnd } = useMaintainScrollPosition();

  const { workorders, loading, refetch } = useWorkOrders({
    organizationId: workspace.organizationId,
    active: true,
  });

  // Group work orders by mine and my team
  const { groups, exceedsRenderLimit } = useMemo(() => {
    const { operatorId, teamId, teams } = workspace;

    const myTeam = teams.find((t) => t.id === teamId);

    let othersCount = 0; // Number of work orders not in mine or my team

    const groupsDict = workorders.reduce<{
      [label: string]: {
        label: string;
        items: WorkOrderItemProps['workorder'][];
        count: number;
      };
    }>((acc, wo) => {
      let label = 'No Team';
      if (wo.assignee?.id === operatorId) {
        label = 'Mine';
      } else if (wo.team?.name) {
        label = wo.team.name;
      }

      if (!['Mine', myTeam].includes(label)) othersCount += 1;

      const exceedsOthersLimit = othersCount > othersLimit;

      const match = acc[label];
      if (match) {
        match.count += 1;
      } else {
        acc[label] = { label, items: [], count: 1 };
      }

      if (!exceedsOthersLimit) {
        acc[label].items.push(wo);
      }

      return acc;
    }, {});

    const sortedGroups = Object.values(groupsDict).sort((a, b) => {
      if (a.label === 'Mine' || a.label === myTeam?.name || b.label === 'No Team') return -1;
      if (b.label === 'Mine' || b.label === myTeam?.name || a.label === 'No Team') return 1;

      return a.label.localeCompare(b.label);
    });

    return { groups: sortedGroups, exceedsRenderLimit: othersCount > othersLimit };
  }, [workspace, workorders]);

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Work Orders</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        ref={contentRef}
        onIonScrollEnd={handleScrollEnd}
        scrollEvents={true}
        style={{ '--padding-bottom': '72px' }}
      >
        <PullToRefresh onRefresh={refetch} />

        <AuthorizeRequired required={[AccountAction.GetWorkOrder, AccountAction.ListWorkOrders]}>
          {groups.map((group) => (
            <Fragment key={group.label}>
              <ListSeparator />
              <IonItemDivider color="light" mode="md" sticky={true}>
                <IonLabel>
                  <h2>{group.label}</h2>
                </IonLabel>
                <IonLabel slot="end" color="medium">
                  {group.items.length}
                  {group.items.length < group.count ? `/${group.count}` : ''} Item
                  {group.count > 1 ? 's' : ''}
                </IonLabel>
              </IonItemDivider>
              <GridList<WorkOrderItemProps['workorder']>
                keyName="id"
                items={group.items}
                loading={loading}
                renderSkeletonItem={() => <WorkOrderItemSkeleton />}
                renderListItem={({ value }) => (
                  <WorkOrderItem workorder={value} pageRef={pageRef} showMap={showMap} />
                )}
              />
            </Fragment>
          ))}
          {exceedsRenderLimit && (
            <IonButton
              onClick={() =>
                // NOTE Passing params like `statuses=SUBMITTED%2CSTARTED%2CONHOLD` does not work
                history.push(`/workorders/list/search?statuses=SUBMITTED%2CSTARTED%2CONHOLD`)
              }
              expand="block"
              color="light"
              fill="outline"
              className="ion-margin"
              style={{
                '--padding-top': 'calc(var(--ion-margin, 16px) * 2)',

                '--padding-bottom': 'calc(var(--ion-margin, 16px) * 2)',
              }}
            >
              <p style={{ fontSize: '1rem' }}>
                <IonText color="medium">Explore More in SEARCH</IonText>
              </p>
            </IonButton>
          )}
        </AuthorizeRequired>

        <AuthorizeRequired required={[AccountAction.CreateWorkOrder]}>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => history.push(`/workorders/create`)}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </AuthorizeRequired>
      </IonContent>
    </IonPage>
  );
};

export default ActiveList;
