import { IonChip, IonIcon, IonLabel, IonToolbar } from '@ionic/react';
import { Reporter as Resident } from 'interfaces/Reporter';
import { closeCircleOutline as clearIcon } from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '--border-width': '0 0 0.55px',

    '&.md': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },

    '&.ios': {
      paddingLeft: '7px',
      paddingRight: '7px',
    },

    '& > ion-chip': {
      backgroundColor: 'var(--ion-item-background)',
      marginLeft: 0,
      marginRight: 'calc(var(--ion-margin, 16px) * 0.5)',
    },
  },
});

export interface SelectedResidentsProps {
  selectedResidents: Resident[];
  onRemove: (resident: Resident) => void;
  onClear: () => void;
}

const SelectedResidents: React.FC<SelectedResidentsProps> = ({
  selectedResidents,
  onRemove,
  onClear,
}) => {
  const classes = useStyles();

  return (
    <IonToolbar className={classes.root}>
      <IonChip mode="ios" outline={true} onClick={onClear}>
        Clear All
      </IonChip>
      {selectedResidents.map((resident) => (
        <IonChip key={resident.id} color="primary" mode="ios" outline={true}>
          <IonLabel>{resident.name}</IonLabel>
          <IonIcon icon={clearIcon} onClick={() => onRemove(resident)} />
        </IonChip>
      ))}
    </IonToolbar>
  );
};

export default SelectedResidents;
