import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import ConfirmLocation from './ConfirmLocation';
import ProvideDetails from './ProvideDetails';

const CreateWorkOrder: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      <Route path={`${path}/location`} component={ConfirmLocation} />
      <Route path={`${path}/details`} component={ProvideDetails} />
      <Redirect from={`${path}`} to={`${path}/location`} exact={true} />
    </IonRouterOutlet>
  );
};

export default CreateWorkOrder;
