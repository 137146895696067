import { IonItem, IonLabel } from '@ionic/react';
import Avatar from 'components/Avatar';
import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

export interface AssignmentSearchProps<T> {
  value: T & { name: string };
  onClick?: () => void;
}

const useStyles = createUseStyles({
  root: {
    '--inner-padding-top': '12px',
    '--inner-padding-bottom': '12px',
    '&:not(.in-list)': {
      margin: {
        top: '15px',
        bottom: '15px',
      },
    },
  },
});

const AssignmentSearch = <T,>({ value, onClick }: PropsWithChildren<AssignmentSearchProps<T>>) => {
  const classes = useStyles();

  return (
    <IonItem button={true} className={classes.root} onClick={onClick}>
      <Avatar alt={value.name} slot="start" />
      <IonLabel>
        <h2>{value.name}</h2>
      </IonLabel>
    </IonItem>
  );
};

export default AssignmentSearch;
