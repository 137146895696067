import {
  IonCol,
  IonGrid,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
  IonRow,
} from '@ionic/react';
import Avatar from 'components/Avatar';
import { Equipment } from 'interfaces/Equipment';
import { carOutline as vehicleIcon, hammerOutline as equipmentIcon } from 'ionicons/icons';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  item: {
    '& h3': {
      fontSize: '0.9375rem',
    },

    '& ion-note': {
      display: 'block',
      lineHeight: 1.6,
      fontSize: '0.875rem',
    },

    '& ion-label + ion-note, & ion-note + ion-note': {
      marginTop: 4,
    },
  },
});

export interface EquipmentItemProps {
  equipment: Pick<Equipment, 'id' | 'isActive' | 'name' | 'rate' | 'vehicle'>;
  routerLink?: string;
  lines?: 'full' | 'inset' | 'none';
  onActivate?: (id: string) => void;
  onDeactivate?: (id: string) => void;
}

const EquipmentItem: React.FC<EquipmentItemProps> = ({
  equipment: { id, isActive, name, rate, vehicle },
  routerLink,
  lines,
  onActivate,
  onDeactivate,
}) => {
  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
  const classes = useStyles();

  const hasVehicle =
    vehicle &&
    (vehicle.year || vehicle.make || vehicle.model || vehicle.vinNumber || vehicle.licenseNumber)
      ? true
      : false;

  const vehicleLine1 = [vehicle?.year, vehicle?.make, vehicle?.model].filter((v) => v).join(' ');
  const vehicleLine2 = [
    vehicle?.vinNumber && `VIN: ${vehicle.vinNumber}`,
    vehicle?.licenseNumber && `Plate: ${vehicle?.licenseNumber}`,
  ]
    .filter((v) => v)
    .join(' ・ ');

  const slideAction = (callback: (id: string) => void) => {
    callback(id);
    if (itemSlidingRef.current) {
      itemSlidingRef.current.close();
    }
  };

  return (
    <IonItemSliding ref={itemSlidingRef}>
      {onActivate && onDeactivate && (
        <IonItemOptions
          side="start"
          onIonSwipe={() => slideAction(isActive ? onDeactivate : onActivate)}
        >
          {isActive ? (
            <IonItemOption
              color="danger"
              expandable={true}
              onClick={() => slideAction(onDeactivate)}
            >
              Deactivate
            </IonItemOption>
          ) : (
            <IonItemOption
              color="success"
              expandable={true}
              onClick={() => slideAction(onActivate)}
            >
              Activate
            </IonItemOption>
          )}
        </IonItemOptions>
      )}

      <IonItem
        className={classes.item}
        lines={lines}
        routerLink={routerLink}
        detail={false}
        routerDirection="forward"
      >
        <Avatar
          alt={hasVehicle ? 'VEH' : 'EQ'}
          icon={hasVehicle ? vehicleIcon : equipmentIcon}
          color={isActive ? undefined : 'var(--ion-color-medium)'}
          bgColor={isActive ? undefined : 'var(--ion-color-light)'}
          slot="start"
        />
        <IonGrid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <IonRow className="ion-align-items-center">
            <IonCol style={{ paddingLeft: 0 }} size="12" sizeSm="5">
              <IonLabel>
                <h3>{name}</h3>
              </IonLabel>
              <IonNote>Rate: ${rate.primary}/hr</IonNote>
            </IonCol>
            {hasVehicle && (
              <IonCol style={{ paddingLeft: 0 }} size="12" sizeSm="7">
                <IonNote>{vehicleLine1}</IonNote>
                <IonNote>{vehicleLine2}</IonNote>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonItemSliding>
  );
};

export default EquipmentItem;
