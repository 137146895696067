import { JSX } from '@ionic/core/components';
import { IonItem, IonLabel, IonNote } from '@ionic/react';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import { Activity } from 'interfaces/Activity';
import { ActivityType } from 'interfaces/ActivityType';
import { build, chatbox, document, image, pulse, swapHorizontal } from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { prettyDate } from 'utils/prettyDate';

const selectIcon = (activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.Assignment:
      return swapHorizontal;
    case ActivityType.Comment:
      return chatbox;
    case ActivityType.Document:
      return document;
    case ActivityType.Status:
      return pulse;
    case ActivityType.Photo:
      return image;
    default:
      return build;
  }
};

const useStyles = createUseStyles({
  item: {
    '& h3': {
      marginBottom: 6,
    },
    '& p': {
      marginBottom: 0,
    },
  },
  date: {
    '--padding-start': 'var(--ion-margin, 16px)',
    '.sc-ion-label-md-s &': {
      // Match top margin on `.sc-ion-label-md-s h3` to align baselines
      marginTop: 2,
    },
  },
  content: {
    whiteSpace: 'pre-wrap !important',
    wordBreak: 'break-all',
  },
});

type IonItemProps = Omit<JSX.IonItem, 'button' | 'detail' | 'onClick'>;
interface ActivityItemProps extends IonItemProps {
  activity: Pick<Activity, 'activityType' | 'operator' | 'hasOperator' | 'content' | 'timestamp'>;
  className?: string;
}

const ActivityItem: React.FC<ActivityItemProps> = ({ activity, className, ...ionItemProps }) => {
  const classes = useStyles();

  const { activityType, operator, hasOperator, content, timestamp } = activity;

  const icon = selectIcon(activityType);

  return (
    <IonItem {...ionItemProps} className={clsx(classes.item, className)}>
      <Avatar alt={icon} icon={icon} slot="start" />
      <IonLabel style={{ marginInline: 0 }}>
        <IonNote
          className={clsx('ion-float-right', classes.date)}
          style={{ paddingLeft: 'var(--ion-margin, 16px)', fontSize: '0.875rem' }}
        >
          {prettyDate(timestamp)}
        </IonNote>
        <h3>{hasOperator === false ? 'Resident' : operator?.name}</h3>
        <p className={classes.content}>{content}</p>
      </IonLabel>
    </IonItem>
  );
};

export default ActivityItem;
