import { NotificationReference } from 'interfaces/Notification';

export const resolvers = {
  Notification: {
    url: ({ reference }: { reference?: Partial<NotificationReference> }): string | undefined => {
      if (reference?.kind === 'WorkOrder' && reference?.referenceId) {
        return `/workorders/details/${reference.referenceId}`;
      }
      return undefined;
    },
  },
};
