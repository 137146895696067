import moment from 'moment';

const pretty = (time: number, format: string) => {
  // If timestamp is over a week old return the Calendar date
  // diff for a week is 604800000
  if (moment().diff(time) > 604800000) {
    return moment(time).format(format);
  } else {
    return moment(time).fromNow();
  }
};

export const prettyDate = (time: number) => pretty(time, 'l');

export const prettyDateTime = (time: number) => pretty(time, 'l LT');
