import { gql } from '@apollo/client';
import { Account } from 'interfaces/Account';
import { Attachment, AttachmentUrls } from 'interfaces/Attachment';
import { Connection } from 'interfaces/Connection';
import { Equipment } from 'interfaces/Equipment';
import { MaintenanceEntry } from 'interfaces/EquipmentMaintenance';

export type MaintenanceProps = Pick<
  MaintenanceEntry,
  'id' | 'service' | 'date' | 'cost' | 'mileage' | 'notes'
> & {
  performedBy: Pick<Account, 'id' | 'name'>;
  attachments: Connection<
    Pick<Attachment, 'id' | 'uploadId' | 'name' | 'mimetype' | 'attachmentType'> & {
      urls: Pick<AttachmentUrls, 'raw' | 'large2X'>;
    }
  >;
};

const maintenancePropsFragment = gql`
  fragment MaintenanceProps on EquipmentMaintenance {
    id
    service
    date
    performedBy {
      id
      name
    }
    cost
    mileage
    notes
    attachments {
      nodes {
        id
        uploadId
        name
        mimetype
        attachmentType @client
        urls {
          raw
          large2X
        }
      }
    }
  }
`;

export interface GetMaintenanceLogVariables {
  equipmentId: string;
}

export interface GetMaintenanceLogData {
  equipment: Pick<Equipment, 'id'> & {
    maintenance: Connection<MaintenanceProps>;
  };
}

export const GET_MAINTENANCE_LOG = gql`
  query GetMaintenanceLog($equipmentId: ID!) {
    equipment(id: $equipmentId) {
      id
      maintenance {
        nodes {
          ...MaintenanceProps
        }
      }
    }
  }
  ${maintenancePropsFragment}
`;

export interface LogMaintenanceVariables {
  equipmentId: string;
  service: string;
  date: number;
  performedBy: string;
  cost?: number;
  mileage?: number;
  notes?: string;
  attachments?: (Pick<Attachment, 'uploadId' | 'name' | 'mimetype'> & { storageKey: string })[];
}

export const LOG_EQUIPMENT_MAINTENANCE = gql`
  mutation LogEquipmentMaintenance(
    $equipmentId: ID!
    $service: String!
    $date: Float!
    $performedBy: ID!
    $cost: Float
    $mileage: Float
    $notes: String
    $attachments: [MaintenanceAttachmentInput]
  ) {
    logEquipmentMaintenance(
      input: {
        equipmentId: $equipmentId
        service: $service
        date: $date
        performedBy: $performedBy
        cost: $cost
        mileage: $mileage
        notes: $notes
        attachments: $attachments
      }
    ) {
      equipment {
        id
        maintenance {
          nodes {
            ...MaintenanceProps
          }
        }
      }
    }
  }
  ${maintenancePropsFragment}
`;

export interface CorrectMaintenanceVariables {
  equipmentId: string;
  entryId: string;
  service: string;
  date: number;
  performedBy: string;
  cost?: number;
  mileage?: number;
  notes?: string;
  attachments?: (Pick<Attachment, 'uploadId' | 'name' | 'mimetype'> & { storageKey: string })[];
}

export const CORRECT_EQUIPMENT_MAINTENANCE = gql`
  mutation CorrectEquipmentMaintenanceEntry(
    $equipmentId: ID!
    $entryId: ID!
    $service: String!
    $date: Float!
    $performedBy: ID!
    $cost: Float
    $mileage: Float
    $notes: String
    $attachments: [MaintenanceAttachmentInput]
  ) {
    correctMaintenanceEntry(
      input: {
        equipmentId: $equipmentId
        maintenanceEntryId: $entryId
        service: $service
        date: $date
        performedBy: $performedBy
        cost: $cost
        mileage: $mileage
        notes: $notes
        attachments: $attachments
      }
    ) {
      equipment {
        id
        maintenance {
          nodes {
            ...MaintenanceProps
          }
        }
      }
    }
  }
  ${maintenancePropsFragment}
`;

export interface DeleteMaintenancVariables {
  equipmentId: string;
  entryId: string;
}

export const DELETE_EQUIPMENT_MAINTENANCE = gql`
  mutation DeleteEquipmentMaintenance($equipmentId: ID!, $entryId: ID!) {
    deleteMaintenanceEntry(input: { equipmentId: $equipmentId, entryId: $entryId }) {
      equipment {
        id
        maintenance {
          nodes {
            ...MaintenanceProps
          }
        }
      }
    }
  }
  ${maintenancePropsFragment}
`;
