export enum MapContext {
  WorkOrderCreation = 'workorders_create',
  WorkOrderDetails = 'workorders_details',
  WorkOrdersMap = 'workorders_map',
  WorkSummaryReport = 'reports_worksummary',
  ResidentReport = 'reports_resident',
}

export interface MapStyles {
  light: string;
  lightUrl: string;
  dark: string;
  darkUrl: string;
}

export type Basemaps = Partial<Record<MapContext, MapStyles>>;
