import { IonCol, IonGrid, IonRow } from '@ionic/react';
// import EmptyList from 'components/EmptyList';
import ListGenerator from 'components/ListGenerator';
import React, { PropsWithChildren } from 'react';

interface RenderProps<T> {
  value: T;
}

interface EmptyOptions {
  title?: string;
  subtitle?: string;
  condensed?: boolean;
}

interface IGridList<T> {
  loading?: boolean;
  multiplier?: number;
  items: T[];
  renderListItem?: (props: RenderProps<T>) => JSX.Element;
  renderSkeletonItem?: () => JSX.Element;
  emptyOptions?: EmptyOptions;
  keyName?: keyof T;
}

const GridList = <T,>({
  loading,
  multiplier = 6,
  items,
  renderListItem,
  renderSkeletonItem,
  emptyOptions = {
    title: 'It feels a little bit empty',
  },
  keyName,
}: PropsWithChildren<IGridList<T>>) => {
  const noSpace = {
    padding: 0,
    margin: 0,
  };

  if (!loading && items.length > 0) {
    return (
      <IonGrid style={noSpace}>
        <IonRow style={noSpace}>
          {items.map((props: T, i: number) => {
            const key: any = keyName ? props[keyName] : i;
            return (
              <IonCol sizeXs="12" sizeMd="6" sizeXl="4" key={key}>
                {renderListItem && renderListItem({ value: props })}
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    );
  }

  if (!loading && items.length === 0) {
    // TODO. Find a better solution for this without the graphic
    // return <EmptyList {...emptyOptions} />;
    return null;
  }

  return (
    <IonGrid style={noSpace}>
      <IonRow style={noSpace}>
        <ListGenerator
          multiplier={multiplier}
          renderItem={() => (
            <IonCol sizeXs="12" sizeMd="6" sizeXl="4">
              {renderSkeletonItem && renderSkeletonItem()}
            </IonCol>
          )}
        />
      </IonRow>
    </IonGrid>
  );
};

export default GridList;
