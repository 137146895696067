import { IonLabel } from '@ionic/react';
import { useStatus } from 'hooks/useStatus';
import { Status } from 'interfaces/Status';
import React from 'react';

export interface StatusLabelProps {
  status: Status;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
  const { color, label } = useStatus(status);

  return (
    <IonLabel className="ion-text-nowrap">
      <p style={{ color }}>{label}</p>
    </IonLabel>
  );
};

export default StatusLabel;
