import { IonItem, IonLabel, IonSearchbar, IonToolbar } from '@ionic/react';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import SearchResults from 'components/SearchResults';
import { Reporter } from 'interfaces/Reporter';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { prettyPhone } from 'utils/prettyPhone';

const useStyles = createUseStyles({
  topSearchResult: {
    'border-top-left-radius': '10px',
    'border-top-right-radius': '10px',
  },
  bottomSearchResult: {
    'border-bottom-left-radius': '10px',
    'border-bottom-right-radius': '10px',
  },
});

export interface ReporterSearchProps {
  results: Reporter[];
  query?: string;
  setQuery: (value?: string) => void;
  onSelectResult: (reporter: Reporter) => void;
}

const ReporterSearch: React.FC<ReporterSearchProps> = ({
  results,
  query,
  setQuery,
  onSelectResult,
}) => {
  const styles = useStyles();

  const searchbarRef = useRef<HTMLIonSearchbarElement>(null);

  const onSelect = async (reporter: Reporter) => {
    if (!searchbarRef.current) {
      return;
    }
    const input = await searchbarRef.current.getInputElement();
    onSelectResult(reporter);
    input.blur();
  };

  return (
    <IonToolbar>
      <IonSearchbar
        debounce={1000}
        mode="md"
        placeholder="Search residents..."
        ref={searchbarRef}
        style={{ '--border-radius': '100px' }}
        value={query}
        onIonChange={(e) => setQuery(e.detail.value)}
        onIonBlur={() => setQuery(undefined)}
      />
      <SearchResults
        show={!!query?.length}
        results={results}
        inputRef={searchbarRef}
        onSelect={onSelect}
        renderSearchItem={({ value, onClick, isFirst, isLast }) => (
          <IonItem
            className={clsx({
              [styles.topSearchResult]: isFirst,
              [styles.bottomSearchResult]: isLast,
            })}
            lines={isLast ? 'none' : 'inset'}
            button={true}
            onClick={onClick}
            // Prevent ionBlur event from firing until after the reporter is selected
            onMouseDown={(e) => e.preventDefault()}
          >
            <Avatar alt={value.name} slot="start" />
            <IonLabel>
              <h3>{value.name}</h3>
              <p>
                {[value.email, value.phone ? prettyPhone(value.phone) : '']
                  .filter(Boolean)
                  .join(' ・ ')}
              </p>
            </IonLabel>
          </IonItem>
        )}
        renderNoItems={() => (
          <IonItem className={clsx(styles.topSearchResult, styles.bottomSearchResult)} lines="none">
            <IonLabel class="ion-text-wrap">
              <h3>No residents found</h3>
              <p>
                We couldn't find any residents that match your search criteria. Please adjust your
                search and try again.
              </p>
            </IonLabel>
          </IonItem>
        )}
      />
    </IonToolbar>
  );
};

export default ReporterSearch;
