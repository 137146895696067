import { ToastOptions } from '@ionic/core/components';
import { useIonToast } from '@ionic/react';
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';
import { Reducer, useEffect, useReducer } from 'react';

type ToastPresenter = (options: ToastOptions & HookOverlayOptions) => void;

type UseSmartToastResult = [ToastPresenter, () => void];

interface UseTaskSchedulerResult {
  waiting: boolean;
  addTask: (execute: () => Promise<void>) => void;
}

interface Task {
  execute: () => Promise<void>;
}

interface ReducerState {
  tasks: Task[];
  waiting: boolean;
}

interface ReducerAction {
  type: ActionType;
  payload?: ActionPayload;
}

interface ScheduleTaskPayload {
  task: Task;
}

enum ActionType {
  Schedule,
  Execute,
  Success,
}

type ActionPayload = ScheduleTaskPayload;

type TaskReducer = Reducer<ReducerState, ReducerAction>;

const reducer: TaskReducer = (state, action) => {
  switch (action.type) {
    case ActionType.Schedule: {
      const { task } = action.payload as ScheduleTaskPayload;

      return {
        ...state,
        tasks: [...state.tasks, task],
      };
    }

    case ActionType.Execute: {
      return {
        ...state,
        waiting: true,
      };
    }

    case ActionType.Success: {
      return {
        tasks: state.tasks.slice(1),
        waiting: false,
      };
    }

    default: {
      return state;
    }
  }
};

const initialState = {
  tasks: [],
  waiting: false,
};

const useTaskScheduler = (): UseTaskSchedulerResult => {
  const [{ tasks, waiting }, dispatch] = useReducer<TaskReducer>(reducer, initialState);

  const addTask = (execute: () => Promise<void>) => {
    const payload: ScheduleTaskPayload = {
      task: { execute },
    };

    dispatch({
      type: ActionType.Schedule,
      payload,
    });
  };

  useEffect(() => {
    const executeTask = async () => {
      if (tasks.length === 0 || waiting) {
        return;
      }

      const [currentTask] = tasks;

      // Begin task execution
      dispatch({ type: ActionType.Execute });

      await currentTask.execute();

      // Complete task execution
      dispatch({ type: ActionType.Success });
    };

    executeTask();
  }, [tasks, waiting, dispatch]);

  return {
    waiting,
    addTask,
  };
};

export const useSmartToast = (): UseSmartToastResult => {
  const [present, dismiss] = useIonToast();
  const scheduler = useTaskScheduler();

  const schedule: ToastPresenter = (config) => {
    scheduler.addTask(
      () =>
        new Promise<void>((resolve) => {
          present({
            ...config,
            onDidDismiss: (e) => {
              resolve();
              config.onDidDismiss && config.onDidDismiss(e);
            },
          });
        })
    );
  };

  return [schedule, dismiss];
};
