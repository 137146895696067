import {
  AlertInput,
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Avatar from 'components/Avatar';
import { IStatus, StatusOption, useStatus } from 'hooks/useStatus';
import { Status } from 'interfaces/Status';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React, { RefObject, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface RenderProps<T> {
  onClick?: any;
  value: T;
}

interface IStatusTracker {
  status: Status;
  pageRef?: RefObject<HTMLElement>;
  onStatusChange: (status: Status, reason?: string, date?: string) => void;
  renderStatusItem?: (props: RenderProps<IStatus>) => JSX.Element;
}

const useStyles = createUseStyles({
  reasonAlert: {
    '& .alert-head': {
      display: 'none',
    },
  },
  alertLabel: {
    '&.alert-input': {
      marginBottom: 0,
      paddingBottom: 0,
      backgroundColor: 'initial',
      borderStyle: 'none',
    },
  },
  item: {
    '--inner-padding-top': '12px',
    '--inner-padding-bottom': '12px',
    cursor: 'pointer',
  },
});

const StatusTracker = ({
  status: current,
  pageRef,
  onStatusChange,
  renderStatusItem,
}: IStatusTracker) => {
  const classes: any = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  // Holder for status while prompting for reason
  const [upcomingStatus, setUpcomingStatus] = useState(current);

  const statusProps: IStatus = useStatus(current);
  const { options } = statusProps;

  const reasonButtons: any = [
    { text: 'Cancel', role: 'cancel', handler: () => setUpcomingStatus(current) },
    {
      text: 'Submit',
      handler: (value: any) => {
        setIsModalOpen(false);
        onStatusChange(upcomingStatus, value.reason, value.date);
      },
    },
  ];

  const handleChangeStatus = (option: StatusOption) => {
    setUpcomingStatus(option.value);
    setIsAlertOpen(true);
  };

  return (
    <React.Fragment>
      {renderStatusItem &&
        renderStatusItem({
          onClick: () => setIsModalOpen(true),
          value: statusProps,
        })}

      <IonModal
        onDidDismiss={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>Select Status</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList className="ion-no-padding" lines="full">
            {options.length > 0 ? (
              options.map((option, index) => (
                <IonItem
                  key={index}
                  className={classes.item}
                  button={true}
                  onClick={() => handleChangeStatus(option)}
                >
                  <Avatar icon={option.icon} color="white" bgColor={option.color} slot="start" />
                  <IonLabel>
                    <h2>{option.label}</h2>
                    <p className="ion-text-wrap" style={{ marginTop: 4 }}>
                      {option.description}
                    </p>
                  </IonLabel>
                </IonItem>
              ))
            ) : (
              <IonItem>
                <IonLabel>No Available Statuses</IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      </IonModal>

      <IonAlert
        cssClass={classes.reasonAlert}
        backdropDismiss={false}
        onDidDismiss={() => setIsAlertOpen(false)}
        isOpen={isAlertOpen}
        inputs={[
          { name: 'reason', type: 'text', placeholder: `Optional status message` },
          ...((upcomingStatus === Status.Completed
            ? [
                {
                  name: 'dateLabel',
                  type: 'text',
                  value: 'Completed On',
                  disabled: true,
                  cssClass: classes.alertLabel,
                },
                { name: 'date', type: 'date', value: new Date().toISOString().split('T')[0] },
              ]
            : []) as AlertInput[]),
        ]}
        buttons={reasonButtons}
      />
    </React.Fragment>
  );
};

export default StatusTracker;
