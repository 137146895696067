import { Layer } from 'mapbox-gl';

import circleBackgroundImage from './symbol-asset-circle.png';

enum ImageIds {
  Circle = 'asset-circle',
}

export const images = [{ id: ImageIds.Circle, url: circleBackgroundImage, sdf: true }];

/**
 * Mapbox Style Spec layers to product assets features symbology.
 * `feature-state` is supported following the `InteractiveMap` `geoJSON` feature implementation - hover, focus.
 * Symbols use Font Awesome glyphs. These must be accessible from the map style glyphs. https://docs.mapbox.com/mapbox-gl-js/style-spec/glyphs/
 * Since glyphs cannot be set manually on Mapbox hosted styles, add a layer using the font that filters out all features.
 * Each feature should include an `assetType` property - used for symbol icon style (Font Awesome).
 * The value of `assetType` should match a value from the type `/interfaces/Assets/AssetType`.
 * If feature does not have the property or property value does not match an expected value, icon falls back to "small-dot" glyph.
 */
export const layers: Layer[] = [
  {
    id: 'assets-fill',
    type: 'fill',
    filter: ['match', ['geometry-type'], ['Polygon', 'MultiPolygon'], true, false],
    paint: {
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'focus'], false],
        'rgba(26, 199, 187, 0.7)',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(189, 151, 94, 0.7)',
        'rgba(202, 171, 125, 0.7)',
      ],
    },
  },
  {
    id: 'assets-fill-border',
    type: 'line',
    filter: ['match', ['geometry-type'], ['Polygon', 'MultiPolygon'], true, false],
    paint: {
      'line-width': 1.5,
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'focus'], false],
        'rgb(26, 199, 187)',
        ['boolean', ['feature-state', 'hover'], false],
        'rgb(189, 151, 94)',
        'rgb(202, 171, 125)',
      ],
    },
  },
  {
    id: 'assets-line',
    type: 'line',
    filter: ['match', ['geometry-type'], ['LineString', 'MultiLineString'], true, false],
    paint: {
      'line-width': 4,
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'focus'], false],
        'rgba(26, 199, 187, 0.7)',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(189, 151, 94, 0.7)',
        'rgba(202, 171, 125, 0.7)',
      ],
    },
  },
  {
    id: 'assets-symbol',
    type: 'symbol',
    paint: {
      'text-color': '#FFFFFF',
      'icon-color': [
        'case',
        ['boolean', ['feature-state', 'focus'], false],
        '#1AC7BB',
        ['boolean', ['feature-state', 'hover'], false],
        '#BD975E',
        '#CAAB7D',
      ],
    },
    layout: {
      'text-font': ['Font Awesome 6 Sharp Solid'],
      'text-field': [
        'match',
        ['get', 'assetType'],
        ['Water Utility'],
        '',
        ['Path'],
        '',
        ['Camera'],
        '',
        ['Wall'],
        '',
        ['Gate'],
        '',
        ['Irrigation Component'],
        '',
        ['Lake'],
        '',
        ['Fence'],
        '',
        ['Pet Station'],
        '',
        ['Mailbox'],
        '',
        ['Fountain'],
        '',
        ['Entry Monument'],
        '',
        ['Decorative Light'],
        '',
        ['Park Equipment'],
        '',
        ['Street Light'],
        '',
        ['Landscape'],
        '',
        ['Pool'],
        '',
        ['Road Sign'],
        '',
        ['Sport Court'],
        '',
        '',
      ],
      'icon-image': ImageIds.Circle,
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 16, 0.4, 18, 0.5],
      'text-line-height': 1,
      'text-size': ['interpolate', ['linear'], ['zoom'], 10, 11, 16, 13, 18, 15],
      'text-offset': [0.0, 0.2],
    },
  },
];
