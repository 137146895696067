import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { MagicField, MagicForm } from 'components/MagicForm';
import UnsafeArea from 'components/UnsafeArea';
import { useWorkOrderDescription } from 'hooks/useWorkOrderDescription';
import { WorkOrder } from 'interfaces/WorkOrder';
import { closeOutline as closeIcon, createOutline as editIcon } from 'ionicons/icons';
import React, { RefObject, useState } from 'react';
import { createUseStyles } from 'react-jss';

export interface DescriptionModalProps {
  workOrder: WorkOrder;
  pageRef?: RefObject<HTMLElement>;
}

const useStyles = createUseStyles({
  form: {
    height: '100%',
  },
});

const DescriptionModal: React.FC<DescriptionModalProps> = ({ workOrder, pageRef }) => {
  const { editDescription } = useWorkOrderDescription(workOrder.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <IonButtons slot="end" onClick={() => setIsModalOpen(true)}>
        <IonButton>
          <IonIcon icon={editIcon} slot="icon-only" />
        </IonButton>
      </IonButtons>
      <IonModal
        onDidDismiss={() => {
          setIsModalOpen(false);
        }}
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={setIsModalOpen.bind(null, false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>Update {workOrder.titleDisplay}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <MagicForm
            className={classes.form}
            submitText="Save"
            onSubmit={(e) => {
              setIsModalOpen(false);
              editDescription(e.title, e.description);
            }}
            defaultValues={{
              title: workOrder.title || undefined,
              description: workOrder.description || undefined,
            }}
          >
            <MagicField
              name="title"
              fieldType="text"
              label="Title"
              placeholder="Title"
              isModal={true}
              rules={{
                required: 'You must provide a title',
              }}
            />
            <MagicField
              name="description"
              fieldType="text"
              label="Description"
              placeholder="Describe the work to perform"
              isModal={true}
              multiline={true}
              rules={{
                required: 'You must provide a description',
              }}
            />
          </MagicForm>
        </IonContent>
        <UnsafeArea position="bottom" color="primary" />
      </IonModal>
    </>
  );
};

export default DescriptionModal;
