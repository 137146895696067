import { useQuery } from '@apollo/client';
import { InputChangeEventDetail } from '@ionic/core/components';
import {
  IonChip,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPopover,
} from '@ionic/react';
import { useMessages } from 'hooks/useMessages';
import {
  GET_ORGANIZATION_TAGS,
  GetOrganizationTagsData,
  GetOrganizationTagsVariables,
} from 'hooks/useTags';
import { useWorkspace } from 'hooks/useWorkspace';
import { closeCircleOutline as closeIcon } from 'ionicons/icons';
import * as React from 'react';
import { createUseStyles } from 'react-jss';

interface TagsFieldProps {
  tags: string[];
  setTags: (label: string[]) => void;
}

export const useStyles = createUseStyles({
  root: {
    border: '1px solid var(--ion-color-light)',
    '--inner-padding-top': 'calc(var(--ion-margin, 16px) * 0.75)',
    '--inner-padding-bottom': 'calc(var(--ion-margin, 16px) * 0.75)',
  },
  input: {
    marginLeft: 'calc(var(--ion-margin, 16px) * 0.25)',
    marginTop: 'calc(var(--ion-margin, 16px) * .5)',
    marginBottom: 'calc(var(--ion-margin, 16px) * .5)',
  },
  chipsList: {
    paddingBottom: 'calc(var(--ion-margin, 16px) * 0.75)',
    marginLeft: 'calc(var(--ion-margin, 16px) * -0.25)',
  },
});

const TagsField: React.FC<TagsFieldProps> = ({ tags, setTags }) => {
  const [label, setLabel] = React.useState<string | undefined>(undefined);
  const [popoverState, setPopoverState] = React.useState<{
    showPopup: boolean;
    event: CustomEvent<InputChangeEventDetail> | undefined;
  }>({
    showPopup: false,
    event: undefined,
  });
  const classes = useStyles();
  const { workspace } = useWorkspace();
  const { addMessage } = useMessages();

  const { data: getOrganizationTagsData } = useQuery<
    GetOrganizationTagsData,
    GetOrganizationTagsVariables
  >(GET_ORGANIZATION_TAGS, {
    displayName: 'OganizationTagsSearch',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      organizationId: workspace.organizationId,
      text: label || '',
      size: 10,
    },
    onError: () => addMessage('Unable to load tag suggestions.', 'danger'),
  });

  const filteredSearchResults = (getOrganizationTagsData?.organization?.tags?.nodes || []).filter(
    (item) => !tags.includes(item)
  );

  /** Label is neither a currently used tag nor listed in suggestions */
  const shouldSuggestNew = label && !tags.includes(label) && !filteredSearchResults.includes(label);

  const removeTag = (indexToRemove: number) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTag = async (tag: string | undefined) => {
    if (tag) {
      if (!tags.includes(tag)) {
        setPopoverState({ showPopup: false, event: undefined });
        setTags([...tags, tag]);
        setLabel(undefined);
      }
    }
  };

  const handlePopover = (e: CustomEvent<InputChangeEventDetail>) => {
    setLabel(e.detail.value || undefined);
    label
      ? setPopoverState({ showPopup: true, event: e })
      : setPopoverState({ showPopup: false, event: undefined });
  };

  return (
    <IonItemGroup className={classes.root}>
      <IonItem lines="none">
        <IonInput
          mode="md"
          inputmode="text"
          value={label}
          placeholder="Add tags"
          onIonChange={handlePopover}
          className={classes.input}
          onKeyPress={(e) => (e.key === 'Enter' ? addTag(label || undefined) : null)}
        />
        <IonPopover
          mode="md"
          showBackdrop={false}
          keyboardClose={false}
          event={popoverState.event}
          isOpen={popoverState.showPopup}
          onDidDismiss={() => setPopoverState({ showPopup: false, event: undefined })}
        >
          <IonList style={{ padding: 0 }}>
            {shouldSuggestNew && (
              <IonItem
                lines="none"
                mode="md"
                button={true}
                detail={false}
                onClick={() => addTag(label)}
                style={{ '--min-height': '36px' }}
              >
                <p style={{ lineHeight: 0.5 }}>New tag "{label}"</p>
              </IonItem>
            )}
            {filteredSearchResults.map((tag, index) => (
              <IonItem
                lines="none"
                mode="md"
                button={true}
                detail={false}
                key={index}
                onClick={() => addTag(tag)}
                style={{ '--min-height': '36px' }}
              >
                <p style={{ lineHeight: 0.5 }}>{tag}</p>
              </IonItem>
            ))}
          </IonList>
        </IonPopover>
      </IonItem>
      <IonItem lines="none">
        <div className={classes.chipsList}>
          {tags.map((tag, index) => (
            <IonChip key={index} color="primary" outline={true}>
              <IonLabel>{tag}</IonLabel>
              <IonIcon icon={closeIcon} color="primary" onClick={() => removeTag(index)} />
            </IonChip>
          ))}
        </div>
      </IonItem>
    </IonItemGroup>
  );
};

export default TagsField;
