import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    fontSize: '0.875rem',
  },
});

export interface TableHeaderItemProps {
  nameLabel?: string;
  rateLabel?: string;
  quantityLabel?: string;
  totalLabel?: string;
}

/** Header with table columns for for each itemized section */
const TableHeaderItem: React.FC<TableHeaderItemProps> = ({
  nameLabel = '',
  rateLabel = 'Rate',
  quantityLabel = 'Quantity',
  totalLabel = '',
}) => {
  const classes = useStyles();

  return (
    <IonItem className={classes.root}>
      <IonGrid style={{ paddingLeft: 0, paddingRight: 0 }}>
        <IonRow className="ion-align-items-center">
          <IonCol style={{ paddingLeft: 0 }} size="4">
            <IonLabel>{nameLabel}</IonLabel>
          </IonCol>
          <IonCol className="ion-text-center" size="3">
            <IonLabel>{rateLabel}</IonLabel>
          </IonCol>
          <IonCol className="ion-text-center" size="2">
            <IonLabel>{quantityLabel}</IonLabel>
          </IonCol>
          <IonCol className="ion-text-center" size="3" style={{ paddingRight: 0 }}>
            <IonLabel>{totalLabel}</IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default TableHeaderItem;
