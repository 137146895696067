import { WorkOrder } from 'interfaces/WorkOrder';

export const assetsSection = ({ assets }: Pick<WorkOrder, 'assets'>) => [
  {
    columns: [
      [
        { text: 'Assets', style: ['sectionHeader', 'verticalSpacingLg'] },
        ...assets.edges.map((assetEdge) => {
          return [
            {
              text: assetEdge.node.name,
              style: ['verticalSpacingLg'],
            },
            {
              text: assetEdge.node.assetType,
              style: ['assetTypeLocation', 'verticalSpacingSm'],
            },
            {
              text: assetEdge.node.location.description,
              style: ['assetTypeLocation', 'verticalSpacingSm'],
            },
          ];
        }),
      ],
    ],
  },
];
