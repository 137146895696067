import { IonIcon, IonLabel } from '@ionic/react';
import AssetIcon from 'components/AssetIcon';
import { Asset } from 'interfaces/Asset';
import { scanCircleOutline as locationIcon } from 'ionicons/icons';
import React from 'react';

export interface AssetDetailsHeaderProps {
  asset: Asset;
}

const AssetDetailsHeader: React.FC<AssetDetailsHeaderProps> = ({
  asset: { location, assetType, name },
}) => {
  return (
    <div
      style={{
        marginTop: 'calc(var(--ion-margin, 16px) * 0.25 * -1)',
        marginBottom: 'calc(var(--ion-margin, 16px) * 0.25 * -1)',
        marginLeft: 'calc(var(--ion-margin, 16px) * -1)',
        marginRight: 'calc(var(--ion-margin, 16px) * -1)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <IonLabel
        color="dark"
        style={{
          marginTop: 'calc(var(--ion-margin, 16px) * 0.25)',
          marginBottom: 'calc(var(--ion-margin, 16px) * 0.25)',
          marginLeft: 'var(--ion-margin, 16px)',
          marginRight: 'var(--ion-margin, 16px)',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
        }}
      >
        <IonIcon
          icon={locationIcon}
          style={{ fontSize: 20, marginRight: 'calc(var(--ion-margin, 16px) * 0.5)' }}
        />
        <p>
          {location?.description ||
            JSON.parse(location.geometry)
              .coordinates.map((coord: unknown) => parseFloat(coord as string).toFixed(5)) // coords are numbers, but parse to be safe
              .join(', ')}
        </p>
      </IonLabel>
      <IonLabel
        color="dark"
        style={{
          marginTop: 'calc(var(--ion-margin, 16px) * 0.25)',
          marginBottom: 'calc(var(--ion-margin, 16px) * 0.25)',
          marginLeft: 'var(--ion-margin, 16px)',
          marginRight: 'var(--ion-margin, 16px)',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
        }}
      >
        <div style={{ marginRight: 'calc(var(--ion-margin, 16px) * 0.5)' }}>
          <AssetIcon iconStyle="light" assetType={assetType} color="inherit" />
        </div>
        <p>{[assetType, name].filter(Boolean).join(' - ')}</p>
      </IonLabel>
    </div>
  );
};

export default AssetDetailsHeader;
