import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';
import { formatCurrency } from 'utils/formatCurrency';

export const worklogSection = ({ worklog }: Pick<WorkOrder, 'worklog'>) => [
  {
    columns: [
      [
        { text: 'Work Logs', style: ['sectionHeader', 'verticalSpacingLg'] },
        ...worklog.map((task) => {
          return [
            {
              text: `${task.name} (${moment(task.createdAt).format('MMMM D, YYYY')})`,
              style: ['bold', 'verticalSpacingLg'],
            },
            {
              layout: 'noBorders',
              style: ['bold', 'verticalSpacingMd'],
              table: {
                widths: ['*', '*'],
                body: [['Total', { text: formatCurrency(task.totalCost), alignment: 'right' }]],
              },
            },
            {
              layout: 'noBorders',
              style: ['commentHeader', 'verticalSpacingMd'],
              table: {
                widths: ['*', '*'],
                body: [['Labor', { text: formatCurrency(task.laborCost), alignment: 'right' }]],
              },
            },
            {
              layout: 'itemizedWorklog',
              table: {
                headerRows: 1,
                widths: ['*', 100, 100, 100],
                body: [
                  task.laborItems.length && [
                    '',
                    { text: 'Rate', alignment: 'right' },
                    { text: 'Hours', alignment: 'right' },
                    '',
                  ],
                  ...task.laborItems.map((labor) => {
                    return [
                      labor.account.name,
                      { text: formatCurrency(labor.rate), alignment: 'right' },
                      { text: labor.hours, alignment: 'right' },
                      { text: formatCurrency(labor.itemCost), alignment: 'right' },
                    ];
                  }),
                ],
              },
            },
            {
              layout: 'noBorders',
              style: ['commentHeader', 'verticalSpacingMd'],
              table: {
                widths: ['*', '*'],
                body: [
                  ['Equipment', { text: formatCurrency(task.equipmentCost), alignment: 'right' }],
                ],
              },
            },
            {
              layout: 'itemizedWorklog',
              table: {
                headerRows: 1,
                widths: ['*', 100, 100, 100],
                body: [
                  task.equipmentItems.length && [
                    '',
                    { text: 'Rate', alignment: 'right' },
                    { text: 'Hours', alignment: 'right' },
                    '',
                  ],
                  ...task.equipmentItems.map((equipment) => {
                    return [
                      equipment.equipment.name,
                      { text: formatCurrency(equipment.rate), alignment: 'right' },
                      { text: equipment.hours, alignment: 'right' },
                      { text: formatCurrency(equipment.itemCost), alignment: 'right' },
                    ];
                  }),
                ],
              },
            },
            {
              layout: 'noBorders',
              style: ['commentHeader', 'verticalSpacingMd'],
              table: {
                widths: ['*', '*'],
                body: [
                  ['Materials', { text: formatCurrency(task.materialCost), alignment: 'right' }],
                ],
              },
            },
            {
              layout: 'itemizedWorklog',
              table: {
                headerRows: 1,
                widths: ['*', 100, 100, 100],
                body: [
                  task.materialItems.length && [
                    '',
                    { text: 'Unit Cost', alignment: 'right' },
                    { text: 'Units', alignment: 'right' },
                    '',
                  ],
                  ...task.materialItems.map((material) => {
                    return [
                      material.name,
                      { text: formatCurrency(material.unitCost), alignment: 'right' },
                      { text: material.quantity, alignment: 'right' },
                      { text: formatCurrency(material.itemCost), alignment: 'right' },
                    ];
                  }),
                ],
              },
            },
          ];
        }),
      ],
    ],
  },
];
