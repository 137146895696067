import React from 'react';

const MapPopup: React.FC = (props) => {
  return (
    <div
      style={{
        zIndex: 1000, // Place over any map buttons (ie. geolocation)
        position: 'absolute',
        top: 10,
        left: 0,
        width: '100vw',
        maxWidth: 414,
      }}
    >
      {props.children}
    </div>
  );
};

export default MapPopup;
