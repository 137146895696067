import { IonItem, IonLabel } from '@ionic/react';
import Avatar from 'components/Avatar';
import React from 'react';
import { createUseStyles } from 'react-jss';

export interface AssignmentDisplayProps {
  label: string;
  placeholderIcon: string;
  placeholderText: string;
  value?: string;
  onClick: () => void;
}

const lightColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-light');

const mediumColor = getComputedStyle(document.documentElement).getPropertyValue(
  '--ion-color-medium'
);

const useStyles = createUseStyles({
  root: {
    borderRadius: '4px',
    '--background': 'var(--ion-color-light-tint, var(--ion-item-background, transparent))',
  },
});

const AssignmentDisplay: React.FC<AssignmentDisplayProps> = ({
  label,
  placeholderIcon,
  placeholderText,
  value,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <IonItem button={true} className={classes.root} lines="none" onClick={onClick}>
      {value ? (
        <Avatar alt={value} />
      ) : (
        <Avatar bgColor={lightColor} color={mediumColor} icon={placeholderIcon} />
      )}
      <IonLabel>
        <h3 style={{ fontSize: '0.9375rem' }}>{label}</h3>
        <p>{value || placeholderText}</p>
      </IonLabel>
    </IonItem>
  );
};

export default AssignmentDisplay;
