import { gql, useMutation, useQuery } from '@apollo/client';
import { Message } from 'interfaces/Message';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { logException } from 'services/logger';

export const GET_MESSAGES = gql`
  query GetMessages {
    messages @client
  }
`;

export const ADD_MESSAGE = gql`
  mutation addMessage($content: String!, $color: String, $timestamp: Int!) {
    addMessage(input: { color: $color, content: $content, timestamp: $timestamp }) @client {
      messages @client
    }
  }
`;

export const CLEAR_MESSAGE = gql`
  mutation clearMessage($timestamp: Int!) {
    clearMessage(input: { timestamp: $timestamp }) @client {
      messages @client
    }
  }
`;

export const useMessages = () => {
  const [display, setDisplay] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  const { loading, data } = useQuery(GET_MESSAGES);

  const [add, { loading: addMessageLoading }] = useMutation(ADD_MESSAGE);
  const [clear, { loading: clearMessageLoading }] = useMutation(CLEAR_MESSAGE);

  const addMessage = useCallback(
    async (content: string, color?: string) => {
      try {
        const timestamp = moment().valueOf();
        const result = await add({ variables: { content, color, timestamp } });
        return result;
      } catch (err) {
        logException(err);
      }
    },
    [add]
  );

  const clearMessage = useCallback(
    async (timestamp: number) => {
      try {
        const result = await clear({ variables: { timestamp } });
        return result;
      } catch (err) {
        logException(err);
      }
    },
    [clear]
  );

  useEffect(() => {
    messages.length > 0 ? setDisplay(true) : setDisplay(false);
  }, [messages.length, setDisplay]);

  useEffect(() => {
    if (!loading && data && data.messages) {
      const records: Message[] = data.messages;
      setMessages(records);
    }
  }, [data, loading]);

  return {
    display,
    messages,
    loading: loading || addMessageLoading || clearMessageLoading,
    addMessage,
    clearMessage,
  };
};
