import 'quill-mention';
import 'react-quill/dist/quill.snow.css';

import './CommentBar.css';

import { Mention, Mentionable } from 'interfaces/Comment';
import * as Quill from 'quill';
import React, { useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';

const DENOTATION_CHAR = '@';
const PARSE_CONTENT_REGEX = /<@|>/;
const VALIDATE_MENTION_REGEX = /(user|team|org):([a-zA-Z0-9-]*):([^>]*)/;

const toString = (contents: Quill.DeltaStatic): string => {
  return contents.reduce((acc, content, idx, arr) => {
    if (typeof content.insert === 'string') {
      acc += content.insert;
    }

    if (content.insert?.mention) {
      const { kind, entityId, label } = content.insert.mention;
      acc += `<@${kind}:${entityId}:${label}>`;
    }

    if (idx === arr.length - 1) {
      const end = acc.lastIndexOf('\n');
      acc = acc.substr(0, ~end ? end : arr.length);
    }

    return acc;
  }, '');
};

const toContents = (str: string): Pick<Quill.DeltaStatic, 'ops'> => {
  const chunks = str.split(PARSE_CONTENT_REGEX).filter((v) => v);

  const ops = chunks.map<Quill.DeltaOperation>((chunk, index) => {
    const [mention] = chunk.match(VALIDATE_MENTION_REGEX) || [];

    if (mention) {
      const [kind, entityId, label] = mention.split(':');

      return {
        insert: {
          mention: {
            index,
            denotationChar: DENOTATION_CHAR,
            id: entityId,
            value: label,
            kind,
            entityId,
            label,
          },
        },
      };
    }

    return { insert: chunk };
  });

  return { ops: [...ops, { insert: '\n' }] };
};

type RenderList<TProps> = (items: TProps[], query: string) => void;

type ListItem<TProps> = TProps & {
  id: string;
  value: string;
};

type InsertItem<TProps> = (item: ListItem<TProps>) => void;

export interface CommentInputProps {
  className?: string;
  allowMentions?: boolean;
  mentionables?: Mentionable[];
  placeholder?: string;
  readOnly?: boolean;
  /**
   * Optional string value to initialize component content.
   * Changes after component mounts will have no affect. (ie. uncontrolled input)
   */
  defaultValue?: string;
  onChange?: (value: string) => void;
  onEnter?: (value: string) => void;
}

const CommentInput: React.FC<CommentInputProps> = ({
  className,
  allowMentions = true,
  mentionables = [],
  placeholder = 'Add a comment...',
  readOnly = false,
  defaultValue = '',
  onChange,
  onEnter,
}) => {
  const menuRef = useRef({ isOpen: false });
  const quillRef = useRef<ReactQuill>(null);

  const modules = useMemo(
    () => ({
      toolbar: false,
      mention: {
        allowedChars: allowMentions ? /[^<:>]/ : /a^/,
        dataAttributes: ['denotationChar', 'detail', 'kind', 'name'],
        isolateCharacter: true,
        mentionDenotationChars: [DENOTATION_CHAR],
        source: (query: string, renderSearchResults: RenderList<Mentionable>) => {
          const searchResults = query.length
            ? mentionables.filter((m) => ~m.name.toLowerCase().indexOf(query.toLowerCase()))
            : mentionables;

          renderSearchResults(searchResults, query);
        },
        renderItem: (item: Mentionable) => `@${item.name}${item.detail ? ` (${item.detail})` : ''}`,
        onOpen: () => (menuRef.current.isOpen = true),
        onClose: () => (menuRef.current.isOpen = false),
        onSelect: (item: Mentionable, insertItem: InsertItem<Mention>) => {
          insertItem({
            ...item,
            value: item.name,
            kind: item.kind,
            entityId: item.id,
            label: item.name,
          });
        },
      },
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: () => {
              if (!(onEnter && quillRef.current) || menuRef.current.isOpen) {
                return true;
              }

              const editor = quillRef.current.getEditor();
              onEnter(toString(editor.getContents()));
            },
          },
        },
      },
    }),
    [allowMentions, mentionables, onEnter]
  );

  return (
    <ReactQuill
      ref={quillRef}
      className={className}
      modules={modules}
      placeholder={placeholder}
      readOnly={readOnly}
      defaultValue={toContents(defaultValue) as Quill.Delta}
      onChange={(_content, _delta, _source, editor) => {
        if (!menuRef.current.isOpen) {
          onChange && onChange(toString(editor.getContents()));
        }
      }}
    />
  );
};

export default CommentInput;
