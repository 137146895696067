import { useMutation, useQuery, useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { useMessages } from 'hooks/useMessages';

import {
  ChannelQueryResult,
  ChannelQueryVariables,
  ChannelSubResult,
  ChannelSubVariables,
  DeleteMessageResult,
  DeleteMessageVariables,
  EditMessageResult,
  EditMessageVariables,
  PostMessageResult,
  PostMessageVariables,
  UseChatConfig,
  UseChatResult,
} from './interfaces';

const fragments = {
  channelDisplay: gql`
    fragment ChannelDisplay on Channel {
      id
      topicId
      messages {
        id
        author {
          name
          email
          phone
        }
        content
        createdAt
        createdBy {
          id
        }
        modifiedAt
        isCurrentUser @client
        isEdited @client
      }
      createdAt
      modifiedAt
    }
  `,
  messageDisplay: gql`
    fragment MessageDisplay on Message {
      id
      author {
        name
        email
        phone
      }
      content
      createdAt
      createdBy {
        id
      }
      modifiedAt
      isCurrentUser @client
      isEdited @client
    }
  `,
};

const GET_CHANNEL_BY_TOPIC_ID = gql`
  query GetChannelByTopicId($topicId: ID!) {
    chatChannelByTopicId(topicId: $topicId) {
      ...ChannelDisplay
    }
  }
  ${fragments.channelDisplay}
`;

const CHANNEL_UPDATED_SUBSCRIPTION = gql`
  subscription ChannelUpdatedSubscription($id: ID!) {
    channelUpdated(id: $id) {
      ...ChannelDisplay
    }
  }
  ${fragments.channelDisplay}
`;

const POST_MESSAGE = gql`
  mutation PostChatMessage($input: PostChatMessageInput!) {
    postChatMessage(input: $input) {
      channel {
        ...ChannelDisplay
      }
      message {
        ...MessageDisplay
      }
    }
  }
  ${fragments.channelDisplay}
  ${fragments.messageDisplay}
`;

const EDIT_MESSAGE = gql`
  mutation EditChatMessage($input: EditChatMessageInput!) {
    editChatMessage(input: $input) {
      channel {
        ...ChannelDisplay
      }
      message {
        ...MessageDisplay
      }
    }
  }
  ${fragments.channelDisplay}
  ${fragments.messageDisplay}
`;

const DELETE_MESSAGE = gql`
  mutation DeleteChatMessage($input: DeleteChatMessageInput!) {
    deleteChatMessage(input: $input) {
      channel {
        ...ChannelDisplay
      }
    }
  }
  ${fragments.channelDisplay}
`;

export const useChat = ({ topicId }: UseChatConfig): UseChatResult => {
  const { addMessage: addErrorMessage } = useMessages();

  const [postMessage] = useMutation<PostMessageResult, PostMessageVariables>(POST_MESSAGE, {
    onError: () => addErrorMessage('Unable to post message', 'danger'),
  });

  const [editMessage] = useMutation<EditMessageResult, EditMessageVariables>(EDIT_MESSAGE, {
    onError: () => addErrorMessage('Unable to save changes', 'danger'),
  });

  const [deleteMessage] = useMutation<DeleteMessageResult, DeleteMessageVariables>(DELETE_MESSAGE, {
    onError: () => addErrorMessage('Unable to delete message', 'danger'),
  });

  const { data: channelQuery } = useQuery<ChannelQueryResult, ChannelQueryVariables>(
    GET_CHANNEL_BY_TOPIC_ID,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !topicId,
      variables: { topicId },
    }
  );

  const { data: channelSubscription } = useSubscription<ChannelSubResult, ChannelSubVariables>(
    CHANNEL_UPDATED_SUBSCRIPTION,
    {
      skip: !channelQuery,
      variables: { id: channelQuery?.chatChannelByTopicId?.id },
    }
  );

  return {
    channel: channelSubscription?.channelUpdated || channelQuery?.chatChannelByTopicId,
    postMessage,
    editMessage,
    deleteMessage,
  };
};
