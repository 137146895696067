import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonList,
  IonMenu,
  IonMenuButton,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import { useStatus as fetchStatus } from 'hooks/useStatus';
import { Account } from 'interfaces/Account';
import { AreaOfInterest } from 'interfaces/AreaOfInterest';
import { Category } from 'interfaces/Category';
import { Status } from 'interfaces/Status';
import { Team } from 'interfaces/Team';
import { ellipse as indicatorIcon, filterCircleOutline as filtersIcon } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';

import { FilterType } from './FilterType';
import Filter, { FilterCriterion } from './SearchFilter';
import { ReducerState as Filters } from './reducer';
import { FiltersContext } from './useWorkOrderFilter';

const useStyles = createUseStyles({
  indicator: {
    position: 'absolute',
    '.md &': {
      height: '0.625em',
      width: '0.625em',
      bottom: '10px',
      right: '4px',
    },
    '.ios &': {
      height: '0.7832em',
      width: '0.7832em',
      bottom: 0,
      right: 0,
    },
  },
  searchbar: {
    '& input.searchbar-input': {
      paddingLeft: 'var(--ion-padding, 16px) !important',
    },
  },
});

export interface ToggleProps {
  hasChecked: boolean;
}

export interface WorkOrderFilterProps {
  categories: { category: Pick<Category, 'id' | 'name'>; count: number }[];
  statuses: { status: Status; count: number }[];
  areasOfInterest: { areaOfInterest: Pick<AreaOfInterest, 'id' | 'name'>; count: number }[];
  teams: { team: Pick<Team, 'id' | 'name'>; count: number }[];
  assignees: { assignee: Pick<Account, 'id' | 'name'>; count: number }[];
  tags: { tag: string; count: number }[];
}

interface ComponentProps extends WorkOrderFilterProps {
  contentId: string;
  filters: Filters;
  street?: string;
  /** Properties to apply to `IonContent` parent of filter panel */
  filterContentProps?: React.ComponentProps<typeof IonContent>;
  clear: (filter: FilterType) => void;
  clearAll: () => void;
  isChecked: (filter: FilterType, criterion: string) => boolean;
  toggleChecked: (filter: FilterType, criterion: string) => void;
  search: (filter: FilterType, criterion: string | undefined) => void;
  update: (filter: FilterType, criteria: Map<string, boolean>) => void;
}

const WorkOrderFilters = ({
  contentId,
  categories,
  statuses,
  areasOfInterest,
  street,
  filterContentProps,
  teams,
  assignees,
  tags,
  filters,
  clear,
  clearAll,
  isChecked,
  toggleChecked,
  search,
  update,
}: React.PropsWithChildren<ComponentProps>) => {
  const classes = useStyles();

  const value = useMemo(
    () => ({
      filters,
      clear,
      clearAll,
      isChecked,
      toggleChecked,
      search,
      update,
    }),
    [filters, clear, clearAll, isChecked, toggleChecked, search, update]
  );

  return (
    <FiltersContext.Provider value={value}>
      <IonMenu contentId={contentId} menuId="work-order-filters" side="end">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Advanced Filters</IonTitle>
            <IonButton
              fill="clear"
              mode="ios"
              slot="end"
              style={{ fontSize: 'inherit', marginBottom: 0 }}
              onClick={clearAll}
            >
              Clear All
            </IonButton>
          </IonToolbar>
        </IonHeader>

        <IonContent {...filterContentProps}>
          <IonList className="ion-no-padding">
            <Filter
              title="Category"
              filterBy={FilterType.Category}
              items={categories.reduce<FilterCriterion[]>(
                (acc, { category, count }) =>
                  category
                    ? [
                        ...acc,
                        {
                          value: category.id,
                          label: category.name,
                          count,
                          isActive: isChecked(FilterType.Category, category.id),
                        },
                      ]
                    : acc,
                []
              )}
              buttonText={`See All ${categories.length} Categories`}
            />

            <Filter
              title="Status"
              filterBy={FilterType.Status}
              items={statuses.map(({ status, count }) => ({
                value: status,
                label: fetchStatus(status).label,
                count,
                isActive: isChecked(FilterType.Status, status),
              }))}
              buttonText={`See All ${statuses.length} Statuses`}
            />

            <Filter
              title="Areas"
              filterBy={FilterType.AreasOfInterest}
              items={areasOfInterest.reduce<FilterCriterion[]>(
                (acc, { areaOfInterest, count }) =>
                  areaOfInterest
                    ? [
                        ...acc,
                        {
                          value: areaOfInterest.id,
                          label: areaOfInterest.name,
                          count,
                          isActive: isChecked(FilterType.AreasOfInterest, areaOfInterest.id),
                        },
                      ]
                    : acc,
                []
              )}
              buttonText={`See All ${areasOfInterest.length} Areas`}
            />

            <ListSeparator />
            <IonItemGroup>
              <IonItemDivider>Street</IonItemDivider>
              <IonItem lines="full" style={{ '--padding-start': 0, '--inner-padding-end': 0 }}>
                <IonSearchbar
                  className={classes.searchbar}
                  debounce={1000}
                  mode="md"
                  searchIcon="undefined"
                  value={street}
                  onIonChange={(e) => search(FilterType.Street, e.detail.value || undefined)}
                />
              </IonItem>
            </IonItemGroup>

            <Filter
              title="Team"
              filterBy={FilterType.Team}
              items={teams.reduce<FilterCriterion[]>(
                (acc, { team, count }) =>
                  team
                    ? [
                        ...acc,
                        {
                          value: team.id,
                          label: team.name,
                          count,
                          isActive: isChecked(FilterType.Team, team.id),
                        },
                      ]
                    : acc,
                []
              )}
              buttonText={`See All ${teams.length} Teams`}
            />

            <Filter
              title="Assigned To"
              filterBy={FilterType.Assignee}
              items={assignees.reduce<FilterCriterion[]>(
                (acc, { assignee, count }) =>
                  assignee
                    ? [
                        ...acc,
                        {
                          value: assignee.id,
                          label: assignee.name,
                          count,
                          isActive: isChecked(FilterType.Assignee, assignee.id),
                        },
                      ]
                    : acc,
                []
              )}
              buttonText={`See All ${assignees.length} Assignees`}
            />

            <Filter
              title="Tags"
              filterBy={FilterType.Tag}
              items={tags.map(({ tag, count }) => ({
                value: tag,
                label: tag,
                count,
                isActive: isChecked(FilterType.Tag, tag),
              }))}
              buttonText={`See All ${tags.length} Tags`}
            />

            <ListSeparator hideBorder={true} />
          </IonList>
        </IonContent>
      </IonMenu>
    </FiltersContext.Provider>
  );
};

const Toggle: React.FC<ToggleProps> = ({ hasChecked }) => {
  const classes = useStyles();

  return (
    <IonMenuButton
      menu="work-order-filters"
      onClick={(event) => event.stopPropagation()}
      style={{ position: 'relative' }}
    >
      <IonIcon icon={filtersIcon} />
      {hasChecked && (
        <IonIcon
          className={classes.indicator}
          color="secondary"
          icon={indicatorIcon}
          size="small"
        />
      )}
    </IonMenuButton>
  );
};

WorkOrderFilters.Toggle = Toggle;

export default WorkOrderFilters;
