import { Location } from 'interfaces/Location';
import { useCallback } from 'react';
import { GeocoderService } from 'services/GeocoderService';

export const parseAddress = (input: string) => {
  if (!input || input.length === 0) return input;
  const regex = /([^,]+)/gi;
  const found = input.match(regex);
  return found && found[0] ? found[0] : input;
};

export const isValidAddress = (address: string) => {
  const regex = /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|Ave|Dr|Rd|Blvd|Ln|St)\.?/g;
  return address.length > 0 && regex.test(address);
};

export const useGeocoder = () => {
  const reverseGeocode = useCallback(async (longitude: number, latitude: number): Promise<
    Location
  > => {
    try {
      const geocoder = new GeocoderService();
      const result: Location = await geocoder.reverse(longitude, latitude);
      return result;
    } catch (err) {
      throw err;
    }
  }, []);

  const forwardGeocode = useCallback(
    async (query: string, options: { proximity?: [number, number] } = {}): Promise<Location[]> => {
      try {
        const geocoder = new GeocoderService();
        const items = await geocoder.forward(query, options);
        return items;
      } catch (err) {
        throw err;
      }
    },
    []
  );

  return {
    forwardGeocode,
    reverseGeocode,
  };
};

export default useGeocoder;
