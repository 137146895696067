import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AddNewItemButton from 'components/AddNewItemButton';
import SelectionItem from 'components/SelectionItem';
import UnsafeArea from 'components/UnsafeArea';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import { Equipment } from 'interfaces/Equipment';
import { TaskEquipment } from 'interfaces/Worklog';
import {
  arrowBackOutline as backIcon,
  arrowForwardOutline as nextIcon,
  closeOutline as closeIcon,
} from 'ionicons/icons';
import isEmpty from 'lodash.isempty';
import sort from 'lodash.sortby';
import React, { PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import EquipmentForm from './EquipmentForm';

const useStyles = createUseStyles({
  root: {
    '--inner-padding-start': '10px',
    '--inner-padding-end': '0px',
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
});

export interface EquipmentModalProps {
  availableEquipment: Pick<Equipment, 'id' | 'name' | 'rate' | 'isActive'>[];
  keys?: string[];
  sortBy?: string;
  label?: string;
  pageRef?: RefObject<HTMLElement>;
  previouslyUsed: Pick<TaskEquipment, 'equipment' | 'rate' | 'hours' | 'itemCost' | 'mileage'>[];
  onSubmit: (
    data: {
      equipmentId: string;
      rate: number;
      hours: number;
      mileage?: number;
    }[]
  ) => Promise<void>;
}

export type EquipmentOption = Pick<Equipment, 'id' | 'name' | 'rate'>;

export interface SelectedEquipment {
  equipment: EquipmentOption;
  form: {
    rate: string | undefined;
    hours: string | undefined;
    mileage?: string;
  };
}

const EquipmentModal = ({
  availableEquipment,
  keys = ['name'],
  sortBy = 'name',
  label = 'Add Equipment',
  pageRef,
  previouslyUsed,
  onSubmit,
}: PropsWithChildren<EquipmentModalProps>) => {
  const classes = useStyles();
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<SelectedEquipment[]>([]);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { results, setQuery } = useFuzzySearch({
    list: availableEquipment,
    options: { keys, threshold: 0.2 },
  });

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false,
  };

  const submit = async () => {
    if (isEmpty(selectedEquipment)) return;

    try {
      await onSubmit(
        selectedEquipment.map((item) => ({
          equipmentId: item.equipment.id,
          rate: parseFloat(item.form.rate as string),
          hours: parseFloat(item.form.hours as string),
          mileage: parseFloat(item.form.mileage as string),
        }))
      );
      setIsModalOpen(false);
    } catch (_err) {
      // NOOP
    }
  };

  const handleSelect = (props: SelectedEquipment) => {
    const index = selectedEquipment.findIndex((el) => el.equipment.id === props.equipment.id);
    if (index === -1) {
      setSelectedEquipment([...selectedEquipment, props]);
    } else {
      setSelectedEquipment([
        ...selectedEquipment.slice(0, index),
        ...selectedEquipment.slice(index + 1),
      ]);
    }
  };

  const updateForm = (props: SelectedEquipment) => {
    setSelectedEquipment(
      selectedEquipment?.map((item) => (item.equipment === props.equipment ? props : item))
    );
  };

  const updateRateAll = (props: SelectedEquipment) => {
    setSelectedEquipment(
      selectedEquipment?.map((item) =>
        item.equipment === props.equipment
          ? props
          : { ...item, form: { ...item.form, rate: props.form.rate } }
      )
    );
  };

  const updateRateEmpty = (props: SelectedEquipment) => {
    setSelectedEquipment(() => {
      return selectedEquipment?.map((item) => {
        if (item.equipment === props.equipment) {
          return props;
        } else {
          if (item.form.rate === undefined) {
            return { ...item, form: { ...item.form, rate: props.form.rate } };
          }
          return item;
        }
      });
    });
  };

  const updateHoursAll = (props: SelectedEquipment) => {
    setSelectedEquipment(
      selectedEquipment?.map((item) =>
        item.equipment === props.equipment
          ? props
          : { ...item, form: { ...item.form, hours: props.form.hours } }
      )
    );
  };

  const updateHoursEmpty = (props: SelectedEquipment) => {
    setSelectedEquipment(() => {
      return selectedEquipment?.map((item) => {
        if (item.equipment === props.equipment) {
          return props;
        } else {
          if (item.form.hours === undefined) {
            return { ...item, form: { ...item.form, hours: props.form.hours } };
          }
          return item;
        }
      });
    });
  };

  const canSubmit = !selectedEquipment.some(
    (item) => item.form.rate === undefined || item.form.hours === undefined
  );

  useEffect(() => {
    contentRef.current && contentRef.current.scrollToTop();
  }, [slideIndex]);

  return (
    <>
      <AddNewItemButton title={label} onClick={() => setIsModalOpen(true)} />
      <IonModal
        onDidDismiss={() => {
          setSlideIndex(0);
          setIsModalOpen(false);
          setSelectedEquipment([]);
          setSearchTerm('');
        }}
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>{label}</IonTitle>
          </IonToolbar>
          {slideIndex === 0 && (
            <IonToolbar>
              <IonSearchbar
                mode="md"
                debounce={500}
                value={searchTerm}
                onIonChange={({ target }: any) => {
                  setSearchTerm(target.value);
                  setQuery(target.value);
                }}
              />
            </IonToolbar>
          )}
        </IonHeader>

        <IonContent ref={contentRef}>
          <IonSlides pager={false} options={slideOpts} ref={slidesRef}>
            <IonSlide>
              {Boolean(slideIndex === 0) && (
                <IonList lines="full" className="ion-no-padding" style={{ width: '100%' }}>
                  {results.length > 0 ? (
                    sort(results, sortBy).map((result, i) => (
                      <SelectionItem
                        key={i}
                        detail={false}
                        lines="full"
                        title={result.name}
                        onClick={() =>
                          handleSelect({
                            equipment: result,
                            form: {
                              rate: result?.rate?.primary.toString(),
                              hours: undefined,
                            },
                          })
                        }
                        disabled={previouslyUsed.some((e) => e.equipment.id === result.id)}
                        selected={selectedEquipment?.some(
                          (account) => account.equipment.id === result.id
                        )}
                      />
                    ))
                  ) : (
                    <IonItem>
                      <IonLabel>No Results</IonLabel>
                    </IonItem>
                  )}
                </IonList>
              )}
            </IonSlide>
            <IonSlide>
              <IonList lines="full" className="ion-no-padding" style={{ width: '100%' }}>
                {Boolean(selectedEquipment.length) && (
                  <IonItem lines="full" detail={false} className={classes.root}>
                    <IonGrid style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                      <IonRow className="ion-align-items-center">
                        <IonCol size="4" />
                        <IonCol className="ion-text-center" size="2">
                          <IonLabel>Rate</IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-center" size="3">
                          <IonLabel>Hours</IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-center" size="3">
                          <IonLabel>Mileage</IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonButtons slot="end" style={{ visibility: 'hidden' }}>
                      <IonButton>
                        <IonIcon slot="icon-only" icon={closeIcon} />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                )}
                {selectedEquipment.length > 0 ? (
                  selectedEquipment?.map((selected) => (
                    <EquipmentForm
                      selected={selected}
                      handleUpdate={updateForm}
                      handleUpdateRateAll={updateRateAll}
                      handleUpdateRateEmpty={updateRateEmpty}
                      handleUpdateHoursAll={updateHoursAll}
                      handleUpdateHoursEmpty={updateHoursEmpty}
                      key={selected.equipment.id}
                    />
                  ))
                ) : (
                  <IonItem>
                    <IonLabel>No Selected Equipment Remaining</IonLabel>
                  </IonItem>
                )}
              </IonList>
            </IonSlide>
          </IonSlides>
        </IonContent>
        <IonToolbar>
          {slideIndex === 1 && (
            <>
              <IonButtons slot="start">
                <IonButton
                  fill="clear"
                  onClick={() => {
                    slidesRef.current?.slidePrev();
                    slidesRef.current?.getActiveIndex().then((index) => setSlideIndex(index));
                  }}
                >
                  <IonIcon slot="icon-only" icon={backIcon} />
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton fill="clear" disabled={!canSubmit} onClick={submit}>
                  Submit
                </IonButton>
              </IonButtons>
            </>
          )}
          {slideIndex === 0 && (
            <IonButtons slot="end">
              <IonButton
                fill="clear"
                disabled={Boolean(!selectedEquipment?.length)}
                onClick={() => {
                  slidesRef.current?.slideNext();
                  slidesRef.current?.getActiveIndex().then((index) => setSlideIndex(index));
                }}
              >
                <IonIcon slot="icon-only" icon={nextIcon} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
        <UnsafeArea position="bottom" />
      </IonModal>
    </>
  );
};

export default EquipmentModal;
