import { IonItem } from '@ionic/react';
import React from 'react';

export interface ListSeparatorProps {
  height?: string;
  hideBorder?: boolean;
}

/** Light color separation between sections of a list */
const ListSeparator: React.FC<ListSeparatorProps> = ({ height, hideBorder }) => (
  <IonItem
    lines={hideBorder ? 'none' : 'full'}
    style={{
      '--min-height': height || 'calc(var(--ion-padding, 16px) * 0.5)',
      '--background': 'var(--ion-background-color, #fff)',
    }}
  />
);

export default ListSeparator;
