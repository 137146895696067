import { useMutation, useQuery } from '@apollo/client';
import { IonContent, IonPage } from '@ionic/react';
import invitationAccepted from 'assets/images/invitation-accepted.svg';
import AccountCard, { AccountButton, AccountCardSkeleton } from 'components/AccountCard';
import FluidBanner from 'components/FluidBanner';
import { useIdentityUser } from 'hooks/useIdentityUser';
import { Account } from 'interfaces/Account';
import { AccountStatus } from 'interfaces/AccountStatus';
import { chevronForward, thumbsDown, thumbsUp } from 'ionicons/icons';
import { ACTIVATE_ACCOUNT, GET_ACCOUNT } from 'pages/Accounts/graphql';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

const ActivateAccount: React.FC<{}> = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const decoded = JSON.parse(atob(token));

  const user = useIdentityUser();

  const { data, loading: loadingAccount } = useQuery<{ account: Account }>(GET_ACCOUNT, {
    displayName: 'useAccount',
    partialRefetch: true,
    variables: { accountId: decoded.accountId },
  });

  const isInviteInvalid = useMemo(() => {
    if (!data) return;

    const { status } = data.account;

    const isTokenExpired = decoded.expiresAt < Date.now();

    return (
      status === AccountStatus.Deactivated || (isTokenExpired && status !== AccountStatus.Activated)
    );
  }, [data, decoded]);

  const [activateAccount, { loading: loadingActivateAccount }] = useMutation(ACTIVATE_ACCOUNT);

  if (!data?.account || loadingAccount || loadingActivateAccount) {
    return (
      <IonPage>
        <IonContent>
          <FluidBanner graphic={invitationAccepted}>
            <AccountCardSkeleton hasMultipleActions={true} />
          </FluidBanner>
        </IonContent>
      </IonPage>
    );
  }

  const actions: AccountButton[] = [];

  if (!data.account.activatedAt) {
    actions.push({
      label: 'Decline',
      icon: thumbsDown,
      color: 'danger',
      expand: 'full',
      onClick: () => history.push('/'),
    });
    actions.push({
      label: 'Activate',
      icon: thumbsUp,
      color: 'success',
      expand: 'full',
      onClick: () =>
        activateAccount({
          variables: {
            input: {
              accountId: data.account.id,
              identityId: user.id,
            },
          },
        }),
    });
  } else {
    actions.push({
      label: 'Get Started',
      icon: chevronForward,
      iconSlot: 'end',
      color: 'primary',
      expand: 'full',
      onClick: () => setTimeout(() => history.push('/'), 3000),
    });
  }

  return (
    <IonPage>
      <IonContent>
        <FluidBanner graphic={invitationAccepted}>
          <AccountCard account={data.account} actions={actions} invalidInvite={isInviteInvalid} />
        </FluidBanner>
      </IonContent>
    </IonPage>
  );
};

export default ActivateAccount;
