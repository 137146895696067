import { useIonViewDidEnter } from '@ionic/react';
import { useRef } from 'react';

export const useMaintainScrollPosition = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    const restoreScrollPosition = async () => {
      const storedScrollY = sessionStorage.getItem('scrollY');
      if (storedScrollY && contentRef.current) {
        const targetScrollY = parseInt(storedScrollY, 10);
        contentRef.current.scrollToPoint(undefined, targetScrollY, 1000);
      }
    };
    setTimeout(() => {
      restoreScrollPosition();
    }, 1000);
  });

  // Save scroll position on scroll end
  const handleScrollEnd = async () => {
    if (contentRef.current) {
      const scrollEl = await contentRef.current.getScrollElement();
      sessionStorage.setItem('scrollY', String(scrollEl.scrollTop));
    }
  };

  return { contentRef, handleScrollEnd };
};
