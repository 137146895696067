import { JSX } from '@ionic/core/components';
import {
  IonActionSheet,
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonModal,
  IonNote,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import { CommentInput } from 'components/CommentBar';
import UnsafeArea from 'components/UnsafeArea';
import { Comment, Mentionable } from 'interfaces/Comment';
import {
  closeOutline as closeIcon,
  createOutline as editIcon,
  trashBinOutline as deleteIcon,
} from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { prettyDate } from 'utils/prettyDate';

const isMobile = isPlatform('mobile');

const useStyles = createUseStyles({
  item: {
    '& h3': {
      marginBottom: 6,
    },
    '& p': {
      marginBottom: 0,
    },
  },
  date: {
    '--padding-start': 'var(--ion-margin, 16px)',
    '.sc-ion-label-md-s &': {
      // Match top margin on `.sc-ion-label-md-s h3` to align baselines
      marginTop: 2,
    },
  },
  content: {
    '& .ql-editor': {
      padding: 0,

      '.md & > p': {
        color: 'var(--ion-color-step-600, #666666)',
        padding: '3px 0',
        lineHeight: 1.5,
      },

      '.ios & > p': {
        color: 'rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.4)',
        padding: '3px 0',
        lineHeight: 1.58,
      },
    },
  },
  inputContainer: {
    overflow: 'visible',
    margin: 'calc(var(--ion-margin, 8px) * 2)',
    border: '1px solid var(--ion-color-light)',
    '--padding-start': 'calc(var(--ion-padding, 16px) * 0.5)',
    '--padding-end': 'calc(var(--ion-padding, 16px) * 0.5)',
    '--inner-padding-end': 0,
  },
  input: {
    width: '100%',
  },
});

type IonItemProps = Omit<JSX.IonItem, 'button' | 'detail' | 'onClick'>;
interface CommentItemProps extends IonItemProps {
  comment: Pick<Comment, 'id' | 'content' | 'createdAt' | 'operator'>;
  /** User is allowed to delete this item. Default: false */
  deleteAllowed?: boolean;
  /** User is allowed to edit this item. Default: false */
  editAllowed?: boolean;
  mentionables?: Mentionable[];
  pageRef?: React.RefObject<HTMLElement>;
  className?: string;
  /* Callback to delete the attachment item by id */
  onDelete: (id: string) => void;
  onEdit: (id: string, content: string) => void;
}

const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  deleteAllowed = false,
  editAllowed = false,
  className,
  mentionables,
  pageRef,
  onDelete,
  onEdit,
  ...ionItemProps
}) => {
  const classes = useStyles();

  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
  const commentBuffer = useRef(comment.content);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { id, content, createdAt, operator } = comment;

  const deleteHeader = `Would you like to permanently delete this comment?`;

  const deleteDismiss = () => {
    if (itemSlidingRef.current) {
      itemSlidingRef.current.close();
    }
    setShowConfirmDelete(false);
  };

  const editDismiss = () => {
    if (itemSlidingRef.current) {
      itemSlidingRef.current.close();
    }
    setIsEditModalOpen(false);
  };

  const deleteButtons = [
    { text: 'Cancel', role: 'cancel' },
    { text: 'Delete', role: 'destructive', handler: () => onDelete(id) },
  ];

  return (
    <>
      <IonItemSliding ref={itemSlidingRef} disabled={!deleteAllowed && !editAllowed}>
        <IonItemOptions side="start">
          {deleteAllowed && (
            <IonItemOption color="danger" onClick={() => setShowConfirmDelete(true)}>
              <IonIcon slot="icon-only" icon={deleteIcon} />
            </IonItemOption>
          )}
          {editAllowed && (
            <IonItemOption color="secondary" onClick={() => setIsEditModalOpen(true)}>
              <IonIcon slot="icon-only" icon={editIcon} />
            </IonItemOption>
          )}
        </IonItemOptions>

        <IonItem {...ionItemProps} className={clsx(classes.item, className)}>
          <Avatar alt={operator.name} slot="start" />
          <IonLabel style={{ marginInline: 0 }}>
            <IonNote
              className={clsx('ion-float-right', classes.date)}
              style={{ paddingLeft: 'var(--ion-margin, 16px)', fontSize: '0.875rem' }}
            >
              {prettyDate(createdAt)}
            </IonNote>
            {operator?.name && <h3>{operator.name}</h3>}
            <CommentInput
              key={content} // Force remount to display value change on content change (after user edit)
              className={classes.content}
              readOnly={true}
              defaultValue={content}
            />
          </IonLabel>
        </IonItem>
      </IonItemSliding>

      <IonAlert
        isOpen={showConfirmDelete && !isMobile}
        onDidDismiss={deleteDismiss}
        header="Delete"
        message={deleteHeader}
        buttons={deleteButtons}
      />
      <IonActionSheet
        isOpen={showConfirmDelete && isMobile}
        header={deleteHeader}
        onDidDismiss={deleteDismiss}
        buttons={deleteButtons}
      />

      <IonModal
        isOpen={isEditModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
        onDidDismiss={editDismiss}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsEditModalOpen(false)}>
                <IonIcon icon={closeIcon} slot="icon-only" />
              </IonButton>
            </IonButtons>

            <IonTitle>Edit Comment</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonItem className={classes.inputContainer} lines="none">
            <CommentInput
              className={classes.input}
              mentionables={mentionables}
              defaultValue={content}
              onChange={(value) => (commentBuffer.current = value)}
            />
          </IonItem>
        </IonContent>

        <IonFooter>
          <IonButton
            className="ion-no-margin"
            color="primary"
            expand="full"
            size="large"
            onClick={() => {
              onEdit(comment.id, commentBuffer.current);
              setIsEditModalOpen(false);
            }}
          >
            Save
          </IonButton>
        </IonFooter>

        <UnsafeArea color="primary" position="bottom" />
      </IonModal>
    </>
  );
};

export default CommentItem;
