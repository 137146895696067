import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import InfiniteList from 'components/InfiniteList/InfiniteList';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React, { PropsWithChildren, ReactNode, RefObject, useRef, useState } from 'react';

enum View {
  Default = 'DEFAULT',
  Modal = 'MODAL',
}

export interface InfiniteListProps<T> {
  title: string;
  itemCount?: number; // Number of items to display while in default view
  itemSize: number;
  items: T[];
  searchKeys: string[];
  pageRef?: RefObject<HTMLElement>;
  renderListItem: (props: T, index: number, items: T[]) => ReactNode;
  renderListHeader?: (items: T[]) => ReactNode;
}

const Default = <T,>({
  title,
  itemCount = 10,
  itemSize,
  items,
  searchKeys,
  pageRef,
  renderListItem,
  renderListHeader = () => null,
}: PropsWithChildren<InfiniteListProps<T>>) => {
  const [view, setView] = useState<View>(View.Default);

  const modalRef = useRef<HTMLIonModalElement>(null);

  const { results, query, setQuery } = useFuzzySearch({
    list: items,
    options: { keys: searchKeys, threshold: 0.3 },
  });

  return (
    <>
      {renderListHeader(items)}

      {items.slice(0, itemCount).map((_, index, list) => renderListItem(items[index], index, list))}

      {items.length > itemCount && (
        <>
          <IonItem button={true} detail={false} lines="full" onClick={() => setView(View.Modal)}>
            <IonLabel
              className="ion-text-center"
              color="primary"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>Show More</p>
            </IonLabel>
          </IonItem>

          <IonModal
            ref={modalRef}
            isOpen={view === View.Modal}
            presentingElement={pageRef?.current || undefined}
            swipeToClose={true}
            onDidDismiss={() => setView(View.Default)}
          >
            <IonHeader>
              <IonToolbar>
                <IonTitle>{title}</IonTitle>
                <IonButtons slot="start">
                  <IonButton onClick={() => modalRef.current?.dismiss()}>
                    <IonIcon icon={closeIcon} slot="icon-only" />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              <IonToolbar>
                <IonSearchbar
                  debounce={500}
                  mode="md"
                  value={query}
                  onIonChange={(e) => setQuery(e.detail.value)}
                />
              </IonToolbar>
            </IonHeader>

            <IonContent scrollY={false}>
              <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: '0 0 auto' }}>{renderListHeader(items)}</div>
                <div style={{ flex: '1 1 0%' }}>
                  <InfiniteList
                    items={results}
                    itemSize={itemSize}
                    renderListItem={renderListItem}
                  />
                </div>
              </div>
            </IonContent>

            <IonFooter>
              <IonToolbar style={{ '--border-width': '1px 0 0' }}>
                <IonNote className="ion-margin" slot="end" style={{ fontSize: '0.875rem' }}>
                  {`Viewing ${results.length} of ${items.length} items`}
                </IonNote>
              </IonToolbar>
            </IonFooter>
          </IonModal>
        </>
      )}
    </>
  );
};

export default Default;
