import { gql } from '@apollo/client';
import { Notification, NotificationChannel } from 'interfaces/Notification';

export interface NotificationsQueryVariables {
  accountId: string;
  unread?: boolean;
  after?: string;
  first?: number;
}

export interface NotificationQueryResult {
  notificationSubscriber: {
    notifications: {
      nodes: Notification[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
      };
    };
  };
}

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($accountId: ID!, $unread: Boolean, $after: String, $first: Int) {
    notificationSubscriber(accountId: $accountId) {
      notifications(unread: $unread, after: $after, first: $first) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          reference {
            kind
            referenceId
          }
          subject
          message
          unread
          channel {
            id
            topic {
              topicId
              kind
            }
          }
          createdAt
          url @client
        }
      }
    }
  }
`;

export interface UnreadNotificationsQueryVariables {
  accountId: string;
  first: number;
}

export interface UnreadNotificationQueryResult {
  notificationSubscriber: {
    notifications: {
      nodes: Pick<Notification, 'id' | 'channel'>[];
    };
  };
}

// pass in $first to avoid app sync list size exceeded 1000 limit error
export const GET_UNREAD_NOTIFICATIONS = gql`
  query GetUnreadNotifications($accountId: ID!, $first: Int) {
    notificationSubscriber(accountId: $accountId) {
      notifications(unread: true, first: $first) {
        nodes {
          id
          channel {
            id
          }
        }
      }
    }
  }
`;

export interface ReadSubscriberNotificationsInput {
  channelId: string;
  accountId: string;
}

export interface ReadSubscriberNotificationsResponse {
  readSubscriberNotifications: {
    channel: Pick<NotificationChannel, 'id'>;
  };
}

export const READ_SUBSCRIBER_NOTIFICATIONS = gql`
  mutation ReadSubscriberNotifications($channelId: String!, $accountId: String!) {
    readSubscriberNotifications(input: { channelId: $channelId, accountId: $accountId }) {
      channel {
        id
      }
    }
  }
`;
