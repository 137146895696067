import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import InteractiveMap, { MapMarker } from 'components/InteractiveMap';
import MapPopup from 'components/MapPopup';
import WorkOrderItem from 'components/WorkOrderItem';
import { MapStyles } from 'interfaces/Basemaps';
import { Geography } from 'interfaces/Geography';
import { closeOutline } from 'ionicons/icons';
import { WorkOrderDisplay } from 'pages/Reports/graphql';
import React, { RefObject, useMemo, useState } from 'react';

export interface WorkOrdersMapProps {
  workorders: WorkOrderDisplay[];
  geography: Geography;
  mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
  pageRef?: RefObject<HTMLElement>;
}

const WorkOrdersMap: React.FC<WorkOrdersMapProps> = ({
  workorders,
  geography,
  mapStyles,
  pageRef,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<WorkOrderDisplay | null>(null);

  const markers = useMemo(() => {
    return workorders.reduce<any>(
      (acc, workorder) =>
        workorder.location
          ? [
              ...acc,
              {
                id: workorder.id,
                location: [workorder.location.longitude, workorder.location.latitude],
                color: 'var(--ion-color-primary-tint)',
              },
            ]
          : acc,
      []
    );
  }, [workorders]);

  const onSelectMarker = (marker: MapMarker | null) => {
    if (!marker) {
      setActiveItem(null);
      return;
    }

    const selectedWorkOrder = workorders.find((w) => w.id === marker.id);
    setActiveItem(selectedWorkOrder || null);
  };

  const dismissModal = () => {
    setIsModalOpen(false);
    setActiveItem(null);
  };

  return (
    <>
      <InteractiveMap
        mapStyles={mapStyles}
        // Zoom out 1 zoom to better fit org since map is relatively short
        initialPosition={{ ...geography, zoom: geography.zoom - 1 }}
        isMoveable={false}
        markers={markers}
        style={{ cursor: 'pointer', height: 400 }}
        onClick={() => setIsModalOpen(true)}
      />

      <IonModal
        isOpen={isModalOpen}
        presentingElement={pageRef?.current || undefined}
        swipeToClose={true}
        onDidDismiss={dismissModal}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={dismissModal}>
                <IonIcon icon={closeOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>Resident Report</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {activeItem && (
            <MapPopup>
              <WorkOrderItem workorder={activeItem} disableSliding={true} showMap={false} />
            </MapPopup>
          )}

          <InteractiveMap
            mapStyles={mapStyles}
            initialPosition={geography}
            isMoveable={true}
            markers={markers.map((marker: { id: string }) =>
              marker.id === activeItem?.id
                ? { ...marker, color: 'var(--ion-color-highlight)' }
                : marker
            )}
            style={{ height: '100%', width: '100%' }}
            onSelectedMarker={onSelectMarker}
          />
        </IonContent>
      </IonModal>
    </>
  );
};

export default WorkOrdersMap;
