export enum AccountAction {
  ListWorkOrders = 'ListWorkOrders',
  GetWorkOrder = 'GetWorkOrder',
  CreateWorkOrder = 'CreateWorkOrder',
  StartWorkOrder = 'StartWorkOrder',
  CancelWorkOrder = 'CancelWorkOrder',
  DenyWorkOrder = 'DenyWorkOrder',
  CompleteWorkOrder = 'CompleteWorkOrder',
  ReopenWorkOrder = 'ReopenWorkOrder',
  HoldWorkOrder = 'HoldWorkOrder',
  AssignWorkOrderAssignee = 'AssignWorkOrderAssignee',
  AssignWorkOrderLocation = 'AssignWorkOrderLocation',
  AssignWorkOrderOrganization = 'AssignWorkOrderOrganization',
  AssignWorkOrderTeam = 'AssignWorkOrderTeam',
  AssignWorkOrderCategory = 'AssignWorkOrderCategory',
  AssignWorkOrderReporter = 'AssignWorkOrderReporter',
  AttachWorkOrderAttachment = 'AttachWorkOrderAttachment',
  DetachWorkOrderAttachment = 'DetachWorkOrderAttachment',
  AddWorkOrderComment = 'AddWorkOrderComment',
  EditWorkOrderComment = 'EditWorkOrderComment',
  DeleteWorkOrderComment = 'DeleteWorkOrderComment',
  EditWorkOrderDescription = 'EditWorkOrderDescription',
  ListOrganizations = 'ListOrganizations',
  GetOrganization = 'GetOrganization',
  CreateOrganization = 'CreateOrganization',
  DefineGeography = 'DefineGeography',
  CreateTeam = 'CreateTeam',
  CreateCategory = 'CreateCategory',
  EnableCategory = 'EnableCategory',
  DisableCategory = 'DisableCategory',
  ListAccounts = 'ListAccounts',
  GetAccount = 'GetAccount',
  CreateAccount = 'CreateAccount',
  CreateVirtualAccount = 'CreateVirtualAccount',
  AttachPermissions = 'AttachPermissions',
  DetachPermissions = 'DetachPermissions',
  SetAccountRate = 'SetAccountRate',
  DeactivateAccount = 'DeactivateAccount',
  ReactivateAccount = 'ReactivateAccount',
  ReinviteAccount = 'ReinviteAccount',
  CreateEquipment = 'CreateEquipment',
  ActivateEquipment = 'ActivateEquipment',
  DeactivateEquipment = 'DeactivateEquipment',
  SetEquipmentName = 'SetEquipmentName',
  SetEquipmentRate = 'SetEquipmentRate',
  SetEquipmentVehicle = 'SetEquipmentVehicle',
  LogEquipmentMaintenance = 'LogEquipmentMaintenance',
  CorrectEquipmentMaintenanceEntry = 'CorrectEquipmentMaintenanceEntry',
  DeleteEquipmentMaintenanceEntry = 'DeleteEquipmentMaintenanceEntry',
  CreateWorklogInvoice = 'CreateWorklogInvoice',
  CreateWorklogTask = 'CreateWorklogTask',
  EditWorklogTask = 'EditWorklogTask',
  RemoveWorklogTask = 'RemoveWorklogTask',
  AddWorklogTaskLabor = 'AddWorklogTaskLabor',
  AddMultipleWorklogTaskLabor = 'AddMultipleWorklogTaskLabor',
  AddWorklogTaskEquipment = 'AddWorklogTaskEquipment',
  AddMultipleWorklogTaskEquipment = 'AddMultipleWorklogTaskEquipment',
  AddMultipleWorklogTaskMaterial = 'AddMultipleWorklogTaskMaterial',
  AddWorklogTaskMaterial = 'AddWorklogTaskMaterial',
  RemoveWorklogTaskLabor = 'RemoveWorklogTaskLabor',
  RemoveWorklogTaskEquipment = 'RemoveWorklogTaskEquipment',
  RemoveWorklogTaskMaterial = 'RemoveWorklogTaskMaterial',
  GetChatChannel = 'GetChatChannel',
  PostChatMessage = 'PostChatMessage',
  EditChatMessage = 'EditChatMessage',
  DeleteChatMessage = 'DeleteChatMessage',
  AddWorkOrderTag = 'AddWorkOrderTag',
  RemoveWorkOrderTag = 'RemoveWorkOrderTag',
  TransferTeam = 'TransferTeam',
  MuteSecondaryNotifications = 'MuteSecondaryNotifications',
  UnmuteSecondaryNotifications = 'UnmuteSecondaryNotifications',
  TagWorkOrderAssets = 'TagWorkOrderAssets',
}
