import { HeatmapLayer } from 'mapbox-gl';
import { v4 as uuidv4 } from 'uuid';

/**
 * Mapbox style layers for rendering heat map overlay.
 * `source` should not be defined in layers. `source` is applied at runtime.
 */
const layers: Omit<HeatmapLayer, 'source' | 'source-layer'>[] = [
  {
    id: `heatmap-${uuidv4()}`,
    type: 'heatmap',
    paint: {
      'heatmap-weight': ['get', 'weight'],
      'heatmap-intensity': {
        stops: [
          [8, 1],
          [20, 4],
        ],
      },
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(59, 165, 227, 0)',
        0.1,
        'rgba(59, 165, 227, 0.2)',
        1,
        'rgba(145, 18, 29, 0.6)',
      ],
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 0, 8, 2, 20, 128],
    },
  },
];

export default layers;
