export const notesSection = (): any => [
  { text: 'Notes', style: ['sectionHeader', 'verticalSpacingLg'] },
  {
    style: ['verticalSpacingLg'],
    layout: {
      hLineWidth: () => 0.5,
      vLineWidth: () => 0,
      hLineColor: () => '#999999',
      paddingTop: () => 10,
    },
    table: {
      widths: ['*'],
      body: [[' '], [' '], [' '], [' '], [' ']],
    },
  },
];
