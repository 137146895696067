import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import {
  mapOutline as mapIcon,
  searchOutline as searchIcon,
  syncOutline as activeIcon,
} from 'ionicons/icons';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import ActiveList from './ActiveList';
import CreateWorkOrder from './CreateWorkOrder';
import Map from './Map';
import WorkDetails from './WorkDetails';
import WorkOrdersSearch from './WorkOrdersSearch';

const List: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path={`${path}/active`} component={ActiveList} />
        <Route path={`${path}/map`} component={Map} />
        <Route path={`${path}/search`} component={WorkOrdersSearch} />
        <Redirect from={path} to={`${path}/active`} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="active" href={`${path}/active`}>
          <IonIcon icon={activeIcon} />
          <IonLabel>Active</IonLabel>
        </IonTabButton>
        <IonTabButton tab="map" href={`${path}/map`}>
          <IonIcon icon={mapIcon} />
          <IonLabel>Map</IonLabel>
        </IonTabButton>
        <IonTabButton tab="search" href={`${path}/search`}>
          <IonIcon icon={searchIcon} />
          <IonLabel>Search</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

const WorkOrderRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={List} />
      <Route path={`${path}/create`} component={CreateWorkOrder} />
      <Route path={`${path}/details/:id`} component={WorkDetails} />
      <Redirect from={path} to={`${path}/list`} exact={true} />
    </Switch>
  );
};

export { WorkOrderRoutes, CreateWorkOrder, WorkDetails };
