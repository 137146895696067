import { gql } from '@apollo/client';
import { Equipment } from 'interfaces/Equipment';

export type EquipmentDisplay = Pick<Equipment, 'id' | 'name' | 'isActive' | 'rate' | 'vehicle'>;

/** Equipment fields required to display equipment in list */
const equipmentDisplayFragment = {
  equipmentDisplay: gql`
    fragment EquipmentDisplay on Equipment {
      id
      name
      isActive
      rate {
        primary
      }
      vehicle {
        make
        model
        year
        vinNumber
        licenseNumber
      }
    }
  `,
};

export interface GetOrganizationEquipmentResponse {
  organization: {
    equipment: EquipmentDisplay[];
  };
}

export const GET_ORGANIZATION_EQUIPMENT = gql`
  query GetOrganizationEquipment($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      equipment {
        ...EquipmentDisplay
      }
    }
  }
  ${equipmentDisplayFragment.equipmentDisplay}
`;

export interface GetEquipmentResponse {
  equipment: EquipmentDisplay & Pick<Equipment, 'utilization' | 'createdAt' | 'modifiedAt'>;
}

export const GET_EQUIPMENT = gql`
  query GetEquipment($equipmentId: ID!) {
    equipment(id: $equipmentId) {
      ...EquipmentDisplay
      utilization {
        nodes {
          task {
            id
            name
            taskDate
            workorder {
              id
              title
              workorderNumber
              titleDisplay @client
            }
          }
          rate
          hours
          itemCost
        }
      }
      createdAt
      modifiedAt
    }
  }
  ${equipmentDisplayFragment.equipmentDisplay}
`;

export interface CreateEquipmentInput {
  organizationId: string;
  name: string;
  rate: { primary: number };
  vehicle?: {
    make?: string;
    model?: string;
    year?: number;
    vinNumber?: string;
    licenseNumber?: string;
  };
}

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment(
    $organizationId: ID!
    $name: String!
    $rate: CreateEquipmentRateInput!
    $vehicle: CreateEquipmentVehicleInput
  ) {
    createEquipment(
      input: { organizationId: $organizationId, name: $name, rate: $rate, vehicle: $vehicle }
    ) {
      equipment {
        organization {
          id
        }
        ...EquipmentDisplay
      }
    }
  }
  ${equipmentDisplayFragment.equipmentDisplay}
`;

export const ACTIVATE_EQUIPMENT = gql`
  mutation ActivateEquipment($equipmentId: ID!) {
    activateEquipment(input: { equipmentId: $equipmentId }) {
      equipment {
        id
        isActive
      }
    }
  }
`;

export const DEACTIVATE_EQUIPMENT = gql`
  mutation DeactivateEquipment($equipmentId: ID!) {
    deactivateEquipment(input: { equipmentId: $equipmentId }) {
      equipment {
        id
        isActive
      }
    }
  }
`;

export const SET_EQUIPMENT_NAME = gql`
  mutation SetEquipmentName($equipmentId: ID!, $name: String!) {
    setEquipmentName(input: { equipmentId: $equipmentId, name: $name }) {
      equipment {
        id
        name
      }
    }
  }
`;

export const SET_EQUIPMENT_RATE = gql`
  mutation SetEquipmentRate($equipmentId: ID!, $primary: Float!) {
    setEquipmentRate(input: { equipmentId: $equipmentId, primary: $primary }) {
      equipment {
        id
        rate {
          primary
        }
      }
    }
  }
`;

export const SET_EQUIPMENT_VEHICLE = gql`
  mutation SetEquipmentVehicle(
    $equipmentId: ID!
    $make: String
    $model: String
    $year: Int
    $vinNumber: String
    $licenseNumber: String
  ) {
    setEquipmentVehicle(
      input: {
        equipmentId: $equipmentId
        make: $make
        model: $model
        year: $year
        vinNumber: $vinNumber
        licenseNumber: $licenseNumber
      }
    ) {
      equipment {
        id
        vehicle {
          make
          model
          year
          vinNumber
          licenseNumber
        }
      }
    }
  }
`;
