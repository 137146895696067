import { IonIcon, IonItemDivider, IonLabel } from '@ionic/react';
import React, { HTMLAttributes } from 'react';
import { formatCurrency } from 'utils/formatCurrency';

type RootHTMLAttributes = Pick<HTMLAttributes<HTMLIonItemDividerElement>, 'className'>;

export interface TableDividerProps extends RootHTMLAttributes {
  icon: string;
  title: string;
  itemCost: number;
}

/** IonItemDivider header with title and cost for each item list section */
const TableDivider: React.FC<TableDividerProps> = ({ className, icon, title, itemCost }) => (
  <IonItemDivider className={className} mode="md" sticky={true}>
    <IonIcon icon={icon} slot="start" />
    <IonLabel>
      <h2>{title}</h2>
    </IonLabel>
    <IonLabel slot="end" color="secondary">
      {formatCurrency(itemCost)}
    </IonLabel>
  </IonItemDivider>
);

export default TableDivider;
