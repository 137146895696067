import React, { PropsWithChildren, ReactNode } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

export interface InfiniteListProps<T> {
  items: T[];
  itemSize: number;
  renderListItem: (props: T, index: number, items: T[]) => ReactNode;
}

const InfiniteList = <T, _>({
  items,
  itemSize,
  renderListItem,
}: PropsWithChildren<InfiniteListProps<T>>) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List height={height} itemCount={items.length} itemSize={itemSize} width={width}>
          {({ index, style }) => (
            <div style={style as React.CSSProperties}>
              {renderListItem(items[index], index, items)}
            </div>
          )}
        </List>
      )}
    </AutoSizer>
  );
};

export default InfiniteList;
