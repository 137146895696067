import { RefresherEventDetail } from '@ionic/core/components';
import { IonRefresher, IonRefresherContent } from '@ionic/react';
import React from 'react';

interface PullToRefreshProps {
  onRefresh: () => void;
}

export const PullToRefresh: React.FC<PullToRefreshProps> = ({ onRefresh }) => {
  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    onRefresh();
    setTimeout(() => event.detail.complete(), 600);
  };

  return (
    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
      <IonRefresherContent pullingIcon="lines" refreshingSpinner="lines" />
    </IonRefresher>
  );
};

export default PullToRefresh;
