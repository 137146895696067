import { IonBackButton, IonMenuButton, isPlatform } from '@ionic/react';
import React from 'react';

interface BackButtonProps {
  /** Navigate to a default location if there is no history to navigate back */
  defaultHref?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ defaultHref }) =>
  isPlatform('mobile') ? <IonBackButton text="" defaultHref={defaultHref} /> : <IonMenuButton />;

export default BackButton;
