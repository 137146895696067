import { useMutation, useQuery } from '@apollo/client';
import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import useHistoryPersist from 'hooks/useHistoryPersist';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { add } from 'ionicons/icons';
import {
  ACTIVATE_EQUIPMENT,
  DEACTIVATE_EQUIPMENT,
  EquipmentDisplay,
  GET_ORGANIZATION_EQUIPMENT,
  GetOrganizationEquipmentResponse,
} from 'pages/Equipment/graphql';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

import EquipmentItem from './components/EquipmentItem';

const searchKeys = ['name', 'vehicle.year', 'vehicle.make', 'vehicle.model'];

const useStyles = createUseStyles({
  button: {
    textTransform: 'none',
  },
  list: {
    paddingTop: 0,
  },
});

interface GroupedEquipment {
  active: EquipmentDisplay[];
  inactive: EquipmentDisplay[];
}

/** Group list of equipment by active/inactive and sort by name asc */
const groupEquipment = (equipment: EquipmentDisplay[]): GroupedEquipment => {
  const sorted = [...equipment].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const initialGroups: GroupedEquipment = {
    active: [],
    inactive: [],
  };

  return sorted.reduce(
    (acc, item) =>
      item.isActive
        ? { ...acc, active: [...acc.active, item] }
        : { ...acc, inactive: [...acc.inactive, item] },
    initialGroups
  );
};

const EquipmentList: React.FC = () => {
  const [params, setParams] = useHistoryPersist<{ q?: string }>();
  const classes = useStyles();

  const history = useHistory();

  const { addMessage } = useMessages();

  const { workspace } = useWorkspace();
  const { actions } = workspace;

  const [mutating, setMutating] = useState(false);

  const { data, refetch } = useQuery<GetOrganizationEquipmentResponse>(GET_ORGANIZATION_EQUIPMENT, {
    displayName: 'EquipmentList',
    variables: { organizationId: workspace.organizationId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    partialRefetch: true,
    onError: () => addMessage('Unable to load equipment.', 'danger'),
  });

  const { results: searchedEquipment, setQuery, query } = useFuzzySearch({
    list: data?.organization?.equipment || [],
    options: { keys: searchKeys, threshold: 0.2 },
    term: params.q,
  });

  const [activateMutation] = useMutation(ACTIVATE_EQUIPMENT);
  const [deactivateMutation] = useMutation(DEACTIVATE_EQUIPMENT);

  const canActivateDeactivate = [
    AccountAction.ActivateEquipment,
    AccountAction.DeactivateEquipment,
  ].every((ra) => actions.includes(ra));

  // Grouped by `isActive` and sorted by `name`
  const equipment: GroupedEquipment = useMemo(
    () => (searchedEquipment ? groupEquipment(searchedEquipment) : { active: [], inactive: [] }),
    [searchedEquipment]
  );

  const activate = async (equipmentId: string) => {
    try {
      setMutating(true);
      await activateMutation({ variables: { equipmentId } });
    } catch ({ graphQLErrors }) {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to activate', ...messages].join('. ')}.`, 'danger');
    }
    setMutating(false);
  };

  const deactivate = async (equipmentId: string) => {
    try {
      setMutating(true);
      await deactivateMutation({ variables: { equipmentId } });
    } catch ({ graphQLErrors }) {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to deactivate', ...messages].join('. ')}.`, 'danger');
    }
    setMutating(false);
  };

  useEffect(() => {
    setParams({
      q: query?.length ? query : undefined,
    });
  }, [query, setParams]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Equipment</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            mode="md"
            style={{ '--border-radius': '100px' }}
            debounce={500}
            value={query}
            onIonChange={({ target }: any) => setQuery(target.value)}
          />
        </IonToolbar>
        {query && (
          <IonToolbar style={{ '--min-height': 0 }}>
            <IonTitle
              className="ion-text-start"
              size="small"
              color="medium"
              style={{
                paddingTop: 0,
                paddingBottom: isPlatform('ios') ? 12 : 'var(--ion-margin, 16px)',
                fontSize: '0.875rem',
              }}
            >
              {`${searchedEquipment.length} match${
                searchedEquipment.length !== 1 ? 'es' : ''
              } for search "${query}"`}
            </IonTitle>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent style={{ '--padding-bottom': '72px' }}>
        <PullToRefresh onRefresh={refetch} />

        <ListSeparator />

        <IonList className={classes.list}>
          <IonItemDivider mode="md" sticky={true}>
            <IonLabel>
              <h2>Active</h2>
            </IonLabel>
          </IonItemDivider>
          {equipment.active.length > 0 &&
            equipment.active.map((item, i) => (
              <EquipmentItem
                key={item.id}
                equipment={item}
                routerLink={`/equipment/details/${item.id}`}
                lines={i === equipment.active.length - 1 ? 'full' : undefined}
                onActivate={canActivateDeactivate ? activate : undefined}
                onDeactivate={canActivateDeactivate ? deactivate : undefined}
              />
            ))}

          {equipment.inactive.length > 0 && (
            <>
              <ListSeparator />

              <IonItemDivider mode="md" sticky={true}>
                <IonLabel>
                  <h2>Inactive</h2>
                </IonLabel>
              </IonItemDivider>
              {equipment.inactive.map((item, i) => (
                <EquipmentItem
                  key={item.id}
                  equipment={item}
                  routerLink={`/equipment/details/${item.id}`}
                  lines={i === equipment.inactive.length - 1 ? 'full' : undefined}
                  onActivate={canActivateDeactivate ? activate : undefined}
                  onDeactivate={canActivateDeactivate ? deactivate : undefined}
                />
              ))}
            </>
          )}
        </IonList>

        <IonLoading isOpen={mutating} />

        <AuthorizeRequired required={[AccountAction.CreateEquipment]}>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => history.push(`/equipment/create`)}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </AuthorizeRequired>
      </IonContent>
    </IonPage>
  );
};

export default EquipmentList;
