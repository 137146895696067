import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import { MapStyles } from 'interfaces/Basemaps';
import React from 'react';

import WorkOrderDetailsAssetCard, {
  WorkOrderDetailsAssetCardProps,
} from './WorkOrderDetailsAssetCard';

export interface WorkOrderDetailsAssetCardGroupProps {
  assets: WorkOrderDetailsAssetCardProps['asset'][];
  selectedAssets: string[];
  handleAssetSelectionChange: (assetId: string, checked: boolean) => void;
  mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
}

const WorkOrderDetailsAssetCardGroup: React.FC<WorkOrderDetailsAssetCardGroupProps> = ({
  assets,
  selectedAssets,
  handleAssetSelectionChange,
  mapStyles,
}) => {
  return (
    <IonItem className="ion-no-padding" style={{ '--inner-padding-end': '0px' }}>
      <IonGrid>
        <IonRow>
          {assets.map((asset) => (
            <IonCol key={asset.id} sizeXl="4" sizeMd="6" sizeXs="12" className="ion-no-padding">
              <WorkOrderDetailsAssetCard
                asset={asset}
                checked={selectedAssets.includes(asset.id)}
                onCheckChange={(assetId: string, checked: boolean) =>
                  handleAssetSelectionChange(assetId, checked)
                }
                mapStyles={mapStyles}
              />
            </IonCol>
          ))}
          {assets.length === 0 && (
            <IonItem lines="none">
              <IonLabel>
                <p className="ion-text-wrap">No assets have been tagged for this work order</p>
              </IonLabel>
            </IonItem>
          )}
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default WorkOrderDetailsAssetCardGroup;
