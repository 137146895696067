import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { MagicField, MagicForm } from 'components/MagicForm';
import UnsafeArea from 'components/UnsafeArea';
import { closeOutline as closeIcon, createOutline as editIcon } from 'ionicons/icons';
import moment from 'moment';
import React, { PropsWithChildren, RefObject, useState } from 'react';
import { createUseStyles } from 'react-jss';

export interface EditTaskModalProps {
  pageRef?: RefObject<HTMLElement>;
  taskName: string | undefined;
  currTaskDate: number | undefined;
  onSubmit: (data: { title: string; taskDate: number }) => Promise<void>;
}

const useStyles = createUseStyles({
  form: {
    height: '100%',
  },
});

const EditTaskModal = ({
  pageRef,
  taskName,
  currTaskDate,
  onSubmit,
}: PropsWithChildren<EditTaskModalProps>) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submit = async (title: string, taskDate: number) => {
    // avoid submitting if no changes made
    if (
      !title ||
      !taskDate ||
      (title === defaultValues.title && taskDate.toString() === defaultValues.taskDate)
    )
      return;

    try {
      setLoading(true);
      await onSubmit({ title, taskDate: moment(taskDate, 'YYYY-MM-DD').valueOf() });
      setLoading(false);
      setIsModalOpen(false);
    } catch (_err) {
      // NOOP
    }
  };

  const today = moment(currTaskDate).format('YYYY-MM-DD');
  const defaultValues = { title: taskName, taskDate: today };

  return (
    <>
      <IonButtons slot="end" onClick={() => setIsModalOpen(true)}>
        <IonButton>
          <IonIcon icon={editIcon} slot="icon-only" />
        </IonButton>
      </IonButtons>
      <IonModal
        onDidDismiss={() => {
          setIsModalOpen(false);
        }}
        isOpen={isModalOpen}
        canDismiss={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={setIsModalOpen.bind(null, false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>Update {taskName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonLoading isOpen={isLoading} />
          <MagicForm
            className={classes.form}
            submitText="Save"
            onSubmit={(e) => {
              submit(e.title, e.taskDate);
            }}
            defaultValues={defaultValues}
          >
            <MagicField
              name="title"
              fieldType="text"
              label="title"
              placeholder="Title"
              isModal={true}
              rules={{ required: 'You must provide a title' }}
            />
            <MagicField
              name="taskDate"
              type="date"
              inputMode="text"
              label="Date"
              rules={{ required: 'Date is required' }}
            />
          </MagicForm>
        </IonContent>
        <UnsafeArea position="bottom" color="primary" />
      </IonModal>
    </>
  );
};

export default EditTaskModal;
