import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import centerOfMass from '@turf/center-of-mass';
import AddressSearchBar from 'components/AddressSearchBar';
import BackButton from 'components/BackButton';
import GeolocateButton from 'components/GeolocateButton';
import InteractiveMap, { IInteractiveMap } from 'components/InteractiveMap';
import { images, layers } from 'components/InteractiveMap/assetSymbology';
import { useGeocoder } from 'hooks/useGeocoder';
import { useLocation } from 'hooks/useLocation';
import { useMapStyles } from 'hooks/useMapStyles';
import { useMessages } from 'hooks/useMessages';
import { useOrganization } from 'hooks/useOrganization';
import { useWorkspace } from 'hooks/useWorkspace';
import { AssetType } from 'interfaces/Asset';
import { MapContext } from 'interfaces/Basemaps';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface GISProperties {
  OBJECTID: number;
  gisId: string;
  name?: string;
  assetType?: AssetType;
  location?: string;
  photos?: string; // array as string after going through map component GeoJSON
}

const inlineDivider =
  '<hr style="margin-block-start: 0.7em; margin-block-end: 0.5em; border-top-width:1px; border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))"/>';

// TODO. factor out to service
async function fetchGISAssets(assetsUrls: string[]) {
  const promises = assetsUrls.map((url) => fetch(url).then((res) => res.json()));
  const responses = await Promise.all(promises);
  type geoJSONResponse = GeoJSON.FeatureCollection<GeoJSON.Geometry, GISProperties>;
  return responses
    .map((asset: geoJSONResponse) => asset.features)
    .flat()
    .filter((asset) => asset.properties.gisId); // All GIS assets should have `gisId` but filter to ensure.
}

const ConfirmLocation: React.FC = () => {
  const { addMessage } = useMessages();
  const history = useHistory();
  const { reverseGeocode } = useGeocoder();

  const [gisAssets, setGISAssets] = useState<GeoJSON.Feature<GeoJSON.Geometry, GISProperties>[]>(
    []
  );

  const { workspace } = useWorkspace();
  const { organization } = useOrganization(workspace.organizationId);
  const mapStyles = useMapStyles({
    organizationId: workspace.organizationId,
    context: MapContext.WorkOrderCreation,
  });

  const { center, zoom: workspaceZoom } = workspace.geography;

  const { location, updateLocation } = useLocation({
    value: {
      latitude: center[1],
      longitude: center[0],
      address: '',
    },
  });
  const [selectedAsset, setSelectedAsset] = useState<GeoJSON.Feature<
    GeoJSON.Geometry,
    GISProperties
  > | null>(null);
  const selectedAssetPhoto: string | null = selectedAsset?.properties.photos
    ? JSON.parse(selectedAsset.properties.photos)[0]
    : null;

  const { longitude, latitude, address, zoom: focusZoom } = location;
  const [addressLine1, ...addressRemaining] = (address || '')
    .replace(', United States', '')
    .split(', ');
  const addressLine2 = addressRemaining.join(', ');

  const assetsUrls = organization?.assetsUrls;

  const updateLocationWithAddress = useCallback(
    async (lng: number, lat: number) => {
      const geocode = await reverseGeocode(lng, lat);
      updateLocation({ zoom: undefined, longitude: lng, latitude: lat, address: geocode.address });
    },
    [reverseGeocode, updateLocation]
  );

  // Apply address to workspace center (initial location)
  useEffect(() => {
    updateLocationWithAddress(center[0], center[1]);
  }, [center, updateLocationWithAddress]);

  useEffect(() => {
    if (!assetsUrls?.length) return;
    fetchGISAssets(assetsUrls).then(setGISAssets);
  }, [assetsUrls]);

  const geoJSON = useMemo(() => {
    const result: IInteractiveMap['geoJSON'] = {
      features: gisAssets,
      layers,
      images,
      selectable: true,
      onGeoJSONSelectionChange: (feature) => {
        if (!feature) {
          setSelectedAsset(null);
          return;
        }

        const { geometry } = centerOfMass(feature.geometry);

        // If selected asset has a location defined, use that for the WO location text.
        // Otherwise, use reverse geocode like any other location change.
        feature.properties?.location
          ? updateLocation({
              zoom: undefined,
              longitude: geometry.coordinates[0],
              latitude: geometry.coordinates[1],
              address: feature.properties.location,
            })
          : updateLocationWithAddress(geometry.coordinates[0], geometry.coordinates[1]);

        setSelectedAsset(feature as GeoJSON.Feature<GeoJSON.Geometry, GISProperties>);
      },
    };
    return result;
  }, [gisAssets, updateLocation, updateLocationWithAddress]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref="/workorders" />
          </IonButtons>
          <IonTitle>Create Work Order</IonTitle>
        </IonToolbar>
        <AddressSearchBar
          proximity={center}
          onSelectResult={(loc) => {
            updateLocation({ zoom: 16, ...loc });
            setSelectedAsset(null);
          }}
        />
      </IonHeader>
      <IonContent scrollY={false}>
        <GeolocateButton
          vertical="top"
          horizontal="end"
          slot="fixed"
          onGeolocate={(loc) => {
            updateLocation({ zoom: 16, ...loc });
            setSelectedAsset(null);
          }}
          onError={({ message }) => addMessage(message, 'danger')}
          renderLoadingDisplay={(isLoading) => <IonLoading isOpen={isLoading} />}
        />
        <InteractiveMap
          mapStyles={mapStyles}
          style={{ height: '100%' }}
          isMoveable={true}
          initialPosition={{ center: [longitude, latitude], zoom: focusZoom || workspaceZoom }}
          focusLocation={{
            title: [
              [addressLine1, addressLine2].filter(Boolean).join('<br />'),
              [
                selectedAsset?.properties.assetType,
                selectedAsset?.properties.name,
                selectedAssetPhoto &&
                  `<a href="${selectedAssetPhoto}" target="_blank" style="text-decoration: none">Photo</a>`,
              ]
                .filter(Boolean)
                .join('<br />'),
            ]
              .filter(Boolean)
              .join(inlineDivider),
            color: selectedAsset ? '#15A299' : 'black',
            center: [longitude, latitude],
            zoom: focusZoom,
          }}
          geoJSON={geoJSON}
          moveFocusLocationWithMap={true}
          onMapMoved={({ center: { lng, lat }, centerChanged }) => {
            if (!centerChanged) return;
            updateLocationWithAddress(lng, lat);
          }}
          renderLoadingDisplay={(isLoading) => <IonLoading isOpen={isLoading} />}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar
          color="primary"
          style={{
            '--padding-top': 0,
            '--padding-bottom': 0,
            '--padding-start': 0,
            '--padding-end': 0,
          }}
        >
          <IonButton
            color="primary"
            expand="full"
            size="large"
            className="ion-no-margin"
            onClick={() => {
              const assetState = selectedAsset
                ? {
                    id: selectedAsset.properties.gisId,
                    name: selectedAsset.properties.name || '',
                    assetType: selectedAsset.properties.assetType || 'Other',
                    location: {
                      geometry: JSON.stringify(selectedAsset.geometry),
                      description: selectedAsset.properties.location || '',
                    },
                  }
                : null;
              history.push(`details`, { address, longitude, latitude, asset: assetState });
            }}
          >
            Select Location
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ConfirmLocation;
