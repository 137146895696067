import React from 'react';

export interface IndicatorProps {
  color?: string;
  isVertical?: boolean;
  size?: number;
}

const Indicator: React.FC<IndicatorProps> = ({
  color = `var(--ion-color-primary)`,
  isVertical = false,
  size = 4,
}) => {
  return (
    <div
      style={{
        height: isVertical ? '100%' : size,
        width: isVertical ? size : '100%',
        backgroundColor: color,
      }}
    />
  );
};

export default Indicator;
