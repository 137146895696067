import { Status } from 'interfaces/Status';
import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';

const statusMap = (status: Status): string => {
  switch (status) {
    case Status.Submitted:
      return 'Submitted';
    case Status.Denied:
      return 'Denied';
    case Status.Completed:
      return 'Completed';
    case Status.Cancelled:
      return 'Cancelled';
    case Status.Started:
      return 'Started';
    case Status.Reopened:
      return 'Reopened';
    default:
      return 'Unknown Status';
  }
};

const labelSpacer = '  ';

export const headerSection = ({
  category,
  team,
  assignee,
  reporter,
  createdAt,
  modifiedAt,
  titleDisplay,
  description,
  location,
  status,
  reason,
}: Pick<
  WorkOrder,
  | 'category'
  | 'team'
  | 'assignee'
  | 'reporter'
  | 'createdAt'
  | 'modifiedAt'
  | 'titleDisplay'
  | 'description'
  | 'location'
  | 'status'
  | 'reason'
>): any => [
  { text: titleDisplay, style: 'header' },
  location && { text: location.address, style: ['subheader', 'verticalSpacingSm'] },
  {
    text: ['Status:', labelSpacer, statusMap(status), reason ? ` - ${reason}` : ''],
    style: 'verticalSpacingLg',
  },
  {
    text: ['Created:', labelSpacer, moment(createdAt).format('LLL')],
    style: 'verticalSpacingSm',
  },
  {
    text: ['Last Updated:', labelSpacer, moment(modifiedAt).format('LLL')],
    style: 'verticalSpacingSm',
  },
  category && { text: ['Category:', labelSpacer, category.name], style: 'verticalSpacingSm' },
  team && { text: ['Team:', labelSpacer, team.name], style: 'verticalSpacingSm' },
  assignee && { text: ['Assignee:', labelSpacer, assignee.name], style: 'verticalSpacingSm' },
  reporter && {
    text: [
      'Resident:',
      labelSpacer,
      reporter.name,
      labelSpacer,
      reporter.email,
      labelSpacer,
      reporter.phone,
    ],
    style: 'verticalSpacingSm',
  },
  { text: 'Description', style: ['sectionHeader', 'verticalSpacingLg'] },
  { text: description, style: 'verticalSpacingMd' },
];
