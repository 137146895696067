import { gql, useMutation } from '@apollo/client';
import { Asset } from 'interfaces/Asset';
import { WorkOrder } from 'interfaces/WorkOrder';
import { logException } from 'services/logger';

export const CREATE_WORKORDER = gql`
  mutation createWorkOrder(
    $summary: String!
    $description: String!
    $address: String!
    $latitude: Float!
    $longitude: Float!
    $categoryId: ID
    $organizationId: ID
    $teamId: ID
    $assigneeId: ID
    $reporter: ReporterInput
    $tags: [String!]
    $assets: [WorkOrderAssetsInput!]
  ) {
    createWorkOrder(
      input: {
        title: $summary
        description: $description
        location: { address: $address, latitude: $latitude, longitude: $longitude }
        categoryId: $categoryId
        organizationId: $organizationId
        teamId: $teamId
        assigneeId: $assigneeId
        reporter: $reporter
        tags: $tags
        assets: $assets
      }
    ) {
      workorder {
        id
        title
      }
    }
  }
`;

export interface NewWorkOrder {
  summary: string;
  description: string;
  address: string;
  latitude: number;
  longitude: number;
  categoryId?: string;
  organizationId?: string;
  teamId?: string;
  assigneeId?: string;
  reporter?: { name: string; email?: string; phone?: string };
  tags?: string[];
  assets?: (Pick<Asset, 'id' | 'name' | 'assetType'> & {
    location: Pick<Asset['location'], 'description' | 'geometry'>;
  })[];
}

export const useWorkOrderCreator = () => {
  const [createWorkOrder] = useMutation(CREATE_WORKORDER);

  const submitWorkOrder = async (input: NewWorkOrder): Promise<WorkOrder> => {
    try {
      const { data: record } = await createWorkOrder({ variables: input });

      return record.createWorkOrder.workorder as WorkOrder;
    } catch (err) {
      logException(err as any);
      throw err;
    }
  };

  return {
    submitWorkOrder,
  };
};
