import { gql } from '@apollo/client';
import { Message } from 'interfaces/Message';

export const initialState = {
  messages: [],
};

export const resolvers = {
  Mutation: {
    addMessage: async (_: any, params: any, { cache }: any) => {
      const query = gql`
        query Messages {
          messages @client
        }
      `;

      const { input } = params;
      const { messages } = cache.readQuery({ query });
      Object.keys(input).forEach((key) => input[key] === undefined && delete input[key]);
      const message = { ...input, __typename: 'Message' };
      const data = {
        messages: [...messages, message],
      };

      cache.writeQuery({ query, data });

      return data.messages;
    },
    clearMessage: async (_: any, params: any, { cache }: any) => {
      const query = gql`
        query Messages {
          messages @client
        }
      `;

      const { input } = params;
      const { messages } = cache.readQuery({ query });
      Object.keys(input).forEach((key) => input[key] === undefined && delete input[key]);
      const newMessages = messages.filter(
        (message: Message) => message.timestamp !== input.timestamp
      );

      const data = {
        messages: newMessages,
      };

      cache.writeQuery({ query, data });

      return data.messages;
    },
  },
};
