import { gql, useQuery } from '@apollo/client';
import { Reporter } from 'interfaces/Reporter';
import { useCallback, useEffect } from 'react';

export const GET_REPORTERS = gql`
  query GetReporters($organizationId: ID!, $query: String, $after: String, $size: Int) {
    organization(id: $organizationId) {
      id
      reporters(params: { text: $query, after: $after, size: $size }) {
        nodes {
          id
          name
          email
          phone
          secondaryPhone
        }
      }
    }
  }
`;

type ReporterNode = Pick<Reporter, 'id' | 'name' | 'email' | 'phone' | 'secondaryPhone'>;

export interface UseReportersConfig {
  after?: string;
  query?: string;
  size?: number;
  skip?: boolean;
}

export interface UseReportersResult {
  reporters: ReporterNode[];
  fetchReporters: (args?: Partial<GetReportersVariables>) => void;
}

export interface GetReportersData {
  organization: {
    reporters: {
      nodes: ReporterNode[];
    };
  };
}

export interface GetReportersVariables
  extends Pick<UseReportersConfig, 'after' | 'size' | 'query'> {
  organizationId: string;
}

export const useReporters = (
  organizationId: string,
  { after, query, size, skip }: UseReportersConfig = {}
): UseReportersResult => {
  const { data, refetch } = useQuery<GetReportersData, GetReportersVariables>(GET_REPORTERS, {
    displayName: 'useReporters',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
    variables: {
      organizationId,
      query,
      after,
      size,
    },
    // Previously displayed message on errors but removed
    // Hook is used in components where message is not necessary
    // May want to add back and make notifications a configuration option
    // onError: () => addMessage('Unable to load reporters.', 'danger'),
  });

  const fetchReporters = useCallback(
    (args: Partial<GetReportersVariables> = {}) => {
      refetch({
        organizationId: args.organizationId || organizationId,
        query: args.query || query,
        after: args.after || after,
        size: args.size || size,
      });
    },
    [organizationId, query, after, size, refetch]
  );

  useEffect(() => {
    fetchReporters();
  }, [fetchReporters]);

  const reporters = data?.organization.reporters.nodes || [];

  return {
    reporters,
    fetchReporters,
  };
};
