import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import { caretDownOutline, caretForwardOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface TreeBranch {
  name: string;
  url: string;
}

export interface MenuTreeProps {
  label: string;
  branches: TreeBranch[];
}

const MenuTree: React.FC<MenuTreeProps> = ({ label, branches }) => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  if (!branches.length) {
    return null;
  }

  return (
    <IonList>
      <IonItem lines="none" button={true} onClick={() => setIsOpen(!isOpen)}>
        <IonLabel>{label}</IonLabel>
        <IonIcon slot="start" icon={isOpen ? caretDownOutline : caretForwardOutline} />
      </IonItem>

      {isOpen &&
        branches.map(({ name, url }, index) => (
          <IonItem
            key={index}
            lines="none"
            button={true}
            onClick={() => history.push(url, { name })}
            className="ion-margin-start"
          >
            <IonLabel>{name}</IonLabel>
          </IonItem>
        ))}
    </IonList>
  );
};

export default MenuTree;
