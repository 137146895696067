import { IonRow } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import WorkHistoryCardLeft, { WorkHistoryCardLeftProps } from './WorkHistoryCardLeft';
import WorkHistoryCardRight, { WorkHistoryCardRightProps } from './WorkHistoryCardRight';

export interface WorkHistoryCardProps {
  timelineItem: WorkHistoryCardLeftProps['timelineItem'] &
    WorkHistoryCardRightProps['timelineItem'];
  index: number;
  position: WorkHistoryCardLeftProps['position'];
  condensed: boolean;
}

export interface WorkHistoryCardStyleProps {
  condensed: boolean;
}

const WorkHistoryCard: React.FC<WorkHistoryCardProps> = ({
  timelineItem,
  index,
  position,
  condensed = false,
}) => {
  const history = useHistory();

  const navToWorkHistoryDetails = (id: string) => {
    history.replace(`/workorders/details/${id}`);
  };

  return (
    <IonRow
      key={index}
      onClick={() => navToWorkHistoryDetails(timelineItem.id)}
      style={{ cursor: 'pointer' }}
    >
      <WorkHistoryCardLeft timelineItem={timelineItem} position={position} condensed={condensed} />
      <WorkHistoryCardRight timelineItem={timelineItem} condensed={condensed} />
    </IonRow>
  );
};

export default WorkHistoryCard;
