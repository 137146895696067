import { IonBadge, IonItem, IonLabel, IonNote, IonSegment, IonSegmentButton } from '@ionic/react';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { AccountKind } from 'interfaces/AccountKind';
import { Activity } from 'interfaces/Activity';
import { Attachment } from 'interfaces/Attachment';
import { Comment, MentionKind } from 'interfaces/Comment';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';

import ActivityItem from './ActivityItem';
import AttachmentItem from './AttachmentItem';
import CommentItem from './CommentItem';

export interface ActivityListProps {
  activity: Activity[];
  attachments: Attachment[];
  comments: Comment[];
  pageRef?: React.RefObject<HTMLElement>;
  onEditComment: (id: string, content: string) => Promise<unknown>;
  onDeleteComment: (id: string) => Promise<unknown>;
  onDetachAttachment: (id: string) => Promise<unknown>;
}

const useStyles = createUseStyles({
  badge: {
    flex: '0 0 auto',
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 11,
    fontSize: '0.625rem',
  },
  segment: {
    '--background': 'var(--ion-item-background, var(--ion-background-color, #fff))',
    borderBottom: '1px solid var(--ion-color-light-shade)',

    '& ion-label': {
      textTransform: 'none',
    },
  },
  emptyItem: {
    '& ion-note': {
      alignSelf: 'center',
      fontSize: '0.875rem',
    },
  },
});

const ActivityList: React.FC<ActivityListProps> = ({
  comments,
  attachments,
  activity,
  pageRef,
  onEditComment,
  onDeleteComment,
  onDetachAttachment,
}) => {
  const classes = useStyles();

  const [view, setView] = useState<string>('comments');

  const { workspace } = useWorkspace();
  const { actions, assignees, operatorId, organizationId, teams, teamId } = workspace;

  const mentionables = useMemo(() => {
    return [
      ...assignees
        .filter((assignee) => assignee.kind === AccountKind.User)
        .map((assignee) => ({
          id: assignee.id,
          name: assignee.name,
          kind: MentionKind.User,
          detail: assignee.id === operatorId ? 'you' : undefined,
        })),
      ...teams.map((team) => ({
        id: team.id,
        name: team.name,
        kind: MentionKind.Team,
        detail: team.id === teamId ? 'your team' : undefined,
      })),
      {
        id: organizationId,
        name: 'everyone',
        kind: MentionKind.Organization,
      },
    ];
  }, [assignees, operatorId, organizationId, teamId, teams]);

  const editComment = (id: string, content: string) => {
    content && onEditComment(id, content);
  };

  return (
    <>
      <IonSegment
        className={classes.segment}
        scrollable={false}
        mode="md"
        value={view}
        onIonChange={(e) => setView(e.detail.value!)}
      >
        <IonSegmentButton layout="icon-end" value="comments">
          <IonBadge className={classes.badge} color="light">
            {comments.length}
          </IonBadge>
          <IonLabel>Comments</IonLabel>
        </IonSegmentButton>

        <IonSegmentButton layout="icon-end" value="attachments">
          <IonBadge className={classes.badge} color="light">
            {attachments.length}
          </IonBadge>
          <IonLabel>Files</IonLabel>
        </IonSegmentButton>

        <IonSegmentButton layout="icon-end" value="activity">
          <IonBadge className={classes.badge} color="light">
            {activity.length}
          </IonBadge>
          <IonLabel>Activity</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      {view === 'comments' && (
        <>
          {comments.length ? (
            comments.map((props, index) => (
              <CommentItem
                key={props.id}
                comment={props}
                lines={index === comments.length - 1 ? 'full' : 'inset'}
                deleteAllowed={
                  operatorId === props.operator?.id &&
                  actions.includes(AccountAction.DeleteWorkOrderComment)
                }
                editAllowed={
                  operatorId === props.operator?.id &&
                  actions.includes(AccountAction.EditWorkOrderComment)
                }
                mentionables={mentionables}
                pageRef={pageRef}
                onEdit={editComment}
                onDelete={onDeleteComment}
              />
            ))
          ) : (
            <IonItem lines="full" className={classes.emptyItem}>
              <IonNote>No Comments</IonNote>
            </IonItem>
          )}
        </>
      )}

      {view === 'attachments' && (
        <>
          {attachments.length ? (
            attachments.map((props, index) => (
              <AttachmentItem
                key={props.id}
                attachment={props}
                lines={index === attachments.length - 1 ? 'full' : 'inset'}
                deleteAllowed={actions.includes(AccountAction.DetachWorkOrderAttachment)}
                onDelete={onDetachAttachment}
              />
            ))
          ) : (
            <IonItem lines="full" className={classes.emptyItem}>
              <IonNote>No Files</IonNote>
            </IonItem>
          )}
        </>
      )}

      {view === 'activity' && (
        <>
          {activity.length ? (
            activity.map((props, index) => (
              <ActivityItem
                key={index}
                lines={index === activity.length - 1 ? 'full' : 'inset'}
                activity={props}
              />
            ))
          ) : (
            <IonItem lines="full" className={classes.emptyItem}>
              <IonNote>No Activity</IonNote>
            </IonItem>
          )}
        </>
      )}
    </>
  );
};

export default ActivityList;
