import { MutationUpdaterFn, useMutation } from '@apollo/client';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import { MagicField, MagicForm } from 'components/MagicForm';
import SearchModal from 'components/SearchModal';
import SelectionItem from 'components/SelectionItem';
import UnsafeArea from 'components/UnsafeArea';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountKind } from 'interfaces/AccountKind';
import { Team } from 'interfaces/Team';
import {
  atOutline as emailIcon,
  personCircleOutline as nameIcon,
  phonePortraitOutline as phoneIcon,
} from 'ionicons/icons';
import {
  CREATE_ACCOUNT,
  CREATE_VIRTUAL_ACCOUNT,
  CreateAccountInput,
  CreateAccountResponse,
  CreateVirtualAccountInput,
  CreateVirtualAccountResponse,
  GET_ORGANIZATION_ACCOUNTS,
} from 'pages/Accounts/graphql';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { formatPhone } from 'utils/formatPhone';

type TeamAssignment = Pick<Team, 'id' | 'name'>;
const initialTeam = { id: '', name: 'Select Team' };

const useStyles = createUseStyles({
  searchModalItem: {
    border: '1px solid var(--ion-color-light)',
  },
});

const createAccountCacheUpdate: MutationUpdaterFn<CreateAccountResponse> = (cache, { data }) => {
  if (!data) {
    return;
  }

  const queryData: any = cache.readQuery({
    query: GET_ORGANIZATION_ACCOUNTS,
    variables: { organizationId: data.createAccount.account.organization.id },
  });

  cache.writeQuery({
    query: GET_ORGANIZATION_ACCOUNTS,
    variables: { organizationId: data.createAccount.account.organization.id },
    data: {
      accounts: {
        ...queryData.accounts,
        nodes: [...queryData.accounts.nodes, data.createAccount.account],
      },
    },
  });
};

const createVirtualAccountCacheUpdate: MutationUpdaterFn<CreateVirtualAccountResponse> = (
  cache,
  { data }
) => {
  if (!data) {
    return;
  }

  const queryData: any = cache.readQuery({
    query: GET_ORGANIZATION_ACCOUNTS,
    variables: { organizationId: data.createVirtualAccount.account.organization.id },
  });

  cache.writeQuery({
    query: GET_ORGANIZATION_ACCOUNTS,
    variables: { organizationId: data.createVirtualAccount.account.organization.id },
    data: {
      accounts: {
        ...queryData.accounts,
        nodes: [...queryData.accounts.nodes, data.createVirtualAccount.account],
      },
    },
  });
};

const CreateAccount: React.FC<{}> = () => {
  const classes = useStyles();

  const pageRef = useRef<HTMLElement>(null); // Bind to modals for card style display

  const history = useHistory();
  const [mutating, setMutating] = useState(false);
  const [createAccountKind, setCreateAccountKind] = useState<AccountKind>(AccountKind.User);
  const [team, setTeam] = useState<TeamAssignment>(initialTeam);
  const { workspace } = useWorkspace();
  const { addMessage } = useMessages();

  const [createAccount] = useMutation<CreateAccountResponse>(CREATE_ACCOUNT, {
    update: createAccountCacheUpdate,
    onError: () => addMessage('Unable to create account.', 'danger'),
  });

  const [createVirtualAccount] = useMutation<CreateVirtualAccountResponse>(CREATE_VIRTUAL_ACCOUNT, {
    update: createVirtualAccountCacheUpdate,
    onError: () => addMessage('Unable to create account.', 'danger'),
  });

  const onSubmitCreateAccount = async (data: { name: string; email: string; phone: string }) => {
    const createAccountInput: CreateAccountInput = {
      organizationId: workspace.organizationId,
      name: data.name,
      teamId: team.id,
      phone: formatPhone(data.phone),
      email: data.email,
    };

    setMutating(true);

    try {
      const { data: record } = await createAccount({ variables: { input: createAccountInput } });

      const newAccountId = record?.createAccount.account.id;

      addMessage(`We have sent ${data.name} an invitation to join ${team.name}`);

      newAccountId &&
        setTimeout(() => {
          setMutating(false);
          history.replace(`/accounts/manage/${newAccountId}`);
        }, 3000);
    } catch (_err) {
      setMutating(false);
    }
  };

  const onSubmitCreateVirtualAccount = async (data: { name: string }) => {
    const createVirtualAccountInput: CreateVirtualAccountInput = {
      name: data.name,
      teamId: team.id,
      organizationId: workspace.organizationId,
    };

    setMutating(true);

    try {
      const { data: record } = await createVirtualAccount({
        variables: { input: createVirtualAccountInput },
      });

      const newAccountId = record?.createVirtualAccount.account.id;

      addMessage(`Virtual account created for ${data.name}`);

      newAccountId &&
        setTimeout(() => {
          setMutating(false);
          history.replace(`/accounts/manage/${newAccountId}`);
        }, 3000);
    } catch (_err) {
      setMutating(false);
    }
  };

  const { teams } = workspace;

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref={'/accounts'} />
          </IonButtons>
          <IonTitle>Create Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <IonSegment
            style={{ marginTop: 'var(--ion-margin, 16px)' }}
            mode="md"
            value={createAccountKind}
            onIonChange={(e) => setCreateAccountKind(e.detail.value as AccountKind)}
          >
            <IonSegmentButton value={AccountKind.User}>
              <IonLabel>User</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={AccountKind.Virtual}>
              <IonLabel>Virtual</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <MagicForm
            subtitle={
              createAccountKind === AccountKind.User
                ? 'Invite a new team member'
                : 'Track work for staff members without sign in access'
            }
            onSubmit={
              createAccountKind === AccountKind.User
                ? onSubmitCreateAccount
                : onSubmitCreateVirtualAccount
            }
            submitText="Create"
            style={{ flex: '1 1 0%' }}
          >
            <SearchModal<TeamAssignment>
              pageRef={pageRef}
              keys={['name']}
              items={teams}
              onSelect={(props) => setTeam(props)}
              value={team}
              renderDisplayItem={({ onClick, value }) => (
                <SelectionItem
                  className={classes.searchModalItem}
                  title={value.name}
                  onClick={onClick}
                  lines="none"
                />
              )}
              renderSearchItem={({ value, onClick }) => (
                <SelectionItem title={value.name} onClick={onClick} />
              )}
            />

            <MagicField
              name="name"
              fieldType="text"
              icon={nameIcon}
              placeholder="Full Name"
              rules={{
                required: 'You must provide a full name.',
              }}
            />

            {createAccountKind === AccountKind.User && (
              <>
                <MagicField
                  name="email"
                  type="email"
                  inputmode="text"
                  icon={emailIcon}
                  placeholder="Email Address"
                  rules={{
                    required: 'You must provide an email address.',
                  }}
                />

                <MagicField
                  name="phone"
                  fieldType="phone"
                  icon={phoneIcon}
                  placeholder="Phone Number"
                  rules={{
                    required: 'You must provide a phone number.',
                  }}
                />
              </>
            )}
          </MagicForm>

          <IonLoading isOpen={mutating} />
        </div>
      </IonContent>
      <UnsafeArea position="bottom" color="primary" />
    </IonPage>
  );
};

export default CreateAccount;
