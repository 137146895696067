import { gql, useMutation, useQuery } from '@apollo/client';
import { useOrganization } from 'hooks/useOrganization';
import { useWorkspace } from 'hooks/useWorkspace';
import { Asset } from 'interfaces/Asset';
import { WorkOrder } from 'interfaces/WorkOrder';
import { useState } from 'react';

type WorkOrderAsset = Pick<Asset, 'id' | 'name' | 'assetType'> & {
  location: Pick<Asset['location'], 'description' | 'geometry'>;
};

//#region Tag Mutation
export interface TagAssetMutationInput {
  input: { workOrderId: string; assets: WorkOrderAsset[] };
}

export interface TagAssetMutationResponse {
  tagWorkOrderAssets: { assets: WorkOrderAsset[] };
}

export const TAG_ASSET_MUTATION = gql`
  mutation TagWorkOrderAssets($input: TagWorkOrderAssetsInput) {
    tagWorkOrderAssets(input: $input) {
      assets {
        id
        name
        assetType
        location {
          description
          geometry
        }
      }
    }
  }
`;
//#endregion

export interface QueryWOResponse {
  workorder: {
    id: WorkOrder['id'];
    assets: { edges: { node: WorkOrderAsset }[] };
  };
}

export const QUERY_WO = gql`
  query WorkOrderAssets($workorderId: ID!) {
    workorder(id: $workorderId) {
      id
      assets {
        edges {
          node {
            id
            name
            assetType
            location {
              description
              geometry
            }
          }
        }
      }
    }
  }
`;

export interface UseWorkOrderDetailsAssetsOptions {
  workorderId: string;
}

export interface State {
  loadingCount: number;
  workorderAssets: WorkOrderAsset[];
  error: string | null;
}

const useWorkOrderDetailsAssets = ({ workorderId }: UseWorkOrderDetailsAssetsOptions) => {
  const { workspace } = useWorkspace();
  const { organization } = useOrganization(workspace.organizationId);
  const assetsUrls = organization?.assetsUrls;

  const [state, setState] = useState<State>({ loadingCount: 1, workorderAssets: [], error: null });

  useQuery<QueryWOResponse, { workorderId: string }>(QUERY_WO, {
    fetchPolicy: 'network-only',
    skip: !workorderId,
    variables: { workorderId },
    onError: () => {
      setState((prev) => ({
        ...prev,
        loadingCount: prev.loadingCount - 1,
        error: 'Unable to load work order assets.',
      }));
    },
    onCompleted: ({ workorder }) => {
      setState((prev) => ({
        ...prev,
        workorderAssets: workorder.assets.edges.map((e) => e.node),
        loadingCount: prev.loadingCount - 1,
        error: null,
      }));
    },
  });

  const [tagAssetMutation] = useMutation<TagAssetMutationResponse, TagAssetMutationInput>(
    TAG_ASSET_MUTATION
  );

  async function tagAsset(asset: WorkOrderAsset) {
    setState((prev) => ({ ...prev, loadingCount: prev.loadingCount + 1, error: null }));

    let responseAssets: WorkOrderAsset[];

    try {
      const response = await tagAssetMutation({
        variables: {
          input: {
            workOrderId: workorderId,
            assets: [...state.workorderAssets, asset].map((a) => ({
              id: a.id,
              name: a.name,
              assetType: a.assetType,
              location: {
                geometry: a.location.geometry,
                description: a.location.description,
              },
            })),
          },
        },
      });

      if (!response.data?.tagWorkOrderAssets?.assets) {
        throw new Error('Unexpected GraphQL response.');
      }

      responseAssets = response.data?.tagWorkOrderAssets.assets;
    } catch (err) {
      setState((prev) => ({
        ...prev,
        loadingCount: prev.loadingCount - 1,
        error: 'Unable to add assets to work order.',
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      workorderAssets: responseAssets,
      loadingCount: prev.loadingCount - 1,
      error: null,
    }));
  }

  async function untagAssets(assetIds: string[]) {
    const newAssets = state.workorderAssets.filter((a) => !assetIds.includes(a.id));

    if (newAssets.length === state.workorderAssets.length) return;

    setState((prev) => ({ ...prev, loadingCount: prev.loadingCount + 1, error: null }));

    let responseAssets: WorkOrderAsset[];

    try {
      const response = await tagAssetMutation({
        variables: {
          input: {
            workOrderId: workorderId,
            assets: newAssets.map((asset) => ({
              id: asset.id,
              name: asset.name,
              assetType: asset.assetType,
              location: {
                geometry: asset.location.geometry,
                description: asset.location.description,
              },
            })),
          },
        },
      });

      if (!response.data?.tagWorkOrderAssets?.assets) {
        throw new Error('Unexpected GraphQL response.'); // TODO. Review all errors before completing
      }

      responseAssets = response.data?.tagWorkOrderAssets.assets;
    } catch (err) {
      setState((prev) => ({
        ...prev,
        loadingCount: prev.loadingCount - 1,
        error: 'Unable to untag assets from work order.',
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      workorderAssets: responseAssets,
      loadingCount: prev.loadingCount - 1,
      error: null,
    }));
  }

  return {
    workorderAssets: state.workorderAssets,
    orgHasGISAssetURLs: Boolean(assetsUrls?.length),
    loading: state.loadingCount > 0,
    error: state.error,
    tagAsset,
    untagAssets,
  };
};

export default useWorkOrderDetailsAssets;
