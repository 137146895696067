import { useQuery } from '@apollo/client';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import InfiniteList from 'components/InfiniteList';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import ResidentSearch from 'components/ReporterSearch';
import WorkOrderListItem from 'components/WorkOrderItem/WorkOrderListItem';
import { useHistoryPersist } from 'hooks/useHistoryPersist';
import { useMapStyles } from 'hooks/useMapStyles';
import { useMessages } from 'hooks/useMessages';
import { useReporters } from 'hooks/useReporters';
import { useWorkspace } from 'hooks/useWorkspace';
import { MapContext } from 'interfaces/Basemaps';
import { Reporter as Resident } from 'interfaces/Reporter';
import { downloadOutline as downloadIcon } from 'ionicons/icons';
import {
  GET_WORK_ORDERS_BY_RESIDENT,
  GetWorkOrdersData,
  GetWorkOrdersVariables,
} from 'pages/Reports/graphql';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PdfGenerator from './components/PdfGenerator';
import SelectedResidents from './components/SelectedResidents';
import WorkOrdersMap from './components/WorkOrdersMap';

interface PathParams {
  residents?: string;
}

const init = (pathParams: PathParams) => {
  if (!pathParams.residents) {
    return [];
  }

  const selectedResidentIds = pathParams.residents.split(',');

  return selectedResidentIds.map((encoded) => {
    const decoded = JSON.parse(Buffer.from(encoded, 'base64').toString());

    const resident: Resident = {
      ...decoded,
      id: encoded,
    };

    return resident;
  });
};

const ResidentReport: React.FC = () => {
  const pageRef = useRef<HTMLElement>(null);

  const [pathParams, setPathParams] = useHistoryPersist<PathParams>();

  const [query, setQuery] = useState<string>();
  const [selectedResidents, setSelectedResidents] = useState<Resident[]>(() => init(pathParams));
  const [showPdfOptions, setShowPdfOptions] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { workspace } = useWorkspace();
  const { reporters } = useReporters(workspace.organizationId, { query, size: 8, skip: !query });
  const { addMessage } = useMessages();

  const mapStyles = useMapStyles({
    organizationId: workspace.organizationId,
    context: MapContext.ResidentReport,
  });

  const { data: getWorkOrdersData, refetch: getWorkOrdersRefetch } = useQuery<
    GetWorkOrdersData,
    GetWorkOrdersVariables
  >(GET_WORK_ORDERS_BY_RESIDENT, {
    displayName: 'ResidentReport',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { organizationId: workspace.organizationId },
    skip: !selectedResidents.length,
  });

  useEffect(() => {
    const selectedResidentIds = selectedResidents.reduce<string | undefined>((acc, resident) => {
      return acc ? `${acc},${resident.id}` : resident.id;
    }, undefined);

    setPathParams({ residents: selectedResidentIds });
  }, [selectedResidents, setPathParams]);

  const fetchWorkOrders = useCallback(() => {
    if (pathParams.residents) {
      getWorkOrdersRefetch({
        organizationId: workspace.organizationId,
        residents: pathParams.residents,
      });
    }
  }, [workspace.organizationId, pathParams.residents, getWorkOrdersRefetch]);

  useEffect(() => {
    fetchWorkOrders();
  }, [fetchWorkOrders]);

  const residentSearchResults = useMemo(
    () =>
      reporters.filter((reporter) => {
        const isSelected = !!selectedResidents.find((target) => reporter.id === target.id);

        return !isSelected;
      }),
    [reporters, selectedResidents]
  );

  const workOrderSearchResults = useMemo(() => {
    return getWorkOrdersData?.organization.workorders.nodes || [];
  }, [getWorkOrdersData]);

  const addSelectedResident = (resident: Resident) => {
    if (selectedResidents.includes(resident)) {
      return;
    }

    setSelectedResidents([...selectedResidents, resident]);
  };

  const removeSelectedResident = (resident: Resident) => {
    setSelectedResidents(selectedResidents.filter((target) => resident.id !== target.id));
  };

  const clearSelectedResidents = () => {
    setSelectedResidents([]);
  };

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref="/reports" />
          </IonButtons>

          <IonTitle>Resident Report</IonTitle>

          <IonButtons slot="end">
            <IonButton
              disabled={!workOrderSearchResults.length}
              onClick={() => setShowPdfOptions(true)}
            >
              {isDownloading ? (
                <IonSpinner color="primary" name="crescent" />
              ) : (
                <IonIcon icon={downloadIcon} slot="icon-only" />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <ResidentSearch
          query={query}
          setQuery={setQuery}
          results={residentSearchResults}
          onSelectResult={addSelectedResident}
        />

        {!!selectedResidents.length && (
          <SelectedResidents
            selectedResidents={selectedResidents}
            onRemove={removeSelectedResident}
            onClear={clearSelectedResidents}
          />
        )}
      </IonHeader>

      <IonContent style={{ '--padding-bottom': 'var(--ion-safe-area-bottom)' }}>
        <PullToRefresh onRefresh={fetchWorkOrders} />

        {!!workOrderSearchResults.length && (
          <React.Fragment>
            <ListSeparator />

            <WorkOrdersMap
              workorders={workOrderSearchResults}
              geography={workspace.geography}
              mapStyles={mapStyles}
              pageRef={pageRef}
            />

            <ListSeparator height="0" />

            {/* Extra space between map and list. Feels cluttered without it */}
            <div
              style={{
                height: 4,
                background: 'var(--ion-item-background, var(--ion-background-color, #fff))',
              }}
            />

            <InfiniteList
              title="Work Orders"
              pageRef={pageRef}
              searchKeys={['workorderNumber', 'title']}
              items={workOrderSearchResults}
              itemSize={isPlatform('ios') ? 86 : 90}
              renderListItem={(workorder, index, items) => (
                <WorkOrderListItem
                  workorder={workorder as any}
                  key={workorder.id}
                  lines={index === items.length - 1 ? 'full' : 'inset'}
                />
              )}
            />

            <PdfGenerator
              isOpen={showPdfOptions}
              pageRef={pageRef}
              residents={selectedResidents}
              workorders={workOrderSearchResults}
              onDismiss={() => setShowPdfOptions(false)}
              onWillDownload={() => setIsDownloading(true)}
              onDidDownload={() => setIsDownloading(false)}
              onFailure={(error) => addMessage(error, 'danger')}
            />
          </React.Fragment>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ResidentReport;
