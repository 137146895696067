import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { useStatus } from 'hooks/useStatus';
import { Status } from 'interfaces/Status';
import React from 'react';

export interface StatusBannerProps {
  hasAlert?: false | string;
  status: Status;
  reason?: string;
}

const StatusBanner: React.FC<StatusBannerProps> = ({ status, reason, hasAlert }) => {
  const { color, icon, label } = useStatus(status);

  return (
    <IonItem
      lines="full"
      style={{
        '--background': hasAlert ? 'var(--ion-color-danger-tint)' : color,
        '--color': '#ffffff',
      }}
    >
      <IonIcon icon={icon} size="small" slot="start" color="#ffffff" />
      <IonLabel>
        <h2>{label}</h2>
        {reason && <p style={{ color: 'inherit' }}>{reason}</p>}
        {hasAlert && <p style={{ color: 'inherit' }}>{hasAlert}</p>}
      </IonLabel>
    </IonItem>
  );
};

export default StatusBanner;
