import { useQuery } from '@apollo/client';
import { isPlatform } from '@ionic/core/components';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import PullToRefresh from 'components/PullToRefresh';
import WorkOrderListItem from 'components/WorkOrderItem/WorkOrderListItem';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import WorkOrderFilters, {
  FilterType,
  WorkOrderFilterProps,
  useWorkOrderFilters,
} from 'containers/WorkOrderFilters';
import { useHistoryPersist } from 'hooks/useHistoryPersist';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import {
  GET_WORK_ORDERS,
  GET_WORK_ORDER_FILTERS,
  GetWorkOrderFiltersData,
  GetWorkOrderFiltersVariables,
  GetWorkOrdersData,
  GetWorkOrdersVariables,
  WorkOrderAggregations,
  WorkOrderSearchResult,
} from 'pages/WorkOrders/graphql';
import React, { useEffect, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface PathParams {
  query?: string;
  categories?: string;
  statuses?: string;
  areasOfInterest?: string;
  street?: string;
  teams?: string;
  assignees?: string;
  tags?: string;
}

const toPathParam = (ids: string[] | string) => {
  if (!Array.isArray(ids)) return ids;

  return ids.reduce<string | undefined>((acc, id) => {
    return acc ? `${acc},${id}` : id;
  }, undefined);
};

const initFilterGroup = (availableFilters: Map<string, boolean>, activeFilters?: string) => {
  if (!activeFilters) return availableFilters;

  const filterState = new Map(availableFilters);

  const activeFilterIds = activeFilters.split(',');

  activeFilterIds.forEach((id) => filterState.set(id, true));

  return filterState;
};

const SearchResultItem: React.FC<ListChildComponentProps<WorkOrderSearchResult[]>> = ({
  index,
  style,
  data,
}) => {
  return (
    <WorkOrderListItem
      workorder={data[index]}
      style={style as React.CSSProperties}
      lines={index === data.length - 1 ? 'none' : 'inset'}
    />
  );
};

const WorkOrdersSearch: React.FC = () => {
  const [pathParams, setPathParams] = useHistoryPersist<PathParams>();

  const [query, setQuery] = useState<string | undefined>(pathParams.query);
  const [inputValue, setInputValue] = useState<string | undefined>(pathParams.query);
  const { workspace } = useWorkspace();
  const { addMessage } = useMessages();

  const { data: getWorkOrderFiltersData } = useQuery<
    GetWorkOrderFiltersData,
    GetWorkOrderFiltersVariables
  >(GET_WORK_ORDER_FILTERS, {
    displayName: 'WorkOrdersSearch',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      organizationId: workspace.organizationId,
      query,
    },
    onError: () => addMessage('Unable to load work orders.', 'danger'),
  });

  const { data: getWorkOrdersData, loading, refetch, fetchMore } = useQuery<
    GetWorkOrdersData,
    GetWorkOrdersVariables
  >(GET_WORK_ORDERS, {
    displayName: 'WorkOrdersSearch',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      organizationId: workspace.organizationId,
      query: pathParams.query,
      categories: pathParams.categories,
      statuses: pathParams.statuses,
      areasOfInterest: pathParams.areasOfInterest,
      street: pathParams.street,
      teams: pathParams.teams,
      assignees: pathParams.assignees,
      tags: pathParams.tags,
      size: 50,
    },
    onError: () => addMessage('Unable to load work orders.', 'danger'),
  });

  const { categories, statuses, areasOfInterest, teams, assignees, tags } = useMemo(() => {
    const aggs = getWorkOrderFiltersData?.organization.workorders.aggs || {};

    return (Object.keys(aggs) as (keyof WorkOrderAggregations)[]).reduce<WorkOrderFilterProps>(
      (acc, key) => ({ ...acc, [key]: aggs[key]!.nodes }),
      { categories: [], statuses: [], areasOfInterest: [], teams: [], assignees: [], tags: [] }
    );
  }, [getWorkOrderFiltersData]);

  const {
    filters,
    clear,
    clearAll,
    hasChecked,
    isChecked,
    toggleChecked,
    search,
    update,
  } = useWorkOrderFilters({
    categories: categories.reduce<any[]>(
      (acc, { category }) => (category ? [...acc, category] : acc),
      []
    ),
    statuses: statuses.map((agg) => agg.status),
    areasOfInterest: areasOfInterest.reduce<any[]>(
      (acc, { areaOfInterest }) => (areaOfInterest ? [...acc, areaOfInterest] : acc),
      []
    ),
    teams: teams.reduce<any[]>((acc, { team }) => (team ? [...acc, team] : acc), []),
    assignees: assignees.reduce<any[]>(
      (acc, { assignee }) => (assignee ? [...acc, assignee] : acc),
      []
    ),
    street: pathParams.street,
    tags: tags?.map((agg) => agg.tag),
  });

  useEffect(() => {
    update(FilterType.Category, initFilterGroup(filters.categories, pathParams.categories));
    update(FilterType.Status, initFilterGroup(filters.statuses, pathParams.statuses));
    update(FilterType.Team, initFilterGroup(filters.teams, pathParams.teams));
    update(FilterType.Assignee, initFilterGroup(filters.assignees, pathParams.assignees));
    update(FilterType.Tag, initFilterGroup(filters.tags, pathParams.tags));
    update(
      FilterType.AreasOfInterest,
      initFilterGroup(filters.areasOfInterest, pathParams.areasOfInterest)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const activeFilters = useMemo<Record<FilterType, string>>(() => {
    return (Object.keys(filters) as FilterType[]).reduce<any>((acc, filter) => {
      const criteria =
        filter === FilterType.Street
          ? filters[FilterType.Street]
          : [...filters[filter].keys()].reduce<string[]>((list, criterion) => {
              return isChecked(filter, criterion) ? [...list, criterion] : list;
            }, []);

      return {
        ...acc,
        [filter]: criteria,
      };
    }, {});
  }, [filters, isChecked]);

  useEffect(() => {
    setPathParams({
      query,
      street: activeFilters.street ? activeFilters.street : undefined,
      categories: toPathParam(activeFilters.categories),
      statuses: toPathParam(activeFilters.statuses),
      areasOfInterest: toPathParam(activeFilters.areasOfInterest),
      teams: toPathParam(activeFilters.teams),
      assignees: toPathParam(activeFilters.assignees),
      tags: toPathParam(activeFilters.tags),
    });
  }, [query, activeFilters, setPathParams]);

  const { searchResults = [], hasNextPage } = useMemo(() => {
    return {
      searchResults: getWorkOrdersData?.organization.workorders.nodes,
      hasNextPage: getWorkOrdersData?.organization.workorders.pageInfo.hasNextPage,
    };
  }, [getWorkOrdersData]);
  const isItemLoaded = (index: number) => !hasNextPage || index < searchResults.length;

  const itemCount = hasNextPage ? searchResults.length + 1 : searchResults.length;

  const loadMore = () => {
    return fetchMore({
      variables: {
        after: getWorkOrdersData?.organization.workorders.pageInfo.endCursor,
      },
      updateQuery: (previousQueryResult: GetWorkOrdersData, options) => {
        const nodes = previousQueryResult.organization.workorders?.nodes || [];
        const newNodes = options.fetchMoreResult?.organization.workorders.nodes || [];
        const newPageInfo = options.fetchMoreResult?.organization.workorders.pageInfo;

        if (!options.fetchMoreResult) return previousQueryResult;

        return {
          organization: {
            workorders: {
              nodes: [...nodes, ...newNodes],
              pageInfo: newPageInfo!,
            },
          },
        };
      },
    });
  };

  const loadMoreItems = loading
    ? () => {
        return;
      }
    : loadMore; // eslint-disable-line

  return (
    <React.Fragment>
      <IonPage id="work-orders-search">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>

            <IonTitle>Work Orders</IonTitle>

            <IonButtons slot="end">
              <WorkOrderFilters.Toggle hasChecked={hasChecked} />
            </IonButtons>
          </IonToolbar>

          <IonToolbar>
            <IonSearchbar
              debounce={1000}
              mode="md"
              placeholder="Search work orders..."
              style={{ '--border-radius': '100px' }}
              value={inputValue}
              onIonInput={(e) => {
                // runs on every keystroke
                // updates with value of input
                setInputValue(e.target.value || undefined);
              }}
              onIonChange={(e) => {
                // only runs after debounce
                // updates with value for search query param
                setQuery(e.detail.value || undefined);
              }}
            />
          </IonToolbar>

          {!(searchResults.length || loading) && (
            <IonToolbar style={{ '--min-height': 0 }}>
              <IonTitle
                className="ion-text-start"
                color="medium"
                size="small"
                style={{
                  paddingTop: 0,
                  paddingBottom: isPlatform('ios') ? 12 : 'var(--ion-margin, 16px)',
                  fontSize: '0.875rem',
                }}
              >
                {query
                  ? `0 matches for search "${query}"${hasChecked ? ' and active filters' : ''}`
                  : '0 matches for active filters'}
              </IonTitle>
            </IonToolbar>
          )}
        </IonHeader>

        <IonContent scrollY={false}>
          <PullToRefresh onRefresh={refetch} />

          <AuthorizeRequired required={[AccountAction.ListWorkOrders]}>
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems as any}
                >
                  {({ ref, onItemsRendered }) => (
                    <List
                      height={height}
                      width={width}
                      itemCount={itemCount}
                      itemData={searchResults}
                      itemSize={isPlatform('ios') ? 86 : 90}
                      ref={ref}
                      onItemsRendered={onItemsRendered}
                    >
                      {SearchResultItem}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </AuthorizeRequired>
        </IonContent>
      </IonPage>

      <WorkOrderFilters
        contentId="work-orders-search"
        categories={categories}
        statuses={statuses}
        areasOfInterest={areasOfInterest}
        street={activeFilters.street}
        teams={teams}
        assignees={assignees}
        tags={tags}
        filters={filters}
        clear={clear}
        clearAll={clearAll}
        isChecked={isChecked}
        toggleChecked={toggleChecked}
        search={search}
        update={update}
      />
    </React.Fragment>
  );
};

export default WorkOrdersSearch;
