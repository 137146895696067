import { IonButton, IonIcon, IonLabel, IonSlide, IonSlides } from '@ionic/react';
import centerOfMass from '@turf/center-of-mass';
import clsx from 'clsx';
import InteractiveMap from 'components/InteractiveMap';
import { images, layers } from 'components/InteractiveMap/assetSymbology';
import { MapStyles } from 'interfaces/Basemaps';
import {
  chevronBackOutline as backIcon,
  chevronForwardOutline as forwardIcon,
} from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export interface MapItem {
  kind: 'MAP';
  key: string;
  name?: string;
  feature: GeoJSON.Feature<GeoJSON.Geometry, { assetType: string }>;
  mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
}

interface ImageItem {
  kind: 'IMAGE';
  key: string;
  name: string;
  source: string;
}

export type CarouselItem = MapItem | ImageItem;

interface CarouselProps {
  items: CarouselItem[];
  options?: {
    initialItemIndex?: number;
  };
  onActiveItemChange?: (index: number) => void;
}

const useStyles = createUseStyles({
  navigationButtonContainer: {
    zIndex: 2,
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    paddingBottom: 'var(--ion-safe-area-bottom, 0)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgba(31, 31, 31, 0.8)', // Not using theme because color should always be dark overlay
  },
  fillParent: {
    height: '100%',
    width: '100%',
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grabbable: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
});

const AssetCarousel: React.FC<CarouselProps> = ({ items, options, onActiveItemChange }) => {
  const initialSlide = options?.initialItemIndex || 0;

  const classes = useStyles();

  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const [activeIndex, setActiveIndex] = useState<number>(initialSlide);

  // Record for each zoomed image. Key is index and value is true if zoomed in.
  // Used to disable image panning when zoomed out and slide swiping when zoomed in.
  // Navigation controls are not shown when zoomed in.
  // Using index vs `item.key` to more easy work with navigation controls.
  const [slideZoomState, setSlideZoomState] = useState<Record<number, boolean>>({});

  return (
    <React.Fragment>
      <IonSlides
        key={items.length}
        ref={slidesRef}
        pager={false}
        options={{
          // BUG. IonSlides bug. Slide 0 briefly loads before sliding to initialSlide.
          initialSlide,
        }}
        className={classes.fillParent}
        onIonSlideWillChange={async ({ target }) => {
          const index = await (target as HTMLIonSlidesElement).getActiveIndex();
          setActiveIndex(index);
          onActiveItemChange && onActiveItemChange(index);
        }}
        onIonSlidesDidLoad={async ({ target }) => {
          const index = await (target as HTMLIonSlidesElement).getActiveIndex();
          onActiveItemChange && onActiveItemChange(index);
        }}
      >
        {items.map((item, index) => {
          switch (item.kind) {
            case 'MAP':
              const center = centerOfMass(item.feature.geometry);
              const centerCoords = [
                center.geometry.coordinates[0],
                center.geometry.coordinates[1],
              ] as [number, number];
              return (
                <IonSlide key={item.key} className="swiper-no-swiping">
                  <InteractiveMap
                    mapStyles={item.mapStyles}
                    style={{ height: '100%', width: '100%' }}
                    initialPosition={{ center: centerCoords, zoom: 14 }}
                    geoJSON={{ features: [item.feature], layers, images }}
                    isMoveable={true}
                  />
                </IonSlide>
              );
            case 'IMAGE':
              return (
                <IonSlide
                  key={item.key}
                  className={clsx({ 'swiper-no-swiping': slideZoomState[index] === true })}
                >
                  <TransformWrapper
                    panning={{ disabled: slideZoomState[index] !== true }}
                    onZoomStop={(ref) =>
                      setSlideZoomState((prev) => {
                        const zoomed = ref.state.scale > 1;
                        return prev[index] === zoomed ? prev : { ...prev, [index]: zoomed };
                      })
                    }
                  >
                    <TransformComponent
                      wrapperClass={classes.fillParent}
                      contentClass={classes.fillParent}
                    >
                      <div
                        className={clsx(
                          classes.fillParent,
                          classes.centerContent,
                          classes.grabbable
                        )}
                      >
                        <img src={item.source} alt="" />
                      </div>
                    </TransformComponent>
                  </TransformWrapper>
                </IonSlide>
              );
            default:
              // Should not be reached
              return null;
          }
        })}
      </IonSlides>
      {slideZoomState[activeIndex] !== true && (
        <div className={classes.navigationButtonContainer}>
          <IonButton
            style={{ visibility: activeIndex > 0 ? 'visible' : 'hidden' }}
            fill="clear"
            onClick={() => slidesRef.current?.slidePrev()}
          >
            <IonIcon slot="icon-only" icon={backIcon} />
          </IonButton>
          <IonLabel color="medium">
            <p>
              {activeIndex + 1} / {items.length}
            </p>
          </IonLabel>
          <IonButton
            style={{
              visibility: activeIndex < items.length - 1 ? 'visible' : 'hidden',
            }}
            fill="clear"
            onClick={() => slidesRef.current?.slideNext()}
          >
            <IonIcon slot="icon-only" icon={forwardIcon} />
          </IonButton>
        </div>
      )}
    </React.Fragment>
  );
};

export default AssetCarousel;
