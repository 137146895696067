import { ApolloProvider } from '@apollo/client';
import { IonContent, IonLoading } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';

import { checkSchemaVersion, setupApollo } from './setup';

interface StoreConfig {
  version: string;
}

export const StoreProvider: React.FC<StoreConfig> = ({ children, version }) => {
  const [client, setClient] = useState<any>(undefined);

  const setupStore = useCallback(async () => {
    await checkSchemaVersion(version);
    const apolloClient = setupApollo(version);
    setClient(apolloClient);
  }, [version]);

  useEffect(() => {
    setupStore();
  }, [setupStore]);

  if (client === undefined) {
    return (
      <IonContent>
        <IonLoading isOpen={true} />
      </IonContent>
    );
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
