import { IonChip, IonIcon, IonItem, IonItemDivider, IonLabel, IonText } from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import { Category } from 'interfaces/Category';
import { closeCircleOutline as clearIcon, pieChartOutline } from 'ionicons/icons';
import React, { RefObject, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

export interface CategoryProps extends Pick<Category, 'id' | 'name'> {
  averageDuration: number;
  averageLabor: number;
  color: string;
  count: number;
  percentage: number;
}

export interface CategorySummaryProps {
  categories: CategoryProps[];
  pieChartRef: RefObject<PieChart>;
}

const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const textColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-text-color');

  return (
    <>
      {percent > 0.01 && (
        <text
          x={x}
          y={y}
          fontSize="13px"
          fill={textColor}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      )}
    </>
  );
};

const renderActiveShape = (props: any) => <Sector {...props} fill="var(--ion-color-highlight)" />;

const CategorySummary: React.FC<CategorySummaryProps> = ({ categories, pieChartRef }) => {
  const [activeIndex, setActiveIndex] = useState<number>();

  return (
    <>
      <IonItemDivider mode="md" sticky={true}>
        <IonIcon icon={pieChartOutline} slot="start" />
        <IonLabel>
          <h2>Category Summary</h2>
        </IonLabel>
      </IonItemDivider>

      {/* Responsive container does not support specifying width and height properties in [vh]
      Width fixed at 300px to prevent excessively wide SVGS when generating PDF */}
      <div
        style={{
          height: 400,
          maxHeight: '40vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <ResponsiveContainer height="100%" width={320}>
          <PieChart ref={pieChartRef} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
            <Pie
              data={categories}
              dataKey="percentage"
              isAnimationActive={false}
              label={renderLabel}
              labelLine={false}
              minAngle={5}
              startAngle={90}
              endAngle={-450}
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              style={{ cursor: 'pointer' }}
              onClick={(_data, index) => setActiveIndex(index)}
            >
              {categories.map((category) => (
                <Cell key={category.id} fill={category.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {activeIndex !== undefined && (
          <IonChip
            outline={true}
            color="medium"
            style={{
              position: 'absolute',
              top: 4,
              right: 'var(--ion-margin, 16px)',
              fontSize: '0.75rem',
            }}
            onClick={() => setActiveIndex(undefined)}
          >
            <IonIcon icon={clearIcon} />
            <IonLabel>Clear Selection</IonLabel>
          </IonChip>
        )}
      </div>

      <ListSeparator height="0" />

      {categories.map((category, index) => (
        <IonItem
          key={category.id}
          lines={index === categories.length - 1 ? 'full' : 'inset'}
          button={true}
          detail={false}
          style={{ '--min-height': '54px' }}
          onClick={() => setActiveIndex(index)}
        >
          <IonLabel>
            <h3 style={{ fontSize: '0.9375rem' }}>{category.name}</h3>
            {category.averageLabor !== undefined && category.averageDuration !== undefined && (
              <p style={{ marginTop: 4 }}>
                <IonText color="medium">
                  {category.averageLabor.toFixed(2)} Avg Labor (hours) ·{' '}
                  {category.averageDuration.toFixed(2)} Avg Duration (days)
                </IonText>
              </p>
            )}
          </IonLabel>
          <IonChip
            slot="start"
            style={{
              minWidth: 40,
              '--background': index === activeIndex ? 'var(--ion-color-highlight)' : category.color,
              justifyContent: 'center',
            }}
          >
            <IonLabel
              style={{
                fontSize: '0.75rem',
                color:
                  index === activeIndex
                    ? 'var(--ion-color-highlight-contrast)'
                    : 'var(--ion-item-background, var(--ion-background-color, #fff))',
              }}
            >
              {category.count}
            </IonLabel>
          </IonChip>
        </IonItem>
      ))}
    </>
  );
};

export default CategorySummary;
