import { gql, useQuery } from '@apollo/client';
import { Basemaps, MapContext, MapStyles } from 'interfaces/Basemaps';

interface GetBasemapsData {
  organization: {
    basemaps: Basemaps;
  };
}

interface GetBasemapsVariables {
  organizationId: string;
}

interface UseMapStylesConfig {
  organizationId: string;
  context: MapContext;
}

const mapStylesFragment = gql`
  fragment Themes on MapStyles {
    light
    lightUrl @client
    dark
    darkUrl @client
  }
`;

const basemapSelector = (context: MapContext) => {
  switch (context) {
    case MapContext.WorkOrderCreation: {
      return gql`
        fragment SelectedBasemap on CustomBasemaps {
          workorders_create {
            ...Themes
          }
        }
        ${mapStylesFragment}
      `;
    }

    case MapContext.WorkOrderDetails: {
      return gql`
        fragment SelectedBasemap on CustomBasemaps {
          workorders_details {
            ...Themes
          }
        }
        ${mapStylesFragment}
      `;
    }

    case MapContext.WorkOrdersMap: {
      return gql`
        fragment SelectedBasemap on CustomBasemaps {
          workorders_map {
            ...Themes
          }
        }
        ${mapStylesFragment}
      `;
    }

    case MapContext.WorkSummaryReport: {
      return gql`
        fragment SelectedBasemap on CustomBasemaps {
          reports_worksummary {
            ...Themes
          }
        }
        ${mapStylesFragment}
      `;
    }

    case MapContext.ResidentReport: {
      return gql`
        fragment SelectedBasemap on CustomBasemaps {
          reports_resident {
            ...Themes
          }
        }
        ${mapStylesFragment}
      `;
    }

    default: {
      throw new Error(`Unsupported map context ${context}`);
    }
  }
};

const getBasemaps = (context: MapContext) => gql`
  query GetBasemaps($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      basemaps {
        ...SelectedBasemap
      }
    }
  }
  ${basemapSelector(context)}
`;

const defaultMapStyles: MapStyles = {
  light: 'ljagis/ckfywoifl008619p4l84jrj2h',
  lightUrl: 'mapbox://styles/ljagis/ckfywoifl008619p4l84jrj2h?optimize=true',
  dark: 'mapbox/dark-v10',
  darkUrl: 'mapbox://styles/mapbox/dark-v10?optimize=true',
};

export const useMapStyles = ({ organizationId, context }: UseMapStylesConfig): MapStyles => {
  const query = getBasemaps(context);

  const { data } = useQuery<GetBasemapsData, GetBasemapsVariables>(query, {
    displayName: 'useMapStyles',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { organizationId },
  });

  return (data?.organization.basemaps && data.organization.basemaps[context]) || defaultMapStyles;
};
