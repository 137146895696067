import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface FluidBannerProps {
  graphic: string;
  label?: string;
}

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowY: 'scroll',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const FluidBanner: React.FC<FluidBannerProps> = ({
  children,
  graphic,
  label = 'banner graphic',
}) => {
  const classes = useStyles();

  return (
    <IonGrid className={classes.root}>
      <IonRow>
        <IonCol sizeXs="12" sizeMd="6" sizeLg="6" className={classes.column}>
          <IonImg src={graphic} alt={label} />
        </IonCol>

        <IonCol sizeXs="12" sizeMd="6" sizeLg="6" className={classes.column}>
          {children}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default FluidBanner;
