import { useMutation, useQuery } from '@apollo/client';
import { useMessages } from 'hooks/useMessages';
import { Account } from 'interfaces/Account';
import { Attachment, AttachmentUrls } from 'interfaces/Attachment';
import { MaintenanceEntry } from 'interfaces/EquipmentMaintenance';
import { useMemo } from 'react';

import {
  CORRECT_EQUIPMENT_MAINTENANCE,
  CorrectMaintenanceVariables,
  DELETE_EQUIPMENT_MAINTENANCE,
  DeleteMaintenancVariables,
  GET_MAINTENANCE_LOG,
  GetMaintenanceLogData,
  GetMaintenanceLogVariables,
  LOG_EQUIPMENT_MAINTENANCE,
  LogMaintenanceVariables,
} from './graphql';

type MaintenanceLogEntries = (Pick<
  MaintenanceEntry,
  'id' | 'service' | 'date' | 'cost' | 'mileage' | 'notes'
> & {
  performedBy: Pick<Account, 'id' | 'name'>;
  attachments: (Pick<Attachment, 'id' | 'uploadId' | 'name' | 'mimetype' | 'attachmentType'> & {
    urls: Pick<AttachmentUrls, 'raw' | 'large2X'>;
  })[];
})[];

export interface UseMaintenanceLogResult {
  entries: MaintenanceLogEntries;
  logMaintenance: (input: LogMaintenanceVariables) => void;
  correctMaintenance: (input: CorrectMaintenanceVariables) => void;
  deleteMaintenance: (input: DeleteMaintenancVariables) => void;
}

export const useMaintenanceLog = (equipmentId: string) => {
  const { addMessage } = useMessages();

  const { data } = useQuery<GetMaintenanceLogData, GetMaintenanceLogVariables>(
    GET_MAINTENANCE_LOG,
    {
      displayName: 'useMaintenanceLog',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { equipmentId },
      onError: () => addMessage('Unable to load maintenance log', 'danger'),
    }
  );

  const [logMaintenance] = useMutation<any, LogMaintenanceVariables>(LOG_EQUIPMENT_MAINTENANCE);
  const [correctMaintenance] = useMutation<any, CorrectMaintenanceVariables>(
    CORRECT_EQUIPMENT_MAINTENANCE
  );
  const [deleteMaintenance] = useMutation<any, DeleteMaintenancVariables>(
    DELETE_EQUIPMENT_MAINTENANCE
  );

  const entries = useMemo<MaintenanceLogEntries>(() => {
    if (!data) return [];

    return data.equipment.maintenance.nodes.map((entry) => {
      const attachments = entry.attachments.nodes;

      return {
        ...entry,
        attachments,
      };
    });
  }, [data]);

  return {
    entries,
    logMaintenance,
    correctMaintenance,
    deleteMaintenance,
  };
};
