import { IonCol } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

import DetailsCondensed, { DetailsCondensedProps } from './DetailsCondensed';
import DetailsFull, { DetailsFullProps } from './DetailsFull';
import { WorkHistoryCardStyleProps } from './WorkHistoryCard';

export interface WorkHistoryCardRightProps {
  timelineItem: DetailsCondensedProps['timelineItem'] & DetailsFullProps['timelineItem'];
  condensed: boolean;
}

const useStyles = createUseStyles({
  rightSide: (props: WorkHistoryCardStyleProps) => ({
    borderRadius: props.condensed ? '0px 8px 8px 0px' : '0px 36px 36px 0px',
    background: 'var(--ion-background-color)',
    display: 'flex',
  }),
});

const WorkHistoryCardRight: React.FC<WorkHistoryCardRightProps> = ({
  timelineItem,
  condensed = false,
}) => {
  const classes = useStyles({ condensed });

  return (
    <IonCol size="8" className={classes.rightSide}>
      {condensed ? (
        <DetailsCondensed timelineItem={timelineItem} />
      ) : (
        <DetailsFull timelineItem={timelineItem} />
      )}
    </IonCol>
  );
};

export default WorkHistoryCardRight;
