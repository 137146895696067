import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { addOutline as icon } from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '--padding-top': '4px',
    '--padding-bottom': '4px',
    fontSize: '0.875rem',
    '.ios &': {
      fontSize: '0.9375rem',
    },
  },
});

export interface AddNewItemButtonProps {
  title: string;
  disabled?: boolean;
  routerLink?: string;
  onClick?: () => void;
}

/** Button at the end of each item section to add a new item to the list */
const AddNewItemButton: React.FC<AddNewItemButtonProps> = ({
  title,
  disabled,
  routerLink,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <IonItem
      className={classes.root}
      lines="full"
      button={true}
      disabled={disabled}
      detail={false}
      routerLink={routerLink}
      onClick={onClick}
    >
      <IonIcon icon={icon} slot="start" color="primary" />
      <IonLabel color="primary">{title}</IonLabel>
    </IonItem>
  );
};

export default AddNewItemButton;
