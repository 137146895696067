import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/core/components';
import {
  IonBackdrop,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react';
import InfiniteList from 'components/InfiniteList';
import UnsafeArea from 'components/UnsafeArea';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { useAccounts } from 'hooks/useAccounts';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import {
  closeOutline as closeIcon,
  downloadOutline as downloadIcon,
  hammerOutline as maintenanceLogIcon,
} from 'ionicons/icons';
import Carousel, {
  CarouselItem,
} from 'pages/WorkOrders/WorkDetails/components/DetailsPhoto/Carousel';
import React, { useMemo, useState } from 'react';

import MaintenanceEntryForm, { FormInputs } from './MaintenanceEntryForm';
import TableHeaderItem from './TableHeaderItem';
import TableItem, { TableItemProps as MaintenanceEntry } from './TableItem';
import { useMaintenanceLog } from './useMaintenanceLog';

const { Browser } = Plugins;

export interface MaintenanceLogProps {
  equipmentId: string;
  pageRef: React.RefObject<HTMLElement>;
}

interface ModalState {
  view?: 'photos' | 'notes' | 'attachments' | 'form';
  selectedEntry?: MaintenanceEntry;
}

const MaintenanceLog: React.FC<MaintenanceLogProps> = ({ equipmentId, pageRef }) => {
  const { workspace } = useWorkspace();
  const { accounts } = useAccounts({ organizationId: workspace.organizationId });
  const { addMessage } = useMessages();

  const { entries, logMaintenance, correctMaintenance, deleteMaintenance } = useMaintenanceLog(
    equipmentId
  );

  const [activePhoto, setActivePhoto] = useState<number>();
  const [{ view, selectedEntry }, setModalState] = useState<ModalState>({});

  const resetModalState = () => {
    setModalState({});
  };

  const photos = useMemo(() => {
    return (selectedEntry?.attachments || []).reduce<CarouselItem[]>((acc, attachment) => {
      return attachment.attachmentType === 'photo'
        ? [
            ...acc,
            {
              key: attachment.id,
              kind: 'IMAGE',
              name: attachment.name,
              source: attachment.urls.large2X,
            },
          ]
        : acc;
    }, []);
  }, [selectedEntry]);

  const handlePresentPhotos = (index: number) => {
    setModalState({
      view: 'photos',
      selectedEntry: entries[index],
    });
  };

  const handlePresentFiles = (index: number) => {
    setModalState({
      view: 'attachments',
      selectedEntry: entries[index],
    });
  };

  const handlePresentNotes = (index: number) => {
    setModalState({
      view: 'notes',
      selectedEntry: entries[index],
    });
  };

  const handlePresentForm = (index: number = -1) => {
    setModalState({
      view: 'form',
      selectedEntry: entries[index],
    });
  };

  const handleSubmitForm = async (mode: 'create' | 'edit', input: FormInputs, entryId?: string) => {
    try {
      presentLoading({ spinner: 'crescent' });

      mode === 'create'
        ? await logMaintenance({ variables: { ...input, equipmentId } })
        : await correctMaintenance({ variables: { ...input, equipmentId, entryId: entryId! } });

      resetModalState();
    } catch {
      addMessage('Unable to log equipment maintenance', 'danger');
    } finally {
      dismissLoading();
    }
  };

  const handleDeleteEntry = (entryId: string) => {
    deleteMaintenance({ variables: { equipmentId, entryId } });
    resetModalState();
  };

  const [presentLoading, dismissLoading] = useIonLoading();

  return (
    <React.Fragment>
      <IonItemDivider mode="md" sticky={true}>
        <IonIcon icon={maintenanceLogIcon} slot="start" />

        <IonLabel>
          <h2>Maintenance Log</h2>
        </IonLabel>

        <AuthorizeRequired required={[AccountAction.LogEquipmentMaintenance]}>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => handlePresentForm()}>
              Add
            </IonButton>
          </IonButtons>
        </AuthorizeRequired>
      </IonItemDivider>

      <InfiniteList<MaintenanceEntry>
        pageRef={pageRef}
        title="Maintenance Log"
        items={entries}
        itemCount={5}
        itemSize={isPlatform('ios') ? 89 : 91}
        searchKeys={['service', 'notes']}
        renderListHeader={() => <TableHeaderItem />}
        renderListItem={(props, index, items) => (
          <TableItem
            {...props}
            key={props.id}
            lines={index === items.length - 1 ? 'full' : 'inset'}
            onEditItem={() => handlePresentForm(index)}
            onDeleteItem={(entryId) => handleDeleteEntry(entryId)}
            onViewFiles={() => handlePresentFiles(index)}
            onViewNotes={() => handlePresentNotes(index)}
            onViewPhotos={() => handlePresentPhotos(index)}
          />
        )}
      />

      {/** View Photos Modal */}
      <IonModal
        isOpen={view === 'photos'}
        presentingElement={pageRef.current || undefined}
        swipeToClose={true}
        onDidDismiss={resetModalState}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={resetModalState}>
                <IonIcon icon={closeIcon} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {activePhoto !== undefined && photos[activePhoto] ? photos[activePhoto].name : ''}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent scrollY={false}>
          <IonBackdrop style={{ opacity: 0.84, zIndex: 0 }} tappable={false} />
          <Carousel items={photos} onActiveItemChange={setActivePhoto} />
        </IonContent>
      </IonModal>

      {/** View Notes Modal */}
      <IonModal
        isOpen={view === 'notes'}
        presentingElement={pageRef.current || undefined}
        swipeToClose={true}
        onDidDismiss={resetModalState}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={resetModalState}>
                <IonIcon icon={closeIcon} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>{selectedEntry?.service}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonCard style={{ boxShadow: 'none' }}>
            <IonCardHeader>
              <IonCardTitle>Notes</IonCardTitle>
            </IonCardHeader>
            <IonCardContent style={{ whiteSpace: 'pre-wrap' }}>
              <IonText>{selectedEntry?.notes}</IonText>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>

      {/** Attachments Modal */}
      <IonModal
        isOpen={view === 'attachments'}
        presentingElement={pageRef.current || undefined}
        swipeToClose={true}
        onDidDismiss={resetModalState}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={resetModalState}>
                <IonIcon icon={closeIcon} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>{selectedEntry?.service}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList className="ion-no-padding">
            {selectedEntry?.attachments.length ? (
              selectedEntry.attachments.map((file, index) => (
                <IonItem
                  button={true}
                  detail={true}
                  detailIcon={downloadIcon}
                  key={file.id}
                  lines={index === selectedEntry.attachments.length - 1 ? 'full' : 'inset'}
                  onClick={() => Browser.open({ url: file.urls.raw })}
                >
                  <IonLabel>
                    <h3>{file.name}</h3>
                  </IonLabel>
                </IonItem>
              ))
            ) : (
              <IonItem lines="full">
                <IonLabel>
                  <p>No added attachments</p>
                </IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      </IonModal>

      {/** Edit Item Modal */}
      <IonModal
        isOpen={view === 'form'}
        presentingElement={pageRef.current || undefined}
        swipeToClose={true}
        onDidDismiss={resetModalState}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={resetModalState}>
                <IonIcon icon={closeIcon} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>{`${selectedEntry ? 'Edit' : 'Log'} Maintenance`}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <MaintenanceEntryForm
            pageRef={pageRef}
            maintenance={selectedEntry}
            accounts={accounts}
            equipmentId={equipmentId}
            deleteAllowed={workspace.actions.includes(AccountAction.DeleteWorkOrderComment)}
            onError={(message) => addMessage(message, 'danger')}
            onSubmit={handleSubmitForm}
            onDeleteEntry={handleDeleteEntry}
          />
        </IonContent>

        <UnsafeArea color="primary" position="bottom" />
      </IonModal>
    </React.Fragment>
  );
};

export default MaintenanceLog;
