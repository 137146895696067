import { useQuery } from '@apollo/client';
import { InputChangeEventDetail } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useMessages } from 'hooks/useMessages';
import {
  GET_ORGANIZATION_TAGS,
  GetOrganizationTagsData,
  GetOrganizationTagsVariables,
  useTags,
} from 'hooks/useTags';
import { useWorkspace } from 'hooks/useWorkspace';
import { addCircleOutline as addIcon, closeCircleOutline as closeIcon } from 'ionicons/icons';
import * as React from 'react';
import { createUseStyles } from 'react-jss';

interface TagsModalProps {
  tags: string[];
  pageRef?: React.RefObject<HTMLElement>;
  workOrder: string;
}

export const useStyles = createUseStyles({
  root: {
    border: '1px solid var(--ion-color-light)',
    '--inner-padding-top': 'calc(var(--ion-margin, 16px) * 0.75)',
    '--inner-padding-bottom': 'calc(var(--ion-margin, 16px) * 0.75)',
  },
  input: {
    marginLeft: 'calc(var(--ion-margin, 16px) * 0.25)',
    marginTop: 'calc(var(--ion-margin, 16px) * .5)',
    marginBottom: 'calc(var(--ion-margin, 16px) * .5)',
  },
  chipsList: {
    paddingBottom: 'calc(var(--ion-margin, 16px) * 0.75)',
    marginLeft: 'calc(var(--ion-margin, 16px) * -0.25)',
  },
});

const TagsModal: React.FC<TagsModalProps> = ({ pageRef, workOrder, tags: tagsData }) => {
  const [tags, setTags] = React.useState<string[]>(tagsData);
  const [label, setLabel] = React.useState<string | undefined>(undefined);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [popoverState, setPopoverState] = React.useState<{
    showPopup: boolean;
    event: CustomEvent<InputChangeEventDetail> | undefined;
  }>({
    showPopup: false,
    event: undefined,
  });
  const { addTag: add, deleteTag } = useTags(workOrder);
  const classes = useStyles();
  const { workspace } = useWorkspace();
  const { addMessage } = useMessages();

  const { data: getOrganizationTagsData } = useQuery<
    GetOrganizationTagsData,
    GetOrganizationTagsVariables
  >(GET_ORGANIZATION_TAGS, {
    displayName: 'OganizationTagsSearch',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      organizationId: workspace.organizationId,
      text: label || '',
      size: 10,
    },
    onError: () => addMessage('Unable to load tag suggestions.', 'danger'),
  });

  const filteredSearchResults = (getOrganizationTagsData?.organization?.tags?.nodes || []).filter(
    (item) => !tags.includes(item)
  );

  /** Label is neither a currently used tag nor listed in suggestions */
  const shouldSuggestNew = label && !tags.includes(label) && !filteredSearchResults.includes(label);

  const removeTag = (indexToRemove: number, tag: string) => {
    deleteTag(tag);
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTag = async (tag: string | undefined) => {
    if (tag) {
      if (!tags.includes(tag)) {
        add(tag);
        setPopoverState({ showPopup: false, event: undefined });
        setTags([...tags, tag]);
        setLabel(undefined);
      }
    }
  };

  const onChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setLabel(e.detail.value || undefined);
    label
      ? setPopoverState({ showPopup: true, event: e })
      : setPopoverState({ showPopup: false, event: undefined });
  };

  return (
    <React.Fragment>
      <IonChip color="primary" outline={true} onClick={() => setShowModal(true)}>
        <IonIcon icon={addIcon} />
        <IonLabel>Tags</IonLabel>
      </IonChip>
      <IonModal
        isOpen={showModal}
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowModal(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>Tags</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItemGroup className={classes.root}>
            <IonItem lines="none">
              <IonInput
                mode="md"
                inputmode="text"
                value={label}
                placeholder="Add tags"
                onIonChange={onChange}
                className={classes.input}
                onKeyPress={(e) => (e.key === 'Enter' ? addTag(label || undefined) : null)}
              />
              <IonPopover
                mode="md"
                showBackdrop={false}
                keyboardClose={false}
                event={popoverState.event}
                isOpen={popoverState.showPopup}
                onDidDismiss={() => setPopoverState({ showPopup: false, event: undefined })}
              >
                <IonList style={{ padding: 0 }}>
                  {shouldSuggestNew && (
                    <IonItem
                      lines="none"
                      mode="md"
                      button={true}
                      detail={false}
                      onClick={() => addTag(label)}
                      style={{ '--min-height': '36px' }}
                    >
                      <p style={{ lineHeight: 0.5 }}>New tag "{label}"</p>
                    </IonItem>
                  )}
                  {filteredSearchResults.map((tag, index) => (
                    <IonItem
                      lines="none"
                      mode="md"
                      button={true}
                      detail={false}
                      key={index}
                      onClick={() => addTag(tag)}
                      style={{ '--min-height': '36px' }}
                    >
                      <p style={{ lineHeight: 0.5 }}>{tag}</p>
                    </IonItem>
                  ))}
                </IonList>
              </IonPopover>
            </IonItem>
            <IonItem lines="none">
              <div className={classes.chipsList}>
                {tags.map((tag, index) => (
                  <IonChip key={index} color="primary" outline={true}>
                    <IonLabel>{tag}</IonLabel>
                    <IonIcon
                      icon={closeIcon}
                      color="primary"
                      onClick={() => removeTag(index, tag)}
                    />
                  </IonChip>
                ))}
              </div>
            </IonItem>
          </IonItemGroup>
        </IonContent>
      </IonModal>
    </React.Fragment>
  );
};

export default TagsModal;
