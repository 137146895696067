import { IonHeader, IonPopover, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { NotificationModalProps } from './NotificationModalProps';

export const useStyles = createUseStyles({
  /** Set popover width to width of contents */
  popover: {
    '--width': 'unset',
  },
});

const NotificationPopover: React.FC<NotificationModalProps> = ({
  isItemLoaded,
  itemCount,
  loadMoreItems,
  popupState,
  row,
  onClose,
}) => {
  // 200 px is arbitrary number
  // Gets close to full screen but does not overrun window on smaller devices
  // We could improve this to be height of header + any explicit top and bottom padding.
  const height = window.innerHeight - 200;

  const classes = useStyles();

  return (
    <IonPopover
      event={popupState.event}
      isOpen={popupState.showPopup}
      onDidDismiss={onClose}
      className={classes.popover}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        threshold={0}
      >
        {({ onItemsRendered, ref }) => (
          <List
            height={height}
            itemCount={itemCount}
            itemSize={138}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={420}
          >
            {row}
          </List>
        )}
      </InfiniteLoader>
    </IonPopover>
  );
};

export default NotificationPopover;
