import { IonCol, IonGrid, IonItemDivider, IonLabel, IonRow } from '@ionic/react';
import AssigneesSearchModal from 'components/AssigneesSearchModal';
import SearchModal from 'components/SearchModal';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { AccountAction } from 'interfaces/AccountAction';
import {
  businessOutline as organizationIcon,
  gridOutline as categoryIcon,
  peopleOutline as teamIcon,
  personOutline as assigneeIcon,
} from 'ionicons/icons';
import React, { RefObject } from 'react';
import { createUseStyles } from 'react-jss';

import AssignmentDisplay from './AssignmentDisplay';
import AssignmentSearch from './AssignmentSearch';
import {
  AssigneeAssignment,
  CategoryAssignment,
  OrganizationAssignment,
  TeamAssignment,
  WorkOrderProps,
} from './interfaces';

export interface WorkOrderAssignmentsProps {
  assignees: AssigneeAssignment[];
  categories: CategoryAssignment[];
  organizations: OrganizationAssignment[];
  pageRef?: RefObject<HTMLElement>;
  teams: TeamAssignment[];
  workOrder: WorkOrderProps;
  onSelectAssignee: (value: AssigneeAssignment) => Promise<any>;
  onSelectCategory: (value: CategoryAssignment) => Promise<any>;
  onSelectOrganization: (value: OrganizationAssignment) => Promise<any>;
  onSelectTeam: (value: TeamAssignment) => Promise<any>;
}

const useStyles = createUseStyles({
  root: {
    marginLeft: 'calc(var(--ion-grid-column-padding, 5px) * -1)',
    marginRight: 'calc(var(--ion-grid-column-padding, 5px) * -1)',
  },
});

export const WorkOrderAssignments: React.FC<WorkOrderAssignmentsProps> = ({
  assignees,
  categories,
  organizations,
  pageRef,
  teams,
  workOrder: { assignee, category, organization, team },
  onSelectAssignee,
  onSelectCategory,
  onSelectOrganization,
  onSelectTeam,
}) => {
  const classes = useStyles();

  const { userAssignees, virtualAssignees } = assignees.reduce(
    (acc, item) => {
      if (item.kind === 'User') acc.userAssignees.push(item);
      if (item.kind === 'Virtual') acc.virtualAssignees.push(item);
      return acc;
    },
    { userAssignees: [] as AssigneeAssignment[], virtualAssignees: [] as AssigneeAssignment[] }
  );

  return (
    <IonGrid className={classes.root}>
      <IonRow>
        <AuthorizeRequired required={[AccountAction.AssignWorkOrderCategory]}>
          <IonCol>
            <SearchModal<CategoryAssignment>
              items={categories}
              label="Select Category"
              pageRef={pageRef}
              value={category}
              onSelect={onSelectCategory}
              renderDisplayItem={(props) => (
                <AssignmentDisplay
                  label="Category"
                  placeholderIcon={categoryIcon}
                  placeholderText="No Category"
                  value={category?.name}
                  onClick={props.onClick}
                />
              )}
              renderSearchItem={(props) => <AssignmentSearch {...props} />}
            />
          </IonCol>
        </AuthorizeRequired>

        <AuthorizeRequired required={[AccountAction.AssignWorkOrderAssignee]}>
          <IonCol>
            <AssigneesSearchModal<AssigneeAssignment>
              items={userAssignees}
              itemsSecondary={virtualAssignees}
              label="Select Assignee"
              pageRef={pageRef}
              keys={['name', 'email']}
              value={assignee}
              onSelect={onSelectAssignee}
              renderSubtitle={() => (
                <IonItemDivider color="light" mode="md" sticky={true}>
                  <IonLabel>
                    <h2>User Accounts</h2>
                  </IonLabel>
                </IonItemDivider>
              )}
              renderSubtitleSecondary={() => (
                <IonItemDivider color="light" mode="md" sticky={true}>
                  <IonLabel>
                    <h2>Virtual Accounts</h2>
                  </IonLabel>
                </IonItemDivider>
              )}
              renderDisplayItem={(props) => (
                <AssignmentDisplay
                  label="Assignee"
                  placeholderIcon={assigneeIcon}
                  placeholderText="No Assignee"
                  value={assignee?.name}
                  onClick={props.onClick}
                />
              )}
              renderSearchItem={(props) => <AssignmentSearch {...props} />}
            />
          </IonCol>
        </AuthorizeRequired>

        <AuthorizeRequired required={[AccountAction.AssignWorkOrderTeam]}>
          <IonCol>
            <SearchModal<TeamAssignment>
              items={teams}
              label="Select Team"
              pageRef={pageRef}
              value={team}
              onSelect={onSelectTeam}
              renderDisplayItem={(props) => (
                <AssignmentDisplay
                  label="Team"
                  placeholderIcon={teamIcon}
                  placeholderText="No Team"
                  value={team?.name}
                  onClick={props.onClick}
                />
              )}
              renderSearchItem={(props) => <AssignmentSearch {...props} />}
            />
          </IonCol>
        </AuthorizeRequired>

        <AuthorizeRequired required={[AccountAction.AssignWorkOrderOrganization]}>
          <IonCol>
            <SearchModal<OrganizationAssignment>
              items={organizations}
              label="Select Organization"
              pageRef={pageRef}
              value={organization}
              onSelect={onSelectOrganization}
              renderDisplayItem={(props) => (
                <AssignmentDisplay
                  label="Organization"
                  placeholderIcon={organizationIcon}
                  placeholderText="No Organization"
                  value={organization?.name}
                  onClick={props.onClick}
                />
              )}
              renderSearchItem={(props) => <AssignmentSearch {...props} />}
              renderSubtitle={() => (
                <div className="ion-margin">
                  <IonLabel>
                    <p>
                      Reassigning organizations will remove any existing category, team, and
                      assignee values. All comments will continue to be available and visible to the
                      new organization.
                    </p>
                  </IonLabel>
                </div>
              )}
            />
          </IonCol>
        </AuthorizeRequired>
      </IonRow>
    </IonGrid>
  );
};

export default WorkOrderAssignments;
