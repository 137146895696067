import { IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import { Controller } from 'react-hook-form';
// tslint:disable: no-duplicate-imports
import { useFormContext } from 'react-hook-form';
import { ValidationRules } from 'react-hook-form/dist/types/form.d';
// tslint:enable: no-duplicate-imports
import { createUseStyles } from 'react-jss';

import { FieldTypes } from './FieldTypes';
import {
  CodeInput,
  CodeInputProps,
  PhoneInput,
  PhoneInputProps,
  TextInput,
  TextInputProps,
} from './Inputs';

interface InputBase {
  name: string;
  fieldType?: keyof typeof FieldTypes;
  label?: string;
  icon?: string;
  rules?: ValidationRules;
  isModal?: boolean;
}

export type MagicFieldProps = InputBase & (TextInputProps | PhoneInputProps | CodeInputProps);

const useStyles = createUseStyles({
  field: {
    border: '1px solid var(--ion-color-light)',
    margin: {
      top: 'var(--ion-margin, 16px)',
      bottom: 6,
    },
    '--background-focused': 'var(--ion-color-medium)',
    '--background-focused-opacity': 0.08,
  },
  icon: {
    alignSelf: 'center',
    // Default styling for slotted icons is rgba,
    // but alpha trans is applied to each SVG element and overlap is compounded.
    // Mimic default style with color + opacity
    // TODO. Better solution application wide
    color: 'var(--ion-text-color, #000000)',
    opacity: 0.54,
  },
});

export const MagicField: React.FC<MagicFieldProps> = ({
  name,
  fieldType,
  icon,
  label,
  rules,
  isModal,
  // classname and style are applied to root item
  className,
  style,
  ...props
}) => {
  const classes = useStyles();
  const { control, errors } = useFormContext();
  const error = errors && errors[name];

  switch (fieldType) {
    case FieldTypes.text:
      return (
        <React.Fragment key={name}>
          <IonItem lines="none" className={clsx(classes.field, className)} style={style}>
            {icon !== undefined && <IonIcon className={classes.icon} icon={icon} slot="start" />}
            {label !== undefined && <IonLabel position="stacked">{label}</IonLabel>}
            <Controller
              control={control}
              name={name}
              rules={rules}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  onChange={onChange}
                  value={value}
                  onIonBlur={onBlur}
                  {...props}
                  isModal={isModal}
                />
              )}
            />
          </IonItem>
          {error && (
            <div className="ion-padding-horizontal">
              <IonLabel color="danger">
                <p style={{ fontSize: '0.75rem' }}>{error.message}</p>
              </IonLabel>
            </div>
          )}
        </React.Fragment>
      );
    case FieldTypes.phone:
      return (
        <React.Fragment key={name}>
          <IonItem lines="none" className={clsx(classes.field, className)} style={style}>
            {icon !== undefined && <IonIcon className={classes.icon} icon={icon} slot="start" />}
            {label !== undefined && <IonLabel position="stacked">{label}</IonLabel>}
            <Controller
              control={control}
              name={name}
              rules={rules}
              render={({ onChange, onBlur, value }) => (
                <PhoneInput onChange={onChange} value={value} onIonBlur={onBlur} {...props} />
              )}
            />
          </IonItem>
          {error && (
            <div className="ion-padding-horizontal">
              <IonLabel color="danger">
                <p style={{ fontSize: '0.75rem' }}>{error.message}</p>
              </IonLabel>
            </div>
          )}
        </React.Fragment>
      );
    case FieldTypes.code:
      return (
        <React.Fragment key={name}>
          <IonItem lines="none" className={clsx(classes.field, className)} style={style}>
            {icon !== undefined && <IonIcon className={classes.icon} icon={icon} slot="start" />}
            {label !== undefined && <IonLabel position="stacked">{label}</IonLabel>}
            <Controller
              control={control}
              name={name}
              rules={rules}
              render={({ onChange, onBlur, value }) => (
                <CodeInput onChange={onChange} value={value} onIonBlur={onBlur} {...props} />
              )}
            />
          </IonItem>
          {error && (
            <div className="ion-padding-horizontal">
              <IonLabel color="danger">
                <p style={{ fontSize: '0.75rem' }}>{error.message}</p>
              </IonLabel>
            </div>
          )}
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment key={name}>
          <IonItem lines="none" className={clsx(classes.field, className)} style={style}>
            {icon !== undefined && <IonIcon className={classes.icon} icon={icon} slot="start" />}
            {label !== undefined && <IonLabel position="stacked">{label}</IonLabel>}
            <Controller
              control={control}
              name={name}
              rules={rules}
              render={({ onChange, onBlur, value }) => (
                <IonInput onIonChange={onChange} value={value} onIonBlur={onBlur} {...props} />
              )}
            />
          </IonItem>
          {error && (
            <div className="ion-padding-horizontal">
              <IonLabel color="danger">
                <p style={{ fontSize: '0.75rem' }}>{error.message}</p>
              </IonLabel>
            </div>
          )}
        </React.Fragment>
      );
  }
};

export default MagicField;
