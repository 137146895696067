import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
} from '@ionic/react';
import Avatar from 'components/Avatar';
import { Account } from 'interfaces/Account';
import {
  atOutline,
  checkmarkOutline,
  closeOutline,
  peopleCircle,
  phonePortrait,
} from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { prettyDate } from 'utils/prettyDate';

import { AccountButton } from './interfaces.d';

export interface AccountCardProps {
  account: Account;
  actions?: AccountButton[];
  invalidInvite?: boolean;
}

export const useStyles = createUseStyles({
  name: {
    fontSize: '0.875rem !important',
  },
  recipient: {
    fontSize: '0.75rem !important',
  },
  content: {
    borderTop: '1px solid var(--ion-color-light-tint)',
    'padding-inline-start': 0,
    'padding-inline-end': 0,
    '&.md': {
      margin: {
        top: 0,
        right: 16,
        bottom: 0,
        left: 16,
      },
    },
    '&.ios': {
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 20,
      },
    },
  },
  actions: {
    padding: 0,
    margin: 0,
    '& ion-row': {
      padding: 0,
      margin: 0,
      '& ion-col': {
        padding: 0,
        margin: 0,
      },
    },
    '& ion-button': {
      margin: 0,
      '& ion-icon': {
        marginRight: 10,
      },
    },
  },
});

const AccountCard: React.FC<AccountCardProps> = ({
  account,
  actions = [],
  invalidInvite = false,
}) => {
  const classes = useStyles();

  const {
    name,
    email,
    phone,
    isPhoneVerified,
    isEmailVerified,
    createdAt,
    team,
    activatedAt,
  } = account;

  return (
    <IonCard>
      <IonItem lines="none">
        <Avatar alt={name} />
        <IonLabel className="ion-margin-horizontal">
          <h3 className={classes.name}>{name}</h3>
          {activatedAt ? (
            <p>Joined {prettyDate(activatedAt)}</p>
          ) : (
            <p>Invited {prettyDate(createdAt)}</p>
          )}
        </IonLabel>
      </IonItem>

      <IonCardContent className={classes.content}>
        <IonItem lines="none">
          <IonIcon icon={atOutline} slot="start" />
          <IonLabel>
            <h3>
              <strong>Email</strong>
            </h3>
            <p>{email}</p>
          </IonLabel>
          <IonIcon
            icon={isEmailVerified ? checkmarkOutline : closeOutline}
            color={isEmailVerified ? 'success' : 'danger'}
            slot="end"
          />
        </IonItem>
        <IonItem lines="none">
          <IonIcon icon={phonePortrait} slot="start" />
          <IonLabel>
            <h3>
              <strong>Phone Number</strong>
            </h3>
            <p>{phone}</p>
          </IonLabel>
          <IonIcon
            icon={isPhoneVerified ? checkmarkOutline : closeOutline}
            color={isPhoneVerified ? 'success' : 'danger'}
            slot="end"
          />
        </IonItem>

        <IonItem lines="none">
          <IonIcon icon={peopleCircle} slot="start" />
          <IonLabel className="ion-text-wrap">
            <h3>
              <strong>Team</strong>
            </h3>
            <p>{team?.name}</p>
          </IonLabel>
          <IonIcon
            icon={activatedAt ? checkmarkOutline : closeOutline}
            color={activatedAt ? 'success' : 'danger'}
            slot="end"
          />
        </IonItem>

        {invalidInvite && (
          <IonNote color="danger">
            This invite may be expired, or you might not have permission to join.
          </IonNote>
        )}
      </IonCardContent>

      <IonGrid className={classes.actions}>
        <IonRow>
          {actions.map(
            ({ label, icon, iconSlot = 'start', ...buttonProps }: AccountButton, i: number) => (
              <IonCol key={i} size={actions.length > 1 ? '6' : '12'}>
                <IonButton {...buttonProps} disabled={invalidInvite}>
                  {icon && <IonIcon icon={icon} slot={iconSlot} />}
                  {label}
                </IonButton>
              </IonCol>
            )
          )}
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default AccountCard;
