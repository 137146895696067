import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

import { PdfOptions, Section } from '../PdfGenerator';
import { assetsSection } from './assetsSection';
import { commentSection } from './commentSection';
import { headerSection } from './headerSection';
import { imageSection } from './imageSection';
import { notesSection } from './notesSection';
import { worklogSection } from './worklogSection';

export const createDocument = (workOrder: WorkOrder, options: PdfOptions): TDocumentDefinitions => {
  const { imageUrlMap, imageContent } = imageSection(workOrder, options);
  const content = [headerSection(workOrder)];

  if (options[Section.WorkOrderWorkLog]) {
    content.push(worklogSection(workOrder));
  }

  if (workOrder.assets?.nodes.length) {
    content.push(assetsSection(workOrder));
  }

  if (options[Section.WorkOrderComment] && workOrder.comments.length) {
    content.push(commentSection(workOrder));
  }

  if (options[Section.WorkOrderMap] || options[Section.WorkOrderPhoto]) {
    content.push(imageContent);
  }

  if (options[Section.WorkOrderNote]) {
    content.push(notesSection());
  }

  return {
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    pageMargins: [40, 40, 40, 60],
    footer: (currentPage: number) => ({
      margin: [40, 20, 40, 0],
      columns: [
        {
          alignment: 'left',
          style: 'timestampFooter',
          text: `Report created: ${moment().format('MM/DD/YYYY, h:mm A')}`,
        },
        {
          alignment: 'right',
          text: currentPage.toString(),
        },
      ],
    }),
    content,
    images: imageUrlMap,
    defaultStyle: {
      fontSize: 10,
      lineHeight: 1.2,
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
      },
      subheader: {
        fontSize: 11,
        color: '#555555',
      },
      sectionHeader: {
        fontSize: 12,
        color: '#333333',
        bold: true,
      },
      assetTypeLocation: {
        fontSize: 9,
        color: '#333333',
      },
      commentHeader: {
        color: '#555555',
      },
      timestampFooter: {
        color: '#555555',
      },
      verticalSpacingSm: {
        margin: [0, 5, 0, 0],
      },
      verticalSpacingMd: {
        margin: [0, 10, 0, 0],
      },
      verticalSpacingLg: {
        margin: [0, 20, 0, 0],
      },
    },
  };
};

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

pdfMake.tableLayouts = {
  itemizedWorklog: {
    hLineWidth: (i, node) => {
      if (i === 0 || i === node.table.body.length) return 0;
      return i === node.table.headerRows ? 1 : 0;
    },
    vLineWidth: () => 0,
    hLineColor: () => '#333',
    paddingTop: (i) => (i === 1 ? 8 : 0),
    paddingBottom: (i) => (i === 0 ? 4 : 2),
    paddingLeft: (i) => (i === 0 ? 0 : 8),
    paddingRight: (i) => (i === 3 ? 0 : 8),
  },
};
