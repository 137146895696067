import { gql, useQuery } from '@apollo/client';
import { useMessages } from 'hooks/useMessages';
import { useOrganization } from 'hooks/useOrganization';
import { useWorkspace } from 'hooks/useWorkspace';
import { Asset, AssetType } from 'interfaces/Asset';
import { WorkOrder } from 'interfaces/WorkOrder';
import { useEffect, useState } from 'react';
import { calcDistanceByLocation } from 'utils/calcDistanceByLocation';

export type WorkOrderAsset = Pick<Asset, 'id' | 'name' | 'assetType'> & {
  location: Pick<Asset['location'], 'description' | 'geometry'>;
};

export interface QueryWOResponse {
  workorder: {
    id: WorkOrder['id'];
    location: { latitude: number; longitude: number };
    assets: { edges: { node: Pick<WorkOrderAsset, 'id'> }[] };
  };
}

export const QUERY_WO = gql`
  query WorkOrderAssets($workorderId: ID!) {
    workorder(id: $workorderId) {
      id
      location {
        latitude
        longitude
      }
      assets {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export interface UseTagAssetsModalOptions {
  workorderId: string;
}

export interface GISProperties {
  OBJECTID: number;
  gisId: string;
  name?: string;
  assetType?: AssetType;
  location?: string;
  distance: number | null;
}

export interface State {
  loadingCount: number;
  workorderLocation: { latitude: number; longitude: number } | null;
  availableAssets: GeoJSON.Feature<GeoJSON.Geometry, GISProperties>[];
}

const useTagAssetsModal = ({ workorderId }: UseTagAssetsModalOptions) => {
  const { addMessage } = useMessages();

  const { workspace } = useWorkspace();
  const { organization } = useOrganization(workspace.organizationId);
  const assetsUrls = organization?.assetsUrls;

  const [state, setState] = useState<State>({
    loadingCount: 0,
    workorderLocation: null,
    availableAssets: [],
  });

  const { refetch: queryWorkOrderAssets } = useQuery<QueryWOResponse, { workorderId: string }>(
    QUERY_WO,
    {
      fetchPolicy: 'network-only',
      skip: true,
      variables: { workorderId },
    }
  );

  useEffect(() => {
    async function load(gisAssetUrls: string[]) {
      async function loadFromGIS(urls: string[]) {
        const promises = urls.map((url) => fetch(url).then((res) => res.json()));
        const resp = await Promise.all(promises);
        type geoJSONResponse = GeoJSON.FeatureCollection<GeoJSON.Geometry, GISProperties>;
        return resp
          .map((asset: geoJSONResponse) => asset.features)
          .flat()
          .filter((asset) => asset.properties.gisId && asset.geometry); // All GIS assets should have `gisId` but filter to ensure.
      }

      async function loadFromWorkOrder() {
        const response = await queryWorkOrderAssets();
        return {
          location: response.data.workorder.location,
          assets: response.data.workorder.assets.edges.map((e) => e.node.id),
        };
      }

      setState((prev) => ({ ...prev, loadingCount: prev.loadingCount + 1 }));

      // Note. `Awaited` available with TS 4.5+. Patch until.
      type Awaited<T> = T extends PromiseLike<infer U>
        ? { 0: Awaited<U>; 1: U }[U extends PromiseLike<any> ? 0 : 1]
        : T;

      let responses: [
        Awaited<ReturnType<typeof loadFromGIS>>,
        Awaited<ReturnType<typeof loadFromWorkOrder>>
      ];

      try {
        responses = await Promise.all([loadFromGIS(gisAssetUrls), loadFromWorkOrder()]);
      } catch (_err) {
        addMessage('Unable to load GIS assets.', 'danger');
        setState((prev) => ({ ...prev, loadingCount: prev.loadingCount - 1 }));
        return;
      }

      const [gis, workorder] = responses;

      const gisWithDistance = gis
        .map((asset) => ({
          ...asset,
          properties: {
            ...asset.properties,
            distance: workorder.location
              ? calcDistanceByLocation({
                  workorderCoords: [workorder.location.longitude, workorder.location.latitude],
                  assetLocation: asset,
                })
              : null,
          },
        }))
        .sort((a, b) => (a.properties.distance || 999999) - (b.properties.distance || 999999));

      if (!workorder.assets.length) {
        setState((prev) => ({
          ...prev,
          workorderLocation: workorder.location,
          availableAssets: gisWithDistance,
          loadingCount: prev.loadingCount - 1,
        }));

        return;
      }

      const minusWorkOrderAssets = gisWithDistance.filter(
        (a) => !workorder.assets.includes(a.properties?.gisId)
      );

      setState((prev) => ({
        ...prev,
        workorderLocation: workorder.location,
        availableAssets: minusWorkOrderAssets,
        loadingCount: prev.loadingCount - 1,
      }));
    }

    if (!assetsUrls?.length) return;

    load(assetsUrls);
  }, [assetsUrls, queryWorkOrderAssets, setState, addMessage]);

  return {
    workorderLocation: state.workorderLocation,
    availableAssets: state.availableAssets,
    loading: state.loadingCount > 0,
  };
};

export default useTagAssetsModal;
