import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import useLocation from 'hooks/useLocation';
import { Location } from 'interfaces/Location';
import { locateOutline } from 'ionicons/icons';
import React from 'react';

interface IonFabProps {
  horizontal: 'start' | 'end' | 'center';
  vertical: 'top' | 'bottom' | 'center';
  slot: string;
}

interface IGeolocateButton extends Partial<IonFabProps> {
  /** Callback on successful geolocations */
  onGeolocate: (location: Location) => void;
  onError?: (error: { message: string }) => void;
  renderLoadingDisplay?: (loading: boolean) => JSX.Element;
}

/** Button to determine the current user's geolocation */
const GeolocateButton: React.FC<IGeolocateButton> = ({
  onGeolocate,
  onError,
  renderLoadingDisplay,
  ...fabProps
}) => {
  const { fetchCurrentLocation, isFetchingLocation } = useLocation();

  const onClick = async () => {
    try {
      const location = await fetchCurrentLocation();
      onGeolocate(location);
    } catch (e) {
      onError && onError(e);
    }
  };

  return (
    <>
      {renderLoadingDisplay && renderLoadingDisplay(isFetchingLocation)}
      <IonFab {...fabProps}>
        <IonFabButton color="light" size="small" disabled={isFetchingLocation} onClick={onClick}>
          <IonIcon size="small" icon={locateOutline} />
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default GeolocateButton;
