import { gql } from '@apollo/client';
import { Notification } from 'interfaces/Notification';

// NotificationPublished Subscription
// Subscribes to new notifications for a given `accountId`

export interface NotificationPublishedSubscriptionResponse {
  notificationPublished: Pick<
    Notification,
    'id' | 'channel' | 'reference' | 'subject' | 'message' | 'url'
  >;
}

export interface NotificationPublishedSubscriptionVariables {
  accountId: string;
}

export const NOTIFICATION_PUBLISHED_SUBSCRIPTION = gql`
  subscription OnNotificationPublished($accountId: ID!) {
    notificationPublished(accountId: $accountId) {
      id
      channel {
        id
      }
      # query reference to construct url @client
      reference {
        referenceId
        kind
      }
      subject
      message
      url @client
    }
  }
`;

// ReadNotification Mutation
// Mark single notification as read

export interface ReadNotificationInput {
  channelId: string;
  notificationId: string;
  accountId: string;
}

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($channelId: String!, $notificationId: String!, $accountId: String!) {
    readNotification(
      input: { channelId: $channelId, notificationId: $notificationId, accountId: $accountId }
    ) {
      notification {
        id
        # Uncomment if we want to update unread in local cache
        # Mutation returns null, but schema says unread is non-nullable. Would need to fix.
        # Currently would not update subscription data because __typename is NotificationPublishedPayload, not Notification
        # unread
      }
    }
  }
`;
