import { InputChangeEventDetail } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
} from '@ionic/react';
import { ellipsisVertical as menuIcon } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { SelectedLabor } from './LaborModal';

const useStyles = createUseStyles({
  root: {
    '--inner-padding-start': '5px',
    '--inner-padding-end': '0px',
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
});

type LaborFormProps = {
  labor: SelectedLabor;
  handleUpdate: (props: SelectedLabor) => void;
  handleUpdateRateAll: (props: SelectedLabor) => void;
  handleUpdateRateEmpty: (props: SelectedLabor) => void;
  handleUpdateHoursAll: (props: SelectedLabor) => void;
  handleUpdateHoursEmpty: (props: SelectedLabor) => void;
};

const LaborForm: React.FC<LaborFormProps> = ({
  labor,
  handleUpdate,
  handleUpdateRateAll,
  handleUpdateRateEmpty,
  handleUpdateHoursAll,
  handleUpdateHoursEmpty,
}) => {
  const classes = useStyles();
  const [popoverState, setPopoverState] = React.useState<{
    showPopup: boolean;
    event: CustomEvent<InputChangeEventDetail> | undefined;
  }>({
    showPopup: false,
    event: undefined,
  });

  const closePopover = () => {
    setPopoverState({
      showPopup: false,
      event: undefined,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <IonItem lines="full" detail={false} className={classes.root}>
      <IonGrid style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
        <IonRow className="ion-align-items-center">
          <IonCol style={{ paddingLeft: 0 }} size="6">
            <IonLabel>{labor.account.name}</IonLabel>
          </IonCol>
          <IonCol className="ion-text-center" size="3">
            <IonInput
              value={labor.form.rate}
              type="number"
              step="1"
              inputMode="decimal"
              placeholder="Rate"
              onIonChange={(e) =>
                handleUpdate({
                  ...labor,
                  form: {
                    ...labor.form,
                    rate: e.detail.value ? e.detail.value : undefined,
                  },
                })
              }
            />
          </IonCol>
          <IonCol className="ion-text-center" size="3">
            <IonInput
              value={labor.form.hours}
              type="number"
              step="1"
              inputMode="decimal"
              placeholder="Hours"
              onIonChange={(e) =>
                handleUpdate({
                  ...labor,
                  form: {
                    ...labor.form,
                    hours: e.detail.value ? e.detail.value : undefined,
                  },
                })
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButtons slot="end">
        <IonButton
          fill="clear"
          tabIndex={-1}
          onClick={(e: any) => setPopoverState({ showPopup: true, event: e })}
        >
          <IonIcon slot="icon-only" color="primary" icon={menuIcon} size="small" />
        </IonButton>
        <IonPopover
          mode="md"
          showBackdrop={false}
          keyboardClose={false}
          event={popoverState.event}
          isOpen={popoverState.showPopup}
          onDidDismiss={() => closePopover()}
        >
          <IonContent>
            <IonList style={{ padding: 0 }}>
              <IonItem
                button={true}
                disabled={!labor.form.rate}
                onClick={() => {
                  handleUpdateRateEmpty(labor);
                  closePopover();
                }}
              >
                Apply rate to all empty
              </IonItem>
              <IonItem
                button={true}
                disabled={!labor.form.rate}
                onClick={() => {
                  handleUpdateRateAll(labor);
                  closePopover();
                }}
              >
                Apply rate to all
              </IonItem>
              <IonItem
                button={true}
                disabled={!labor.form.hours}
                onClick={() => {
                  handleUpdateHoursEmpty(labor);
                  closePopover();
                }}
              >
                Apply hours to all empty
              </IonItem>
              <IonItem
                button={true}
                disabled={!labor.form.hours}
                onClick={() => {
                  handleUpdateHoursAll(labor);
                  closePopover();
                }}
              >
                Apply hours to all
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
      </IonButtons>
    </IonItem>
  );
};

export default LaborForm;
