import { ApolloClient, ApolloLink, gql } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { CachePersistor } from 'apollo-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import { Auth } from 'aws-amplify';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { logEvent } from 'services/logger';

import { initialState, resolvers } from './index';

const config: any = {
  url: process.env.REACT_APP_GRAPHQL_ENDPOINT || 'unknown',
  region: process.env.REACT_APP_AWS_REGION || 'unknown',
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
};

const options: any = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
};

const storage: any = window.localStorage;
export const cache: any = new InMemoryCache();
export const persistor = new CachePersistor({ cache, storage });

export const writeInitialState = () => {
  // Default state query
  const initQuery = gql`
    query {
      messages
    }
  `;
  // Set default state
  cache.writeQuery({ query: initQuery, data: initialState });
};

export const checkSchemaVersion = async (version: string = '0.0.1') => {
  const SCHEMA_VERSION_KEY = 'apollo-schema-version';
  // Read the current schema version from storage
  const currentVersion = storage.getItem(SCHEMA_VERSION_KEY);
  // If current version matches the latest version
  if (currentVersion === version) {
    // we're good to go and can restore the cache.
    await persistor.restore();
  } else {
    // Otherwise, we'll want to purge the outdated persisted cache and update version to storage
    await persistor.purge();
    await storage.setItem(SCHEMA_VERSION_KEY, version);
  }
};

export const setupApollo = (version: string) => {
  const createErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach((err) => logEvent(`[GraphQL error] ${err.message}`, err));
    if (networkError) {
      logEvent(`[Network error] ${networkError.message}`, networkError);
    }
  });

  const operatorLink = setContext((_, { headers }) => {
    try {
      // get the active workspace to get the accountId for the identity logged in
      const workspace = JSON.parse(storage.getItem(`workspace-v${version}`));
      // return the headers to the context adding operatorId for backend validation
      return {
        headers: {
          ...headers,
          operatorId: workspace.id,
        },
      };
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.log(`Unable to locate workspace for version ${version}`);
    }
  });

  const createUpload = createUploadLink({
    uri: config.url,
    credentials: 'same-origin',
  });

  const link = ApolloLink.from([
    createErrorLink,
    operatorLink,
    createAuthLink(config),
    createSubscriptionHandshakeLink(config, createUpload),
  ]);

  // Setup Apollo client with links and resolvers
  const client = new ApolloClient({
    cache,
    link,
    resolvers,
    defaultOptions: options,
  });
  // Initial state
  writeInitialState();
  // Handle store reset
  client.onResetStore(async () => {
    await persistor.purge();
    writeInitialState();
  });

  return client;
};
