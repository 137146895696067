import { Plugins } from '@capacitor/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import SocialMediaLinks from 'components/SocialMediaLinks';
import { useAccounts } from 'hooks/useAccounts';
import { useIdentityUser } from 'hooks/useIdentityUser';
import { Workspace, WorkspaceManagerResult, useWorkspaceManager } from 'hooks/useWorkspaceManager';
import { Account } from 'interfaces/Account';
import { AccountAction } from 'interfaces/AccountAction';
import { AccountStatus } from 'interfaces/AccountStatus';
import { Category } from 'interfaces/Category';
import { Geography } from 'interfaces/Geography';
import { Team } from 'interfaces/Team';
import React, { createContext, useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

const { Browser } = Plugins;

const CONTACT_URL = 'https://www.getluke311.com/contact-us';

const WorkspaceContext = createContext({});
WorkspaceContext.displayName = 'WorkspaceContext';

type Worker = Pick<Account, 'id' | 'name' | 'rate'>;

interface OrganizationWorkspace extends Workspace {
  worker: Worker;
  operatorId: string;
  organizationId: string;
  teamId: string;
  geography: Geography;
  actions: AccountAction[];
  teams: Team[];
  assignees: Account[];
  categories: Category[];
}

type WorkspaceContext = Required<WorkspaceManagerResult<OrganizationWorkspace>>;

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    width: '100%',
    height: '100%',
  },
});

export const mapAccountsToWorkspaces = (accounts: Account[]): OrganizationWorkspace[] =>
  accounts.map(({ id, organization, actions, team, rate, name }) => ({
    id,
    label: organization.name,
    operatorId: id,
    organizationId: organization.id,
    teamId: team.id,
    geography: organization.geography,
    actions,
    teams: organization.teams || [],
    assignees: organization.accounts || [],
    categories: organization.categories || [],
    worker: {
      id,
      name,
      rate,
    },
  }));

const LoadingScreen = ({ loading }: any) => (
  <IonContent>
    <IonLoading isOpen={loading} />
  </IonContent>
);

const LandingScreen = () => {
  const classes = useStyles();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" fill="clear" onClick={() => Auth.signOut()}>
              Sign Out
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className={clsx(classes.container, 'ion-padding')}>
          <div>
            <h3>Thanks for signing up!</h3>
            <p>
              It looks like you don't currently belong to a <strong>workspace</strong>.
            </p>
            <p>
              Contact your workspace administrator about receiving an <strong>invitation</strong>
            </p>
          </div>

          <div>
            <h4>Don't have a workspace?</h4>
            <p>Contact us for more information about the onboarding process.</p>

            <IonButton
              color="primary"
              fill="outline"
              onClick={() => Browser.open({ url: CONTACT_URL })}
            >
              Contact Us
            </IonButton>
          </div>
        </div>
      </IonContent>

      <IonFooter>
        <SocialMediaLinks />
      </IonFooter>
    </IonPage>
  );
};

export function WorkspaceProvider({ children, version }: any) {
  const user = useIdentityUser();
  const { accounts, loading } = useAccounts({
    identityId: user?.id,
    statuses: [AccountStatus.Invited, AccountStatus.Activated],
  });
  const workspaces = mapAccountsToWorkspaces(accounts);
  const manager = useWorkspaceManager<OrganizationWorkspace>({ workspaces, version });

  useEffect(() => {
    if (!user) return;

    Sentry.setUser({
      id: user.id,
      username: user.name,
      email: user.email,
      'Account ID': manager.workspace?.id,
    });

    return () => Sentry.configureScope((scope) => scope.setUser(null));
  }, [user, manager.workspace]);

  if (loading) {
    return <LoadingScreen loading={loading} />;
  }

  if (accounts.length === 0) {
    return <LandingScreen />;
  }

  if (!manager.workspace) {
    return <LoadingScreen loading={loading} />;
  }

  return <WorkspaceContext.Provider value={manager}>{children}</WorkspaceContext.Provider>;
}

export const useWorkspace = () => {
  return useContext(WorkspaceContext) as WorkspaceContext;
};
