export const generateColor = (key: string) => {
  if (!key) {
    return;
  }

  const hashValue = [...key].reduce((acc, cur) => {
    const charCode = cur.charCodeAt(0);
    acc = (acc << 5) - acc + charCode;
    return acc & acc;
  }, 0);

  const hexValue = (hashValue & 0xfff).toString(16);

  return `#${hexValue.padEnd ? hexValue.padEnd(3, 'f') : hexValue}`;
};
