import { IonItem, IonLabel } from '@ionic/react';
import { MagicField, MagicForm } from 'components/MagicForm';
import { Equipment } from 'interfaces/Equipment';
import React from 'react';
import { createUseStyles } from 'react-jss';

export interface EquipmentFormProps {
  equipment?: Pick<Equipment, 'name' | 'rate' | 'vehicle'>;
  onSubmit: (args: any) => void;
}

export interface EquipmentFormData {
  name: string;
  primaryRate: number;
  make: string;
  model: string;
  year: number;
  vinNumber: string;
  licenseNumber: string;
}

const useStyles = createUseStyles({
  form: {
    height: '100%',
  },
  itemDivider: {
    '--padding-start': 0,
    '--background': 'var(--ion-background-color, #fff)',
  },
});

const EquipmentForm: React.FC<EquipmentFormProps> = ({ equipment, onSubmit }) => {
  const classes = useStyles();
  const { name, rate, vehicle } = equipment || {};
  const { make, model, year, vinNumber, licenseNumber } = vehicle || {};

  const submit = (values: any) =>
    // Coerce empty strings as null, numeric values as float or int
    // Strings may be empty if they have an existing value that is deleted
    onSubmit({
      name: values.name || undefined,
      primaryRate: values.primaryRate ? parseFloat(values.primaryRate) : undefined,
      make: values.make || undefined,
      model: values.model || undefined,
      year: values.year ? parseInt(values.year, 10) : undefined,
      vinNumber: values.vinNumber || undefined,
      licenseNumber: values.licenseNumber || undefined,
    });

  return (
    <MagicForm
      className={classes.form}
      subtitle={
        equipment ? undefined : 'Create new equipment used to work on and complete work orders'
      }
      submitText={equipment ? 'Save Changes' : 'Create'}
      onSubmit={submit}
      defaultValues={{
        name,
        primaryRate: rate?.primary,
        make: make || undefined,
        model: model || undefined,
        year: year || undefined,
        vinNumber: vinNumber || undefined,
        licenseNumber: licenseNumber || undefined,
      }}
    >
      <MagicField
        name="name"
        type="text"
        fieldType="text"
        inputMode="text"
        label="Name"
        rules={{
          required: 'Name is required',
        }}
      />

      <MagicField
        name="primaryRate"
        type="number"
        step="0.01"
        inputMode="decimal"
        label="Primary Rate"
        rules={{
          required: 'Primary rate is required',
        }}
      />

      <IonItem className={classes.itemDivider} lines="none" mode="md">
        <IonLabel>
          <h2>Vehicle Information (optional)</h2>
        </IonLabel>
      </IonItem>

      <MagicField name="year" type="number" inputMode="numeric" label="Year" />

      <MagicField name="make" type="text" fieldType="text" inputMode="text" label="Make" />

      <MagicField name="model" type="text" fieldType="text" inputMode="text" label="Model" />

      <MagicField name="vinNumber" type="text" fieldType="text" inputMode="text" label="VIN" />

      <MagicField
        name="licenseNumber"
        type="text"
        fieldType="text"
        inputMode="text"
        label="License Plate"
      />
    </MagicForm>
  );
};

export default EquipmentForm;
