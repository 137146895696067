import { gql, useQuery } from '@apollo/client';
import { useMessages } from 'hooks/useMessages';
import { WorkOrder } from 'interfaces/WorkOrder';
import sort from 'lodash.sortby';
import { useEffect, useState } from 'react';

export const GET_WORKORDER = gql`
  query GetWorkOrder($workorderId: ID!) {
    workorder(id: $workorderId) {
      id
      title
      description
      workorderNumber
      titleDisplay @client
      hasAlert @client
      status
      reason
      tags {
        nodes
      }
      createdAt
      modifiedAt
      closedAt
      location {
        latitude
        longitude
        address
      }
      category {
        id
        name
      }
      organization {
        id
        name
      }
      team {
        id
        name
      }
      assignee {
        id
        name
      }
      operator {
        id
        name
      }
      invoices {
        id
        invoiceDate
        invoiceNumber
        description
        amountDue
        url
      }
      worklog {
        id
        name
        taskDate
        invoice {
          id
          invoiceNumber
        }
        laborCount
        laborItems {
          account {
            id
            name
          }
          rate
          hours
          itemCost
        }
        laborCost
        equipmentCount
        equipmentItems {
          equipment {
            id
            name
          }
          rate
          hours
          itemCost
          mileage
        }
        equipmentCost
        materialCount
        materialItems {
          id
          name
          unitCost
          quantity
          itemCost
          referenceNumber
        }
        materialCost
        totalCost
      }
      activity {
        activityType
        content
        summary
        timestamp
        operator {
          id
          name
        }
        hasOperator
      }
      comments {
        id
        content
        createdAt
        operator {
          id
          name
        }
      }
      attachments {
        id
        name
        mimetype
        urls {
          raw
          thumb2X
          small
          small2X
          large2X
        }
        createdAt
        operator {
          id
          name
        }
        attachmentType @client
      }
      reporter {
        name
        email
        phone
        secondaryPhone
      }
    }
  }
`;

export const useWorkOrderDetails = (workorderId: string) => {
  const [workorder, setWorkOrder] = useState<WorkOrder>();

  const { addMessage } = useMessages();

  const { data, loading, refetch } = useQuery<{ workorder: WorkOrder }>(GET_WORKORDER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { workorderId },
    onError: () => addMessage('Unable to load work order.', 'danger'),
  });

  useEffect(() => {
    if (!loading && data && data.workorder) {
      const record: WorkOrder = {
        ...data.workorder,
        activity: sort(data.workorder.activity, 'timestamp'),
        comments: sort(data.workorder.comments, 'createdAt'),
        attachments: sort(data.workorder.attachments, 'createdAt'),
      };
      setWorkOrder(record);
    }
  }, [data, loading, setWorkOrder]);

  return {
    workorder,
    loading,
    setWorkOrder,
    refetch,
  };
};
