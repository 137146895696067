import { AuthState, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  AmplifyAuthenticator,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword,
  AmplifySignIn,
  AmplifySignUp,
} from '@aws-amplify/ui-react';
import { isPlatform } from '@ionic/core/components';
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonImg,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import logoLuke from 'assets/images/logo-primary.png';
import Amplify, { Auth } from 'aws-amplify';
import SocialMediaLinks from 'components/SocialMediaLinks';
import UnsafeArea from 'components/UnsafeArea';
import WelcomeSlides from 'components/WelcomeSlides';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

export interface IdentityUser {
  id: string;
  name: string;
  phone: string;
  email: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  timestamp?: number;
}

const {
  REACT_APP_AWS_REGION = 'us-east-1',
  REACT_APP_COGNITO_CLIENTID,
  REACT_APP_COGNITO_USERPOOLID,
  REACT_APP_COGNITO_IDENTITY_ID,
} = process.env;

Amplify.configure({
  region: REACT_APP_AWS_REGION,
  userPoolId: REACT_APP_COGNITO_USERPOOLID,
  userPoolWebClientId: REACT_APP_COGNITO_CLIENTID,
  identityPoolId: REACT_APP_COGNITO_IDENTITY_ID,
});

const useStyles = createUseStyles({
  authenticator: {
    '--box-shadow': 'none',
    '--margin-bottom': 0,
    '--padding': '0 40px 20px 40px',
    '--width': '100%',
    lineHeight: 1.6,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  logoContainer: {
    maxWidth: '280px !important',
  },
});

const mapCognitoUser = (cognitoUser: CognitoUserInterface): IdentityUser => ({
  id: cognitoUser.attributes.sub,
  name: cognitoUser.attributes.name,
  phone: cognitoUser.attributes.phone_number,
  email: cognitoUser.attributes.email,
  isPhoneVerified: cognitoUser.attributes.phone_number_verified,
  isEmailVerified: cognitoUser.attributes.email_verified,
});

// Initial context and context creation
const initialState = {};
const IdentityUserContext = createContext(initialState);

// User provider that provides the props to the children
export function IdentityUserProvider({ children }: any) {
  const classes = useStyles();

  if (
    !REACT_APP_COGNITO_CLIENTID ||
    !REACT_APP_COGNITO_USERPOOLID ||
    !REACT_APP_COGNITO_IDENTITY_ID
  ) {
    throw Error('You must provide COGNITO_* variables.');
  }

  const [showWelcome, setShowWelcome] = useState(true);
  const [initialAuthState, setInitialAuthState] = useState<AuthState.SignIn | AuthState.SignUp>();
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<IdentityUser | undefined>();
  const [signUpData, setSignUpData] = useState<{
    name?: string;
    email: string;
    password: string;
  }>({
    name: undefined,
    email: '',
    password: '',
  });

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);

      if (nextAuthState === AuthState.SignedIn) {
        setUser(mapCognitoUser(authData as CognitoUserInterface));
      }

      if (nextAuthState === AuthState.SignedOut) {
        setUser(undefined);
      }
    });
  }, []);

  const handleSignUpInputChange = (name: string, event: Event) => {
    setSignUpData({
      ...signUpData,
      [name]: (event.target as any).value,
    });
  };

  if (authState === AuthState.SignedIn && user) {
    return <IdentityUserContext.Provider value={user}>{children}</IdentityUserContext.Provider>;
  }

  if (showWelcome) {
    return (
      <IonPage>
        <UnsafeArea position="top" color="transparent" />

        <IonContent className="ion-padding-vertical">
          <AmplifyAuthenticator style={{ display: 'none' }} />
          <IonGrid className={classes.grid}>
            <IonRow className="ion-justify-content-center">
              <IonCol className={classes.logoContainer} size="8">
                <IonImg src={logoLuke} />
              </IonCol>
            </IonRow>

            <IonRow style={{ flex: '1 1 auto' }}>
              <IonCol>
                <WelcomeSlides />
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center">
              <IonCol sizeXs="10" sizeSm="6" sizeMd="4">
                <IonButton
                  expand="block"
                  onClick={() => {
                    setInitialAuthState(AuthState.SignUp);
                    setShowWelcome(false);
                  }}
                >
                  Get Started
                </IonButton>

                <div
                  className="ion-text-center"
                  style={{
                    paddingTop: 'var(--ion-padding, 16px)',
                    paddingBottom: 'calc(var(--ion-padding, 16px) * 0.5)',
                  }}
                >
                  <IonNote>Already have an account?</IonNote>
                </div>
                <IonButton
                  color="medium"
                  expand="block"
                  fill="outline"
                  onClick={() => {
                    setInitialAuthState(AuthState.SignIn);
                    setShowWelcome(false);
                  }}
                >
                  Sign In
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>

        <IonFooter>
          <SocialMediaLinks />
        </IonFooter>
      </IonPage>
    );
  }

  return (
    <IonPage>
      {isPlatform('ios') && (
        <IonHeader>
          <IonToolbar>
            {authState === AuthState.SignIn && <IonTitle>Sign in to your account</IonTitle>}
            {authState === AuthState.SignUp && <IonTitle>Create a new account</IonTitle>}
          </IonToolbar>
        </IonHeader>
      )}

      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <IonRow
            className="ion-align-items-center ion-justify-content-center"
            style={{ height: '100%' }}
          >
            <IonCol sizeXs="12" sizeSm="6" sizeMd="4">
              <AmplifyAuthenticator
                className={classes.authenticator}
                initialAuthState={initialAuthState}
                key={initialAuthState}
              >
                <AmplifySignUp
                  slot="sign-up"
                  usernameAlias="email"
                  headerText={isPlatform('ios') ? undefined : 'Create a new account'}
                  formFields={[
                    {
                      type: 'name',
                      label: 'Full Name',
                      placeholder: 'First Last',
                      required: true,
                      handleInputChange: (e) => handleSignUpInputChange('name', e),
                      value: signUpData.name,
                    },
                    {
                      type: 'email',
                      label: 'Email',
                      placeholder: 'example@domain.com',
                      required: true,
                      handleInputChange: (e) => handleSignUpInputChange('email', e),
                      value: signUpData.email,
                    },
                    {
                      type: 'password',
                      label: 'Password',
                      placeholder: 'Must have at least 6 characters',
                      required: true,
                      handleInputChange: (e) => handleSignUpInputChange('password', e),
                      value: signUpData.password,
                    },
                    {
                      type: 'phone_number',
                      label: 'Mobile Phone',
                      placeholder: '(XXX) XXX-XXXX',
                      required: true,
                    },
                  ]}
                />

                <AmplifyConfirmSignUp
                  handleAuthStateChange={() => Auth.signIn(signUpData.email, signUpData.password)}
                />

                <AmplifySignIn
                  slot="sign-in"
                  headerText={isPlatform('ios') ? undefined : 'Sign in to your account'}
                  usernameAlias="email"
                />

                <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
              </AmplifyAuthenticator>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <SocialMediaLinks />
      </IonFooter>
    </IonPage>
  );
}

// useIdentityUser hook that adds the context to the component
export const useIdentityUser = () => {
  return useContext(IdentityUserContext) as IdentityUser;
};
