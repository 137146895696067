import { useIdentityUser } from 'hooks/useIdentityUser';
import React from 'react';

import ChatDialogueComponent from './components/ChatDialogue';
import {
  DeleteMessageResult,
  DeleteMessageVariables,
  EditMessageResult,
  EditMessageVariables,
  PostMessageResult,
  PostMessageVariables,
  useChat,
} from './hooks/useChat';

interface ChatDialogueProps {
  topicId: string;
}

const ChatDialogue: React.FC<ChatDialogueProps> = ({ topicId }) => {
  const user = useIdentityUser();
  const { channel, postMessage, editMessage, deleteMessage } = useChat({ topicId });

  const onPostMessage = async (content: string): Promise<PostMessageResult | null | undefined> => {
    if (!channel?.id) {
      return;
    }

    const variables: PostMessageVariables = {
      input: {
        channelId: channel.id,
        author: {
          name: user.name,
          email: user.email,
          phone: user.phone,
        },
        content,
      },
    };

    const result = await postMessage({ variables });

    return result.data;
  };

  const onEditMessage = async (
    messageId: string,
    content: string
  ): Promise<EditMessageResult | null | undefined> => {
    if (!channel?.id) {
      return;
    }

    const variables: EditMessageVariables = {
      input: {
        channelId: channel.id,
        messageId,
        content,
      },
    };

    const result = await editMessage({ variables });

    return result.data;
  };

  const onDeleteMessage = async (
    messageId: string
  ): Promise<DeleteMessageResult | null | undefined> => {
    if (!channel?.id) {
      return;
    }

    const variables: DeleteMessageVariables = {
      input: {
        channelId: channel.id,
        messageId,
      },
    };

    const result = await deleteMessage({ variables });

    return result.data;
  };

  return channel ? (
    <ChatDialogueComponent
      messages={channel.messages}
      onPostMessage={onPostMessage}
      onEditMessage={onEditMessage}
      onDeleteMessage={onDeleteMessage}
    />
  ) : null;
};

export default ChatDialogue;
