import { WorkOrder } from 'interfaces/WorkOrder';
import { Content } from 'pdfmake/interfaces';

import { PdfOptions, Section } from '../PdfGenerator';

const { REACT_APP_MAPBOX_TOKEN = '' } = process.env;

const markerColor = getComputedStyle(document.documentElement)
  .getPropertyValue('--ion-color-primary-tint')
  .replace('#', '')
  .trim();

interface ImageTableCell {
  image: string;
  fit: number[];
  alignment: 'left' | 'right' | 'center';
}

type TableCell = ImageTableCell | {};

const constructImageTable = (images: ImageTableCell[], cols: number): Content | {} => {
  if (!images.length) {
    return {};
  }
  const body: TableCell[][] = [];
  images.forEach((image, i) => {
    if (i % cols === 0) {
      body.push([image]);
      return;
    }
    body[body.length - 1].push(image);
  });

  while (body[body.length - 1].length < cols) {
    body[body.length - 1].push({});
  }

  return {
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      paddingTop: () => 5,
      paddingBottom: () => 5,
      paddingLeft: (i) => (i === 0 ? 0 : 5),
      paddingRight: (i, node) =>
        i === node.table.widths?.length && node.table.widths.length - 1 ? 0 : 5,
    },
    table: {
      widths: Array(cols).fill('*'),
      body,
    },
    style: 'verticalSpacingLg',
  };
};

export const imageSection = (
  workOrder: Pick<WorkOrder, 'location' | 'attachments'>,
  options: PdfOptions
) => {
  const imageUrlMap: { [id: string]: string } = {};
  const images: ImageTableCell[] = [];
  const imageWidth = 170;

  if (workOrder.location && options[Section.WorkOrderMap]) {
    const fetchWidth = imageWidth * 2;
    imageUrlMap.map = `https://api.mapbox.com/styles/v1/ljagis/ckfywoifl008619p4l84jrj2h/static/pin-s+${markerColor}(${workOrder.location.longitude},${workOrder.location.latitude})/${workOrder.location.longitude},${workOrder.location.latitude},12,0.00,0.00/${fetchWidth}x${fetchWidth}@2x?access_token=${REACT_APP_MAPBOX_TOKEN}&attribution=false&logo=false`;
    images.push({ image: 'map', fit: [imageWidth, imageWidth], alignment: 'center' });
  }

  if (options[Section.WorkOrderPhoto]) {
    workOrder.attachments.forEach((attachment) => {
      if (attachment.attachmentType !== 'photo') {
        return;
      }
      imageUrlMap[attachment.id] = attachment.urls.thumb2X;
      images.push({ image: attachment.id, fit: [imageWidth, imageWidth], alignment: 'center' });
    });
  }

  const imageContent = constructImageTable(images, 3);

  return { imageUrlMap, imageContent };
};
