import { IonInput, IonTextarea } from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Rifm } from 'rifm';

interface BaseProps extends React.HTMLAttributes<HTMLIonInputElement | HTMLIonTextareaElement> {
  value?: string;
  onChange?: any;
  multiline?: boolean;
  isModal?: boolean;
}

export type TextInputProps = BaseProps & { onIonBlur: (event: any) => void };

const useStyles = createUseStyles({
  isModal: {
    minHeight: '100px',
    '& > div.textarea-wrapper': {
      height: 'fit-content !important',

      '& > textarea.native-textarea': {
        minHeight: '100px',
      },
    },
  },
});

export const TextInput: React.FC<TextInputProps> = ({
  multiline = false,
  isModal = false,
  onChange,
  value = '',
  ...inputProps
}) => {
  const classes = useStyles();
  return (
    <Rifm
      accept={/./g}
      replace={(v) => (v ? v.slice(0, 1).toUpperCase() + v.slice(1) : '')}
      format={(v) => v}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y }: any) => {
        if (multiline) {
          return (
            <IonTextarea
              className={clsx({ [classes.isModal]: isModal })}
              inputmode="text"
              spellcheck={true}
              autoGrow={true}
              {...inputProps}
              value={y}
              onIonChange={x}
            />
          );
        } else {
          return (
            <IonInput inputmode="text" type="text" {...inputProps} value={y} onIonChange={x} />
          );
        }
      }}
    </Rifm>
  );
};

export default TextInput;
