import { IonButton, IonLabel } from '@ionic/react';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createUseStyles } from 'react-jss';

export interface MagicFormProps
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  title?: string;
  subtitle?: string | ReactNode;
  error?: string;
  onSubmit: (data: any) => void;
  submitText?: string;
  defaultValues?: { [key: string]: any };
  checkDirty?: boolean;
}

const useStyles = createUseStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& ion-label p': {
      lineHeight: 1.5,
    },
  },
  scrollable: {
    flex: '1 1 0%',
    overflow: 'auto',
  },
  restrictWidth: {
    width: '100%',
    maxWidth: 992 - 276, // lg breakpoint (992) - menu width at 992
    margin: 'auto',
  },
  noFlex: {
    flex: '0 0 auto',
  },
  error: {
    fontSize: '0.75rem',
  },
});

export const MagicForm: React.FC<MagicFormProps> = React.memo(
  ({
    title,
    subtitle,
    error,
    checkDirty,
    onSubmit,
    submitText = 'Submit',
    children,
    defaultValues,
    className,
    ...formProps
  }) => {
    const classes = useStyles();

    const formEl: any = useRef();
    const methods = useForm({ mode: 'onBlur', defaultValues });
    const {
      formState: { dirtyFields },
      setValue,
    } = methods;

    useEffect(() => {
      const values = defaultValues || {};
      Object.keys(values).forEach((field) => {
        if (checkDirty && dirtyFields[field]) return;
        setValue(field, values[field], { shouldDirty: false, shouldValidate: true });
      });
    }, [checkDirty, defaultValues, dirtyFields, setValue]);

    return (
      <FormProvider {...methods}>
        <form
          {...formProps}
          className={clsx(className, classes.form)}
          onSubmit={methods.handleSubmit(onSubmit)}
          ref={(el) => (formEl.current = el)}
        >
          <div className={clsx('ion-padding-horizontal', 'ion-padding-bottom', classes.scrollable)}>
            <div className={classes.restrictWidth}>
              {title && (
                <div className="ion-margin-top">
                  <IonLabel className="ion-text-wrap">
                    <h1 style={{ lineHeight: 'inherit' }}>{title}</h1>
                  </IonLabel>
                </div>
              )}
              {subtitle && (
                <div className="ion-margin-top">
                  <IonLabel>
                    <p>{subtitle}</p>
                  </IonLabel>
                </div>
              )}
              {error && (
                <div className="ion-margin-top">
                  <IonLabel className={classes.error} color="danger">
                    <p>{error}</p>
                  </IonLabel>
                </div>
              )}
              {children}
            </div>
          </div>
          <IonButton
            style={{ margin: 0 }}
            className={classes.noFlex}
            type="submit"
            expand="full"
            size="large"
          >
            {submitText}
          </IonButton>
        </form>
      </FormProvider>
    );
  }
);

export default MagicForm;
