import { IonLabel } from '@ionic/react';
import { AssetTimelineNode } from 'interfaces/Asset';
import React from 'react';

import StatusLabel from './StatusLabel';

export interface DetailsCondensedProps {
  timelineItem: Pick<AssetTimelineNode, 'id' | 'title' | 'assignee' | 'team' | 'status'>;
}

const DetailsCondensed: React.FC<DetailsCondensedProps> = ({ timelineItem }) => (
  <div style={{ padding: 'calc(var(--ion-margin, 16px) * 0.5)' }}>
    <IonLabel color="dark" className="wrap-2-lines">
      <p style={{ color: 'inherit' }}>{timelineItem.title}</p>
    </IonLabel>
    <StatusLabel status={timelineItem.status} />
    {Boolean(timelineItem.assignee?.name || timelineItem.team?.name) && (
      <IonLabel className="ion-text-nowrap">
        <p>{timelineItem.assignee?.name || timelineItem.team?.name}</p>
      </IonLabel>
    )}
  </div>
);

export default DetailsCondensed;
