import { Status } from 'interfaces/Status';
import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';

export const resolvers = {
  WorkOrder: {
    hasAlert: ({ modifiedAt, status }: Pick<WorkOrder, 'modifiedAt' | 'status'>) => {
      if ([Status.Cancelled, Status.Completed, Status.Denied].includes(status)) {
        return false;
      }
      return moment().diff(moment(modifiedAt), 'days') > 29 && 'Last updated more than 30 days ago';
    },
    titleDisplay: ({
      title,
      workorderNumber,
    }: Partial<Pick<WorkOrder, 'title' | 'workorderNumber'>>) => {
      return [workorderNumber, title].filter((value) => value).join(' ');
    },
  },
};
