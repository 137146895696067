import { useMutation, useSubscription } from '@apollo/client';
import { ToastOptions } from '@ionic/core/components';
import { useSmartToast } from 'hooks/useSmartToast';
import { chevronForwardOutline as navigateIcon, closeOutline as closeIcon } from 'ionicons/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { logEvent } from 'services/logger';

import {
  NOTIFICATION_PUBLISHED_SUBSCRIPTION,
  NotificationPublishedSubscriptionResponse,
  NotificationPublishedSubscriptionVariables,
  READ_NOTIFICATION,
  ReadNotificationInput,
} from './graphql';

export interface UseInAppNotificationsConfig {
  accountId: string;
  toastOptions?: Pick<
    ToastOptions,
    | 'cssClass'
    | 'duration'
    | 'position'
    | 'translucent'
    | 'animated'
    | 'color'
    | 'mode'
    | 'keyboardClose'
  >;
}

/**
 * Subscribe to new notifications for the given `accountId`.
 * Displays toast for new notifications. Toast is configurable via `toastOptions`.
 * Notification is marked as read when toast dismisses.
 */
export const useInAppNotifications = ({
  accountId,
  toastOptions = {},
}: UseInAppNotificationsConfig): void => {
  const { push } = useHistory();
  const [present] = useSmartToast();

  // No navigation button in toast if notif URL matches `pathname`
  // Don't navigate if already there
  const { pathname } = useLocation();

  const [readNotification] = useMutation<unknown, ReadNotificationInput>(READ_NOTIFICATION, {
    onError: (err) =>
      // No need to notify user. Notif will just remain unread. Log to Sentry.
      logEvent('[useInAppNotifications] Read notification failed', { accountId, error: err }),
  });

  useSubscription<
    NotificationPublishedSubscriptionResponse,
    NotificationPublishedSubscriptionVariables
  >(NOTIFICATION_PUBLISHED_SUBSCRIPTION, {
    skip: !accountId,
    variables: { accountId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) return;

      const {
        notificationPublished: { subject, message, url, channel, id: notificationId },
      } = data;

      const canNavigate = url && url !== pathname; // Notif has URL and not already on page
      const buttons = canNavigate
        ? [{ role: 'cancel', icon: navigateIcon, handler: () => push(url as string) }]
        : [{ role: 'cancel', icon: closeIcon }];

      present({
        header: subject,
        // NOTE message can include HTML
        message,
        position: 'bottom',
        mode: 'md',
        color: 'toast',
        translucent: true,
        duration: 10000,
        buttons,
        onDidDismiss: () =>
          readNotification({ variables: { channelId: channel.id, notificationId, accountId } }),
        ...toastOptions,
      });
    },
  });
};
