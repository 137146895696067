import {
  IonButton,
  IonButtons,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
  IonTextarea,
} from '@ionic/react';
import clsx from 'clsx';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { AccountAction } from 'interfaces/AccountAction';
import { Message } from 'interfaces/Chat/Message';
import { MessageAuthor } from 'interfaces/Chat/MessageAuthor';
import {
  chatbubbleEllipsesOutline as editIcon,
  checkmarkOutline as saveIcon,
  closeOutline as cancelIcon,
  trashOutline as deleteIcon,
} from 'ionicons/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { prettyDate } from 'utils/prettyDate';

interface MessageProps
  extends Pick<
    Message,
    'id' | 'content' | 'createdAt' | 'isCurrentUser' | 'isEdited' | 'modifiedAt'
  > {
  author: Pick<MessageAuthor, 'name'>;
}

interface MessageBubbleProps {
  message: MessageProps;
  onEditMessage: (messageId: string, content: string) => Promise<any>;
  onInitDelete: (messageId: string) => void;
}

const useStyles = createUseStyles({
  message: {
    display: 'flex',
    flexDirection: 'column',
    '--border-radius': '16px',

    '& > ion-item': {
      '--border-radius': 'inherit',
      borderRadius: 'var(--border-radius)',
    },
  },
  messageActions: {
    margin: '0 calc(var(--ion-margin, 16px) * 0.125)',
  },
  messageContainer: {
    padding: 'calc(var(--ion-padding, 16px) * 0.25)',
  },
  messageContent: {
    marginTop: 0,
    whiteSpace: 'pre-line',
  },
  messageDraft: {
    background: 'var(--ion-color-secondary-tint)',

    '& ion-item': {
      border: '1px solid var(--ion-color-light)',
    },
  },
  messageFooter: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 var(--border-radius)',
    minHeight: '24px',
  },
  messageTimestamp: {
    fontSize: '0.875em',
  },
});

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message: { id, author, content, createdAt, isCurrentUser, isEdited, modifiedAt },
  onEditMessage,
  onInitDelete,
}) => {
  const classes = useStyles();
  const contentBuffer = useRef<string>(content);
  const textareaRef = useRef<HTMLIonTextareaElement>(null);
  const [editMode, setEditMode] = useState(false);

  const onInitEdit = useCallback(async () => {
    if (!(editMode && textareaRef.current)) {
      return;
    }

    textareaRef.current.setFocus();
    const inputElement = await textareaRef.current.getInputElement();
    inputElement.setSelectionRange(contentBuffer.current.length, contentBuffer.current.length);
  }, [editMode, textareaRef]);

  const onConfirmEdit = async () => {
    await onEditMessage(id, contentBuffer.current);
    setEditMode(false);
  };

  const onCancelEdit = () => {
    setEditMode(false);
    contentBuffer.current = content;
  };

  useEffect(() => {
    onInitEdit();
  }, [onInitEdit]);

  const SentMessage = () => (
    <IonRow className={classes.messageContainer}>
      <IonCol
        className={clsx(classes.message, 'ion-align-items-end')}
        offsetMd="1"
        offsetXs="3"
        sizeMd="11"
        sizeXs="9"
      >
        <IonItem color="primary" lines="none">
          <IonLabel className="ion-text-wrap">
            <h3>
              <strong>{author.name}</strong>
              {isEdited && <span> (edited)</span>}
            </h3>
            <p className={classes.messageContent}>{content}</p>
          </IonLabel>
        </IonItem>
        <div className={classes.messageFooter}>
          <IonButtons className={classes.messageActions}>
            <AuthorizeRequired required={[AccountAction.EditChatMessage]}>
              <IonButton onClick={() => setEditMode(true)}>
                <IonIcon color="medium" icon={editIcon} size="small" />
              </IonButton>
            </AuthorizeRequired>
            <AuthorizeRequired required={[AccountAction.DeleteChatMessage]}>
              <IonButton onClick={() => onInitDelete(id)}>
                <IonIcon color="medium" icon={deleteIcon} size="small" />
              </IonButton>
            </AuthorizeRequired>
          </IonButtons>
          <IonNote className={classes.messageTimestamp}>
            {prettyDate(isEdited ? modifiedAt : createdAt)}
          </IonNote>
        </div>
      </IonCol>
    </IonRow>
  );

  const DraftMessage = () => (
    <IonRow className={clsx(classes.messageContainer, classes.messageDraft)}>
      <IonCol className={clsx(classes.message, 'ion-align-items-stretch')}>
        <IonItem lines="none">
          <IonTextarea
            autoGrow={true}
            className={classes.messageContent}
            ref={textareaRef}
            value={contentBuffer.current}
            onIonChange={(event) => (contentBuffer.current = event.detail.value!)}
          />
        </IonItem>
        <div className={classes.messageFooter}>
          <IonNote color="medium">Save changes?</IonNote>
          <IonButtons className={classes.messageActions}>
            <IonButton onClick={onConfirmEdit}>
              <IonIcon color="medium" icon={saveIcon} />
            </IonButton>
            <IonButton onClick={onCancelEdit}>
              <IonIcon color="medium" icon={cancelIcon} />
            </IonButton>
          </IonButtons>
        </div>
      </IonCol>
    </IonRow>
  );

  const RecievedMessage = () => (
    <IonRow className={classes.messageContainer}>
      <IonCol className={clsx(classes.message, 'ion-align-items-start')} sizeMd="11" sizeXs="9">
        <IonItem lines="none">
          <IonLabel className="ion-text-wrap">
            <h3>
              <strong>{author.name}</strong>
            </h3>
            <p className={classes.messageContent}>{content}</p>
          </IonLabel>
        </IonItem>
        <div className={classes.messageFooter}>
          <IonNote className={classes.messageTimestamp}>{prettyDate(createdAt)}</IonNote>
        </div>
      </IonCol>
    </IonRow>
  );

  return isCurrentUser ? editMode ? <DraftMessage /> : <SentMessage /> : <RecievedMessage />;
};

export default MessageBubble;
