import {
  IonBackdrop,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import centerOfMass from '@turf/center-of-mass';
import { Asset } from 'interfaces/Asset';
import { MapStyles } from 'interfaces/Basemaps';
import { closeOutline as closeIcon } from 'ionicons/icons';
import ImageGrid, {
  Image as ImageGridImage,
} from 'pages/WorkOrders/WorkDetails/components/DetailsPhoto/ImageGrid';
import React, { RefObject, useEffect, useMemo, useState } from 'react';

import AssetCarousel, { CarouselItem, MapItem } from './AssetCarousel';

type Item = Asset;

interface DetailsPhotoProps {
  item: Pick<Item, 'photos' | 'location' | 'assetType'>;
  mapStyles: MapStyles;
  /** Use ref to page element to get card style modals in iOS */
  pageRef?: RefObject<HTMLElement>;
}

const { REACT_APP_MAPBOX_TOKEN = '' } = process.env;

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

/**
 * Generate static map image for an asset location.
 * @param height Must be less than 1280. https://docs.mapbox.com/api/maps/static-images/#static-images-api-errors.
 * @param width Must be less than 1280. https://docs.mapbox.com/api/maps/static-images/#static-images-api-errors.
 */
const mapImage = (
  location: Item['location'],
  height: number,
  width: number,
  retina = false,
  dark = false,
  mapStyles: Pick<MapStyles, 'light' | 'dark'>
) => {
  // https://docs.mapbox.com/api/maps/static-images

  if (!location) return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D'; // empty image

  const locationParsed = JSON.parse(location.geometry);

  const center = centerOfMass(locationParsed);

  const geoJSON = JSON.stringify({
    type: 'Feature',
    geometry: locationParsed,
    properties: {
      'marker-size': 'small',
      'marker-color': dark ? '#9F7A41' : '#CAAB7D',
    },
  });

  const mapStyle = dark ? mapStyles.dark : mapStyles.light;
  const position = [center.geometry.coordinates[0], center.geometry.coordinates[1]].join(',');
  const size = `${width}x${height}${retina ? '@2x' : ''}`;

  const options = new URLSearchParams();
  options.set('access_token', REACT_APP_MAPBOX_TOKEN);
  options.set('attribution', 'false');
  options.set('logo', 'false');

  return `https://api.mapbox.com/styles/v1/${mapStyle}/static/geojson(${encodeURIComponent(
    geoJSON
  )})/${position},14/${size}?${options.toString()}`;
};

const DetailsPhoto: React.FC<DetailsPhotoProps> = ({
  item: { photos, location, assetType },
  mapStyles,
  pageRef,
}) => {
  const [isDarkTheme, setIsDarkTheme] = useState(prefersDark.matches);
  const [modalState, setModalState] = useState<{ isOpen: boolean; initialItem?: number }>({
    isOpen: false,
  });
  const [activeCarouselItem, setActiveCarouselItem] = useState<number | undefined>();

  useEffect(() => {
    const onMediaChange = (e: any) => setIsDarkTheme(e.matches);
    prefersDark.addListener(onMediaChange);
    return () => prefersDark.removeListener(onMediaChange);
  }, []);

  useEffect(() => {
    // Close modal when attachments change.
    // Adding a new photo to the carousel while open throws exception.
    // Issue could likely also be avoided by better handling updates in the carousel.
    setModalState({ isOpen: false });
  }, [photos]);

  /** Transform attachment data to formatted used by ImageGrid and Carousel */
  const [gridImages, carouselItems] = useMemo<[ImageGridImage[], CarouselItem[]]>((): [
    ImageGridImage[],
    CarouselItem[]
  ] => {
    const mapGridImage = (loc: Parameters<typeof mapImage>[0]): ImageGridImage => ({
      src: mapImage(loc, 480, 1280, false, isDarkTheme, mapStyles),
      srcSet: `${mapImage(loc, 480, 1280, true, isDarkTheme, mapStyles)} 2x`,
      alt: 'Location map',
      style: {
        height: 480,
        width: 1280,
        objectFit: 'none',
        backgroundColor: isDarkTheme ? '#343332' : '#D6DAE1',
      },
    });

    const mapCarouselItem = (opts: Pick<MapItem, 'feature' | 'name'>): CarouselItem => ({
      kind: 'MAP',
      key: 'map',
      name: ['Location', opts.name].filter(Boolean).join(' - '),
      feature: opts.feature,
      mapStyles,
    });

    const assetPhotos = photos || [];

    return assetPhotos.reduce<[ImageGridImage[], CarouselItem[]]>(
      (acc, photo) => [
        [...acc[0], { src: photo, alt: '' }],
        [...acc[1], { kind: 'IMAGE', key: photo, name: '', source: photo }],
      ],
      // Initialize with static map and interactive map data if a location is defined.
      // Otherwise, initialize with empty arrays to exclude maps
      location
        ? [
            [mapGridImage(location)],
            [
              mapCarouselItem({
                name: location.description,
                feature: {
                  type: 'Feature',
                  geometry: JSON.parse(location.geometry),
                  properties: { assetType },
                },
              }),
            ],
          ]
        : [[], []]
    );
  }, [location, photos, assetType, isDarkTheme, mapStyles]);

  return (
    <React.Fragment>
      <ImageGrid
        images={gridImages}
        limit={3}
        onImageSelect={(index) => {
          setModalState({ isOpen: true, initialItem: index });
        }}
      />
      <IonModal
        isOpen={modalState.isOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
        onDidDismiss={() => setModalState({ isOpen: false })}
      >
        <React.Fragment>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setModalState({ isOpen: false, initialItem: 0 })}>
                  <IonIcon slot="icon-only" icon={closeIcon} />
                </IonButton>
              </IonButtons>
              <IonTitle>
                {activeCarouselItem !== undefined ? carouselItems[activeCarouselItem].name : ''}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent scrollY={false}>
            <IonBackdrop tappable={false} style={{ opacity: 0.84, zIndex: 0 }} />
            <AssetCarousel
              items={carouselItems}
              options={{ initialItemIndex: modalState.initialItem }}
              onActiveItemChange={setActiveCarouselItem}
            />
          </IonContent>
        </React.Fragment>
      </IonModal>
    </React.Fragment>
  );
};

export default DetailsPhoto;
