import { IonAvatar } from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';

export type Image = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

interface ImageGridProps {
  images: Image[];
  limit?: number;
  onImageSelect: (index: number) => void;
}

const useStyles = createUseStyles({
  list: {
    display: 'flex',
    height: '30vh',
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  item: {
    flex: '1 1 auto',
    minWidth: '30%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  image: {
    minHeight: '100%',
    minWidth: '100%',
    objectFit: 'cover',
  },
  notShown: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    fontSize: '0.9375rem',
  },
});

const ImageGrid: React.FC<ImageGridProps> = ({ images, limit = 3, onImageSelect }) => {
  const classes = useStyles();

  const displayedImages = images.slice(0, limit);
  const notShown = images.length - limit;

  return (
    <div style={{ position: 'relative' }}>
      <ul className={classes.list}>
        {displayedImages.map(({ className, ...imgProps }, index) => (
          <li className={classes.item} key={index} onClick={() => onImageSelect(index)}>
            <img className={clsx(classes.image, className)} alt="" {...imgProps} />
          </li>
        ))}
      </ul>
      {notShown > 0 && (
        <IonAvatar className={clsx('ion-margin', classes.notShown)}>{`+${notShown}`}</IonAvatar>
      )}
    </div>
  );
};

export default ImageGrid;
