import { Plugins } from '@capacitor/core';
import { useGeocoder } from 'hooks/useGeocoder';
import { Location } from 'interfaces/Location';
import { useCallback, useEffect, useState } from 'react';
import { logEvent, logException } from 'services/logger';

interface UseLocationOptions {
  autoFetch?: boolean;
  value?: Location;
}

const defaultLocation = {
  address: '',
  latitude: 29.8174782,
  longitude: -95.6814762,
};

const defaultOptions: UseLocationOptions = {
  autoFetch: false,
};

export const parseAddress = (input?: string) => {
  if (!input || input.length === 0) return input;
  const regex = /([^,]+)/gi;
  const found = input.match(regex);
  return found && found[0] ? found[0] : input;
};

export const isValidAddress = (address: string) => {
  const regex = /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|Ave|Dr|Rd|Blvd|Ln|St)\.?/g;
  return address.length > 0 && regex.test(address);
};

export const useLocation = (options?: UseLocationOptions) => {
  const { autoFetch, value = defaultLocation } = { ...defaultOptions, ...options };
  const [location, setLocation] = useState<Location>(value);
  const [isFetchingLocation, setFetchingLocation] = useState(false);
  const { reverseGeocode } = useGeocoder();

  const updateLocation = useCallback(
    (input: Partial<Location>) => setLocation((loc) => ({ ...loc, ...input })),
    [setLocation]
  );

  const resetLocation = () => setLocation(value);

  const fetchCurrentLocation = async (): Promise<Location> => {
    try {
      setFetchingLocation(true);
      const { Geolocation } = Plugins;
      const position = await Geolocation.getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const result: Location = await reverseGeocode(longitude, latitude);
      setFetchingLocation(false);
      return result;
    } catch (err) {
      setFetchingLocation(false);

      if (err.code === 1) {
        logEvent(err.message);
        throw new Error('Location unavailable. Geolocation permissions denied by user.');
      }

      logException(err.message);
      throw new Error('Location unavailable');
    }
  };

  const autoFetchLocation = useCallback(async () => {
    if (autoFetch) {
      const current = await fetchCurrentLocation();
      setLocation(current);
    }
  }, [autoFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    autoFetchLocation();
  }, [autoFetchLocation]);

  return {
    updateLocation,
    resetLocation,
    fetchCurrentLocation,
    isFetchingLocation,
    location,
  };
};

export default useLocation;
