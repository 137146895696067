import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import CreateInvoice from './CreateInvoice';
import CreateTask from './CreateTask';
import TaskDetails from './TaskDetails';

const WorkLogRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      <Route path={`${path}/tasks/details/:id`} component={TaskDetails} exact={true} />
      <Route path={`${path}/tasks/create/:workorderId`} component={CreateTask} exact={true} />
      <Route path={`${path}/invoices/create/:workorderId`} component={CreateInvoice} exact={true} />
    </IonRouterOutlet>
  );
};

export default WorkLogRoutes;
