import clsx from 'clsx';
import { AssetType } from 'interfaces/Asset';
import React, { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

const icons: Record<AssetType, { name: string; flipVertical?: boolean }> = {
  Camera: { name: 'camera-cctv' },
  Gate: { name: 'road-barrier' },
  Fence: { name: 'fence' },
  'Entry Monument': { name: 'monument' },
  Wall: { name: 'block-brick' },
  'Irrigation Component': { name: 'hose' },
  'Decorative Light': { name: 'lightbulb' },
  Landscape: { name: 'tree-deciduous' },
  'Street Light': { name: 'lamp-street' },
  'Road Sign': { name: 'signs-post' },
  Pavement: { name: 'road' },
  Mailbox: { name: 'mailbox' },
  'Detention Facility': { name: 'water-arrow-up' },
  Lake: { name: 'water' },
  Fountain: { name: 'sprinkler-ceiling', flipVertical: true },
  Building: { name: 'warehouse' },
  'Park Equipment': { name: 'bench-tree' },
  'Public Space': { name: 'seedling' },
  Pool: { name: 'person-swimming' },
  'Sport Court': { name: 'racquet' },
  'Playground Equipment': { name: 'castle' },
  Path: { name: 'shoe-prints' },
  'Pet Station': { name: 'dog' },
  'Exercise Station': { name: 'dumbbell' },
  Bridge: { name: 'bridge' },
  'Water Utility': { name: 'faucet' },
  'Sanitary Utility': { name: 'toilet' },
  'Storm Utility': { name: 'grate' },
  'Lift Station': { name: 'pump' },
  Other: { name: 'location-dot' },
};

const useSizes = createUseStyles({
  small: { fontSize: '16px' },
  medium: { fontSize: '20px' },
  large: { fontSize: '24px' },
});

export interface AssetIconProps {
  assetType: AssetType;
  iconStyle?: 'regular' | 'light';
  size?: keyof ReturnType<typeof useSizes>;
  color?: CSSProperties['color'];
}

const AssetIcon: React.FC<AssetIconProps> = ({
  assetType,
  iconStyle = 'regular',
  size,
  color = 'rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)',
}) => {
  const sizeClasses = useSizes();

  const icon = icons[assetType] || icons.Other;
  const sizeClass = sizeClasses[size || 'medium'] || sizeClasses.medium;

  return (
    <i
      className={clsx(
        `fa-${iconStyle}`,
        `fa-${icon.name}`,
        icon.flipVertical && 'fa-flip-vertical',
        sizeClass
      )}
      style={{ color }}
    />
  );
};

export default AssetIcon;
