import { Attachment } from 'interfaces/Attachment';

export const resolvers = {
  // NOTE. Attachment is an attachment on a work order.
  // These were initially the only attachments.
  // A type like `WorkOrderAttachment` would be more ideal,
  // but the type was left as `Attachment` to not cause breaking API changes.
  Attachment: {
    attachmentType: (attachment: Attachment) =>
      /^image/i.test(attachment.mimetype) ? 'photo' : 'document',
  },
  MaintenanceAttachment: {
    attachmentType: (attachment: Attachment) =>
      /^image/i.test(attachment.mimetype) ? 'photo' : 'document',
  },
};
