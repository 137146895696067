import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
} from '@ionic/react';
import AddNewItemButton from 'components/AddNewItemButton';
import ReporterModal from 'components/ReporterModal';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { AccountAction } from 'interfaces/AccountAction';
import { Reporter } from 'interfaces/Reporter';
import { WorkOrder } from 'interfaces/WorkOrder';
import { createOutline as editIcon, personOutline as residentIcon } from 'ionicons/icons';
import React, { RefObject } from 'react';
import { prettyPhone } from 'utils/prettyPhone';

interface WorkOrderReporterProps {
  pageRef: RefObject<HTMLElement>;
  workOrder: Pick<WorkOrder, 'reporter'>;
  onSelectReporter: (value: Reporter) => Promise<any>;
}

const WorkOrderReporter: React.FC<WorkOrderReporterProps> = ({
  pageRef,
  workOrder: { reporter },
  onSelectReporter,
}) => {
  return (
    <ReporterModal
      pageRef={pageRef}
      value={reporter}
      onSelect={onSelectReporter}
      renderDisplayItem={({ onClick }) => (
        <>
          <IonList className="ion-no-padding">
            <IonItemDivider
              mode="md"
              sticky={true}
              style={{
                '--padding-end': 0,
                '--inner-padding-end': 'calc(var(--ion-margin, 16px) * 0.25)',
              }}
            >
              <IonIcon icon={residentIcon} slot="start" />
              <IonLabel>
                <h2>Resident</h2>
              </IonLabel>
              {reporter && (
                <AuthorizeRequired required={[AccountAction.AssignWorkOrderReporter]}>
                  <IonButtons slot="end">
                    <IonButton onClick={onClick}>
                      <IonIcon icon={editIcon} slot="icon-only" />
                    </IonButton>
                  </IonButtons>
                </AuthorizeRequired>
              )}
            </IonItemDivider>
            {reporter ? (
              <>
                <IonItem style={{ '--border-style': 'dashed' }}>
                  <IonLabel>
                    <h3>{reporter?.name}</h3>
                  </IonLabel>
                </IonItem>
                <IonItem lines="full">
                  <IonLabel color="dark">
                    <p className="ion-text-wrap">
                      {reporter?.email && (
                        <a href={`mailto:${reporter.email}`} style={{ textDecoration: 'none' }}>
                          {reporter.email}
                        </a>
                      )}
                      {reporter?.phone && (
                        <React.Fragment>
                          {' · '}
                          <a href={`tel:${reporter.phone}`} style={{ textDecoration: 'none' }}>
                            {prettyPhone(reporter.phone)}
                          </a>
                        </React.Fragment>
                      )}
                      {reporter?.secondaryPhone && (
                        <React.Fragment>
                          {' · '}
                          <a
                            href={`tel:${reporter.secondaryPhone}`}
                            style={{ textDecoration: 'none' }}
                          >
                            {prettyPhone(reporter.secondaryPhone)}
                          </a>
                        </React.Fragment>
                      )}
                    </p>
                  </IonLabel>
                </IonItem>
              </>
            ) : (
              <IonItem lines="full">
                <IonLabel>
                  <p>No resident information is available for this work order</p>
                </IonLabel>
              </IonItem>
            )}
          </IonList>
          {!reporter && (
            <AuthorizeRequired required={[AccountAction.AssignWorkOrderReporter]}>
              <AddNewItemButton title="Add resident information" onClick={onClick} />
            </AuthorizeRequired>
          )}
        </>
      )}
    />
  );
};

export default WorkOrderReporter;
