import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  useIonPopover,
} from '@ionic/react';
import { usePermissions } from 'containers/AuthorizeRequired';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { Account } from 'interfaces/Account';
import { AccountAction } from 'interfaces/AccountAction';
import { Attachment, AttachmentUrls } from 'interfaces/Attachment';
import { MaintenanceEntry } from 'interfaces/EquipmentMaintenance';
import {
  createOutline as editIcon,
  documentAttachOutline as filesIcon,
  ellipsisVertical as optionsIcon,
  imagesOutline as photosIcon,
  readerOutline as notesIcon,
  trashBinOutline as deleteIcon,
} from 'ionicons/icons';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { formatCurrency } from 'utils/formatCurrency';
import { removeNewLines } from 'utils/removeNewLines';

export interface TableItemProps
  extends Pick<MaintenanceEntry, 'id' | 'service' | 'date' | 'mileage' | 'cost' | 'notes'> {
  performedBy: Pick<Account, 'id' | 'name'>;
  attachments: (Pick<Attachment, 'id' | 'uploadId' | 'name' | 'mimetype' | 'attachmentType'> & {
    urls: Pick<AttachmentUrls, 'raw' | 'large2X'>;
  })[];
  lines?: 'full' | 'inset' | 'none';
  onViewPhotos?: () => void;
  onViewFiles?: () => void;
  onViewNotes?: () => void;
  onEditItem?: () => void;
  onDeleteItem?: (entryId: string) => void;
}

const useStyles = createUseStyles({
  root: {
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
  col: {
    '@media screen and (max-width: 576px)': {
      display: 'none',
    },
  },
  grid: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
  icon: {
    cursor: 'pointer',
  },
  popover: {
    '--backdrop-opacity': 0.1,
  },
});

const TableItem: React.FC<TableItemProps> = ({
  id: entryId,
  service,
  date,
  performedBy,
  mileage,
  cost,
  notes,
  attachments,
  lines,
  onViewPhotos,
  onViewFiles,
  onViewNotes,
  onEditItem,
  onDeleteItem,
}) => {
  const classes = useStyles();

  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);

  const photoCount = useMemo(() => {
    return attachments.filter((a) => a.attachmentType === 'photo').length;
  }, [attachments]);

  const [presentConfirmAction] = useConfirmAction({
    header: 'Delete Maintenance',
    message: 'Are you sure you want to permanently delete this maintenance log entry?',
    confirmText: 'Delete',
    destructive: true,
    handler: () => {
      onDeleteItem && onDeleteItem(entryId);
    },
  });

  const deleteAllowed = usePermissions({
    required: [AccountAction.DeleteEquipmentMaintenanceEntry],
  });

  const editAllowed = usePermissions({
    required: [AccountAction.CorrectEquipmentMaintenanceEntry],
  });

  const [presentPopover, dismissPopover] = useIonPopover(() => {
    return (
      <IonList className="ion-no-padding">
        {!!onViewPhotos && (
          <IonItem
            button={true}
            detail={false}
            disabled={!photoCount}
            lines="full"
            onClick={() => {
              onViewPhotos();
              dismissPopover();
            }}
          >
            <IonIcon icon={photosIcon} slot="start" />
            <IonLabel>View Photos</IonLabel>
          </IonItem>
        )}

        {!!onViewNotes && (
          <IonItem
            button={true}
            detail={false}
            disabled={!notes}
            lines="full"
            onClick={() => {
              onViewNotes();
              dismissPopover();
            }}
          >
            <IonIcon icon={notesIcon} slot="start" />
            <IonLabel>View Notes</IonLabel>
          </IonItem>
        )}

        {!!onViewFiles && (
          <IonItem
            button={true}
            detail={false}
            disabled={!attachments.length}
            lines="full"
            onClick={() => {
              onViewFiles();
              dismissPopover();
            }}
          >
            <IonIcon icon={filesIcon} slot="start" />
            <IonLabel>Attachments</IonLabel>
          </IonItem>
        )}

        {!!onEditItem && (
          <IonItem
            button={true}
            detail={false}
            disabled={!editAllowed}
            lines="none"
            onClick={() => {
              onEditItem();
              dismissPopover();
            }}
          >
            <IonIcon icon={editIcon} slot="start" />
            <IonLabel>Edit Item</IonLabel>
          </IonItem>
        )}
      </IonList>
    );
  });

  const presentItemOptions: React.MouseEventHandler<HTMLIonIconElement> = (event) => {
    presentPopover({
      event: event.nativeEvent,
      cssClass: classes.popover,
    });
  };

  const handleEditItem = () => {
    onEditItem && onEditItem();
    itemSlidingRef.current && itemSlidingRef.current.close();
  };

  const handleDeleteItem = () => {
    presentConfirmAction();
    itemSlidingRef.current && itemSlidingRef.current.close();
  };

  return (
    <IonItemSliding key={entryId} ref={itemSlidingRef} disabled={!deleteAllowed && !editAllowed}>
      <IonItemOptions side="start">
        {deleteAllowed && (
          <IonItemOption color="danger" onClick={handleDeleteItem}>
            <IonIcon icon={deleteIcon} slot="icon-only" />
          </IonItemOption>
        )}

        {editAllowed && (
          <IonItemOption color="secondary" onClick={handleEditItem}>
            <IonIcon icon={editIcon} slot="icon-only" />
          </IonItemOption>
        )}
      </IonItemOptions>

      <IonItem className={classes.root} lines={lines}>
        <IonGrid className={classes.grid}>
          <IonRow>
            <IonCol>
              <IonLabel>{`${moment(date).format('l')} ・ ${service}`}</IonLabel>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol sizeXs="5" sizeSm="4">
              <IonLabel>{performedBy?.name || 'No Technician'}</IonLabel>
            </IonCol>

            <IonCol className="ion-text-center" sizeXs="4" sizeSm="3">
              <IonLabel>{mileage ? `${mileage.toLocaleString()} mi` : ''}</IonLabel>
            </IonCol>

            <IonCol className="ion-text-center" sizeXs="3" sizeSm="2">
              <IonLabel>{cost ? formatCurrency(cost) : ''}</IonLabel>
            </IonCol>

            <IonCol className={classes.col} size="3" />
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel>
                <p>
                  <IonText color="medium">{notes ? removeNewLines(notes) : 'No notes'}</IonText>
                </p>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonIcon
          className={classes.icon}
          icon={optionsIcon}
          slot="end"
          onClick={presentItemOptions}
        />
      </IonItem>
    </IonItemSliding>
  );
};

export default TableItem;
