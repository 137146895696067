import { Message } from 'interfaces/Chat/Message';

export const resolvers = {
  Message: {
    isCurrentUser: ({ createdBy }: Partial<Message>) => {
      if (!createdBy?.id) {
        return false;
      }

      const storage: any = window.localStorage;
      const version = storage.getItem(`apollo-schema-version`);
      const workspace = JSON.parse(storage.getItem(`workspace-v${version}`));

      return createdBy?.id === workspace.operatorId;
    },
    isEdited: ({ createdAt, modifiedAt }: Partial<Message>) => {
      if (!(createdAt && modifiedAt)) {
        return false;
      }

      return modifiedAt > createdAt;
    },
  },
};
