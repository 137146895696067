import { useQuery } from '@apollo/client';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import HeatMap, { HeatMapProps } from 'components/HeatMap/HeatMap';
import InfiniteList from 'components/InfiniteList';
import InteractiveMap, { IInteractiveMap } from 'components/InteractiveMap';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import WorkOrderAssetItem from 'components/WorkOrderItem/WorkOrderAssetItem';
import WorkOrderListItem from 'components/WorkOrderItem/WorkOrderListItem';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import WorkOrderFilters, {
  FilterType,
  WorkOrderFilterProps,
  useWorkOrderFilters,
} from 'containers/WorkOrderFilters';
import { useHistoryPersist } from 'hooks/useHistoryPersist';
import { useMapStyles } from 'hooks/useMapStyles';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { MapContext } from 'interfaces/Basemaps';
import {
  alertCircleOutline as warningIcon,
  buildOutline as workOrdersIcon,
  downloadOutline as downloadIcon,
} from 'ionicons/icons';
import moment from 'moment';
import {
  GET_WORK_ORDER_FILTERS,
  GET_WORK_SUMMARY_REPORT,
  GetWorkOrderFiltersData,
  GetWorkOrderFiltersVariables,
  GetWorkSummaryReportData,
  GetWorkSummaryReportVariables,
} from 'pages/Reports/graphql';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PieChart } from 'recharts';
import { generateColor } from 'utils/generateColor';

import CategorySummary, { CategoryProps } from './components/CategorySummary';
import DateSearch from './components/DateSearch';
import PdfGenerator from './components/PdfGenerator';

export interface PathParams {
  createdFrom?: string;
  createdTo?: string;
  categories?: string;
  statuses?: string;
  areasOfInterest?: string;
  street?: string;
  teams?: string;
  assignees?: string;
  tags?: string;
}

const toPathParam = (ids: string[] | string) => {
  if (!Array.isArray(ids)) return ids;

  return ids.reduce<string | undefined>((acc, id) => (acc ? `${acc},${id}` : id), undefined);
};

const initCreatedFrom = (pathParams: PathParams): number => {
  if (pathParams.createdFrom) {
    return Number(pathParams.createdFrom);
  }
  return moment().subtract(1, 'month').startOf('day').valueOf();
};

const initCreatedTo = (pathParams: PathParams): number => {
  if (pathParams.createdTo) {
    return Number(pathParams.createdTo);
  }
  return moment().endOf('day').valueOf();
};

const initFilterGroup = (availableFilters: Map<string, boolean>, activeFilters?: string) => {
  if (!activeFilters) return availableFilters;

  const filterState = new Map(availableFilters);

  const activeFilterIds = activeFilters.split(',');

  activeFilterIds.forEach((id) => filterState.set(id, true));

  return filterState;
};

type MapView = 'markers' | 'heatmap';

const WorkSummaryReport: React.FC = () => {
  const pageRef = useRef<HTMLElement>(null);
  const pieChartRef = useRef<PieChart>(null);

  // Position tracked as user navigates marker map or heatmap
  const mapPosition = useRef<{ center: { lng: number; lat: number }; zoom: number } | undefined>();
  const setMapPosition = (position: typeof mapPosition['current']) =>
    (mapPosition.current = position);

  const [pathParams, setPathParams] = useHistoryPersist<PathParams>();

  const { workspace } = useWorkspace();
  const { addMessage } = useMessages();

  const mapStyles = useMapStyles({
    organizationId: workspace.organizationId,
    context: MapContext.WorkSummaryReport,
  });

  const [createdFrom, setCreatedFrom] = useState(initCreatedFrom(pathParams));
  const [createdTo, setCreatedTo] = useState(initCreatedTo(pathParams));

  const [showPdfOptions, setShowPdfOptions] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [mapView, setMapView] = useState<MapView>('markers');

  const { data: reportData, refetch: fetchReportData, loading: reportLoading } = useQuery<
    GetWorkSummaryReportData,
    GetWorkSummaryReportVariables
  >(GET_WORK_SUMMARY_REPORT, {
    displayName: 'WorkSummaryReport',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      organizationId: workspace.organizationId,
      createdFrom,
      createdTo,
      categories: pathParams.categories,
      statuses: pathParams.statuses,
      areasOfInterest: pathParams.areasOfInterest,
      street: pathParams.street,
      teams: pathParams.teams,
      assignees: pathParams.assignees,
      tags: pathParams.tags,
    },
    onError: () => addMessage('Unable to load report.', 'danger'),
  });

  const { data: filtersData } = useQuery<GetWorkOrderFiltersData, GetWorkOrderFiltersVariables>(
    GET_WORK_ORDER_FILTERS,
    {
      displayName: 'WorkSummaryReport',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        organizationId: workspace.organizationId,
        createdFrom,
        createdTo,
      },
      onError: () => addMessage('Unable to load report.', 'danger'),
    }
  );

  const { averageLabor = 0, averageDuration = 0, workOrderCount = 0 } =
    reportData?.organization.categorySummary.aggs || {};

  const workOrders = reportData?.organization.workorders.nodes || [];
  const heatMapBounds = reportData?.organization.workorders.aggs.heatmap?.viewport;
  const someHaveAssets = workOrders.some((workOrder) => workOrder.assets.edges.length > 0);

  const categorySummary = useMemo(() => {
    const nodes = reportData?.organization.categorySummary.nodes || [];
    const total = reportData?.organization.categorySummary.aggs || { workOrderCount: 0 };

    return nodes.reduce<(CategoryProps & { closedWorkOrderCount?: number })[]>(
      (acc, { category, ...agg }) => {
        return category
          ? [
              ...acc,
              {
                id: category.id,
                name: category.name,
                color:
                  category.id === 'NONE'
                    ? '#92949c'
                    : (generateColor(category.name || 'Unknown') as string), // will be defined if `category.name` is defined
                count: agg.workOrderCount,
                percentage: agg.workOrderCount / total.workOrderCount,
                averageDuration: agg.averageDuration || 0,
                averageLabor: agg.averageLabor || 0,
              },
            ]
          : acc;
      },
      []
    );
  }, [reportData]);

  const heatMapFeatures: HeatMapProps['features'] | undefined = useMemo(() => {
    const featureJSONString = reportData?.organization.workorders.aggs.heatmap?.features;
    return featureJSONString && JSON.parse(featureJSONString);
  }, [reportData]);

  const mapMarkers = useMemo(
    () =>
      workOrders.reduce<NonNullable<IInteractiveMap['markers']>>((acc, workorder) => {
        if (!workorder.location) return acc;

        return [
          ...acc,
          {
            id: workorder.id,
            location: [workorder.location.longitude, workorder.location.latitude],
            color: 'var(--ion-color-primary-tint)',
          },
        ];
      }, []),
    [workOrders]
  );

  // Map GraphQL connections to lists of nodes
  const { categories, statuses, areasOfInterest, teams, assignees, tags } = useMemo(() => {
    const aggs = filtersData?.organization.workorders.aggs || {};

    return (Object.keys(aggs) as (keyof typeof aggs)[]).reduce<WorkOrderFilterProps>(
      (acc, key) => ({ ...acc, [key]: aggs[key]!.nodes }),
      { categories: [], statuses: [], areasOfInterest: [], teams: [], assignees: [], tags: [] }
    );
  }, [filtersData]);

  // Initialize filters reducer state from server
  const {
    filters,
    clear,
    clearAll,
    hasChecked,
    isChecked,
    toggleChecked,
    search,
    update,
  } = useWorkOrderFilters({
    categories: categories.reduce<any[]>(
      (acc, { category }) => (category ? [...acc, category] : acc),
      []
    ),
    statuses: statuses.map((agg) => agg.status),
    areasOfInterest: areasOfInterest.reduce<any[]>(
      (acc, { areaOfInterest }) => (areaOfInterest ? [...acc, areaOfInterest] : acc),
      []
    ),
    teams: teams.reduce<any[]>((acc, { team }) => (team ? [...acc, team] : acc), []),
    assignees: assignees.reduce<any[]>(
      (acc, { assignee }) => (assignee ? [...acc, assignee] : acc),
      []
    ),
    street: pathParams.street,
    tags: tags?.map((agg) => agg.tag),
  });

  // Initialize filters reducer state from path params
  useEffect(() => {
    update(FilterType.Category, initFilterGroup(filters.categories, pathParams.categories));
    update(FilterType.Status, initFilterGroup(filters.statuses, pathParams.statuses));
    update(FilterType.Team, initFilterGroup(filters.teams, pathParams.teams));
    update(FilterType.Assignee, initFilterGroup(filters.assignees, pathParams.assignees));
    update(FilterType.Tag, initFilterGroup(filters.tags, pathParams.tags));
    update(
      FilterType.AreasOfInterest,
      initFilterGroup(filters.areasOfInterest, pathParams.areasOfInterest)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Map filters state to list of active filters
  const activeFilters = useMemo<Record<FilterType, string>>(() => {
    return (Object.keys(filters) as FilterType[]).reduce<any>((acc, filter) => {
      const criteria =
        filter === FilterType.Street
          ? filters[FilterType.Street]
          : [...filters[filter].keys()].reduce<string[]>((list, criterion) => {
              return isChecked(filter, criterion) ? [...list, criterion] : list;
            }, []);

      return {
        ...acc,
        [filter]: criteria,
      };
    }, {});
  }, [filters, isChecked]);

  // Update path params on filters state update
  useEffect(() => {
    setPathParams({
      createdFrom: createdFrom.toString(),
      createdTo: createdTo.toString(),
      street: activeFilters.street ? activeFilters.street : undefined,
      categories: toPathParam(activeFilters.categories),
      statuses: toPathParam(activeFilters.statuses),
      areasOfInterest: toPathParam(activeFilters.areasOfInterest),
      teams: toPathParam(activeFilters.teams),
      assignees: toPathParam(activeFilters.assignees),
      tags: toPathParam(activeFilters.tags),
    });
  }, [createdFrom, createdTo, activeFilters, setPathParams]);

  return (
    <React.Fragment>
      <IonPage id="work-summary-report" ref={pageRef}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackButton defaultHref="/reports" />
            </IonButtons>

            <IonTitle>Work Summary Report</IonTitle>

            <IonButtons slot="end">
              <IonButton onClick={() => setShowPdfOptions(true)}>
                {isDownloading ? (
                  <IonSpinner color="primary" name="crescent" />
                ) : (
                  <IonIcon icon={downloadIcon} slot="icon-only" />
                )}
              </IonButton>

              <WorkOrderFilters.Toggle hasChecked={hasChecked} />
            </IonButtons>
          </IonToolbar>

          <IonToolbar>
            <DateSearch
              startDate={createdFrom}
              endDate={createdTo}
              onSetStartDate={setCreatedFrom}
              onSetEndDate={setCreatedTo}
            />
          </IonToolbar>
        </IonHeader>

        <IonContent
          style={{
            '--padding-bottom': 'calc(var(--ion-margin, 16px) + var(--ion-safe-area-bottom, 0))',
          }}
        >
          <PullToRefresh onRefresh={fetchReportData} />

          <IonLoading isOpen={reportLoading} />

          {!(reportLoading && workOrderCount === 0) && (
            <React.Fragment>
              <ListSeparator />

              <IonItem lines="full">
                <IonLabel>
                  <h2 style={{ marginBottom: '0.5rem' }}>{workOrderCount} Work Orders</h2>
                  <p>
                    {averageLabor.toFixed(2)} Avg Labor (hours) · {averageDuration.toFixed(2)} Avg
                    Duration (days)
                  </p>
                </IonLabel>
              </IonItem>
            </React.Fragment>
          )}

          {!!categorySummary.length && (
            <React.Fragment>
              <ListSeparator />

              <CategorySummary categories={categorySummary} pieChartRef={pieChartRef} />
            </React.Fragment>
          )}

          {!!heatMapBounds && !!heatMapFeatures && !!workOrders?.length && (
            <React.Fragment>
              <ListSeparator />
              <IonItemDivider mode="md" sticky={true}>
                <IonIcon icon={workOrdersIcon} slot="start" />
                <IonLabel>
                  <h2>Work Orders</h2>
                </IonLabel>
                <IonSegment
                  style={{ maxWidth: '10rem' }}
                  slot="end"
                  mode="ios"
                  onIonChange={(e) => setMapView(e.detail.value as MapView)}
                  value={mapView}
                >
                  <IonSegmentButton value="markers">
                    <IonLabel>Markers</IonLabel>
                  </IonSegmentButton>

                  <IonSegmentButton value="heatmap">
                    <IonLabel>Heat Map</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonItemDivider>
              {workOrderCount > 999 && (
                <IonItem lines="full" color="secondary">
                  <IonIcon icon={warningIcon} slot="start" />
                  <IonLabel>
                    <h5 className="ion-text-wrap">
                      Showing most recent 999 of {workOrderCount} work orders.
                    </h5>
                    <p className="ion-text-wrap">
                      Reduce the reporting period or add advanced filters to avoid this limit and
                      view all work orders. Limit only applies to the work order map and list. All{' '}
                      {workOrderCount} work orders are represented in the category summary.
                    </p>
                  </IonLabel>
                </IonItem>
              )}
              <IonItem class="ion-no-padding" lines="full" style={{ '--inner-padding-end': 0 }}>
                {mapView === 'markers' && (
                  <InteractiveMap
                    mapStyles={mapStyles}
                    initialPosition={mapPosition.current || { bounds: heatMapBounds }}
                    maxZoom={14}
                    isMoveable={true}
                    markers={mapMarkers}
                    style={{ height: 400, width: '100%' }}
                    onMapMoved={setMapPosition}
                  />
                )}
                {mapView === 'heatmap' && (
                  <HeatMap
                    mapStyles={mapStyles}
                    features={heatMapFeatures}
                    initialPosition={mapPosition.current || { bounds: heatMapBounds }}
                    maxZoom={14}
                    style={{ height: 400, width: '100%' }}
                    onMapMoved={setMapPosition}
                  />
                )}
              </IonItem>
              <AuthorizeRequired required={[AccountAction.ListWorkOrders]}>
                {/* Extra space between map and list. Feels cluttered without it */}
                <div
                  style={{
                    height: 4,
                    background: 'var(--ion-item-background, var(--ion-background-color, #fff))',
                  }}
                />

                <InfiniteList
                  title="Work Orders"
                  pageRef={pageRef}
                  searchKeys={['workorderNumber', 'title']}
                  items={workOrders}
                  itemSize={
                    isPlatform('ios') ? (someHaveAssets ? 126 : 86) : someHaveAssets ? 130 : 90
                  }
                  renderListItem={(workorder, index, items) => (
                    <React.Fragment key={workorder.id}>
                      <WorkOrderListItem
                        workorder={workorder}
                        // if assets are enabled, let that component handle the bottom border
                        lines={
                          someHaveAssets
                            ? 'none'
                            : index === workOrders.length - 1
                            ? 'full'
                            : 'inset'
                        }
                        key={workorder.id}
                      />
                      {someHaveAssets && (
                        <WorkOrderAssetItem
                          assets={workorder.assets.edges.map(({ node }) => node)}
                          lines={index === workOrders.length - 1 ? 'full' : 'inset'}
                        />
                      )}
                    </React.Fragment>
                  )}
                />
              </AuthorizeRequired>
            </React.Fragment>
          )}

          <PdfGenerator
            isOpen={showPdfOptions}
            pageRef={pageRef}
            pieChartRef={pieChartRef}
            fromDate={moment(createdFrom).toDate()}
            toDate={moment(createdTo).toDate()}
            averageLabor={averageLabor}
            averageDuration={averageDuration}
            categories={categorySummary}
            workorders={workOrders}
            workOrderCount={workOrderCount}
            onDismiss={() => setShowPdfOptions(false)}
            onWillDownload={() => setIsDownloading(true)}
            onDidDownload={() => setIsDownloading(false)}
            onFailure={(error) => addMessage(error, 'danger')}
          />
        </IonContent>
      </IonPage>

      <WorkOrderFilters
        contentId="work-summary-report"
        categories={categories}
        statuses={statuses}
        areasOfInterest={areasOfInterest}
        street={activeFilters.street}
        teams={teams}
        assignees={assignees}
        tags={tags}
        filters={filters}
        clear={clear}
        clearAll={clearAll}
        isChecked={isChecked}
        toggleChecked={toggleChecked}
        search={search}
        update={update}
        filterContentProps={{ style: { '--padding-bottom': 'var(--ion-safe-area-bottom, 0)' } }}
      />
    </React.Fragment>
  );
};

export default WorkSummaryReport;
