import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import CreateEquipment from './CreateEquipment';
import EquipmentDetails from './EquipmentDetails';
import EquipmentList from './EquipmentList';

const EquipmentRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      <Route path={path} component={EquipmentList} exact={true} />
      <Route path={`${path}/create`} component={CreateEquipment} />
      <Route path={`${path}/details/:id`} component={EquipmentDetails} />
    </IonRouterOutlet>
  );
};

export { EquipmentRoutes, EquipmentList };
