import { Plugins } from '@capacitor/core';
import { IonButton, IonSlide, IonSlides } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

const { Browser } = Plugins;

const CONTACT_URL = 'https://www.getluke311.com/contact-us';

const slideOptions = {
  autoplay: { delay: 8000 },
};

const useStyles = createUseStyles({
  slideContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

const WelcomeSlides: React.FC = () => {
  const classes = useStyles();

  return (
    <IonSlides options={slideOptions} pager={false} style={{ height: '100%' }}>
      <IonSlide>
        <div className={classes.slideContent}>
          <h3>Getting started is simple.</h3>
          <p>
            Create your <strong>account</strong>.
          </p>
          <p>
            Look out for an <strong>invitation</strong> from your workspace administrator.
          </p>
          <p>
            <strong>Accept</strong> and you're in!
          </p>
        </div>
      </IonSlide>

      <IonSlide>
        <div className={classes.slideContent}>
          <h3>Don't have a workspace?</h3>
          <p>Contact us for more information about the onboarding process.</p>

          <IonButton
            color="primary"
            fill="outline"
            onClick={() => Browser.open({ url: CONTACT_URL })}
          >
            Contact Us
          </IonButton>
        </div>
      </IonSlide>
    </IonSlides>
  );
};

export default WelcomeSlides;
