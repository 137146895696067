import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import ReportsList from './ReportsList';
import ResidentReport from './ResidentReport';
import WorkSummaryReport from './WorkSummaryReport';

const ReportsRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      <Route path={`${path}`} component={ReportsList} exact={true} />
      <Route path={`${path}/create/worksummary`} component={WorkSummaryReport} exact={true} />
      <Route path={`${path}/create/resident`} component={ResidentReport} exact={true} />
    </IonRouterOutlet>
  );
};

export { ReportsRoutes };
