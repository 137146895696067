import { Feature, Point, featureCollection, point } from '@turf/helpers';
import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import { Content, ContentColumns, TDocumentDefinitions } from 'pdfmake/interfaces';
import { simplifyGeojson } from 'utils/simplifyGeojson';

import { CategoryProps, createContent as categorySummary } from '../CategorySummary/createContent';
import { WorkOrderProps, createContent as workordersList } from '../WorkOrders/createContent';
import { Section } from './PdfGenerator';
import { PdfOptions } from './reducer';

const { REACT_APP_MAPBOX_TOKEN } = process.env;

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

export interface CreateDocumentConfig {
  fromDate: Date;
  toDate: Date;
  categories: CategoryProps[];
  options: PdfOptions;
  pieChartSvg: string | null;
  workorders: WorkOrderProps[];
  workOrderCount: number;
  averageLabor: number;
  averageDuration: number;
}

interface StaticMapProps {
  height: number;
  width: number;
  workorders: WorkOrderProps[];
}

const buildMarkers = (
  workorders: Pick<WorkOrder, 'status' | 'location'>[],
  dimensions: [number, number]
) => {
  const features = workorders.reduce<Feature<Point, any>[]>(
    (acc, workorder) =>
      workorder.location
        ? [...acc, point([workorder.location.longitude, workorder.location.latitude])]
        : acc,
    []
  );

  const markers = simplifyGeojson({ collection: featureCollection(features), dimensions });

  return `geojson(${markers})`;
};

const fetchStaticMap = ({ height, width, workorders }: StaticMapProps) => {
  const MAPBOX_STYLES_API = 'https://api.mapbox.com/styles/v1';
  const style = 'ljagis/ckfywoifl008619p4l84jrj2h';

  // Image content (markers, labels) are relatively large
  // Apply a map scale greater than 1 to "zoom out" the content
  // Mapbox does not support heights or widths greater than 1280 px.
  // Make sure scaled map doesn't exceed that limit.
  const mapScale = 1.5;
  const mapHeight = height * mapScale;
  const mapWidth = width * mapScale;

  const markers = buildMarkers(workorders, [mapWidth, mapHeight]);

  return `${MAPBOX_STYLES_API}/${style}/static/${markers}/auto/${mapWidth}x${mapHeight}@2x?access_token=${REACT_APP_MAPBOX_TOKEN}&padding=50,50,50,50`;
};

const createHeader = (options: { fromDate: Date; toDate: Date }): ContentColumns => {
  const { fromDate, toDate } = options;
  return {
    columns: [
      { text: 'Work Summary Report', style: 'header' },
      {
        text: `${moment(fromDate).format('L')} - ${moment(toDate).format('L')}`,
        alignment: 'right',
        style: ['subheader', 'verticalSpacingSm'],
      },
    ],
  };
};

export const createDocument = ({
  fromDate,
  toDate,
  categories,
  options,
  pieChartSvg,
  workorders,
  workOrderCount,
  averageLabor,
  averageDuration,
}: CreateDocumentConfig): TDocumentDefinitions => {
  const isVisible = (section: Section) => {
    return options[section].visible;
  };

  // Top margin is header height + header vert margins
  const pageMargins: [number, number, number, number] = [40, 30 + 30 + 16, 40, 60];

  const images: Record<string, string> = {};

  const content: any[] = [
    { text: `${workOrderCount} Work Orders`, style: ['sectionHeader'] },
    {
      text: `${averageLabor.toFixed(2)} Avg Labor (hours) · ${averageDuration.toFixed(
        2
      )} Avg Duration (days)`,
      style: ['verticalSpacingSm'],
    },
    { text: '', style: ['verticalSpacingLg'] },
  ];

  if (pieChartSvg && isVisible(Section.CategorySummary)) {
    const section: Content = categorySummary({ categories, pieChartSvg });

    content.push(section);
  }

  if (workorders.length && isVisible(Section.WorkOrdersMap)) {
    const verticalMargins = pageMargins[1] + pageMargins[3];
    const horizontalMargins = pageMargins[0] + pageMargins[2];

    const mapHeight = 8.5 * 72 - verticalMargins;
    const mapWidth = 11 * 72 - horizontalMargins;

    images.staticMap = fetchStaticMap({
      height: mapHeight,
      width: mapWidth,
      workorders,
    });

    const section: Content = {
      pageBreak: isVisible(Section.CategorySummary) ? 'before' : undefined,
      image: 'staticMap',
      fit: [mapWidth, mapHeight],
    };

    content.push(section);
  }

  if (isVisible(Section.WorkOrdersList)) {
    const section: Content = {
      pageBreak:
        isVisible(Section.CategorySummary) || isVisible(Section.WorkOrdersMap)
          ? 'before'
          : undefined,
      stack: workordersList({ workorders }),
    };

    content.push(section);
  }

  return {
    pageSize: 'LETTER',
    pageOrientation: 'landscape',
    pageMargins,
    header: { margin: [40, 30, 40, 0], ...createHeader({ fromDate, toDate }) },
    footer: (currentPage: number) => ({
      margin: [40, 20, 40, 0],
      columns: [
        {
          alignment: 'left',
          style: 'timestampFooter',
          text: `Report created: ${moment().format('MM/DD/YYYY, h:mm A')}`,
        },
        {
          alignment: 'right',
          text: currentPage.toString(),
        },
      ],
    }),
    images,
    content,
    defaultStyle: {
      fontSize: 10,
      lineHeight: 1.2,
    },
    // Styles are almost identical to Work Order Details PDF. Maybe factor out to common style.
    styles: {
      // Page header (H1)
      header: {
        fontSize: 16,
        bold: true,
      },
      // Page subheader
      subheader: {
        fontSize: 11,
        color: '#555555',
      },
      // Header for each section (H2)
      sectionHeader: {
        fontSize: 12,
        color: '#333333',
        bold: true,
      },
      // Table header row cells (TH)
      tableHeader: {
        fontSize: 9,
        color: '#999999',
        alignment: 'center',
      },
      // Timestamp footer
      timestampFooter: {
        color: '#555555',
      },
      // Vertical spacing is always on top to separate from content above
      verticalSpacingSm: {
        margin: [0, 5, 0, 0],
      },
      verticalSpacingMd: {
        margin: [0, 10, 0, 0],
      },
      verticalSpacingLg: {
        margin: [0, 20, 0, 0],
      },
    },
  };
};
