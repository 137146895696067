import {
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonLabel,
  IonModal,
  IonRow,
} from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface DateInputProps {
  id: string;
  value: string;
  label: string;
  onChange: (value: string) => void;
}

export interface DateSearchProps {
  startDate: number;
  endDate: number;
  onSetStartDate: (start: number) => void;
  onSetEndDate: (end: number) => void;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    '@media (min-width: 500px)': {
      maxWidth: '340px',
    },

    // Padding consistent with ion-searchbar
    padding: '3px 0 11px 0',

    '& > ion-item': {
      border: '1px solid var(--ion-color-light)',
      borderRadius: '5px',
    },
  },
  label: {
    paddingLeft: 'var(--ion-margin, 16px)',
    fontSize: '0.75rem',
  },
  divider: {
    color: 'var(--ion-color-light-shade)',

    '@media screen and (max-width: 320px)': {
      display: 'none',
    },
  },
  icon: {
    margin: 'auto var(--ion-margin, 16px) auto 0',

    '@media screen and (max-width: 375px)': {
      display: 'none',
    },
  },
  modal: {
    '--width': 'fit-content !important',
    '--height': 'fit-content !important',
  },
});

const DateInput: React.FC<DateInputProps> = ({ id, value, label, onChange }) => {
  const classes = useStyles();

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonLabel className={classes.label}>{label}</IonLabel>
            <IonDatetimeButton datetime={id} />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonModal keepContentsMounted={true} className={classes.modal}>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol>
              <IonDatetime
                id={id}
                presentation="date"
                value={value}
                onIonChange={(e) => onChange(e.detail.value! as any)}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
    </>
  );
};

const DateSearch: React.FC<DateSearchProps> = ({
  startDate,
  endDate,
  onSetStartDate,
  onSetEndDate,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DateInput
        id="start"
        value={moment(startDate).toISOString()}
        label="Start Date"
        onChange={(value) => onSetStartDate(moment(value).startOf('day').valueOf())}
      />

      <IonLabel className={classes.divider}>—</IonLabel>

      <DateInput
        id="end"
        value={moment(endDate).toISOString()}
        label="End Date"
        onChange={(value) => onSetEndDate(moment(value).endOf('day').valueOf())}
      />
    </div>
  );
};

export default DateSearch;
