import { MutationUpdaterFn, useMutation } from '@apollo/client';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import { MagicField, MagicForm } from 'components/MagicForm';
import UnsafeArea from 'components/UnsafeArea';
import { useMessages } from 'hooks/useMessages';
import moment from 'moment';
import {
  CREATE_TASK,
  CreateTaskInput,
  CreateTaskResponse,
  GET_WORKORDER_WORKLOG,
} from 'pages/WorkLog/graphql';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';

export interface TaskFormData {
  name: string;
  taskDate: number;
}

const cacheUpdate: MutationUpdaterFn<CreateTaskResponse> = (cache, { data }) => {
  if (!data) return;

  const { createWorklogTask } = data;

  const workorderId = createWorklogTask.task.workorder.id;

  const queryData: any = cache.readQuery({
    query: GET_WORKORDER_WORKLOG,
    variables: { workorderId },
  });

  cache.writeQuery({
    query: GET_WORKORDER_WORKLOG,
    variables: { workorderId },
    data: {
      workorder: {
        ...queryData.workorder,
        worklog: [...queryData.workorder.worklog, createWorklogTask.task],
      },
    },
  });
};

const useStyles = createUseStyles({
  form: {
    height: '100%',
  },
});

const today = moment(Date.now()).format('YYYY-MM-DD');
const defaultValues = { taskDate: today };

const CreateTask: React.FC = () => {
  const { workorderId } = useParams<any>();
  const history = useHistory();
  const classes = useStyles();
  const { addMessage } = useMessages();

  const [loading, setLoading] = useState(false);

  const [create] = useMutation(CREATE_TASK, { update: cacheUpdate });

  const onSubmit = async ({ name, taskDate }: CreateTaskInput) => {
    const variables = {
      workorderId,
      name,
      taskDate: moment(taskDate, 'YYYY-MM-DD').valueOf(),
    };

    setLoading(true);

    try {
      const { data } = await create({ variables });
      setTimeout(async () => {
        // Provide time for projections to rebuild before navigating
        setLoading(false);
        history.replace(`/worklog/tasks/details/${data!.createWorklogTask.task.id}`);
      }, 3000);
    } catch ({ graphQLErrors }) {
      setLoading(false);
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to create task.', ...messages].join('. ')}.`, 'danger');
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref={`/worklog/${workorderId}`} />
          </IonButtons>
          <IonTitle>Create a Worklog Task</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <IonLoading isOpen={loading} />

        <MagicForm
          className={classes.form}
          subtitle={
            <>
              Start logging work by creating a worklog task.
              <br />
              Tasks describe labor, equipment, and material used to perform work on a work order.
              <br />
              Tasks can later be used to generate invoices.
            </>
          }
          submitText="Create"
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        >
          <MagicField
            name="name"
            type="text"
            fieldType="text"
            inputMode="text"
            label="Name"
            rules={{
              required: 'Name is required',
            }}
          />

          <MagicField
            name="taskDate"
            type="date"
            inputMode="text"
            label="Date"
            rules={{
              required: 'Date is required',
            }}
          />
        </MagicForm>
      </IonContent>
      <UnsafeArea position="bottom" color="primary" />
    </IonPage>
  );
};

export default CreateTask;
