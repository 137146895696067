import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';

export const commentSection = ({ comments }: Pick<WorkOrder, 'comments'>) => [
  {
    columns: [
      [
        { text: 'Comments', style: ['sectionHeader', 'verticalSpacingLg'] },
        ...comments.map((comment) => {
          return [
            {
              text: `${comment.operator.name} (${moment(comment.createdAt).format(
                'MMMM D, YYYY'
              )}):`,
              style: ['commentHeader', 'verticalSpacingMd'],
            },
            {
              text: comment.content,
              style: 'verticalSpacingSm',
            },
          ];
        }),
      ],
    ],
  },
];
