import gql from 'graphql-tag';

export interface ChannelQueryVariables {
  topicId: string;
}

export interface ChannelQueryResult {
  chatChannelByTopicId: { id: string };
}

export const GET_CHANNEL_BY_TOPIC_ID = gql`
  query GetChannelByTopicId($topicId: ID!) {
    chatChannelByTopicId(topicId: $topicId) {
      id
    }
  }
`;
