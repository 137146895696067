import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import UnsafeArea from 'components/UnsafeArea';
import { useMapStyles } from 'hooks/useMapStyles';
import { useWorkspace } from 'hooks/useWorkspace';
import { MapContext } from 'interfaces/Basemaps';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router';

import AssetDetailsHeader from './components/AssetDetailsHeader';
import AssetDetailsPhoto from './components/AssetDetailsPhoto';
import WorkHistoryTimeline from './components/Timeline/WorkHistoryTimeline';
import { useAssetInsights } from './hooks/useAssetInsights';

const useStyles = createUseStyles({
  header: {
    '--padding-top': 'calc(var(--ion-margin, 16px) * 0.5)',
    '--padding-bottom': 'calc(var(--ion-margin, 16px) * 0.5)',
    '--ion-grid-padding': 0,
  },
});

const Asset: React.FC = () => {
  const { id: assetId } = useParams<{ id: string }>();

  const classes = useStyles();

  const { asset, loading, refetch } = useAssetInsights(assetId);

  const { workspace } = useWorkspace();
  const { organizationId } = workspace;

  const mapStyles = useMapStyles({
    organizationId,
    context: MapContext.WorkOrderDetails,
  });

  const contentRef = useRef<HTMLIonContentElement>(null);
  const pageRef = useRef<HTMLElement>(null); // Bind to modals for card style display

  const scrollToTop = () => contentRef.current && contentRef.current.scrollToTop(500);

  return (
    <IonPage id="asset-insights-page" ref={pageRef}>
      <IonHeader className={classes.header}>
        <IonToolbar onClick={scrollToTop} style={{ cursor: 'pointer' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{asset?.name || 'Asset'}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef}>
        <ListSeparator />
        <PullToRefresh onRefresh={refetch} />
        {!asset && loading && (
          <IonLoading isOpen={true} message={'Loading asset...'} duration={0} />
        )}
        {asset && !loading && (
          <>
            <AssetDetailsPhoto
              pageRef={pageRef}
              item={{
                photos: [],
                location: asset.location,
                assetType: asset.assetType,
              }}
              mapStyles={mapStyles}
            />

            <ListSeparator height="0" />

            <IonItem lines="full">
              <div className="ion-padding-vertical">
                <AssetDetailsHeader asset={asset} />
              </div>
            </IonItem>

            <ListSeparator />

            <IonList className="ion-no-padding">
              <IonItemDivider mode="md" sticky={true}>
                <IonLabel>
                  <h2>Work Order Timeline</h2>
                </IonLabel>
              </IonItemDivider>
              <IonItem lines="full">
                <div className="ion-padding-vertical" style={{ flex: '1 1 0%' }}>
                  <WorkHistoryTimeline timeline={asset.timeline.workorders.nodes} />
                </div>
              </IonItem>
            </IonList>

            <ListSeparator />

            {Boolean(asset.id) && (
              <IonItem lines="full">
                <IonLabel color="light">
                  <p>GIS ID: {asset.id}</p>
                </IonLabel>
              </IonItem>
            )}
          </>
        )}
      </IonContent>

      <UnsafeArea position="bottom" />
    </IonPage>
  );
};

export default Asset;
