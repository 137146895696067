import { Plugins } from '@capacitor/core';
import { IonCol, IonGrid, IonItem, IonLabel, IonNote, IonRow } from '@ionic/react';
import { Invoice } from 'interfaces/Worklog';
import moment from 'moment';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { formatCurrency } from 'utils/formatCurrency';

const { Browser } = Plugins;

export interface InvoiceItemProps {
  invoice: Pick<Invoice, 'invoiceDate' | 'invoiceNumber' | 'description' | 'amountDue' | 'url'>;
  lines?: 'full' | 'inset' | 'none';
}

const useStyles = createUseStyles({
  root: {
    fontSize: '0.875rem',

    '& h3': {
      fontSize: '0.9375rem',
    },

    '& ion-label, & ion-note': {
      display: 'block',
      lineHeight: 1.6,
    },
  },
  grid: {
    paddingLeft: 0,
    paddingRight: 0,

    '& ion-col': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

const InvoiceItem: React.FC<InvoiceItemProps> = ({
  invoice: { invoiceDate, invoiceNumber, description, amountDue, url },
  lines,
}) => {
  const classes = useStyles();

  return (
    <IonItem
      className={classes.root}
      lines={lines}
      button={url ? true : false}
      onClick={() => {
        if (!url) return;
        Browser.open({ url });
      }}
      detail={false}
    >
      <IonGrid className={classes.grid}>
        <IonRow>
          <IonCol style={{ paddingRight: 'var(--ion-margih, 16px)' }}>
            <IonLabel>
              <h3>{description}</h3>
            </IonLabel>
          </IonCol>
          <IonCol size="auto">
            <IonNote color="dark">{formatCurrency(amountDue)}</IonNote>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonNote>{`${moment(invoiceDate).format(
              'MM/D/YYYY'
            )} · Invoice ${invoiceNumber}`}</IonNote>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default InvoiceItem;
