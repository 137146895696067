import { JSX } from '@ionic/core/components';
import { IonItem, IonLabel } from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';

export interface SelectableAssetItemProps extends React.HTMLAttributes<HTMLIonItemElement> {
  title: string;
  subtitle?: string;
  StartSlot?: React.ComponentType<{ slot?: 'start' }>;
  EndSlot?: React.ComponentType<{ slot?: 'end' }>;
  selected?: boolean;
}

const useStyles = createUseStyles({
  selected: {
    '--background': 'var(--ion-color-step-150, #f4f5f8)',
  },
});

const SelectableAssetItem: React.FC<SelectableAssetItemProps & JSX.IonItem> = ({
  title,
  subtitle,
  StartSlot,
  EndSlot,
  className,
  lines = 'full',
  selected = false,
  ...inputProps
}) => {
  const classes = useStyles();

  return (
    <IonItem
      {...inputProps}
      lines={lines}
      className={clsx(selected && classes.selected, className)}
      button={!(inputProps.disabled || false)}
    >
      {!!StartSlot && <StartSlot slot="start" />}
      <IonLabel>
        <h2>{title}</h2>
        {subtitle && (
          <h3 style={{ marginTop: `calc(var(--ion-margin, 16px) * 0.25)` }}>{subtitle}</h3>
        )}
      </IonLabel>
      {!!EndSlot && <EndSlot slot="end" />}
    </IonItem>
  );
};

export default SelectableAssetItem;
