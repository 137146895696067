import { IonSearchbar, IonToolbar } from '@ionic/react';
import SearchResults from 'components/SearchResults';
import { useGeocoder } from 'hooks/useGeocoder';
import { Location } from 'interfaces/Location';
import React, { useEffect, useRef, useState } from 'react';

import AddressItem from './AddressItem';

interface IAddressSearchBar {
  /** Bias results nearer to the proximity location in the format `[longitude, latitude]`  */
  proximity?: [number, number];
  onSelectResult: (r: Location) => void;
}

const AddressSearchBar: React.FC<IAddressSearchBar> = ({ proximity, onSelectResult }) => {
  const [query, setQuery] = useState('');
  const [display, setDisplay] = useState(false);
  const [results, setResults] = useState<Location[]>([]);
  const searchEl: any = useRef(null);
  const { forwardGeocode } = useGeocoder();

  const onChange = async (_: CustomEvent) => {
    if (query && query.length > 0) {
      const items = await forwardGeocode(query, { proximity });
      setResults(items);
    }
  };

  const onInput = (e: CustomEvent) => {
    const { value }: any = e.target;
    setQuery(value);
  };

  const onSelect = (result: Location) => {
    setQuery('');
    onSelectResult(result);
  };

  useEffect(() => {
    if (query && query.length > 0) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [query]);

  return (
    <IonToolbar>
      <IonSearchbar
        placeholder="Find an address"
        inputmode="search"
        mode="md"
        debounce={500}
        onIonInput={onInput}
        onIonChange={onChange}
        value={query}
        ref={searchEl}
      />
      <SearchResults
        show={display}
        results={results}
        inputRef={searchEl}
        onSelect={onSelect}
        renderSearchItem={({ value, onClick }) => (
          <AddressItem address={value.address} onClick={onClick} />
        )}
      />
    </IonToolbar>
  );
};

export default AddressSearchBar;
