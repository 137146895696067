import { IonItem, IonLabel, IonSkeletonText, IonText } from '@ionic/react';
import Avatar from 'components/Avatar';
import { parseAddress } from 'hooks/useLocation';
import { useStatus as fetchStatus } from 'hooks/useStatus';
import { Account } from 'interfaces/Account';
import { Category } from 'interfaces/Category';
import { Team } from 'interfaces/Team';
import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';
import React from 'react';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    '& h3': {
      fontSize: '0.9375rem',
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& > h3, & > p': {
      flex: '1 1 0%',
      lineHeight: 1.2, // Need explicitly to ensure consistent across browsers
      marginLeft: 'var(--ion-margin, 16px)',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        flex: '0 0 auto',
        textAlign: 'right',
      },
    },
  },
  skeleton: {
    '&::part(native)': {
      height: '100%',
    },
  },
});

export interface WorkOrderListItemProps {
  workorder: Pick<WorkOrder, 'id' | 'createdAt' | 'location' | 'status' | 'titleDisplay'> & {
    team?: Pick<Team, 'name'>;
    category?: Pick<Category, 'name'>;
    assignee?: Pick<Account, 'name'>;
  };
  lines?: 'full' | 'inset' | 'none';
  style?: React.CSSProperties;
}

const WorkOrderListItem: React.FC<WorkOrderListItemProps> = ({ workorder, lines, style }) => {
  const classes = useStyles();

  if (!workorder) {
    return (
      <IonItem className={classes.skeleton} lines="none" mode="md" style={style}>
        <Avatar
          bgColor="var(--ion-color-light)"
          className="ion-hide-sm-down"
          icon="undefined"
          slot="start"
        />
        <IonLabel>
          <IonSkeletonText animated={true} style={{ width: '55%' }} />
          <IonSkeletonText animated={true} style={{ width: '30%' }} />
          <IonSkeletonText animated={true} style={{ width: '15%' }} />
        </IonLabel>
      </IonItem>
    );
  }

  const { id, assignee, category, createdAt, location, status, team, titleDisplay } = workorder;

  const { color: statusColor, label: statusLabel, icon: statusIcon } = fetchStatus(status);

  return (
    <IonItem
      className={classes.root}
      lines={lines}
      routerLink={`/workorders/details/${id}`}
      style={style}
      detail={false}
    >
      <Avatar
        className="ion-hide-sm-down"
        icon={statusIcon}
        bgColor="var(--ion-color-light)"
        color={statusColor}
        slot="start"
      />
      <IonLabel>
        <div className={classes.flexRow}>
          <h3>{titleDisplay}</h3>
          <p>
            <IonText color="medium">{moment(createdAt).format('L')}</IonText>
          </p>
        </div>

        <div className={classes.flexRow}>
          <p style={{ marginTop: 4 }}>
            <IonText color="medium">{location && parseAddress(location.address)}</IonText>
          </p>
          <p style={{ marginTop: 4 }}>
            <IonText color="medium">{category?.name || 'No Category'}</IonText>
          </p>
        </div>

        <div className={classes.flexRow}>
          <p style={{ flex: '0 0 auto', marginTop: 4, color: statusColor }}>{statusLabel}</p>
          <p style={{ flex: '1 1 0%', marginTop: 4 }}>
            <IonText color="medium">
              {[team?.name, assignee?.name].filter((v) => v).join(' · ')}
            </IonText>
          </p>
        </div>
      </IonLabel>
    </IonItem>
  );
};

export default WorkOrderListItem;
