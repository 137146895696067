import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import { closeOutline as closeIcon } from 'ionicons/icons';
import sort from 'lodash.sortby';
import React, { PropsWithChildren, RefObject, useState } from 'react';

export interface RenderProps<T> {
  onClick?: any;
  value: T;
}

export interface ISearchModal<T> {
  items: T[];
  itemsSecondary?: T[];
  keys?: string[];
  sortBy?: string;
  value?: T;
  label?: string;
  /** Use ref to page element to get card style modals in iOS */
  pageRef?: RefObject<HTMLElement>;
  onSelect: (props: T) => void;
  renderSearchItem?: (props: RenderProps<T>) => JSX.Element;
  renderDisplayItem?: (props: RenderProps<T>) => JSX.Element;
  renderSubtitle?: () => JSX.Element;
  renderSubtitleSecondary?: () => JSX.Element;
}

const AssigneesSearchModal = <T,>({
  items,
  itemsSecondary,
  keys = ['name'],
  sortBy = 'name',
  value,
  label = 'Select Item',
  pageRef,
  onSelect,
  renderSearchItem,
  renderDisplayItem,
  renderSubtitle,
  renderSubtitleSecondary: renderSubtitle2,
}: PropsWithChildren<ISearchModal<T>>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState<T>(value as T);

  const { results, setQuery } = useFuzzySearch({
    list: items,
    options: { keys, threshold: 0.2 },
  });

  const { results: resultsSecondary, setQuery: setQuery2 } = useFuzzySearch({
    list: itemsSecondary || [],
    options: { keys, threshold: 0.2 },
  });

  const handleSelect = (props: T) => {
    setSelected(props);
    onSelect(props);
    setIsModalOpen(false);
  };

  return (
    <>
      {renderDisplayItem &&
        renderDisplayItem({
          onClick: () => setIsModalOpen(true),
          value: selected,
        })}

      <IonModal
        onDidDismiss={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>{label}</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar
              mode="md"
              debounce={500}
              onIonChange={({ target }: any) => {
                setQuery(target.value);
                setQuery2(target.value);
              }}
            />
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {renderSubtitle && (
            <React.Fragment>
              {renderSubtitle()}
              <ListSeparator height="0" />
            </React.Fragment>
          )}
          <IonList lines="full" className="ion-no-padding">
            {results.length > 0 ? (
              sort(results, sortBy).map((result, i) => (
                <React.Fragment key={i}>
                  {renderSearchItem &&
                    renderSearchItem({
                      onClick: () => handleSelect(result),
                      value: result,
                    })}
                </React.Fragment>
              ))
            ) : (
              <IonItem>
                <IonLabel>No Results</IonLabel>
              </IonItem>
            )}
          </IonList>
          {renderSubtitle2 && (
            <React.Fragment>
              {renderSubtitle2()}
              <ListSeparator height="0" />
            </React.Fragment>
          )}
          <IonList lines="full" className="ion-no-padding">
            {resultsSecondary.length > 0 ? (
              sort(resultsSecondary, sortBy).map((result2, i) => (
                <React.Fragment key={i}>
                  {renderSearchItem &&
                    renderSearchItem({
                      onClick: () => handleSelect(result2),
                      value: result2,
                    })}
                </React.Fragment>
              ))
            ) : (
              <IonItem>
                <IonLabel>No Results</IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default AssigneesSearchModal;
