import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import { WorkOrder } from 'interfaces/WorkOrder';
import { closeOutline as closeIcon, cogOutline as optionsIcon } from 'ionicons/icons';
import React, { RefObject, useState } from 'react';
import PdfService from 'services/PdfService';

import { createDocument } from './createPdf';

export enum Section {
  WorkOrderPhoto = 'WORKORDER_PHOTO',
  WorkOrderMap = 'WORKORDER_MAP',
  WorkOrderComment = 'WORKORDER_COMMENT',
  WorkOrderNote = 'WORKORDER_NOTE',
  WorkOrderWorkLog = 'WORKORDER_WORK_LOG',
}

export type PdfOptions = Record<Section, boolean>;

export interface PdfGeneratorProps {
  isOpen: boolean;
  pageRef?: RefObject<HTMLElement>;
  workorder: WorkOrder | undefined;
  onDismiss: () => void;
  onDownload: (downloading: boolean) => void;
  onFailure: (error: string) => void;
}

const PdfGenerator: React.FC<PdfGeneratorProps> = ({
  isOpen,
  pageRef,
  workorder,
  onDismiss,
  onDownload,
  onFailure,
}) => {
  const [options, setOptions] = useState<PdfOptions>({
    [Section.WorkOrderPhoto]: true,
    [Section.WorkOrderMap]: true,
    [Section.WorkOrderComment]: true,
    [Section.WorkOrderNote]: true,
    [Section.WorkOrderWorkLog]: true,
  });

  const onChangeOptions = (section: Section) => {
    setOptions({
      ...options,
      [section]: !options[section],
    });
  };

  const generatePdf = () => {
    onDismiss();

    if (!workorder) {
      return;
    }

    onDownload(true);

    const docDefinition = createDocument(workorder, options);

    PdfService.open({
      docDefinition,
      fileName: `Work Order${workorder.workorderNumber ? ` ${workorder.workorderNumber}` : ''}`,
      onComplete: (success, error) => {
        onDownload(false);
        !success && onFailure(error as string);
      },
    });
  };

  return (
    <IonModal
      isOpen={isOpen}
      presentingElement={pageRef?.current || undefined}
      swipeToClose={true}
      onDidDismiss={onDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" onClick={onDismiss}>
            <IonButton>
              <IonIcon icon={closeIcon} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Work Order Download</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <ListSeparator />

        <IonList className="ion-no-padding">
          <IonItemDivider mode="md" sticky={true}>
            <IonIcon icon={optionsIcon} slot="start" />
            <IonLabel>
              <h2>PDF Options</h2>
            </IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>Include Comments</IonLabel>
            <IonToggle
              checked={options[Section.WorkOrderComment]}
              onIonChange={() => onChangeOptions(Section.WorkOrderComment)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Include Photos</IonLabel>
            <IonToggle
              checked={options[Section.WorkOrderPhoto]}
              onIonChange={() => onChangeOptions(Section.WorkOrderPhoto)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Include Map</IonLabel>
            <IonToggle
              checked={options[Section.WorkOrderMap]}
              onIonChange={() => onChangeOptions(Section.WorkOrderMap)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Include Work Log</IonLabel>
            <IonToggle
              checked={options[Section.WorkOrderWorkLog]}
              onIonChange={() => onChangeOptions(Section.WorkOrderWorkLog)}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>Include Notes</IonLabel>
            <IonToggle
              checked={options[Section.WorkOrderNote]}
              onIonChange={() => onChangeOptions(Section.WorkOrderNote)}
            />
          </IonItem>
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar color="primary" className="ion-no-padding">
          <IonButton
            className="ion-no-margin"
            expand="full"
            color="primary"
            size="large"
            onClick={generatePdf}
          >
            Download
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default PdfGenerator;
