import { Category } from 'interfaces/Category';
import { Content } from 'pdfmake/interfaces';

export interface CategoryProps extends Pick<Category, 'id' | 'name'> {
  averageDuration: number;
  averageLabor: number;
  color: string;
  count: number;
  percentage: number;
}

export interface CreateContentConfig {
  categories: CategoryProps[];
  pieChartSvg: string;
}

export const createContent = ({ categories, pieChartSvg }: CreateContentConfig): Content[] => {
  return [
    { text: 'Category Summary', style: 'sectionHeader' },
    {
      style: 'verticalSpacingMd',
      table: {
        widths: '*',
        body: [
          [
            {
              alignment: 'center',
              svg: pieChartSvg,
              height: 240,
              style: 'verticalSpacingMd',
            },
          ],
        ],
      },
      layout: {
        hLineWidth: (i, node) => (i === node.table.body.length ? 0 : 0.5),
        vLineWidth: () => 0.5,
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Category', colSpan: 2, style: 'tableHeader' },
            '',
            { text: 'Count', style: 'tableHeader' },
            { text: 'Percentage', style: 'tableHeader' },
            { text: 'Avg Labor (hours)', style: 'tableHeader' },
            { text: 'Avg Duration (days)', style: 'tableHeader' },
          ],
          ...categories.map((category): Content[] => [
            {
              canvas: [{ type: 'ellipse', color: category.color, x: 5, y: 6, r1: 5, r2: 5 }],
            },
            { text: category.name },
            { text: category.count.toString(), alignment: 'right' },
            { text: `${(category.percentage * 100).toFixed(0)}%`, alignment: 'right' },
            { text: category.averageLabor?.toFixed(2) || '', alignment: 'right' },
            { text: category.averageDuration?.toFixed(2) || '', alignment: 'right' },
          ]),
        ],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: (i, node) =>
          i === 0 || i === (node.table.headerRows || 1) || i === node.table.body.length
            ? '#000000'
            : '#CCCCCC',
        vLineWidth: (i, node) => {
          if (i === 1) return 0; // ellipse cell
          if (i === 0 || i === node.table.body[0].length) return 0.5; // first and last line
          return 0;
        },
        // Text is slightly high in cell if top and bottom match. Maybe from lineHeight.
        // Small offset to compensate and vertically center.
        paddingTop: () => 9,
        paddingBottom: () => 7,
        paddingLeft: () => 10,
        paddingRight: (i) => (i === 0 ? 0 : 10),
      },
    },
  ];
};
