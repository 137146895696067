import { ApolloError, gql, useQuery } from '@apollo/client';
import { Organization as FullOrganization } from 'interfaces/Organization';
import { useState } from 'react';

type Organization = Pick<FullOrganization, 'id' | 'name' | 'assetsUrls'> & {
  geography: Pick<FullOrganization['geography'], 'center' | 'zoom'>;
  teams: Pick<FullOrganization['teams'][0], 'id' | 'name'>[];
  accounts: Pick<FullOrganization['accounts'][0], 'id' | 'name' | 'email'>[];
  categories: Pick<FullOrganization['categories'][0], 'id' | 'name' | 'isEnabled' | 'type'>[];
};

export const GET_ORGANIZATION = gql`
  query Organization($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      name
      geography {
        center
        zoom
      }
      teams {
        id
        name
      }
      accounts {
        id
        name
        email
      }
      categories {
        id
        name
        isEnabled
        type
      }
      assetsUrls
    }
  }
`;

/** Abstraction over `ApolloError` to not expose Apollo outside our hooks */
export type DataError = ApolloError;

export interface UseOrganizationResult {
  organization: Organization | null;
  loading: boolean;
  error?: DataError;
}

export const useOrganization = (organizationId: string): UseOrganizationResult => {
  const [organization, setOrganization] = useState<Organization | null>(null);

  const { loading, error } = useQuery<{ organization: Organization }>(GET_ORGANIZATION, {
    displayName: 'useOrganization',
    partialRefetch: true,
    variables: { organizationId },
    onError: () => setOrganization(null),
    onCompleted: ({ organization: org }) => {
      setOrganization(org);
    },
  });

  return { organization, loading, error };
};
