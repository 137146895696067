import './WorkHistoryTimeline.css';

import { IonGrid } from '@ionic/react';
import { WorkOrder } from 'interfaces/WorkOrder';
import React, { useEffect, useState } from 'react';

import WorkHistoryCard, { WorkHistoryCardProps } from './WorkHistoryCard';
import YearNoYearSeparator from './YearNoYearSeparator';

export interface WorkHistoryTimelineProps {
  timeline: (Pick<
    WorkOrder,
    'id' | 'createdAt' | 'closedAt' | 'workorderNumber' | 'title' | 'status' | 'category'
  > & {
    team: Pick<NonNullable<WorkOrder['team']>, 'id' | 'name'>;
    assignee: Pick<NonNullable<WorkOrder['assignee']>, 'id' | 'name'>;
    category: Pick<NonNullable<WorkOrder['category']>, 'id' | 'name'>;
  })[];
}

const WorkHistoryTimeline: React.FC<WorkHistoryTimelineProps> = ({ timeline }) => {
  // State to determine if the WorkHistoryCards should be condensed.
  // Condense if the screen width is less than 576px.
  const [shouldCondense, setShouldCondense] = useState(
    window.matchMedia('screen and (max-width: 576px)').matches
  );

  const timelineSorted = [...timeline].sort(
    (a, b) => (b.closedAt || b.createdAt) - (a.closedAt || a.createdAt)
  );

  useEffect(() => {
    window
      .matchMedia('screen and (max-width: 576px)')
      .addEventListener('change', (e) => setShouldCondense(e.matches));
  }, []);

  return (
    <IonGrid style={{ padding: 0 }}>
      {timelineSorted.map((item, index) => {
        let position: WorkHistoryCardProps['position'];

        if (index === 0 && timelineSorted.length === 1) position = 'single';
        else if (index === 0) position = 'start';
        else if (index === timelineSorted.length - 1) position = 'end';
        else position = 'inner';

        return (
          <React.Fragment key={index}>
            <WorkHistoryCard
              timelineItem={item}
              index={index}
              position={position}
              condensed={shouldCondense}
            />
            <YearNoYearSeparator timelineItems={timelineSorted} index={index} />
          </React.Fragment>
        );
      })}
    </IonGrid>
  );
};

export default WorkHistoryTimeline;
