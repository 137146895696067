import { InputChangeEventDetail } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
} from '@ionic/react';
import { ellipsisVertical as menuIcon } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { SelectedEquipment } from './EquipmentModal';

const useStyles = createUseStyles({
  root: {
    '--inner-padding-start': '5px',
    '--inner-padding-end': '0px',
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
});

type EquipmentFormProps = {
  selected: SelectedEquipment;
  handleUpdate: (props: SelectedEquipment) => void;
  handleUpdateRateAll: (props: SelectedEquipment) => void;
  handleUpdateRateEmpty: (props: SelectedEquipment) => void;
  handleUpdateHoursAll: (props: SelectedEquipment) => void;
  handleUpdateHoursEmpty: (props: SelectedEquipment) => void;
};

const EquipmentForm: React.FC<EquipmentFormProps> = ({
  selected,
  handleUpdate,
  handleUpdateRateAll,
  handleUpdateRateEmpty,
  handleUpdateHoursAll,
  handleUpdateHoursEmpty,
}) => {
  const classes = useStyles();
  const [popoverState, setPopoverState] = React.useState<{
    showPopup: boolean;
    event: CustomEvent<InputChangeEventDetail> | undefined;
  }>({
    showPopup: false,
    event: undefined,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <IonItem lines="full" style={{ paddingLeft: 0, paddingRight: 0 }} className={classes.root}>
      <IonGrid style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
        <IonRow className="ion-align-items-center">
          <IonCol size="4">
            <IonLabel>{selected.equipment.name}</IonLabel>
          </IonCol>
          <IonCol className="ion-text-center" size="2">
            <IonInput
              value={selected.form.rate}
              type="number"
              step="1"
              inputMode="decimal"
              placeholder="Rate"
              onIonChange={(e) =>
                handleUpdate({
                  ...selected,
                  form: {
                    ...selected.form,
                    rate: e.detail.value ? e.detail.value : undefined,
                  },
                })
              }
            />
          </IonCol>
          <IonCol className="ion-text-center" size="3">
            <IonInput
              value={selected.form.hours}
              type="number"
              step="1"
              inputMode="decimal"
              placeholder="Hours"
              onIonChange={(e) =>
                handleUpdate({
                  ...selected,
                  form: {
                    ...selected.form,
                    hours: e.detail.value ? e.detail.value : undefined,
                  },
                })
              }
            />
          </IonCol>
          <IonCol className="ion-text-center" size="3">
            <IonInput
              value={selected.form.mileage}
              type="number"
              step="1000"
              inputMode="decimal"
              placeholder="Mileage"
              onIonChange={(e) =>
                handleUpdate({
                  ...selected,
                  form: {
                    ...selected.form,
                    mileage: e.detail.value ? e.detail.value : undefined,
                  },
                })
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButtons slot="end">
        <IonButton
          fill="clear"
          tabIndex={-1}
          onClick={(e: any) => setPopoverState({ showPopup: true, event: e })}
        >
          <IonIcon slot="icon-only" color="primary" icon={menuIcon} size="small" />
          <IonPopover
            mode="md"
            showBackdrop={false}
            keyboardClose={false}
            event={popoverState.event}
            isOpen={popoverState.showPopup}
            onDidDismiss={() => setPopoverState({ showPopup: false, event: undefined })}
          >
            <IonContent>
              <IonList style={{ padding: 0 }}>
                <IonItem
                  button={true}
                  disabled={!selected.form.rate}
                  onClick={() => handleUpdateRateEmpty(selected)}
                >
                  Apply rate to all empty
                </IonItem>
                <IonItem
                  button={true}
                  disabled={!selected.form.rate}
                  onClick={() => handleUpdateRateAll(selected)}
                >
                  Apply rate to all
                </IonItem>
                <IonItem
                  button={true}
                  disabled={!selected.form.hours}
                  onClick={() => handleUpdateHoursEmpty(selected)}
                >
                  Apply hours to all empty
                </IonItem>
                <IonItem
                  button={true}
                  disabled={!selected.form.hours}
                  onClick={() => handleUpdateHoursAll(selected)}
                >
                  Apply hours to all
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </IonButton>
      </IonButtons>
    </IonItem>
  );
};

export default EquipmentForm;
