import { Plugins } from '@capacitor/core';
import { IonButton, IonButtons, IonIcon, IonToolbar } from '@ionic/react';
import {
  logoFacebook as facebookIcon,
  logoTwitter as twitterIcon,
  openOutline as openIcon,
} from 'ionicons/icons';
import React from 'react';

const { Browser } = Plugins;

const GET_LUKE_311_PAGE = 'https://www.getluke311.com';
const FACEBOOK_PAGE = 'https://www.facebook.com/LUKE-Applications-101686195065021';
const TWITTER_PAGE = 'https://twitter.com/getluke311';

const SocialMediaLinks: React.FC = () => {
  return (
    <IonToolbar>
      {/* Get LUKE 311 */}
      <IonButtons slot="start">
        <IonButton
          className="ion-text-lowercase"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            Browser.open({ url: GET_LUKE_311_PAGE });
          }}
        >
          <a href="/" style={{ fontSize: '0.875rem' }}>
            getluke311.com
          </a>
          <IonIcon icon={openIcon} size="small" slot="end" />
        </IonButton>
      </IonButtons>

      <IonButtons slot="end">
        {/* Facebook */}
        <IonButton color="medium" fill="clear" onClick={() => Browser.open({ url: FACEBOOK_PAGE })}>
          <IonIcon icon={facebookIcon} size="large" />
        </IonButton>

        {/* Twitter */}
        <IonButton color="medium" fill="clear" onClick={() => Browser.open({ url: TWITTER_PAGE })}>
          <IonIcon icon={twitterIcon} size="large" />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default SocialMediaLinks;
