import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import React from 'react';

const WorkOrderDetailsAssetCardGroupSkeleton: React.FC = () => (
  <IonItem className="ion-no-padding" style={{ '--inner-padding-end': '0px' }}>
    <IonGrid>
      <IonRow>
        <IonCol sizeXl="4" sizeMd="6" sizeXs="12" className="ion-no-padding">
          <IonCard style={{ background: 'var(--ion-color-light-tint)', boxShadow: 'none' }}>
            <IonCardContent className="ion-no-padding">
              <IonGrid className="ion-no-padding">
                <IonRow style={{ height: '120px' }}>
                  <IonCol size="5" style={{ height: '100%' }}>
                    <IonSkeletonText
                      animated={true}
                      style={{ height: '100%', width: '100%', margin: 0 }}
                    />
                  </IonCol>
                  <IonCol
                    size="7"
                    className="ion-padding ion-align-items-center"
                    style={{ display: 'flex' }}
                  >
                    <div style={{ width: '100%' }}>
                      <IonRow>
                        <IonCol>
                          <IonSkeletonText animated={true} style={{ width: '80%' }} />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonSkeletonText animated={true} style={{ width: '30%' }} />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonSkeletonText animated={true} style={{ width: '90%' }} />
                        </IonCol>
                      </IonRow>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonItem>
);

export default WorkOrderDetailsAssetCardGroupSkeleton;
