import 'mapbox-gl/dist/mapbox-gl.css';

import clsx from 'clsx';
import { MapStyles } from 'interfaces/Basemaps';
import mapboxgl, {
  CameraOptions,
  EventData,
  Layer,
  LngLat,
  LngLatLike,
  MapMouseEvent,
  MapTouchEvent,
  MapboxGeoJSONFeature,
  MapboxOptions,
  Marker,
  Popup,
} from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { withResizeDetector } from 'react-resize-detector';

import { MapMarker } from './MapMarker';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const { REACT_APP_MAPBOX_TOKEN: accessToken } = process.env;

const useStyles = createUseStyles({
  mapboxOverrides: {
    '& .mapboxgl-canvas:focus': {
      outline: 0,
    },
    '& .mapboxgl-popup-content': {
      textAlign: 'center',
    },
    '@media (prefers-color-scheme: dark)': {
      '& .mapboxgl-popup .mapboxgl-popup-content': {
        textAlign: 'center',
        background: 'var(--ion-background-color)',
        color: 'var(--ion-color-text)',
      },
      '& .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip': {
        borderTopColor: 'var(--ion-background-color)',
      },
      '& .mapboxgl-popup-anchor-top .mapboxgl-popup-tip': {
        borderBottomColor: 'var(--ion-background-color)',
      },
      '& .mapboxgl-popup-anchor-left .mapboxgl-popup-tip': {
        borderRightColor: 'var(--ion-background-color)',
      },
      '& .mapboxgl-popup-anchor-right .mapboxgl-popup-tip': {
        borderLeftColor: 'var(--ion-background-color)',
      },
    },
  },
});

// Include all valid `div` element props to apply to root
type RootElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface ReactResizeDetectorDimensions {
  /**
   * Map height as determined by the resize detector.
   * Do not use to set component height. Use `style` or `className` instead.
   */
  height: number;
  /**
   * Map width as determined by the resize detector.
   * Do not use to set component width. Use `style` or `className` instead.
   */
  width: number;
}

/** Map capable of displaying and selecting markers */
interface MarkersMap {
  markers?: MapMarker[];
  onSelectedMarker?: (marker: MapMarker | null) => void;
}

/** Map with a single focused location displayed as a marker and popup */
interface FocusMap {
  /** Map camera, marker location, and popup text content describing a focused location */
  focusLocation?: CameraOptions & { title?: string; center: LngLatLike; color?: string };
  /** When `true`, focus location will move to remained centered on the map */
  moveFocusLocationWithMap?: boolean;
}

interface GeoJSONMap {
  geoJSON?: {
    features: GeoJSON.Feature[];
    layers: Layer[];
    /**
     * Optional URLs of images to make available within layer styles.
     * `id` is used as style reference (ex. `icon-image`) and must be unique across the style.
     */
    images?: { id: string; url: string; sdf?: boolean }[];
    /**
     * Allow users to select features.
     * GeoJSON source features must have an `id` for selection to work. https://datatracker.ietf.org/doc/html/rfc7946#section-3.2 (last bullet)
     * When `true`, features will be given a `feature-state` of `hover` when hovered
     * and `focus` when focused/selected.
     */
    selectable?: boolean;
    /**
     * Invoked on any GeoJSON feature selection change.
     * Selection changes occur when `geoJSON.selectable === true` and user clicks a feature.
     * GeoJSON source features must have an `id` for selection to work. https://datatracker.ietf.org/doc/html/rfc7946#section-3.2 (last bullet)
     * Only 1 feature may be selected at a time.
     * `feature` is the single selected feature or `null` if the feature is unselected.
     * Selection works as a toggle. When a selected feature is clicked, it is unselected.
     */
    onGeoJSONSelectionChange?: (feature: GeoJSON.Feature | null) => void;
  };
}

export interface IInteractiveMap
  extends RootElementProps,
    MarkersMap,
    FocusMap,
    GeoJSONMap,
    Partial<ReactResizeDetectorDimensions> {
  readonly mapStyles: Pick<MapStyles, 'lightUrl' | 'darkUrl'>;
  readonly initialPosition?: Partial<
    Pick<MapboxOptions, 'center' | 'zoom' | 'bearing' | 'pitch' | 'bounds'>
  >;
  readonly minZoom?: number;
  readonly maxZoom?: number;
  /** When `true`, map can be zoomed and panned through user interactions */
  readonly isMoveable?: boolean;
  /** Callback when map position has moved (map zoomed or panned) */
  onMapMoved?: (position: {
    center: { lng: number; lat: number };
    zoom: number;
    centerChanged: boolean;
  }) => void;
  renderLoadingDisplay?: (loading: boolean) => JSX.Element;
}

const mapStyle = ({ lightUrl, darkUrl }: Pick<MapStyles, 'lightUrl' | 'darkUrl'>, dark = false) =>
  dark ? darkUrl : lightUrl;

const InteractiveMap: React.FC<IInteractiveMap> = ({
  mapStyles,
  initialPosition = {},
  minZoom,
  maxZoom,
  isMoveable = false,
  onMapMoved,
  renderLoadingDisplay,
  markers,
  onSelectedMarker,
  focusLocation,
  moveFocusLocationWithMap = false,
  geoJSON,
  style = {},
  className,
  height,
  width,
  ...rootElementProps
}) => {
  const classes = useStyles();

  /**
   * Props that should not be changed once component is rendered.
   * Subsequent changes will have no effect.
   */
  const readOnlyProps = useRef<Partial<IInteractiveMap>>({
    initialPosition,
    minZoom,
    maxZoom,
    isMoveable,
    moveFocusLocationWithMap,
  });

  /** Container `div` element */
  const mapContainer = useRef<HTMLDivElement | null>(null);
  /** Mapbox GL JS `Map` instance */
  const mapInstance = useRef<mapboxgl.Map>();

  /** Ref to coordinates after last map move end. Used to verify coord change occurred on next move end. */
  const lastMovedCoords = useRef<LngLat>();

  /** When `true`, Mapbox GL JS `Map` instance is fully loaded and available for use */
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!mapContainer.current) {
      throw new Error('Ref to map container element must be assigned before rendering');
    }

    const map = (mapInstance.current = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken,
      minZoom: readOnlyProps.current.minZoom,
      maxZoom: readOnlyProps.current.maxZoom,
      attributionControl: false,
      scrollZoom: { around: 'center' } as any, // Incorrectly typed in @types/mapbox-gl
      touchZoomRotate: { around: 'center' } as any, // Incorrectly typed in @types/mapbox-gl
      interactive: readOnlyProps.current.isMoveable,
      ...readOnlyProps.current.initialPosition,
    }));

    map.on('load', () => setLoading(false));

    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    if (!(mapInstance.current && mapStyles)) return;

    const map = mapInstance.current;

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    map.setStyle(mapStyle(mapStyles, prefersDark.matches));

    const onMediaChange = (e: any) => map.setStyle(mapStyle(mapStyles, e.matches));
    prefersDark.addEventListener('change', onMediaChange);

    return () => {
      prefersDark.removeEventListener('change', onMediaChange);
    };
  }, [mapStyles]);

  // Resize Mapbox `Map` on container size changes
  // `height` and `width` changes provided by `withResizeDetector`
  // https://docs.mapbox.com/mapbox-gl-js/api/map/#map#resize
  useEffect(() => {
    if (!(mapInstance.current && height && width)) return;
    mapInstance.current.resize();
  }, [height, width]);

  // Hook `onMapMoved` callback to Mapbox `Map` 'moveend' events
  useEffect(() => {
    if (loading || !(mapInstance.current && onMapMoved)) return;

    const onMoveEnd = ({
      target,
      avoidSideEffects = false, // Internally triggered events that should not invoke `onMapMoved`
    }: (MapMouseEvent | MapTouchEvent) & EventData) => {
      if (avoidSideEffects) return;
      const center = target.getCenter();
      const zoom = target.getZoom();
      const centerChanged =
        !!lastMovedCoords.current && center.distanceTo(lastMovedCoords.current) > 0;
      lastMovedCoords.current = center;
      onMapMoved({ center, zoom, centerChanged });
    };

    const map = mapInstance.current.on('moveend', onMoveEnd);

    return () => {
      map.off('moveend', onMoveEnd);
    };
  }, [loading, onMapMoved]);

  // `MarkersMap` implementation
  // Add markers to map. Call `onSelectedMarker` on marker selection and selection cleared.
  useEffect(() => {
    if (loading || !(mapInstance.current && markers?.length)) return;

    const buildSingleMarker = (mapMarker: MapMarker) => {
      const marker = new Marker({
        color: mapMarker.color || '--ion-color-primary',
        scale: 0.8,
      }).setLngLat(mapMarker.location);

      if (onSelectedMarker) {
        // Make markers selectable if `onSelectedMarker` is defined
        const el = marker.getElement();
        el.style.cursor = 'pointer';
        // NOTE - Listener is not removed
        // Does not need to be removed in most modern browsers, but should probably be done
        el.addEventListener('click', (evt) => {
          evt.stopPropagation();
          map.easeTo({ center: mapMarker.location });
          onSelectedMarker(mapMarker);
        });
      }

      return marker;
    };

    const clearMarkerSelection = () => {
      onSelectedMarker && onSelectedMarker(null);
    };

    const newMarkers = markers
      .map(buildSingleMarker)
      .sort((a, b) => b.getLngLat().lat - a.getLngLat().lat); // Sort so plotted south to north

    newMarkers.forEach((m) => m.addTo(mapInstance.current as mapboxgl.Map));

    const map = mapInstance.current.on('click', clearMarkerSelection);

    return () => {
      newMarkers.forEach((m) => m.remove());
      map.off('click', clearMarkerSelection);
    };
  }, [loading, markers, onSelectedMarker]);

  // `FocusMap` implementation
  // Add marker at focus location and lock to center if `moveLocationWithMap`
  useEffect(() => {
    if (loading || !(mapInstance.current && focusLocation)) return;

    const { title, center, color = 'black', ...cameraOptions } = focusLocation;
    const map = mapInstance.current;

    if (!((center as any)[0] && (center as any)[1])) return;

    const marker = new Marker({ color, scale: 0.8 }).setLngLat(center).addTo(map);

    if (title) {
      const popup = new Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: '200px',
      }).setHTML(title);

      marker.setPopup(popup).togglePopup();
    }

    if (!map.isMoving()) {
      // Don't fly to location if map is already moving.
      // Map is either moving because we are already navigating to focus or user is moving and a new focus will be set.
      // Prevents previous issues with map snapping to previous location after user map move.

      // Remove undefined `CameraOptions`. `easeTo` with undefined options will throw an exception.
      const additionalOptions = Object.keys(cameraOptions).reduce(
        (acc, key) =>
          (cameraOptions as any)[key] === undefined
            ? acc
            : { ...acc, [key]: (cameraOptions as any)[key] },
        {}
      );
      map.easeTo({ center, ...additionalOptions, animate: false }, { avoidSideEffects: true });
    }

    const moveLocationWithMap = ({ target }: MapMouseEvent | MapTouchEvent) =>
      marker.setLngLat(target.getCenter());

    moveFocusLocationWithMap && map.on('move', moveLocationWithMap);

    return () => {
      moveFocusLocationWithMap && map.off('move', moveLocationWithMap);
      marker.remove();
    };
  }, [loading, focusLocation, moveFocusLocationWithMap]);

  // `GeoJSON` implementation
  // Add GeoJSON source and layers to map immediately and on later style changes
  // Support hover and selection GeoJSON features
  useEffect(() => {
    if (loading || !(mapInstance.current && geoJSON)) return;

    const map = mapInstance.current;

    type MapEvent = { features?: MapboxGeoJSONFeature[] | undefined };

    const sourceId = '__geoJSON'; // prefixing with `__` to better avoid potential conficts
    const hoverState = 'hover';
    const focusState = 'focus';

    const imagesAdded: string[] = []; // so we can remove them in cleanup
    const layersAdded: string[] = [];
    const handlers: [string, (evt: any) => void][] = [];

    let hoveredId: string | number | null = null;
    let focusedId: string | number | null = null;

    const addGeoJSON = async () => {
      // If GeoJSON source data is already in style, we can't readd.
      // Let's assume it was added by us (here in this func) and there's no need to continue.
      if (map.getSource(sourceId)) return;

      map.addSource(sourceId, {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: geoJSON.features },
      });

      const loadImagePromise = (m: mapboxgl.Map, url: string) =>
        new Promise<HTMLImageElement | ImageBitmap>((resolve, reject) => {
          m.loadImage(url, (err, image) => {
            if (err) return reject(err);
            if (!image) return reject('Image failed to load');
            resolve(image);
          });
        });

      for (const image of geoJSON.images || []) {
        if (map.hasImage(image.id)) {
          imagesAdded.push(image.id);
          return;
        }
        const loadedImage = await loadImagePromise(map, image.url);
        map.addImage(image.id, loadedImage, { sdf: image.sdf });
        imagesAdded.push(image.id);
      }

      geoJSON.layers.forEach((layer) => {
        const sourcedLayer = {
          source: sourceId,
          ...layer,
        };
        map.addLayer(sourcedLayer as any);

        layersAdded.push(layer.id);
      });

      if (!geoJSON.selectable) return;

      const onMouseEnter = () => (map.getCanvas().style.cursor = 'pointer');
      map.on('mouseenter', layersAdded, onMouseEnter);
      handlers.push(['mouseenter', onMouseEnter]);

      const onMouseMove = ({ features }: MapEvent) => {
        if (hoveredId !== null) {
          map.setFeatureState({ source: sourceId, id: hoveredId }, { [hoverState]: false });
          hoveredId = null;
        }

        if (!features?.length) return;

        const featureId = features[0].id;
        if (!featureId) return;

        map.setFeatureState({ source: sourceId, id: featureId }, { [hoverState]: true });
        hoveredId = featureId;
      };
      map.on('mousemove', layersAdded, onMouseMove);
      handlers.push(['mousemove', onMouseMove]);

      const onMouseLeave = () => {
        map.getCanvas().style.cursor = '';

        if (hoveredId === null) return;
        map.setFeatureState({ source: sourceId, id: hoveredId }, { [hoverState]: false });
        hoveredId = null;
      };
      map.on('mouseleave', layersAdded, onMouseLeave);
      handlers.push(['mouseleave', onMouseLeave]);

      const onClick = ({ features }: MapEvent) => {
        const originalId = focusedId;

        if (originalId !== null) {
          // Unselect previous selection before making any new selections
          map.setFeatureState({ source: sourceId, id: originalId }, { [focusState]: false });
          focusedId = null;
        }

        if (!features?.length) {
          // Not a normal case since clicking a feature triggers this event
          // But logically possible since not enforced by Mapbox
          if (geoJSON.onGeoJSONSelectionChange && originalId !== null) {
            geoJSON.onGeoJSONSelectionChange(null);
          }
          return;
        }

        const featureId = features[0].id;

        if (!featureId) {
          // GeoJSON source feature does not have and `id`
          // `id` is not required on GeoJSON features, but required for our selection product feature
          if (geoJSON.onGeoJSONSelectionChange && originalId !== null) {
            geoJSON.onGeoJSONSelectionChange(null);
          }
          return;
        }

        const focusOn = featureId !== originalId;

        map.setFeatureState({ source: sourceId, id: featureId }, { [focusState]: focusOn });

        focusedId = focusOn ? featureId : null;

        if (geoJSON.onGeoJSONSelectionChange) {
          geoJSON.onGeoJSONSelectionChange(focusOn ? features[0] : null);
        }
      };
      map.on('click', layersAdded, onClick);
      handlers.push(['click', onClick]);
    };

    addGeoJSON();

    map.on('style.load', addGeoJSON);

    return () => {
      map.off('style.load', addGeoJSON);

      handlers.forEach((h) => map.off(h[0], h[1]));

      if (!map.getStyle()) return; // Can only remove layers and source if style is available

      layersAdded.forEach((l) => map.removeLayer(l));
      imagesAdded.forEach((i) => map.removeImage(i));
      map.removeSource(sourceId);
    };
  }, [loading, geoJSON]);

  // Remove `targetRef` added by resize detector. Creates React warning if bound to `div` el.
  const { targetRef, ...divProps } = rootElementProps as any;

  return (
    <>
      {renderLoadingDisplay && renderLoadingDisplay(loading)}
      <div
        {...divProps}
        className={clsx(classes.mapboxOverrides, className)}
        style={style}
        ref={mapContainer}
      />
    </>
  );
};

export default withResizeDetector(InteractiveMap);
