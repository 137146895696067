import { BoundingBox, viewport } from '@mapbox/geo-viewport';
import bbox from '@turf/bbox';
import { Feature, FeatureCollection, Point, featureCollection, point } from '@turf/helpers';
import Cluster from 'supercluster';

export interface MarkerProps {
  'marker-color'?: string;
  'marker-symbol'?: string;
  'marker-size'?: 'small' | 'medium' | 'large';
}

export interface SimplifyGeojsonConfig {
  collection: FeatureCollection<Point>;
  dimensions: [number, number];
  options?: Cluster.Options<any, any>;
}

/**
 * MapBox Static Map API limit: 8192 [bytes]
 * See https://docs.mapbox.com/api/maps/static-images/
 * 7842 = 8192 - 350 (for rest of URL)
 */
const DATA_LIMIT = 7842;

/**
 * Entry point for the utility
 */
export const simplifyGeojson = ({
  collection,
  dimensions,
  options,
}: SimplifyGeojsonConfig): string => {
  const cluster = new Cluster(options);
  cluster.load(collection.features);

  const [minX, minY, maxX, maxY] = bbox(collection);
  const bounds: BoundingBox = [minX, minY, maxX, maxY];

  const { zoom: initialZoom } = viewport(bounds, dimensions);

  return simplify(initialZoom, (zoom) => buildGeojson(cluster, bounds, zoom, collection.features));
};

/**
 * Hepler to recursively decrement the zoom level until the resulting geojson is less than the data limit
 */
const simplify = (zoom: number, callback: (zoom: number) => string): string => {
  const geojson = callback(zoom);

  const encoder = new TextEncoder();
  const bytes = encoder.encode(geojson);

  if (bytes.length < DATA_LIMIT || zoom === 0) {
    return geojson;
  }

  return simplify(zoom - 1, callback);
};

/**
 * Helper to build the simplified geojson on each recursive callback
 */
const buildGeojson = (
  cluster: Cluster,
  bounds: BoundingBox,
  zoom: number,
  originalFeatures: Feature<Point, any>[]
): string => {
  const clusters = cluster.getClusters(bounds, zoom);

  const points = clusters.length ? clusters : originalFeatures;

  const features = points.map(({ geometry, properties }) => {
    const coordinates = geometry.coordinates.map((c) => +c.toFixed(5));

    let count: string | undefined;
    if (properties.point_count > 99) {
      // 99 is maximum number supported. https://docs.mapbox.com/api/maps/static-images/#marker
      // 3 digits or greater will throw an exception
      count = '99';
    } else if (properties.point_count) {
      count = properties.point_count.toString();
    }

    return point<MarkerProps>(coordinates, { 'marker-symbol': count, 'marker-size': 'small' });
  });

  const collection = featureCollection(features);

  const uriEncoded = encodeURI(JSON.stringify(collection)).replace(/#/g, '%23');

  return uriEncoded;
};
