import { Section } from './PdfGenerator';

export type PdfOptions = Record<Section, { visible: boolean }>;

export type State = { options: PdfOptions };

export interface ToggleVisibilityPayload {
  section: Section;
}

export const init = (): State => {
  const options = Object.values(Section).reduce(
    (acc, section) => ({ ...acc, [section]: { visible: true } }),
    {}
  );

  // tslint:disable-next-line: no-object-literal-type-assertion
  return { options } as State;
};

export const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'TOGGLE_VISIBILITY': {
      const { section } = action.payload as ToggleVisibilityPayload;

      const options = {
        ...state.options,
        [section]: { visible: !state.options[section].visible },
      };

      return { options };
    }
    default: {
      return state;
    }
  }
};
