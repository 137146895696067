import { useApolloClient } from '@apollo/client';
import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { Auth } from 'aws-amplify';
import { useWorkspace } from 'hooks/useWorkspace';
import { logOutOutline as signOutIcon } from 'ionicons/icons';
import React from 'react';

const MenuBottomPanel: React.FC = () => {
  const { resetStore } = useApolloClient();
  const { resetWorkspace } = useWorkspace();

  const signOut = async () => {
    await Auth.signOut();
    await resetStore();
    resetWorkspace();
  };

  return (
    <IonItem lines="none" button={true} detail={false} onClick={() => signOut()}>
      <IonIcon slot="start" icon={signOutIcon} />
      <IonLabel>Sign Out</IonLabel>
    </IonItem>
  );
};

export default MenuBottomPanel;
