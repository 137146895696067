import { ApolloError, useMutation } from '@apollo/client';
import {
  IonActionSheet,
  IonAlert,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
  IonRow,
  isPlatform,
} from '@ionic/react';
import IconBadge from 'components/IconBadge';
import { useMessages } from 'hooks/useMessages';
import { Task } from 'interfaces/Worklog';
import {
  carOutline as equipmentIcon,
  cubeOutline as materialIcon,
  peopleOutline as laborIcon,
  trashBinOutline as deleteIcon,
} from 'ionicons/icons';
import moment from 'moment';
import { REMOVE_TASK, RemoveTaskInput } from 'pages/WorkLog/graphql';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { formatCurrency } from 'utils/formatCurrency';

const isMobile = isPlatform('mobile');

const useStyles = createUseStyles({
  root: {
    fontSize: '0.875rem',

    '& h3': {
      fontSize: '0.9375rem',
    },

    '& ion-label, & ion-note': {
      display: 'block',
      lineHeight: 1.6,
    },
  },
  grid: {
    paddingLeft: 0,
    paddingRight: 0,

    '& ion-col': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  badges: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 64px 8px 0 !important',
    '& > *': {
      marginRight: 'calc(var(--ion-margin, 16px) * 0.5)',
      '&:last-child': {
        marginRight: 'var(--ion-margin, 16px)',
      },
    },
  },
});

export interface TaskItemProps {
  task: Pick<
    Task,
    | 'id'
    | 'name'
    | 'taskDate'
    | 'invoice'
    | 'laborCount'
    | 'equipmentCount'
    | 'materialCount'
    | 'totalCost'
  >;
  lines?: 'full' | 'inset' | 'none';
  routerLink?: string;
  onRemove?: () => void;
}

const TaskItem: React.FC<TaskItemProps> = ({
  task: { id, name, taskDate, invoice, totalCost, laborCount, equipmentCount, materialCount },
  lines,
  routerLink,
  onRemove,
}) => {
  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
  const classes = useStyles();
  const { addMessage } = useMessages();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const deleteHeader = `Would you like to permanently delete ${name}?`;

  const [removeTask] = useMutation(REMOVE_TASK);

  const onDeleteTask = async (variables: RemoveTaskInput) => {
    try {
      await removeTask({ variables });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to delete task', ...messages].join('. ')}.`, 'danger');
      return;
    }
    onRemove && onRemove();
  };

  const deleteDismiss = () => {
    if (itemSlidingRef.current) {
      itemSlidingRef.current.close();
    }
    setShowConfirmDelete(false);
  };

  return (
    <>
      <IonItemSliding ref={itemSlidingRef}>
        <IonItemOptions side="start">
          <IonItemOption
            color="danger"
            expandable={true}
            onClick={() => setShowConfirmDelete(true)}
          >
            <IonIcon slot="icon-only" icon={deleteIcon} />
          </IonItemOption>
        </IonItemOptions>
        <IonItem className={classes.root} lines={lines} routerLink={routerLink} detail={false}>
          <IonGrid className={classes.grid}>
            <IonRow>
              <IonCol style={{ paddingRight: 'var(--ion-margin, 16px)' }}>
                <IonLabel>
                  <h3>{name}</h3>
                </IonLabel>
              </IonCol>
              <IonCol size="auto">
                <IonNote color="dark">{formatCurrency(totalCost)}</IonNote>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonNote>
                  {moment(taskDate).format('MM/D/YYYY')}
                  {invoice?.invoiceNumber && <span>{` · Invoice ${invoice.invoiceNumber}`}</span>}
                </IonNote>
              </IonCol>
              <IonCol className={classes.badges} sizeXs="12" sizeSm="auto">
                <IconBadge badgeContent={laborCount} color="light" icon={laborIcon} />
                <IconBadge badgeContent={equipmentCount} color="light" icon={equipmentIcon} />
                <IconBadge badgeContent={materialCount} color="light" icon={materialIcon} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonItemSliding>
      {/* Confirm delete. Show alert on desktop, action sheet on mobile. */}
      <IonAlert
        isOpen={showConfirmDelete && !isMobile}
        onDidDismiss={deleteDismiss}
        header="Delete"
        message={deleteHeader}
        buttons={[
          { text: 'Cancel', role: 'cancel' },
          { text: 'Delete', role: 'destructive', handler: () => onDeleteTask({ taskId: id }) },
        ]}
      />
      <IonActionSheet
        isOpen={showConfirmDelete && isMobile}
        header={deleteHeader}
        onDidDismiss={deleteDismiss}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Delete',
            role: 'destructive',
            handler: () => onDeleteTask({ taskId: id }),
          },
        ]}
      />
    </>
  );
};

export default TaskItem;
