import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import UnsafeArea from 'components/UnsafeArea';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React, { useCallback, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { FilterType } from './FilterType';
import { useWorkOrderFilter } from './useWorkOrderFilter';

export interface FilterCriterion {
  value: any;
  label: string;
  count: number;
  isActive: boolean;
}

interface FilterItemProps {
  item?: FilterCriterion;
  lines?: 'full' | 'inset' | 'none';
  onToggleChecked: (criterion: string) => void;
}

interface FilterGroupProps {
  title: string;
  filterBy: FilterType;
  items?: FilterCriterion[];
  buttonText?: string;
  columnCount?: number;
  itemCount?: number;
}

const useStyles = createUseStyles({
  root: {
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  tagList: {
    marginLeft: -4,
    padding: 'var(--ion-margin, 16px) 0',
  },
});

export const ChipItem: React.FC<FilterItemProps> = ({ item, onToggleChecked }) => {
  if (!item) {
    return null;
  }

  return (
    <IonChip
      onClick={() => onToggleChecked(item.value)}
      color={item.isActive ? 'primary' : 'medium'}
      outline={true}
    >
      <IonLabel>{item.label}</IonLabel>
    </IonChip>
  );
};

const CheckboxItem: React.FC<FilterItemProps> = ({ item, lines = 'none', onToggleChecked }) => {
  const classes = useStyles();

  if (!item) {
    return null;
  }

  return (
    <IonItem className={classes.root} lines={lines}>
      <IonLabel>{item.label}</IonLabel>
      <IonCheckbox
        checked={item.isActive}
        slot="start"
        onClick={() => onToggleChecked(item.value)}
      />
      <IonBadge color="light" mode="ios" slot="end">
        {item.count}
      </IonBadge>
    </IonItem>
  );
};

const FilterGroup: React.FC<FilterGroupProps> = ({
  title,
  filterBy,
  items = [],
  buttonText = 'Show all',
  columnCount = 2,
  itemCount = 4,
}) => {
  const classes = useStyles();
  const rowCount = Math.ceil(items.length / columnCount);

  const modalRef = useRef<HTMLIonModalElement>(null);
  const [showAll, setShowAll] = useState(false);

  const { criteria, clear, toggle, update } = useWorkOrderFilter(filterBy);

  const updatesCache = useRef(criteria);

  const cacheUpdates = useCallback((criterion: string) => {
    const checked = updatesCache.current.get(criterion);

    if (checked === undefined) {
      return;
    }

    updatesCache.current.set(criterion, !checked);
  }, []);

  const applyUpdates = useCallback(() => {
    update(updatesCache.current);
    setShowAll(false);
  }, [update]);

  const Expanded = () => (
    <IonModal
      isOpen={showAll}
      ref={modalRef}
      onDidDismiss={() => setShowAll(false)}
      onWillPresent={() => (updatesCache.current = criteria)}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowAll(false)}>
              <IonIcon icon={closeIcon} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          {[...Array(rowCount).keys()].map((row) => (
            <IonRow key={row}>
              {[...Array(columnCount).keys()].map((col) => (
                <IonCol key={col} sizeSm={(12 / columnCount).toString()}>
                  <CheckboxItem
                    item={items[row * columnCount + col]}
                    onToggleChecked={cacheUpdates}
                  />
                </IonCol>
              ))}
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonButton className="ion-no-margin" expand="full" size="large" onClick={applyUpdates}>
          Apply
        </IonButton>
      </IonFooter>
      <UnsafeArea position="bottom" color="primary" />
    </IonModal>
  );

  if (items.length < 2) {
    return null;
  }

  const noValueItem = items.find((item) => item.value === 'NONE');

  return (
    <React.Fragment>
      <ListSeparator />
      <IonItemGroup>
        <IonItemDivider>
          <IonLabel>{title}</IonLabel>
          <IonButton
            fill="clear"
            mode="ios"
            slot="end"
            style={{ '--padding-end': 0, fontSize: 'inherit', marginBottom: 0 }}
            onClick={clear}
          >
            Clear
          </IonButton>
        </IonItemDivider>

        {filterBy !== FilterType.Tag && (
          <>
            {items
              .filter((item) => item.value !== noValueItem?.value)
              .slice(0, noValueItem ? itemCount - 1 : itemCount)
              .map((item, index) => (
                <CheckboxItem
                  key={item.value}
                  item={item}
                  lines={index === items.length - 1 ? 'full' : 'none'}
                  onToggleChecked={toggle}
                />
              ))}

            {!!noValueItem && (
              <CheckboxItem
                key={noValueItem.value}
                item={noValueItem}
                lines={items.length <= itemCount ? 'full' : 'none'}
                onToggleChecked={toggle}
              />
            )}

            {items.length > itemCount && (
              <IonItem button={true} lines="full" onClick={() => setShowAll(true)}>
                <IonLabel color="primary">{buttonText}</IonLabel>
              </IonItem>
            )}
          </>
        )}

        {filterBy === FilterType.Tag && (
          <>
            <IonItem lines="none">
              <div className={classes.tagList}>
                {items.slice(0, 8).map((item, index) => (
                  <ChipItem
                    key={item.value}
                    item={item}
                    lines={index === items.length - 1 ? 'full' : 'none'}
                    onToggleChecked={toggle}
                  />
                ))}
              </div>
            </IonItem>

            {items.length > 8 && (
              <IonItem button={true} lines="full" onClick={() => setShowAll(true)}>
                <IonLabel color="primary">{buttonText}</IonLabel>
              </IonItem>
            )}
          </>
        )}
      </IonItemGroup>
      <Expanded />
    </React.Fragment>
  );
};

export default FilterGroup;
