import { MutationUpdaterFn, useMutation } from '@apollo/client';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import BackButton from 'components/BackButton';
import UnsafeArea from 'components/UnsafeArea';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import EquipmentForm, { EquipmentFormData } from 'pages/Equipment/components/EquipmentForm';
import {
  CREATE_EQUIPMENT,
  CreateEquipmentInput,
  GET_ORGANIZATION_EQUIPMENT,
} from 'pages/Equipment/graphql';
import React from 'react';
import { useHistory } from 'react-router-dom';

const cacheUpdate: MutationUpdaterFn<any> = (cache, { data: { createEquipment } }) => {
  const organizationId = createEquipment.equipment.organization.id;

  const queryData: any = cache.readQuery({
    query: GET_ORGANIZATION_EQUIPMENT,
    variables: { organizationId },
  });

  cache.writeQuery({
    query: GET_ORGANIZATION_EQUIPMENT,
    variables: { organizationId },
    data: {
      organization: {
        ...queryData.organization,
        equipment: [...queryData.organization.equipment, createEquipment.equipment],
      },
    },
  });
};

const CreateEquipment: React.FC = () => {
  const history = useHistory();

  const { workspace } = useWorkspace();
  const { addMessage } = useMessages();

  const [create] = useMutation(CREATE_EQUIPMENT, { update: cacheUpdate });

  const onSubmit = async ({
    name,
    primaryRate,
    make,
    model,
    year,
    licenseNumber,
    vinNumber,
  }: EquipmentFormData) => {
    const variables: CreateEquipmentInput = {
      organizationId: workspace.organizationId,
      name,
      rate: { primary: primaryRate },
    };

    // Only use vehicle properties that are defined
    // and only submit vehicle if it contains properies
    const vehicle = { make, model, year, licenseNumber, vinNumber };
    (Object.keys(vehicle) as (keyof typeof vehicle)[]).forEach((key) => {
      vehicle[key] === undefined && delete vehicle[key];
    });
    if (Object.keys(vehicle).length) {
      variables.vehicle = vehicle;
    }

    try {
      await create({ variables });
      history.push(`/equipment`);
    } catch ({ graphQLErrors }) {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to create equipment', ...messages].join('. ')}.`, 'danger');
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref="/equipment" />
          </IonButtons>
          <IonTitle>Create Equipment</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <EquipmentForm onSubmit={onSubmit} />
      </IonContent>
      <UnsafeArea position="bottom" color="primary" />
    </IonPage>
  );
};

export default CreateEquipment;
