/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
/* Theme variables */
import 'assets/overrides.css';
import 'assets/theme.css';
import 'assets/utilities.css';

import { IonApp, IonPage, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Menu from 'components/Menu';
import InAppNotifications from 'containers/InAppNotifications';
import MenuBottomPanel from 'containers/MenuBottomPanel';
import MenuTopPanel from 'containers/MenuTopPanel';
import MessagesDisplay from 'containers/MessagesDisplay';
import { IdentityUserProvider } from 'hooks/useIdentityUser';
import { WorkspaceProvider } from 'hooks/useWorkspace';
import { AccountsRoutes } from 'pages/Accounts';
import ActivateAccount from 'pages/ActivateAccount';
import { AssetsRoutes } from 'pages/Assets';
import { EquipmentRoutes } from 'pages/Equipment';
import { ReportsRoutes } from 'pages/Reports';
import { SettingsRoutes } from 'pages/Settings';
import WorkLogRoutes from 'pages/WorkLog';
import { WorkOrderRoutes } from 'pages/WorkOrders';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { version } from '../package.json';
import { navigation } from './navigation';
import { StoreProvider } from './store';

setupIonicReact();

const App: React.FC = () => {
  return (
    <IdentityUserProvider>
      <IonApp>
        <StoreProvider version={version}>
          <IonReactRouter>
            <Switch>
              <Route path="/activateAccount/:token" component={ActivateAccount} />
              <Route>
                <WorkspaceProvider version={version}>
                  <IonSplitPane contentId="main-content" style={{ '--side-max-width': '300px' }}>
                    <Menu
                      contentId="main-content"
                      items={navigation}
                      renderTopPanel={() => <MenuTopPanel />}
                      renderBottomPanel={() => <MenuBottomPanel />}
                    />
                    <IonPage id="main-content">
                      <Switch>
                        <Route path="/workorders" component={WorkOrderRoutes} />
                        <Route path="/worklog" component={WorkLogRoutes} />
                        <Route path="/accounts" component={AccountsRoutes} />
                        <Route path="/reports" component={ReportsRoutes} />
                        <Route path="/settings" component={SettingsRoutes} />
                        <Route path="/equipment" component={EquipmentRoutes} />
                        <Route path="/assets" component={AssetsRoutes} />
                        <Redirect from="/" to="/workorders" exact={true} />
                      </Switch>
                    </IonPage>
                  </IonSplitPane>
                  <InAppNotifications /> {/* Requires workspace and router */}
                </WorkspaceProvider>
              </Route>
            </Switch>
            <MessagesDisplay />
          </IonReactRouter>
        </StoreProvider>
      </IonApp>
    </IdentityUserProvider>
  );
};

export default App;
