import { IonCol, IonIcon, IonLabel, IonRow } from '@ionic/react';
import clsx from 'clsx';
import { AssetTimelineNode } from 'interfaces/Asset';
import { ellipse } from 'ionicons/icons';
import moment from 'moment';
import React from 'react';
import { createUseStyles } from 'react-jss';

import { WorkHistoryCardStyleProps } from './WorkHistoryCard';

export interface WorkHistoryCardLeftProps {
  timelineItem: Pick<AssetTimelineNode, 'id' | 'createdAt' | 'closedAt' | 'workorderNumber'>;
  position: 'start' | 'inner' | 'end' | 'single';
  condensed: boolean;
}

const useStyles = createUseStyles({
  leftSide: (props: WorkHistoryCardStyleProps) => ({
    borderRadius: props.condensed ? '8px 0px 0px 8px' : '36px 0px 0px 36px',
    background: 'var(--ion-color-light)',
    display: 'flex',
    alignItems: 'center',
  }),
  circleIcon: (props: WorkHistoryCardStyleProps) => ({
    fontSize: props.condensed ? '0.5rem' : '0.625rem',
    marginLeft: props.condensed ? 'var(--ion-margin, 16px)' : 'calc(var(--ion-margin, 16px) * 2)',
    verticalAlign: 'middle',
    position: 'absolute',
    top: props.condensed ? 'calc(50% - (0.5rem * 0.5))' : 'calc(50% - (0.625rem * 0.5))',
  }),
  topLine: (props: WorkHistoryCardStyleProps) => ({
    height: '50%',
    width: '1px',
    backgroundColor: 'var(--ion-color-primary)',
    position: 'absolute',
    top: 0,
    marginLeft: props.condensed
      ? 'calc(var(--ion-margin, 16px) + (0.5rem * 0.5) - 0.5px)'
      : 'calc((var(--ion-margin, 16px) * 2) + (0.625rem * 0.5) - 0.5px)',
    opacity: 0.5,
    zIndex: 1,
  }),
  bottomLine: (props: WorkHistoryCardStyleProps) => ({
    height: '50%',
    width: '1px',
    backgroundColor: 'var(--ion-color-primary)',
    position: 'absolute',
    top: '50%',
    marginLeft: props.condensed
      ? 'calc(var(--ion-margin, 16px) + (0.5rem * 0.5) - 0.5px)'
      : 'calc((var(--ion-margin, 16px) * 2) + (0.625rem * 0.5) - 0.5px)',
    opacity: 0.5,
    zIndex: 1,
  }),
  label: (props: WorkHistoryCardStyleProps) => ({
    marginLeft: props.condensed
      ? 'calc(var(--ion-margin, 16px) * 2 + 0.5rem)'
      : 'calc(var(--ion-margin, 16px) * 3.5 + 0.625rem)',
  }),
});

const getShortDate = (condensed: boolean, date: Date) => {
  const dateMoment = moment(date);
  const month = condensed
    ? dateMoment.format('MMM') // Short month name (e.g., Jul)
    : dateMoment.format('MMMM'); // Full month name (e.g., July)
  const day = dateMoment.date(); // Get the day of the month

  return `${month} ${day}`;
};

const WorkHistoryCardLeft: React.FC<WorkHistoryCardLeftProps> = ({
  timelineItem,
  condensed,
  position,
}) => {
  const classes = useStyles({ condensed });

  return (
    <IonCol size="4" className={clsx('ion-no-padding', classes.leftSide)}>
      <IonIcon icon={ellipse} color="primary" className={classes.circleIcon} />
      {['start', 'inner'].includes(position) && <div className={classes.bottomLine} />}
      {['end', 'inner'].includes(position) && <div className={classes.topLine} />}
      <div>
        <IonRow>
          <IonLabel color="dark" className={classes.label}>
            <p style={{ color: 'inherit' }}>
              {getShortDate(condensed, new Date(timelineItem.closedAt || timelineItem.createdAt))}
            </p>
          </IonLabel>
        </IonRow>
        <IonRow style={{ marginTop: 'calc(var(--ion-margin, 16px) * 0.5)' }}>
          <IonLabel color="medium" className={classes.label}>
            <p>{timelineItem.workorderNumber}</p>
          </IonLabel>
        </IonRow>
      </div>
    </IonCol>
  );
};

export default WorkHistoryCardLeft;
