import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { AssetTimelineNode } from 'interfaces/Asset';
import React from 'react';

import StatusLabel from './StatusLabel';

export interface DetailsFullProps {
  timelineItem: Pick<
    AssetTimelineNode,
    'id' | 'title' | 'assignee' | 'team' | 'status' | 'category'
  >;
}

const DetailsFull: React.FC<DetailsFullProps> = ({ timelineItem }) => (
  <IonGrid style={{ paddingRight: 'calc(var(--ion-padding, 16px) * 1.5)' }}>
    <IonRow>
      <IonCol size="6">
        <IonLabel color="dark" className="wrap-2-lines">
          <h3 style={{ color: 'inherit' }}>{timelineItem.title}</h3>
        </IonLabel>
      </IonCol>
      <IonCol size="6">
        <IonLabel>
          <p className="ion-text-right ion-text-nowrap">{timelineItem.category?.name}</p>
        </IonLabel>
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol size="6">
        <StatusLabel status={timelineItem.status} />
      </IonCol>
      <IonCol size="6">
        <IonLabel>
          <p className="ion-text-right ion-text-nowrap">
            {[timelineItem.team?.name, timelineItem.assignee?.name].filter(Boolean).join(' - ')}
          </p>
        </IonLabel>
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default DetailsFull;
