import './Menu.css';

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  isPlatform,
} from '@ionic/react';
import clsx from 'clsx';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { AccountAction } from 'interfaces/AccountAction';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import MenuTree, { TreeBranch } from './MenuTree';

interface MenuItem {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  isTree?: boolean;
  branches?: TreeBranch[];
  requiredActions?: AccountAction[];
}

interface IMenu {
  menuId?: string;
  contentId: string;
  items: MenuItem[];
  renderTopPanel?: () => JSX.Element;
  renderBottomPanel?: () => JSX.Element;
}

const Menu: React.FC<IMenu> = ({
  menuId = 'main-menu',
  contentId,
  items = [],
  renderTopPanel,
  renderBottomPanel,
}) => {
  const location = useLocation();

  const menuRef = useRef<HTMLIonMenuElement>(null);

  useEffect(() => {
    menuRef.current?.close(true);
  }, [location]);

  return (
    <IonMenu
      ref={menuRef}
      contentId={contentId}
      menuId={menuId}
      type="push"
      side="start"
      className={menuId}
    >
      <IonContent>
        <div style={{ paddingTop: 'var(--ion-safe-area-top, 0)' }}>
          <IonList id="nav-list">
            <React.Fragment>{renderTopPanel && renderTopPanel()}</React.Fragment>
            <IonMenuToggle autoHide={false} menu={menuId}>
              {items.map((item) => (
                <React.Fragment key={item.url}>
                  <AuthorizeRequired required={item.requiredActions || []}>
                    {item.isTree && item.branches ? (
                      <MenuTree label={item.title} branches={item.branches} />
                    ) : (
                      <IonItem
                        className={clsx({ selected: location.pathname.startsWith(item.url) })}
                        routerLink={item.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon
                          slot="start"
                          icon={isPlatform('ios') ? item.iosIcon : item.mdIcon}
                        />
                        <IonLabel>{item.title}</IonLabel>
                      </IonItem>
                    )}
                  </AuthorizeRequired>
                </React.Fragment>
              ))}
            </IonMenuToggle>
          </IonList>
          <div className="ion-margin-top">{renderBottomPanel && renderBottomPanel()}</div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
