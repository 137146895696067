import { useLocalStorage } from 'hooks/useLocalStorage';
import { useEffect } from 'react';

export interface Workspace {
  id: string;
  label: string;
}

export interface WorkspaceManagerResult<T> {
  workspaces: T[];
  workspace?: T;
  activateWorkspace: (id: string) => void;
  resetWorkspace: () => void;
}

export interface WorkspaceManagerConfig<T> {
  workspaces: T[];
  version: string;
}

const findWorkspace = <T extends { id: string }>(workspaces: T[], id: string) =>
  workspaces.find((w) => w.id === id);

const isEqual = <T>(a: T, b: T) => JSON.stringify(a) === JSON.stringify(b);

/**
 * Manage available and active workspaces.
 *
 * "Available" workspaces are defined by `workspaces` in the config and returned as `workspaces`.
 * Available workspaces may change. Changes are handled appropriately.
 * "Active" workspace is the returned `workspace` prop.
 *
 * Rules around workspaces:
 *
 * - A workspace may not be active if not in available workspaces.
 * `activateWorkspace` will not activate the workspace if this requirement is not met.
 * If the available workspaces change and the active workspace is no longer available,
 * the first available is made active.
 *
 * - There will always be an active workspace if there are available workspaces.
 * The active workspace will be the first available if not set directly.
 *
 * - Changes to `workspaces` will be reflected on the active workspace.
 * If the `workspaces` config value changes, a workspace is updated, and that workspace is active,
 * the active workspace, `workspace`, will reflect those changes.
 */
export const useWorkspaceManager = <T extends Workspace>({
  workspaces,
  version,
}: WorkspaceManagerConfig<T>): WorkspaceManagerResult<T> => {
  const { value, setValue, clearValue } = useLocalStorage<T>(`workspace-v${version}`);

  const activateWorkspace = (id: string) => {
    const inWorkspaces = findWorkspace(workspaces, id);
    if (inWorkspaces) setValue(inWorkspaces);
  };

  const resetWorkspace = () => clearValue();

  // Effect to handle `workspace` validation checks
  useEffect(() => {
    if (!workspaces.length) return;

    // No activated workspace
    // There should always be an activated workspace if there are `workspaces`
    // Activate first available from `workspaces`
    if (!value) {
      setValue(workspaces[0]);
      return;
    }

    const inWorkspaces = findWorkspace(workspaces, value.id);

    // Activated workspace is not an available workspace
    // Maybe available workspaces have changed (ie. user is no longer a member)
    // This is not allowed, activate first available from `workspaces`
    if (!inWorkspaces) {
      setValue(workspaces[0]);
      return;
    }

    // Activated workspace is out  f sync with the current state of the workspace in `workspaces`
    // Sync up by activating the workspace as exists in `workspaces`
    if (!isEqual(inWorkspaces, value)) {
      setValue(inWorkspaces);
      return;
    }
  }, [value, workspaces, setValue]);

  return {
    workspaces,
    workspace: value,
    activateWorkspace,
    resetWorkspace,
  };
};
