import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AddNewItemButton from 'components/AddNewItemButton';
import SelectionItem from 'components/SelectionItem';
import UnsafeArea from 'components/UnsafeArea';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import {
  arrowBackOutline as backIcon,
  arrowForwardOutline as nextIcon,
  closeOutline as closeIcon,
} from 'ionicons/icons';
import sort from 'lodash.sortby';
import React, { PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import LaborForm from './LaborForm';

const useStyles = createUseStyles({
  root: {
    '--inner-padding-start': '5px',
    '--inner-padding-end': '0px',
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
});

export type AvailableAccount = {
  id: string;
  name: string;
  previouslyUsed: boolean;
  defaultRate?: number;
};

export type SelectedLabor = {
  account: AvailableAccount;
  form: {
    rate: string | undefined;
    hours: string | undefined;
  };
};
export interface LaborModalProps {
  availableAccounts: AvailableAccount[];
  keys?: string[];
  sortBy?: string;
  label?: string;
  pageRef?: RefObject<HTMLElement>;
  onSubmit: (data: { accountId: string; rate: number; hours: number }[]) => Promise<void>;
}

const LaborModal = ({
  availableAccounts,
  keys = ['name'],
  sortBy = 'name',
  label = 'Add Labor',
  pageRef,
  onSubmit,
}: PropsWithChildren<LaborModalProps>) => {
  const classes = useStyles();
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLabor, setSelectedLabor] = useState<SelectedLabor[]>([]);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { results, setQuery } = useFuzzySearch({
    list: availableAccounts,
    options: { keys, threshold: 0.2 },
  });

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false,
  };

  const submit = async () => {
    if (Object.values(selectedLabor).every((el) => el === undefined)) return;

    try {
      await onSubmit(
        selectedLabor.map((item) => ({
          accountId: item.account.id,
          rate: parseFloat(item.form.rate as string),
          hours: parseFloat(item.form.hours as string),
        }))
      );
      setIsModalOpen(false);
    } catch (_err) {
      // NOOP
    }
  };

  const handleSelect = (props: SelectedLabor) => {
    const index = selectedLabor.findIndex((el) => el.account.id === props.account.id);
    if (index === -1) {
      setSelectedLabor([...selectedLabor, props]);
    } else {
      setSelectedLabor([...selectedLabor.slice(0, index), ...selectedLabor.slice(index + 1)]);
    }
  };

  const updateForm = (props: SelectedLabor) => {
    setSelectedLabor(selectedLabor?.map((item) => (item.account === props.account ? props : item)));
  };

  const updateRateAll = (props: SelectedLabor) => {
    setSelectedLabor(
      selectedLabor?.map((item) =>
        item.account === props.account
          ? props
          : { ...item, form: { ...item.form, rate: props.form.rate } }
      )
    );
  };

  const updateRateEmpty = (props: SelectedLabor) => {
    setSelectedLabor(() => {
      return selectedLabor?.map((item) => {
        if (item.account === props.account) {
          return props;
        } else {
          if (item.form.rate === undefined || item.form.rate === null) {
            return { ...item, form: { ...item.form, rate: props.form.rate } };
          }
          return item;
        }
      });
    });
  };

  const updateHoursAll = (props: SelectedLabor) => {
    setSelectedLabor(
      selectedLabor?.map((item) =>
        item.account === props.account
          ? props
          : { ...item, form: { ...item.form, hours: props.form.hours } }
      )
    );
  };

  const updateHoursEmpty = (props: SelectedLabor) => {
    setSelectedLabor(() => {
      return selectedLabor?.map((item) => {
        if (item.account === props.account) {
          return props;
        } else {
          if (item.form.hours === undefined) {
            return { ...item, form: { ...item.form, hours: props.form.hours } };
          }
          return item;
        }
      });
    });
  };

  const canSubmit = !selectedLabor.some(
    (item) => item.form.rate === undefined || item.form.hours === undefined
  );

  useEffect(() => {
    contentRef.current && contentRef.current.scrollToTop();
  }, [slideIndex]);

  return (
    <>
      <AddNewItemButton title={label} onClick={() => setIsModalOpen(true)} />
      <IonModal
        onDidDismiss={() => {
          setSlideIndex(0);
          setIsModalOpen(false);
          setSelectedLabor([]);
          setSearchTerm('');
        }}
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>{label}</IonTitle>
          </IonToolbar>
          {slideIndex === 0 && (
            <IonToolbar>
              <IonSearchbar
                mode="md"
                debounce={500}
                value={searchTerm}
                onIonChange={({ target }: any) => {
                  setSearchTerm(target.value);
                  setQuery(target.value);
                }}
              />
            </IonToolbar>
          )}
        </IonHeader>

        <IonContent ref={contentRef}>
          <IonSlides pager={false} options={slideOpts} ref={slidesRef}>
            <IonSlide>
              {Boolean(slideIndex === 0) && (
                <IonList lines="full" className="ion-no-padding" style={{ width: '100%' }}>
                  {results.length > 0 ? (
                    sort(results, sortBy).map((result, i) => (
                      <SelectionItem
                        key={i}
                        detail={false}
                        lines="full"
                        title={result.name}
                        onClick={() =>
                          handleSelect({
                            account: result,
                            form: {
                              rate: result?.defaultRate?.toString(),
                              hours: undefined,
                            },
                          })
                        }
                        disabled={result.previouslyUsed}
                        selected={selectedLabor?.some(
                          (account) => account.account.id === result.id
                        )}
                      />
                    ))
                  ) : (
                    <IonItem>
                      <IonLabel>No Results</IonLabel>
                    </IonItem>
                  )}
                </IonList>
              )}
            </IonSlide>
            <IonSlide>
              <IonList lines="full" className="ion-no-padding" style={{ width: '100%' }}>
                {Boolean(selectedLabor.length) && (
                  <IonItem lines="full" detail={false} className={classes.root}>
                    <IonGrid style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                      <IonRow className="ion-align-items-center">
                        <IonCol size="6" />
                        <IonCol className="ion-text-center" size="3">
                          <IonLabel>Rate</IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-center" size="3">
                          <IonLabel>Hours</IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonButtons slot="end" style={{ visibility: 'hidden' }}>
                      <IonButton>
                        <IonIcon slot="icon-only" icon={closeIcon} size="small" />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                )}
                {selectedLabor.length > 0 ? (
                  selectedLabor?.map((selected) => (
                    <LaborForm
                      labor={selected}
                      handleUpdate={updateForm}
                      handleUpdateRateAll={updateRateAll}
                      handleUpdateRateEmpty={updateRateEmpty}
                      handleUpdateHoursAll={updateHoursAll}
                      handleUpdateHoursEmpty={updateHoursEmpty}
                      key={selected.account.id}
                    />
                  ))
                ) : (
                  <IonItem>
                    <IonLabel>No Selected Accounts Remaining</IonLabel>
                  </IonItem>
                )}
              </IonList>
            </IonSlide>
          </IonSlides>
        </IonContent>
        <IonToolbar>
          {slideIndex === 1 && (
            <>
              <IonButtons slot="start">
                <IonButton
                  fill="clear"
                  onClick={() => {
                    slidesRef.current?.slidePrev();
                    slidesRef.current?.getActiveIndex().then((index) => setSlideIndex(index));
                  }}
                >
                  <IonIcon slot="icon-only" icon={backIcon} />
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton fill="clear" disabled={!canSubmit} onClick={submit}>
                  Submit
                </IonButton>
              </IonButtons>
            </>
          )}
          {slideIndex === 0 && (
            <IonButtons slot="end">
              <IonButton
                fill="clear"
                disabled={Boolean(!selectedLabor?.length)}
                onClick={() => {
                  slidesRef.current?.slideNext();
                  slidesRef.current?.getActiveIndex().then((index) => setSlideIndex(index));
                }}
              >
                <IonIcon slot="icon-only" icon={nextIcon} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
        <UnsafeArea position="bottom" />
      </IonModal>
    </>
  );
};

export default LaborModal;
