import * as Sentry from '@sentry/browser';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

const {
  NODE_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_AWS_REGION = 'us-east-1',
  REACT_APP_COGNITO_CLIENTID,
  REACT_APP_COGNITO_USERPOOLID,
  REACT_APP_COGNITO_IDENTITY_ID,
} = process.env;

if (NODE_ENV !== 'development' && REACT_APP_SENTRY_DSN) {
  // When not initialized, the Sentry SDK methods (ie. `Sentry.captureEvent`)
  // can be safely called but do not POST to the Sentry service.
  Sentry.init({ dsn: REACT_APP_SENTRY_DSN, environment: NODE_ENV });
}

Amplify.configure({
  region: REACT_APP_AWS_REGION,
  userPoolId: REACT_APP_COGNITO_USERPOOLID,
  userPoolWebClientId: REACT_APP_COGNITO_CLIENTID,
  identityPoolId: REACT_APP_COGNITO_IDENTITY_ID,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
