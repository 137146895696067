import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import React from 'react';

interface UsePermissionsConfig {
  required?: AccountAction[];
  minRequired?: AccountAction[];
}

export const usePermissions = ({
  required = [],
  minRequired = [],
}: UsePermissionsConfig): boolean => {
  const { workspace } = useWorkspace();

  return (
    required.every((action) => workspace.actions.includes(action)) &&
    (minRequired.length ? minRequired.some((action) => workspace.actions.includes(action)) : true)
  );
};

const AuthorizeRequired: React.FC<UsePermissionsConfig> = ({ children, ...permissions }) => {
  const authorized = usePermissions(permissions);

  return <>{authorized ? children : null}</>;
};

export default AuthorizeRequired;
