import _isEqual from 'lodash.isequal';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';

export interface IsEqualOptions<T = unknown> {
  /** Explicit subset of keys to compare */
  keys?: (keyof T)[];
  nullAndUndefinedAreEquivalent?: boolean;
}

export function isEqual<T = unknown>(
  valueA: T,
  valueB: T,
  options: IsEqualOptions<T> = { keys: undefined, nullAndUndefinedAreEquivalent: true }
): boolean {
  let a: Partial<T> = valueA;
  let b: Partial<T> = valueB;

  if (options.nullAndUndefinedAreEquivalent) {
    // If they are equivalent, just drop them so they are not compared
    a = omitBy<Partial<T>>(a, isNil);
    b = omitBy<Partial<T>>(b, isNil);
  }

  if (options.keys) {
    a = pick(a, options.keys);
    b = pick(b, options.keys);
  }

  return _isEqual(a, b);
}
