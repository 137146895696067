import { Account } from './Account';
import { Organization } from './Organization';
import { Team } from './Team';

export enum MentionKind {
  User = 'user',
  Team = 'team',
  Organization = 'org',
}

export interface Mentionable
  extends Pick<Account, 'id' | 'name'>,
    Pick<Team, 'id' | 'name'>,
    Pick<Organization, 'id' | 'name'> {
  detail?: string;
  kind: MentionKind;
}

export interface Mention {
  kind: MentionKind;
  entityId: string;
  label: string;
}

export interface Comment {
  id: string;
  content: string;
  createdAt: number;
  operator: Account;
}
