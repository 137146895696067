import { IonAvatar, IonIcon } from '@ionic/react';
import defaultAvatar from 'assets/images/empty-avatar.svg';
import clsx from 'clsx';
import React, { ComponentProps } from 'react';
import { createUseStyles } from 'react-jss';

export interface IAvatar extends ComponentProps<typeof IonAvatar> {
  /**
   * Image source to display on the Avatar.
   * If both `src` and `icon` are provided, the `src` is used.
   */
  src?: string;
  /**
   * `IonIcon` icon to display on the Avatar. `<IonIcon icon={icon} />`.
   * If both `src` and `icon` are provided, the `src` is used.
   */
  icon?: string;
  /**
   * Alternate text to display for image avatars.
   * Not used with icon avatars.
   * Parsed as a person's full name for initials if no `src` or `icon` is provided.
   */
  alt?: string;
  color?: string;
  bgColor?: string;
}

const generateColor = (key: string) => {
  if (!key) {
    return;
  }

  const hashValue = [...key].reduce((acc, cur) => {
    const charCode = cur.charCodeAt(0);
    acc = (acc << 5) - acc + charCode;
    return acc & acc;
  }, 0);

  const hexValue = (hashValue & 0xfff).toString(16);
  const colorValue = hexValue.padEnd ? hexValue.padEnd(3, 'f') : hexValue;

  return [...colorValue].reduce((acc, c) => `${acc}${Math.abs(parseInt(c, 16) - 8)}`, '#');
};

const parseInitials = (input: string | undefined) => {
  if (typeof input !== 'string') return;

  const arr = input.split(' ');
  const initials = arr.length > 1 ? arr[0].charAt(0) + arr[1].charAt(0) : arr[0].substring(0, 2);
  return initials;
};

const useStyles = createUseStyles({
  avatar: ({ alt, bgColor, color, icon, src }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color,
    backgroundColor: bgColor || generateColor(src || alt),
  }),
});

const AvatarContent: React.FC<Pick<IAvatar, 'src' | 'icon' | 'alt'>> = ({ src, icon, alt }) => {
  if (src) {
    return <img src={src} alt={alt} />;
  }
  if (icon) {
    return <IonIcon icon={icon} />;
  }
  if (alt) {
    return <>{parseInitials(alt)}</>;
  }
  return <img src={defaultAvatar} alt="No Avatar Graphic" />;
};

const Avatar: React.FC<IAvatar> = ({
  className,
  src,
  icon,
  alt,
  slot = 'start',
  color = 'white',
  bgColor,
  children,
  ...inputProps
}) => {
  const classes = useStyles({ alt, color, bgColor });

  return (
    <IonAvatar slot={slot} {...inputProps} className={clsx(classes.avatar, className)}>
      {children || <AvatarContent src={src} icon={icon} alt={alt} />}
    </IonAvatar>
  );
};

export default Avatar;
