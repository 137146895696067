import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import AccountsList from './AccountsList';
import CreateAccount from './CreateAccount';
import ManageAccount from './ManageAccount';

const AccountsRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      <Route path={`${path}`} component={AccountsList} exact={true} />
      <Route path={`${path}/create`} component={CreateAccount} />
      <Route path={`${path}/manage/:id`} component={ManageAccount} />
    </IonRouterOutlet>
  );
};

export { AccountsRoutes, CreateAccount, AccountsList };
