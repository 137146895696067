import { Plugins } from '@capacitor/core';
import { JSX } from '@ionic/core/components';
import {
  IonActionSheet,
  IonAlert,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
  isPlatform,
} from '@ionic/react';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import { Attachment } from 'interfaces/Attachment';
import {
  document as fileIcon,
  image as photoIcon,
  trashBinOutline as deleteIcon,
} from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { prettyDate } from 'utils/prettyDate';

const { Browser } = Plugins;

const isMobile = isPlatform('mobile');

const useStyles = createUseStyles({
  item: {
    '& h3': {
      marginBottom: 6,
    },
    '& p': {
      marginBottom: 0,
    },
  },
  date: {
    '--padding-start': 'var(--ion-margin, 16px)',
    '.sc-ion-label-md-s &': {
      // Match top margin on `.sc-ion-label-md-s h3` to align baselines
      marginTop: 2,
    },
  },
  content: {
    whiteSpace: 'pre-wrap !important',
    wordBreak: 'break-all',
  },
});

type IonItemProps = Omit<JSX.IonItem, 'button' | 'detail' | 'onClick'>;

interface AttachmentItemProps extends IonItemProps {
  attachment: Pick<
    Attachment,
    'id' | 'attachmentType' | 'createdAt' | 'operator' | 'name' | 'urls'
  >;
  /** User is allowed to delete this item. Default: false */
  deleteAllowed?: boolean;
  className?: string;
  /* Callback to delete the attachment item by id */
  onDelete: (id: string) => void;
}

const AttachmentItem: React.FC<AttachmentItemProps> = ({
  attachment,
  deleteAllowed = false,
  className,
  onDelete,
  ...ionItemProps
}) => {
  const classes = useStyles();

  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { id, attachmentType, createdAt, operator, name, urls } = attachment;

  const icon = attachmentType === 'document' ? fileIcon : photoIcon;

  const deleteHeader = `Would you like to permanently delete ${name}?`;

  /**
   * Cleanup after delete confirmation is dismissed.
   * Reset state to show confirmation and close item slide.
   */
  const deleteDismiss = () => {
    if (itemSlidingRef.current) {
      itemSlidingRef.current.close();
    }
    setShowConfirmDelete(false);
  };

  const deleteButtons = [
    { text: 'Cancel', role: 'cancel' },
    { text: 'Delete', role: 'destructive', handler: () => onDelete(id) },
  ];

  return (
    <>
      <IonItemSliding ref={itemSlidingRef} disabled={!deleteAllowed}>
        <IonItemOptions side="start">
          <IonItemOption color="danger" onClick={() => setShowConfirmDelete(true)}>
            <IonIcon slot="icon-only" icon={deleteIcon} />
          </IonItemOption>
        </IonItemOptions>

        <IonItem
          {...ionItemProps}
          className={clsx(classes.item, className)}
          button={true}
          detail={false}
          onClick={() => Browser.open({ url: urls.raw })}
        >
          <Avatar alt={icon} icon={icon} slot="start" />
          <IonLabel style={{ marginInline: 0 }}>
            <IonNote
              className={clsx('ion-float-right', classes.date)}
              style={{ paddingLeft: 'var(--ion-margin, 16px)', fontSize: '0.875rem' }}
            >
              {prettyDate(createdAt)}
            </IonNote>
            {operator?.name && <h3>{operator.name}</h3>}
            <p className={classes.content}>{name}</p>
          </IonLabel>
        </IonItem>
      </IonItemSliding>

      {/* Confirm delete. Show alert on desktop, action sheet on mobile. */}
      <IonAlert
        isOpen={showConfirmDelete && !isMobile}
        onDidDismiss={deleteDismiss}
        header="Delete"
        message={deleteHeader}
        buttons={deleteButtons}
      />
      <IonActionSheet
        isOpen={showConfirmDelete && isMobile}
        header={deleteHeader}
        onDidDismiss={deleteDismiss}
        buttons={deleteButtons}
      />
    </>
  );
};

export default AttachmentItem;
