import { isPlatform, useIonActionSheet, useIonAlert } from '@ionic/react';
import { useCallback } from 'react';

export interface ConfirmActionProps {
  header: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  destructive?: boolean;
  handler?: () => void;
}

export const useConfirmAction = ({
  header,
  message,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  destructive = false,
  handler,
}: ConfirmActionProps) => {
  const [presentActionSheet, dismissActionSheet] = useIonActionSheet();
  const [presentAlert, dismissAlert] = useIonAlert();

  const present = useCallback(() => {
    isPlatform('ios')
      ? presentActionSheet({
          header: message,
          buttons: [
            {
              text: confirmText,
              role: destructive ? 'destructive' : undefined,
              handler,
            },
            { text: cancelText, role: 'cancel' },
          ],
        })
      : presentAlert({
          header,
          message,
          buttons: [
            { text: cancelText, role: 'cancel' },
            { text: confirmText, handler },
          ],
        });
  }, [
    cancelText,
    confirmText,
    destructive,
    handler,
    header,
    message,
    presentActionSheet,
    presentAlert,
  ]);

  const dismiss = useCallback(() => {
    isPlatform('ios') ? dismissActionSheet() : dismissAlert();
  }, [dismissActionSheet, dismissAlert]);

  return [present, dismiss];
};
