import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import React from 'react';

import { useStyles } from './AccountCard';

interface AccountCardSkeletonProps {
  hasMultipleActions?: boolean;
}

const AccountCardSkeleton: React.FC<AccountCardSkeletonProps> = ({
  hasMultipleActions = false,
}) => {
  const classes = useStyles();

  return (
    <IonCard>
      <IonItem lines="none">
        <IonAvatar>
          <IonSkeletonText animated={true} />
        </IonAvatar>
        <IonLabel className="ion-margin-horizontal">
          <h3 className={classes.name}>
            <IonSkeletonText style={{ width: '80%' }} animated={true} />
          </h3>
          <p className={classes.recipient}>
            <IonSkeletonText style={{ width: '60%' }} animated={true} />
          </p>
        </IonLabel>
      </IonItem>

      <IonCardContent className={classes.content}>
        <IonItem lines="none">
          <IonSkeletonText
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            animated={true}
            slot="start"
          />
          <IonLabel>
            <h3>
              <IonSkeletonText style={{ width: '60%' }} animated={true} />
            </h3>
            <p>
              <IonSkeletonText style={{ width: '80%' }} animated={true} />
            </p>
          </IonLabel>
          <IonSkeletonText
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            animated={true}
          />
        </IonItem>
        <IonItem lines="none">
          <IonSkeletonText
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            animated={true}
            slot="start"
          />
          <IonLabel>
            <h3>
              <IonSkeletonText style={{ width: '50%' }} animated={true} />
            </h3>
            <p>
              <IonSkeletonText style={{ width: '80%' }} animated={true} />
            </p>
          </IonLabel>
          <IonSkeletonText
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            animated={true}
          />
        </IonItem>
        <IonItem lines="none">
          <IonSkeletonText
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            animated={true}
            slot="start"
          />
          <IonLabel>
            <h3>
              <IonSkeletonText style={{ width: '50%' }} animated={true} />
            </h3>
            <p>
              <IonSkeletonText style={{ width: '80%' }} animated={true} />
            </p>
          </IonLabel>
          <IonSkeletonText
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            animated={true}
          />
        </IonItem>
      </IonCardContent>

      <IonGrid className={classes.actions}>
        <IonRow>
          {hasMultipleActions ? (
            <>
              <IonCol size="6">
                <IonButton fill="solid" color="medium" expand="full" disabled={true}>
                  <IonSkeletonText
                    style={{ width: '35%', backgroundColor: 'var(--ion-color-light-tint)' }}
                    animated={true}
                  />
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton fill="solid" color="medium" expand="full" disabled={true}>
                  <IonSkeletonText
                    style={{ width: '35%', backgroundColor: 'var(--ion-color-light-tint)' }}
                    animated={true}
                  />
                </IonButton>
              </IonCol>
            </>
          ) : (
            <IonCol size="12">
              <IonButton fill="solid" color="medium" expand="full" disabled={true}>
                <IonSkeletonText
                  style={{ width: '50%', backgroundColor: 'var(--ion-color-light-tint)' }}
                  animated={true}
                />
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default AccountCardSkeleton;
