import { useQuery } from '@apollo/client';
import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonNote,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import GridList from 'components/GridList';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import { useHistoryPersist } from 'hooks/useHistoryPersist';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { AccountKind } from 'interfaces/AccountKind';
import { AccountStatus } from 'interfaces/AccountStatus';
import { add } from 'ionicons/icons';
import sort from 'lodash.sortby';
import {
  GET_ORGANIZATION_ACCOUNTS,
  GetOrganizationAccountsResponse,
  ListAccount,
} from 'pages/Accounts/graphql';
import React, { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useRouteMatch } from 'react-router-dom';

import AccountDisplay from './components/AccountDisplay';

const searchKeys = ['name', 'email', 'phone'];

const useStyles = createUseStyles({
  card: {
    '&.ios': {
      minHeight: '192px',
    },
    '&.md': {
      minHeight: '197px',
    },
  },
  list: {
    paddingTop: 0,
  },
});

interface GroupedAccounts {
  user: ListAccount[];
  virtual: ListAccount[];
  deactivated: ListAccount[];
}

const initialAccounts: GroupedAccounts = {
  user: [],
  virtual: [],
  deactivated: [],
};

const groupAccounts = (accounts: ListAccount[]): GroupedAccounts => {
  return accounts.reduce((acc, account) => {
    if (account.status === AccountStatus.Deactivated) {
      return { ...acc, deactivated: [...acc.deactivated, account] };
    }

    if (account.kind === AccountKind.User) {
      return { ...acc, user: [...acc.user, account] };
    }

    if (account.kind === AccountKind.Virtual) {
      return { ...acc, virtual: [...acc.virtual, account] };
    }

    return acc;
  }, initialAccounts);
};

const AccountsList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [params, setParams] = useHistoryPersist<{ q?: string }>();

  const { workspace } = useWorkspace();

  const { addMessage } = useMessages();

  const { data, loading, refetch } = useQuery<GetOrganizationAccountsResponse>(
    GET_ORGANIZATION_ACCOUNTS,
    {
      displayName: 'AccountsList',
      variables: { organizationId: workspace.organizationId },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      partialRefetch: true,
      onError: () => addMessage('Unable to load accounts.', 'danger'),
    }
  );

  const { results: searchedAccounts, setQuery, query } = useFuzzySearch({
    list: data?.accounts.nodes || [],
    options: { keys: searchKeys, threshold: 0.2 },
    term: params.q,
  });

  const groupedAccounts = useMemo<GroupedAccounts>(() => {
    if (!searchedAccounts) {
      return initialAccounts;
    }
    const sorted = sort(searchedAccounts, 'name');
    const grouped = groupAccounts(sorted);
    return grouped;
  }, [searchedAccounts]);

  useEffect(() => {
    setParams({
      q: query?.length ? query : undefined,
    });
  }, [query, setParams]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Accounts</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            mode="md"
            style={{ '--border-radius': '100px' }}
            debounce={500}
            value={query}
            onIonChange={({ target }: any) => setQuery(target.value)}
          />
        </IonToolbar>
        {query && (
          <IonToolbar style={{ '--min-height': 0 }}>
            <IonTitle
              className="ion-text-start"
              size="small"
              color="medium"
              style={{
                paddingTop: 0,
                paddingBottom: isPlatform('ios') ? 12 : 'var(--ion-margin, 16px)',
                fontSize: '0.875rem',
              }}
            >
              {`${searchedAccounts.length} match${
                searchedAccounts.length !== 1 ? 'es' : ''
              } for search "${query}"`}
            </IonTitle>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent style={{ '--padding-bottom': '72px' }}>
        <PullToRefresh onRefresh={refetch} />

        <AuthorizeRequired required={[AccountAction.ListAccounts, AccountAction.GetAccount]}>
          <IonList className={classes.list}>
            <ListSeparator />
            <IonItemDivider color="light" mode="md" sticky={true}>
              <IonLabel>
                <h2>User Accounts</h2>
              </IonLabel>
            </IonItemDivider>

            <GridList<ListAccount>
              keyName="id"
              items={groupedAccounts.user || []}
              loading={loading}
              renderListItem={(props) => (
                <AccountDisplay
                  account={props.value}
                  routerLink={`${url}/manage/${props.value.id}`}
                />
              )}
            />

            {groupedAccounts.virtual.length > 0 && (
              <>
                <ListSeparator />
                <IonItemDivider color="light" mode="md" sticky={true}>
                  <IonLabel>
                    <h2>Virtual Accounts</h2>
                  </IonLabel>
                </IonItemDivider>

                <IonListHeader mode="md">
                  <IonNote style={{ paddingTop: 8, lineHeight: 1.5 }}>
                    Virtual accounts can be used to track work for staff members without a user
                    account
                  </IonNote>
                </IonListHeader>

                <GridList<ListAccount>
                  keyName="id"
                  items={groupedAccounts.virtual || []}
                  loading={loading}
                  renderListItem={(props) => (
                    <AccountDisplay
                      account={props.value}
                      routerLink={`${url}/manage/${props.value.id}`}
                    />
                  )}
                />
              </>
            )}

            {groupedAccounts.deactivated.length > 0 && (
              <>
                <ListSeparator />
                <IonItemDivider color="light" mode="md" sticky={true}>
                  <IonLabel>
                    <h2>Deactivated Accounts</h2>
                  </IonLabel>
                </IonItemDivider>

                <GridList<ListAccount>
                  keyName="id"
                  items={groupedAccounts.deactivated || []}
                  loading={loading}
                  renderListItem={(props) => (
                    <AccountDisplay
                      className={classes.card}
                      account={props.value}
                      routerLink={`${url}/manage/${props.value.id}`}
                    />
                  )}
                />
              </>
            )}
          </IonList>
        </AuthorizeRequired>

        <AuthorizeRequired
          required={[AccountAction.CreateAccount, AccountAction.CreateVirtualAccount]}
        >
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => history.push(`${url}/create`)}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </AuthorizeRequired>
      </IonContent>
    </IonPage>
  );
};

export default AccountsList;
