import {
  IonBackdrop,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { MapStyles } from 'interfaces/Basemaps';
import { WorkOrder } from 'interfaces/WorkOrder';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React, { RefObject, useEffect, useMemo, useState } from 'react';

import Carousel, { CarouselItem } from './Carousel';
import ImageGrid, { Image as ImageGridImage } from './ImageGrid';

type Item = WorkOrder;

interface DetailsPhotoProps {
  item: Pick<Item, 'attachments' | 'location'>;
  mapStyles: MapStyles;
  /** Use ref to page element to get card style modals in iOS */
  pageRef?: RefObject<HTMLElement>;
}

const { REACT_APP_MAPBOX_TOKEN = '' } = process.env;

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

/**
 * Generate static map image for a work order location.
 * @param height Must be less than 1280. https://docs.mapbox.com/api/maps/static-images/#static-images-api-errors.
 * @param width Must be less than 1280. https://docs.mapbox.com/api/maps/static-images/#static-images-api-errors.
 */
const mapImage = (
  location: Item['location'],
  height: number,
  width: number,
  retina = false,
  dark = false,
  mapStyles: Pick<MapStyles, 'light' | 'dark'>
) => {
  // https://docs.mapbox.com/api/maps/static-images

  if (!location) return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D'; // empty image

  const primary = getComputedStyle(document.documentElement)
    .getPropertyValue('--ion-color-primary')
    .replace('#', '')
    .trim();

  const tint = getComputedStyle(document.documentElement)
    .getPropertyValue('--ion-color-primary-tint')
    .replace('#', '')
    .trim();

  const mapTheme: { style: string; marker: string } = dark
    ? { style: mapStyles.dark, marker: primary }
    : { style: mapStyles.light, marker: tint };

  const marker = `pin-s+${mapTheme.marker}(${location.longitude},${location.latitude})`;
  const position = `${location.longitude},${location.latitude},12,0.00,0.00`;
  const size = `${width}x${height}${retina ? '@2x' : ''}`;
  const options = `access_token=${REACT_APP_MAPBOX_TOKEN}&attribution=false&logo=false`;

  return `https://api.mapbox.com/styles/v1/${mapTheme.style}/static/${marker}/${position}/${size}?${options}`;
};

const DetailsPhoto: React.FC<DetailsPhotoProps> = ({
  item: { attachments, location },
  mapStyles,
  pageRef,
}) => {
  const [isDarkTheme, setIsDarkTheme] = useState(prefersDark.matches);
  const [modalState, setModalState] = useState<{ isOpen: boolean; initialItem?: number }>({
    isOpen: false,
  });
  const [activeCarouselItem, setActiveCarouselItem] = useState<number | undefined>();

  useEffect(() => {
    const onMediaChange = (e: any) => setIsDarkTheme(e.matches);
    prefersDark.addListener(onMediaChange);
    return () => prefersDark.removeListener(onMediaChange);
  }, []);

  useEffect(() => {
    // Close modal when attachments change.
    // Adding a new photo to the carousel while open throws exception.
    // Issue could likely also be avoided by better handling updates in the carousel.
    setModalState({ isOpen: false });
  }, [attachments]);

  /** Transform attachment data to formatted used by ImageGrid and Carousel */
  const [gridImages, carouselItems] = useMemo<[ImageGridImage[], CarouselItem[]]>((): [
    ImageGridImage[],
    CarouselItem[]
  ] => {
    const mapGridImage = (loc: NonNullable<Item['location']>): ImageGridImage => ({
      src: mapImage(loc, 480, 1280, false, isDarkTheme, mapStyles),
      srcSet: `${mapImage(loc, 480, 1280, true, isDarkTheme, mapStyles)} 2x`,
      alt: 'Location map',
      style: {
        height: 480,
        width: 1200,
        objectFit: 'none',
        backgroundColor: isDarkTheme ? '#343332' : '#D6DAE1',
      },
    });

    const mapCarouselItem = (loc: NonNullable<Item['location']>): CarouselItem => ({
      kind: 'MAP',
      key: 'map',
      name: loc.address,
      location: [loc.longitude, loc.latitude],
      mapStyles,
    });

    const photos = attachments.filter((a) => a.attachmentType === 'photo');

    return photos.reduce<[ImageGridImage[], CarouselItem[]]>(
      (acc, photo) => [
        [...acc[0], { src: photo.urls.small, srcSet: `${photo.urls.small2X} 2x`, alt: '' }],
        [...acc[1], { kind: 'IMAGE', key: photo.id, name: photo.name, source: photo.urls.large2X }],
      ],
      // Initialize with static map and interactive map data if a location is defined.
      // Otherwise, initialize with empty arrays to exclude maps
      location ? [[mapGridImage(location)], [mapCarouselItem(location)]] : [[], []]
    );
  }, [location, attachments, isDarkTheme, mapStyles]);

  return (
    <React.Fragment>
      <ImageGrid
        images={gridImages}
        limit={3}
        onImageSelect={(index) => {
          setModalState({ isOpen: true, initialItem: index });
        }}
      />
      <IonModal
        isOpen={modalState.isOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
        onDidDismiss={() => setModalState({ isOpen: false })}
      >
        <React.Fragment>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setModalState({ isOpen: false, initialItem: 0 })}>
                  <IonIcon slot="icon-only" icon={closeIcon} />
                </IonButton>
              </IonButtons>
              <IonTitle>
                {activeCarouselItem !== undefined ? carouselItems[activeCarouselItem].name : ''}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent scrollY={false}>
            <IonBackdrop tappable={false} style={{ opacity: 0.84, zIndex: 0 }} />
            <Carousel
              items={carouselItems}
              options={{ initialItemIndex: modalState.initialItem }}
              onActiveItemChange={setActiveCarouselItem}
            />
          </IonContent>
        </React.Fragment>
      </IonModal>
    </React.Fragment>
  );
};

export default DetailsPhoto;
