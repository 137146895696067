import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import ListSeparator from 'components/ListSeparator';
import { closeOutline as closeIcon, cogOutline as optionsIcon } from 'ionicons/icons';
import React, { RefObject, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { PieChart } from 'recharts';
import PdfService from 'services/PdfService';

import { CreateDocumentConfig, createDocument } from './createDocument';
import { init, reducer } from './reducer';

export enum Section {
  CategorySummary = 'CATEGORY_SUMMARY',
  WorkOrdersMap = 'WORKORDERS_MAP',
  WorkOrdersList = 'WORKORDERS_LIST',
}

export interface PdfGeneratorProps
  extends Pick<
    CreateDocumentConfig,
    | 'fromDate'
    | 'toDate'
    | 'categories'
    | 'workorders'
    | 'workOrderCount'
    | 'averageLabor'
    | 'averageDuration'
  > {
  isOpen: boolean;
  pieChartRef: RefObject<PieChart>;
  pageRef?: RefObject<HTMLElement>;
  onDismiss: () => void;
  onWillDownload: () => void;
  onDidDownload: () => void;
  onFailure: (error: string) => void;
}

const PdfGenerator: React.FC<PdfGeneratorProps> = ({
  isOpen,
  fromDate,
  toDate,
  categories,
  workorders,
  workOrderCount,
  averageLabor,
  averageDuration,
  pieChartRef,
  pageRef,
  onDismiss,
  onWillDownload,
  onDidDownload,
  onFailure,
}) => {
  const [{ options }, dispatch] = useReducer(reducer, init());

  const isVisible = (section: Section) => {
    return options[section].visible;
  };

  const generatePdf = () => {
    onWillDownload();

    const s = new XMLSerializer();
    const pieChartNode = ReactDOM.findDOMNode(pieChartRef.current);
    const pieChartSvg = pieChartNode && s.serializeToString(pieChartNode.firstChild as ChildNode);

    const docDefinition = createDocument({
      fromDate,
      toDate,
      categories,
      options,
      pieChartSvg,
      workorders,
      workOrderCount,
      averageLabor,
      averageDuration,
    });

    PdfService.open({
      docDefinition,
      fileName: 'Work Summary Report',
      onComplete: (success, error) => {
        onDidDownload();
        !success && onFailure(error as string);
      },
    });

    onDismiss();
  };

  return (
    <IonModal
      isOpen={isOpen}
      presentingElement={pageRef?.current || undefined}
      swipeToClose={true}
      onDidDismiss={onDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" onClick={onDismiss}>
            <IonButton>
              <IonIcon icon={closeIcon} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Work Summary Report Download</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <ListSeparator />

        <IonList className="ion-no-padding">
          <IonItemDivider mode="md" sticky={true}>
            <IonIcon icon={optionsIcon} slot="start" />
            <IonLabel>
              <h2>PDF Options</h2>
            </IonLabel>
          </IonItemDivider>
          <IonItem lines="full">
            <IonLabel>Include Category Summary</IonLabel>
            <IonToggle
              checked={isVisible(Section.CategorySummary)}
              onIonChange={() =>
                dispatch({
                  type: 'TOGGLE_VISIBILITY',
                  payload: { section: Section.CategorySummary },
                })
              }
            />
          </IonItem>
          <IonItem>
            <IonLabel>Include Work Orders Map</IonLabel>
            <IonToggle
              checked={isVisible(Section.WorkOrdersMap)}
              onIonChange={() =>
                dispatch({ type: 'TOGGLE_VISIBILITY', payload: { section: Section.WorkOrdersMap } })
              }
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>Include Work Orders List</IonLabel>
            <IonToggle
              checked={isVisible(Section.WorkOrdersList)}
              onIonChange={() =>
                dispatch({
                  type: 'TOGGLE_VISIBILITY',
                  payload: { section: Section.WorkOrdersList },
                })
              }
            />
          </IonItem>
          {/* <IonItem lines="full">
            <IonLabel color="light">Include Heat Map</IonLabel>
          </IonItem> */}
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar color="primary" className="ion-no-padding">
          <IonButton
            className="ion-no-margin"
            expand="full"
            color="primary"
            size="large"
            onClick={generatePdf}
          >
            Download
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default PdfGenerator;
