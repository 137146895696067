import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonPopover,
} from '@ionic/react';
import AssigneesSearchModal from 'components/AssigneesSearchModal';
import Avatar from 'components/Avatar';
import BackButton from 'components/BackButton';
import InteractiveMap from 'components/InteractiveMap';
import { images, layers } from 'components/InteractiveMap/assetSymbology';
import { MagicField, MagicForm } from 'components/MagicForm';
import SearchModal from 'components/SearchModal';
import SelectionItem from 'components/SelectionItem';
import TagsField from 'components/Tags';
import UnsafeArea from 'components/UnsafeArea';
import { parseAddress } from 'hooks/useLocation';
import { useMapStyles } from 'hooks/useMapStyles';
import { useMessages } from 'hooks/useMessages';
import { UseReportersResult, useReporters } from 'hooks/useReporters';
import { useWorkOrderCreator } from 'hooks/useWorkOrderCreator';
import { useWorkspace } from 'hooks/useWorkspace';
import { Account } from 'interfaces/Account';
import { MapContext } from 'interfaces/Basemaps';
import { Category } from 'interfaces/Category';
import { Team } from 'interfaces/Team';
import {
  mailOutline as emailIcon,
  personCircleOutline as nameIcon,
  phonePortraitOutline as phoneIcon,
} from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { formatPhone } from 'utils/formatPhone';
import { prettyPhone } from 'utils/prettyPhone';

type CategoryAssignment = Pick<Category, 'id' | 'name'>;
type TeamAssignment = Pick<Team, 'id' | 'name'>;
type AssigneeAssignment = Pick<Account, 'id' | 'name' | 'email'>;
const initialCategory = { id: '', name: 'Select Category' };
const initialTeam = { id: '', name: 'Select Team' };
const initialAssignee = { id: '', name: 'Select Assignee', email: '' };

const useStyles = createUseStyles({
  mapDivider: {
    boxShadow: '0 -4px 16px rgba(0, 0, 0, 0.12)',
  },
  searchModalItem: {
    border: '1px solid var(--ion-color-light)',
  },
  itemDivider: {
    '--padding-start': 0,
    '--background': 'var(--ion-background-color, #fff)',
  },
  popover: {
    '& .popover-content': { marginLeft: -60 },
  },
});

type Reporter = Omit<UseReportersResult['reporters'][0], 'id'>;

const ReporterOptionsPopover: React.FC<{
  items: Reporter[];
  onSelect: (item: Reporter) => void;
}> = ({ items, onSelect }) => (
  <React.Fragment>
    {items.map((item, i) => (
      <IonItem
        key={i}
        button={true}
        lines={i === items.length - 1 ? 'none' : 'full'}
        onClick={() => onSelect(item)}
        // Prevent blur event on input before selection
        onMouseDown={(e) => e.preventDefault()}
      >
        <Avatar alt={item.name} slot="start" />
        <IonLabel>
          <h3>{item.name}</h3>
          <p>
            {item.email}
            {item.phone && ` ・ ${prettyPhone(item.phone)}`}
          </p>
        </IonLabel>
      </IonItem>
    ))}
  </React.Fragment>
);

const ProvideDetails: React.FC = () => {
  const classes = useStyles();

  const pageRef = useRef<HTMLElement>(null); // Bind to modals for card style display

  const { workspace } = useWorkspace();
  const { state }: any = useLocation();
  const history = useHistory();

  const mapStyles = useMapStyles({
    organizationId: workspace.organizationId,
    context: MapContext.WorkOrderCreation,
  });

  const { addMessage } = useMessages();
  const { submitWorkOrder } = useWorkOrderCreator();

  const [category, setCategory] = useState<CategoryAssignment>(initialCategory);
  const [team, setTeam] = useState<TeamAssignment>(initialTeam);
  const [assignee, setAssignee] = useState<AssigneeAssignment>(initialAssignee);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reporter, setReporter] = useState<Reporter>({ name: '' });
  const [reporterFocus, setReporterFocus] = useState(false);
  const [tags, setTags] = useState<string[]>([]);

  const { reporters } = useReporters(workspace.organizationId, {
    query: reporter?.name,
    size: 3,
    skip: !reporter?.name,
  });

  const [showReporterOptions] = useIonPopover(ReporterOptionsPopover, {
    items: (reporterFocus && reporters) || [],
    onSelect: ({ name, email, phone, secondaryPhone }: Reporter) => {
      setReporter({
        name: name || '',
        email: email || undefined,
        phone: phone || undefined,
        secondaryPhone: secondaryPhone || undefined,
      });
      setReporterFocus(false);
    },
  });

  if (!state) {
    return <Redirect to="/workorders" />;
  }

  const { address, longitude, latitude, asset } = state;

  const onSubmit = async (data: any) => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);

      const record = await submitWorkOrder({
        ...data,
        address,
        latitude,
        longitude,
        categoryId: category.id || undefined,
        organizationId: workspace.organizationId,
        teamId: team.id || undefined,
        assigneeId: assignee.id || undefined,
        reporter: reporter?.name
          ? {
              name: reporter.name,
              email: reporter.email || undefined,
              phone: reporter.phone ? formatPhone(reporter.phone) : undefined,
              secondaryPhone: reporter.secondaryPhone
                ? formatPhone(reporter.secondaryPhone)
                : undefined,
            }
          : undefined,
        tags,
        assets: asset
          ? [
              {
                id: asset.id,
                name: asset.name || '',
                assetType: asset.assetType,
                location: {
                  geometry: asset.location.geometry,
                  description: asset.location.description || '',
                },
              },
            ]
          : undefined,
      });

      setTimeout(() => {
        setIsSubmitting(false);
        history.push(`/workorders/details/${record.id}?new=true`);
      }, 3000);
    } catch (err) {
      addMessage((err as { message: string }).message, 'danger');
      setIsSubmitting(false);
      return;
    }
  };

  const { teams, assignees, categories } = workspace;

  const { userAssignees, virtualAssignees } = assignees.reduce(
    (acc, item) => {
      if (item.kind === 'User') acc.userAssignees.push(item);
      if (item.kind === 'Virtual') acc.virtualAssignees.push(item);
      return acc;
    },
    { userAssignees: [] as Account[], virtualAssignees: [] as Account[] }
  );

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref="/workorders" />
          </IonButtons>
          <IonTitle>Create Work Order</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <IonLoading isOpen={isSubmitting} />
          <div style={{ flex: '0 0 auto' }}>
            <InteractiveMap
              mapStyles={mapStyles}
              initialPosition={{ center: [longitude, latitude], zoom: 14 }}
              focusLocation={{ center: [longitude, latitude] }}
              style={{ height: '25vh', maxHeight: 200, width: '100%' }}
              isMoveable={true}
              geoJSON={
                asset
                  ? {
                      features: [
                        {
                          type: 'Feature',
                          geometry: JSON.parse(asset.location?.geometry),
                          properties: { assetType: asset.assetType },
                        },
                      ],
                      layers,
                      images,
                      selectable: false,
                    }
                  : undefined
              }
              moveFocusLocationWithMap={false}
              renderLoadingDisplay={(isLoading) => <IonLoading isOpen={isLoading} />}
            />
            <IonItem lines="full" color="light" className={classes.mapDivider}>
              {Boolean(asset) ? (
                <React.Fragment>
                  <IonLabel>
                    {[
                      parseAddress(address),
                      `${asset.assetType}${Boolean(asset.name) ? ' (' + asset.name + ')' : ''}`,
                    ]
                      .filter(Boolean)
                      .join(' · ')}
                  </IonLabel>
                </React.Fragment>
              ) : (
                <IonLabel>{parseAddress(address)}</IonLabel>
              )}
            </IonItem>
          </div>

          <MagicForm
            title="Create Work Order"
            checkDirty={true}
            onSubmit={onSubmit}
            submitText="Create"
            style={{ flex: '1 1 0%', overflow: 'auto' }}
            defaultValues={
              category.name !== initialCategory.name ? { summary: category.name } : undefined
            }
          >
            <SearchModal<CategoryAssignment>
              pageRef={pageRef}
              label="Select Category"
              items={categories}
              onSelect={(props) => setCategory(props)}
              value={category}
              renderDisplayItem={({ onClick, value }) => (
                <SelectionItem
                  className={classes.searchModalItem}
                  lines="none"
                  title={value.name}
                  onClick={onClick}
                />
              )}
              renderSearchItem={({ value, onClick }) => (
                <SelectionItem title={value.name} onClick={onClick} />
              )}
            />

            <MagicField
              name="summary"
              fieldType="text"
              label="Title"
              placeholder="Brief work order title"
              rules={{
                required: 'You must provide a title',
              }}
            />

            <MagicField
              name="description"
              fieldType="text"
              label="Description"
              placeholder="Describe the work to perform"
              multiline={true}
              rules={{
                required: 'You must provide a description',
              }}
            />

            <SearchModal<TeamAssignment>
              pageRef={pageRef}
              keys={['name']}
              label="Select Team"
              items={teams}
              onSelect={(props) => setTeam(props)}
              value={team}
              renderDisplayItem={({ onClick, value }) => (
                <SelectionItem
                  className={classes.searchModalItem}
                  lines="none"
                  title={value.name}
                  onClick={onClick}
                />
              )}
              renderSearchItem={({ value, onClick }) => (
                <SelectionItem title={value.name} onClick={onClick} />
              )}
            />

            <AssigneesSearchModal<AssigneeAssignment>
              pageRef={pageRef}
              keys={['name', 'email']}
              label="Select Assignee"
              items={userAssignees}
              itemsSecondary={virtualAssignees}
              onSelect={setAssignee}
              value={assignee}
              renderSubtitle={() => (
                <IonItemDivider color="light" mode="md" sticky={true}>
                  <IonLabel>
                    <h2>User Accounts</h2>
                  </IonLabel>
                </IonItemDivider>
              )}
              renderSubtitleSecondary={() => (
                <IonItemDivider color="light" mode="md" sticky={true}>
                  <IonLabel>
                    <h2>Virtual Accounts</h2>
                  </IonLabel>
                </IonItemDivider>
              )}
              renderDisplayItem={({ onClick, value }) => (
                <SelectionItem
                  className={classes.searchModalItem}
                  lines="none"
                  title={value.name}
                  subtitle={value.email}
                  onClick={onClick}
                />
              )}
              renderSearchItem={({ value, onClick }) => (
                <SelectionItem title={value.name} subtitle={value.email} onClick={onClick} />
              )}
            />

            <IonItem className={classes.itemDivider} lines="none" mode="md">
              <IonLabel>
                <h2>Resident</h2>
              </IonLabel>
            </IonItem>

            <MagicField
              name="reporter.name"
              icon={nameIcon}
              placeholder="Resident Name"
              value={reporter.name || undefined}
              onIonChange={(e: any) => {
                if (!e.target) return;

                setReporter((prev) => ({
                  ...prev,
                  name: ((e.target as unknown) as { value: string }).value || '',
                }));

                // Only open on change and leave open
                // When focus is lost, popover remains open but empty array is passed as items.
                // Previously closed popover on blur, but this caused focus issues on form inputs.
                showReporterOptions({
                  mode: 'md',
                  event: e,
                  showBackdrop: false,
                  keyboardClose: false, // Input will retain focus https://forum.ionicframework.com/t/how-to-show-a-popover-while-retaining-focus/213621/3
                  cssClass: classes.popover,
                });
                setReporterFocus(true);
              }}
              onIonBlur={() => setReporterFocus(false)}
            />

            <MagicField
              name="reporter.email"
              type="email"
              inputmode="text"
              placeholder="Resident Email Address"
              value={reporter.email || undefined}
              icon={emailIcon}
              onIonChange={(e: any) => {
                if (!e.target) return;

                setReporter((prev) => ({
                  ...prev,
                  email: ((e.target as unknown) as { value: string }).value || undefined,
                }));
              }}
            />

            <MagicField
              name="reporter.phone"
              fieldType="phone"
              placeholder="Resident Phone Number"
              value={reporter.phone || undefined}
              icon={phoneIcon}
              // `onIonChange` not firing for phone field types
              // `onChange` looks to work as expected
              onChange={(phone: string) => {
                setReporter((prev) => ({ ...prev, phone }));
              }}
            />

            <MagicField
              name="reporter.secondaryPhone"
              fieldType="phone"
              placeholder="Secondary Phone Number"
              value={reporter.secondaryPhone || undefined}
              icon={phoneIcon}
              onChange={(secondaryPhone: string) => {
                setReporter((prev) => ({ ...prev, secondaryPhone }));
              }}
            />

            <IonItem
              className={classes.itemDivider}
              lines="none"
              mode="md"
              style={{ marginTop: 'var(--ion-margin, 16px)' }}
            >
              <IonLabel>
                <h2>Tags</h2>
              </IonLabel>
            </IonItem>

            <div style={{ marginTop: 'var(--ion-margin, 16px)' }}>
              <TagsField tags={tags} setTags={setTags} />
            </div>
          </MagicForm>
        </div>
      </IonContent>
      <UnsafeArea position="bottom" color="primary" />
    </IonPage>
  );
};

export default ProvideDetails;
