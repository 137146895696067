import axios from 'axios';
import { Location } from 'interfaces/Location';

const {
  // tslint:disable-next-line: variable-name
  REACT_APP_MAPBOX_TOKEN: access_token,
  REACT_APP_MAPBOX_GEOCODE_ENDPOINT: baseUrl,
} = process.env;

export interface GeocodeOptions {
  country?: string;
  proximity?: [number, number];
  types?: string;
  limit?: number;
}

export class GeocoderService {
  searchByType = (type: string, features: any[] = []) => {
    const feature = features.filter((feat: any) => feat.place_type.includes(type));
    return features.length > 0 ? feature[0] : false;
  };

  reverse = (
    longitude: number,
    latitude: number,
    { country = 'US' }: GeocodeOptions = {}
  ): Promise<Location> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`${baseUrl}/${longitude},${latitude}.json`, {
          params: {
            access_token,
            country,
          },
        });

        const { data } = response;
        const { features } = data;
        const feature = this.searchByType('address', features);

        const result = {
          address: 'Address not available for this location',
          latitude,
          longitude,
        };

        if (feature) {
          result.address = feature.place_name;
        }

        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  };

  forward = (
    query: string,
    { limit = 5, proximity, country = 'US', types = 'address' }: GeocodeOptions = {}
  ): Promise<Location[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (query.length > 0) {
          const response = await axios.get(`${baseUrl}/${query}.json`, {
            params: {
              access_token,
              country,
              proximity: proximity && `${proximity[0]},${proximity[1]}`,
              types,
              limit,
            },
          });

          const { data } = response;
          const { features } = data;

          const mapped: Location[] = features.map((feature: any) => ({
            address: feature.place_name,
            latitude: feature.center[1],
            longitude: feature.center[0],
          }));

          resolve(mapped);
        } else {
          reject('No search term provided.');
        }
      } catch (err) {
        reject(err);
      }
    });
  };
}
