import merge from 'lodash.merge';

import { resolvers as attachmentResolvers } from './attachments';
import { resolvers as basemapsResolvers } from './basemaps';
import { resolvers as chatResolvers } from './chat';
import { initialState as messageState, resolvers as messageResolvers } from './messages';
import { resolvers as notificationsResolvers } from './notifications';
import { resolvers as reporterResolvers } from './reporters';
import { resolvers as workordersResolvers } from './workorders';

const initialState = {
  ...messageState,
};

const resolvers = merge(
  attachmentResolvers,
  basemapsResolvers,
  chatResolvers,
  messageResolvers,
  notificationsResolvers,
  reporterResolvers,
  workordersResolvers
);

export { initialState, resolvers };
export * from './StoreProvider';
