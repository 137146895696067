import { IonCard, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSkeletonText } from '@ionic/react';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './WorkOrderItem';

const WorkOrderItemSkeleton: React.FC = () => {
  const classes = useStyles({ statusColor: 'var(--ion-color-light)' });

  return (
    <div className={classes.root}>
      <IonCard className={classes.card}>
        <IonItem
          lines="none"
          className={classes.item}
          style={{ '--inner-padding-end': '28px' }} // Additional space where details arrow wold be
          detail={false}
        >
          <IonLabel>
            <IonGrid className={classes.noSpace}>
              <IonRow>
                <IonCol size="3" className={classes.noSpace}>
                  <IonSkeletonText animated={true} style={{ height: '100%', margin: 0 }} />
                </IonCol>
                <IonCol size="9">
                  <div className={classes.content}>
                    <div className={classes.info}>
                      <p className={classes.title}>
                        <IonSkeletonText animated={true} style={{ width: '100%' }} />
                      </p>
                      <p className={classes.address}>
                        <IonSkeletonText animated={true} style={{ width: '80%' }} />
                      </p>
                      <p className={classes.assignee}>
                        <IonSkeletonText animated={true} style={{ width: '50%' }} />
                      </p>
                    </div>
                    <div className={clsx(classes.flexRow, 'ion-margin-bottom')}>
                      <p className={classes.timestamp}>
                        <IonSkeletonText animated={true} style={{ width: 100 }} />
                      </p>
                    </div>
                    <div className={classes.flexRow}>
                      <p className={classes.status}>
                        <IonSkeletonText animated={true} style={{ width: 100 }} />
                      </p>
                      <div className={classes.badges}>
                        <IonSkeletonText
                          animated={true}
                          style={{
                            width: 24,
                            height: 24,
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 14,
                            borderRadius: 4,
                          }}
                        />
                        <IonSkeletonText
                          animated={true}
                          style={{
                            width: 24,
                            height: 24,
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 14,
                            borderRadius: 4,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonLabel>
        </IonItem>
      </IonCard>
    </div>
  );
};

export default WorkOrderItemSkeleton;
