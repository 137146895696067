import React, { useContext } from 'react';

import { FilterType } from './FilterType';
import { ReducerState } from './reducer';

export interface Context {
  filters: ReducerState;
  clear: (filter: FilterType) => void;
  clearAll: () => void;
  isChecked: (filter: FilterType, criterion: string) => boolean;
  toggleChecked: (filter: FilterType, criterion: string) => void;
  update: (filter: FilterType, criteria: Map<string, boolean>) => void;
}

export const FiltersContext = React.createContext<Context | undefined>(undefined);

export const useWorkOrderFilter = (filter: FilterType) => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('Compound component must be rendered within context provider');
  }

  if (filter === FilterType.Street) {
    throw new Error(
      `useWorkOrderFilter only supported with toggleable filters. Filter used: ${filter}.`
    );
  }

  return {
    criteria: new Map(context.filters[filter]),
    checked: (criterion: string) => context.isChecked(filter, criterion),
    clear: () => context.clear(filter),
    clearAll: context.clearAll,
    toggle: (criterion: string) => context.toggleChecked(filter, criterion),
    update: (criteria: Map<string, boolean>) => context.update(filter, criteria),
  };
};
