import { Account } from 'interfaces/Account';
import { AreaOfInterest } from 'interfaces/AreaOfInterest';
import { Category } from 'interfaces/Category';
import { Status } from 'interfaces/Status';
import { Team } from 'interfaces/Team';
import { Reducer } from 'react';

import { FilterType } from './FilterType';

export interface InitializerArg {
  categories?: Pick<Category, 'id'>[];
  statuses?: Status[];
  areasOfInterest?: Pick<AreaOfInterest, 'id'>[];
  teams?: Pick<Team, 'id'>[];
  assignees?: Pick<Account, 'id'>[];
  street?: string;
  tags?: string[];
}

export interface ReducerState {
  [FilterType.Category]: Map<string, boolean>;
  [FilterType.Status]: Map<string, boolean>;
  [FilterType.AreasOfInterest]: Map<string, boolean>;
  [FilterType.Team]: Map<string, boolean>;
  [FilterType.Assignee]: Map<string, boolean>;
  [FilterType.Tag]: Map<string, boolean>;
  [FilterType.Street]?: string;
}

export enum ActionType {
  Clear,
  ClearAll,
  Toggle,
  Update,
  Search,
}

interface ClearPayload {
  filter: FilterType;
}

interface TogglePayload {
  filter: FilterType;
  criterion: string;
}

interface SearchPayload {
  filter: FilterType;
  criterion: string;
}

interface UpdatePayload {
  filter: FilterType;
  criteria: Map<string, boolean>;
}

type ActionPayload = ClearPayload | TogglePayload | UpdatePayload | SearchPayload;

interface ReducerAction {
  type: ActionType;
  payload?: ActionPayload;
}

export type FiltersReducer = Reducer<ReducerState, ReducerAction>;

export const initializer = ({
  categories = [],
  statuses = [],
  areasOfInterest = [],
  teams = [],
  assignees = [],
  tags = [],
  street,
}: InitializerArg): ReducerState => {
  return {
    categories: new Map<string, boolean>(categories.map((c) => [c.id, false])),
    statuses: new Map<string, boolean>(statuses.map((s) => [s, false])),
    areasOfInterest: new Map<string, boolean>(areasOfInterest.map((a) => [a.id, false])),
    teams: new Map<string, boolean>(teams.map((t) => [t.id, false])),
    assignees: new Map<string, boolean>(assignees.map((a) => [a.id, false])),
    tags: new Map<string, boolean>(tags.map((t) => [t, false])),
    street,
  };
};

export const reducer: FiltersReducer = (state, action) => {
  switch (action.type) {
    case ActionType.Clear: {
      const { filter } = action.payload as ClearPayload;

      const criteria =
        filter === FilterType.Street
          ? undefined
          : new Map([...state[filter].keys()].map((criterion) => [criterion, false]));

      return {
        ...state,
        [filter]: criteria,
      };
    }

    case ActionType.ClearAll: {
      const updates: Partial<ReducerState> = {};

      const keys = Object.keys(state).filter((filter) => filter !== FilterType.Street);

      (keys as (keyof Omit<ReducerState, FilterType.Street>)[]).forEach((filter) => {
        const criteria = new Map([...state[filter].keys()].map((criterion) => [criterion, false]));
        updates[filter] = criteria;
      });

      updates[FilterType.Street] = undefined;

      return {
        ...state,
        ...updates,
      };
    }

    case ActionType.Toggle: {
      const { filter, criterion } = action.payload as TogglePayload;

      if (filter === FilterType.Street) return state;

      const criteria = new Map(state[filter]);

      const checked = criteria.get(criterion);
      criteria.set(criterion, !checked);

      return {
        ...state,
        [filter]: criteria,
      };
    }

    case ActionType.Search: {
      const { filter, criterion } = action.payload as SearchPayload;

      if (filter !== FilterType.Street) return state;

      return {
        ...state,
        [filter]: criterion,
      };
    }

    case ActionType.Update: {
      const { filter, criteria } = action.payload as UpdatePayload;

      return {
        ...state,
        [filter]: new Map(criteria),
      };
    }

    default: {
      return state;
    }
  }
};
