import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import Asset from './Asset';

const AssetsRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      <Route path={`${path}/:id`} component={Asset} exact={true} />
    </IonRouterOutlet>
  );
};

export { AssetsRoutes };
