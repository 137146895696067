import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { FilterType } from './FilterType';
import {
  ActionType,
  FiltersReducer,
  InitializerArg,
  ReducerState as Filters,
  initializer,
  reducer,
} from './reducer';

type UseWorkOrderFiltersConfig = InitializerArg;

interface UseWorkOrderFiltersResult {
  filters: Filters;
  clear: (filter: FilterType) => void;
  clearAll: () => void;
  hasChecked: boolean;
  isChecked: (filter: FilterType, criterion: string) => boolean;
  toggleChecked: (filter: FilterType, criterion: string) => void;
  search: (filter: FilterType, criterion: string | undefined) => void;
  update: (filter: FilterType, criteria: Map<string, boolean>) => void;
}

export const useWorkOrderFilters = (
  config: UseWorkOrderFiltersConfig
): UseWorkOrderFiltersResult => {
  const [filters, dispatch] = useReducer<FiltersReducer, InitializerArg>(
    reducer,
    config,
    initializer
  );

  const clear = useCallback(
    (filter: FilterType) =>
      dispatch({
        type: ActionType.Clear,
        payload: { filter },
      }),
    []
  );

  const clearAll = useCallback(
    () =>
      dispatch({
        type: ActionType.ClearAll,
      }),
    []
  );

  const hasChecked = useMemo(() => {
    const keys = Object.keys(filters).filter((filter) => filter !== FilterType.Street);

    if (filters[FilterType.Street]) return true;

    return (keys as (keyof Omit<Filters, FilterType.Street>)[]).reduce((acc, key) => {
      return acc || [...filters[key].values()].includes(true);
    }, false);
  }, [filters]);

  const isChecked = useCallback(
    (filter: FilterType, criterion: string) => {
      if (filter === filters[FilterType.Street]) return !!filters[FilterType.Street];

      return !!(filters as Omit<Filters, FilterType.Street>)[
        filter as keyof Omit<Filters, FilterType.Street>
      ].get(criterion);
    },
    [filters]
  );

  const toggleChecked = useCallback(
    (filter: FilterType, criterion: string) =>
      dispatch({
        type: ActionType.Toggle,
        payload: { filter, criterion },
      }),
    []
  );

  const search = useCallback(
    (filter: FilterType, criterion: string | undefined) =>
      dispatch({
        type: ActionType.Search,
        payload: { filter, criterion },
      }),
    []
  );

  const update = useCallback(
    (filter: FilterType, criteria: Map<string, boolean>) =>
      dispatch({
        type: ActionType.Update,
        payload: { filter, criteria },
      }),
    []
  );

  useEffect(() => {
    if (config.categories?.length && config.categories.length > filters.categories.size) {
      update(
        FilterType.Category,
        new Map<string, boolean>(
          config.categories.map((c) => [c.id, filters.categories.get(c.id) || false])
        )
      );
    }

    if (config.statuses?.length && config.statuses.length > filters.statuses.size) {
      update(
        FilterType.Status,
        new Map<string, boolean>(config.statuses.map((s) => [s, filters.statuses.get(s) || false]))
      );
    }

    if (
      config.areasOfInterest?.length &&
      config.areasOfInterest.length > filters.areasOfInterest.size
    ) {
      update(
        FilterType.AreasOfInterest,
        new Map<string, boolean>(
          config.areasOfInterest.map((a) => [a.id, filters.areasOfInterest.get(a.id) || false])
        )
      );
    }

    if (config.teams?.length && config.teams.length > filters.teams.size) {
      update(
        FilterType.Team,
        new Map<string, boolean>(config.teams.map((t) => [t.id, filters.teams.get(t.id) || false]))
      );
    }

    if (config.assignees?.length && config.assignees.length > filters.assignees.size) {
      update(
        FilterType.Assignee,
        new Map<string, boolean>(
          config.assignees.map((a) => [a.id, filters.assignees.get(a.id) || false])
        )
      );
    }
    if (config.tags?.length && config.tags.length > filters.tags.size) {
      update(
        FilterType.Tag,
        new Map<string, boolean>(config.tags.map((s) => [s, filters.tags.get(s) || false]))
      );
    }
  }, [config, filters, update]);

  return {
    filters,
    clear,
    clearAll,
    hasChecked,
    isChecked,
    toggleChecked,
    search,
    update,
  };
};
