import { IonItem, IonLabel, IonText } from '@ionic/react';
import { Location } from 'interfaces/Location';
import React from 'react';

export interface AddressItemProps extends Pick<Location, 'address'> {
  onClick: (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => void;
}

const AddressItem: React.FC<AddressItemProps> = ({ address, onClick }) => {
  const [addressLine1, ...addressLine2] = address.replace(', United States', '').split(', ');

  return (
    <IonItem button={true} onClick={onClick}>
      <IonLabel class="ion-text-wrap">
        <h3>{addressLine1}</h3>
        <IonText color="medium">
          <p>{addressLine2.join(', ')}</p>
        </IonText>
      </IonLabel>
    </IonItem>
  );
};

export default AddressItem;
