import { useQuery } from '@apollo/client';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import ActivityList from 'components/ActivityList';
import AddNewItemButton from 'components/AddNewItemButton';
import BackButton from 'components/BackButton';
import CommentBar from 'components/CommentBar';
import DescriptionModal from 'components/DescriptionModal';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import StatusBanner from 'components/StatusBanner';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import ChatDialogue from 'containers/ChatDialogue';
import { useMapStyles } from 'hooks/useMapStyles';
import { useMessages } from 'hooks/useMessages';
import { useWorkOrderAssignment } from 'hooks/useWorkOrderAssignment';
import { useWorkOrderDetails } from 'hooks/useWorkOrderDetails';
import { useWorkOrderDocumentation } from 'hooks/useWorkOrderDocumentation';
import { useWorkOrderTracking } from 'hooks/useWorkOrderTracking';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { MapContext } from 'interfaces/Basemaps';
import {
  chatbubblesOutline as chatIcon,
  chevronBackOutline as closeMenuIcon,
  documentTextOutline as descriptionIcon,
  downloadOutline as downloadIcon,
  pricetagsOutline as tasksIcon,
  receiptOutline as invoicesIcon,
} from 'ionicons/icons';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { formatCurrency } from 'utils/formatCurrency';

import WorkOrderDetailsAssets from './components/AssetsCard/WorkOrderDetailsAssets';
import DetailsHeader from './components/DetailsHeader';
import DetailsPhoto from './components/DetailsPhoto';
import InvoiceItem from './components/InvoiceItem';
import PdfGenerator from './components/PdfGenerator';
import TagAssetsModal from './components/TagAssets/TagAssetsModal';
import TaskItem from './components/TaskItem';
import WorkOrderAssignments from './components/WorkOrderAssignments';
import WorkOrderReporter from './components/WorkOrderReporter';
import { ChannelQueryResult, ChannelQueryVariables, GET_CHANNEL_BY_TOPIC_ID } from './graphql';
import useWorkOrderDetailsAssets from './useWorkOrderDetailsAssets';

const useStyles = createUseStyles({
  header: {
    '--padding-top': 'calc(var(--ion-margin, 16px) * 0.5)',
    '--padding-bottom': 'calc(var(--ion-margin, 16px) * 0.5)',
    '--ion-grid-padding': 0,
  },
  menu: {
    '@media screen and (max-width: 540px)': {
      '--width': '100%',
    },
  },
  menuCloseButton: {
    position: 'absolute',

    '& > ion-button': {
      margin: '0 !important',
      '--padding-start': '0 !important',
    },
  },
  worklog: {
    '& span, & ion-text': {
      marginLeft: 8,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
});

const WorkOrderDetails: React.FC = () => {
  const classes = useStyles();

  const pageRef = useRef<HTMLElement>(null); // Bind to modals for card style display
  const contentRef = useRef<any>(null);
  const menuRef = useRef<HTMLIonMenuElement>(null);

  const { search } = useLocation();
  const history = useHistory();
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { id: workorderId } = useParams<any>();
  const { workspace, workspaces } = useWorkspace();
  const { addMessage } = useMessages();
  const { workorder, refetch, setWorkOrder } = useWorkOrderDetails(workorderId);
  const { changeStatus } = useWorkOrderTracking({ workorderId });
  const [showPdfOptions, setShowPdfOptions] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [addAssetOpen, setAddAssetOpen] = useState(false);
  const {
    addComment,
    deleteComment,
    editComment,
    attachAttachment,
    detachAttachment,
  } = useWorkOrderDocumentation(workorderId);
  const {
    assignAssignee,
    assignCategory,
    assignLocation,
    assignOrganization,
    assignReporter,
    assignTeam,
  } = useWorkOrderAssignment({ workorderId });

  const { teams, assignees, categories, organizationId } = workspace;

  const {
    workorderAssets,
    orgHasGISAssetURLs,
    loading: assetsLoading,
    error: assetError,
    tagAsset,
    untagAssets,
  } = useWorkOrderDetailsAssets({ workorderId });

  const mapStyles = useMapStyles({
    organizationId,
    context: MapContext.WorkOrderDetails,
  });

  const { data: channelQuery } = useQuery<ChannelQueryResult, ChannelQueryVariables>(
    GET_CHANNEL_BY_TOPIC_ID,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !workorderId,
      variables: { topicId: workorderId },
    }
  );

  const organizations = workspaces.map((w) => ({ name: w.label, id: w.organizationId }));
  const totalCost = (workorder?.worklog || []).reduce((total, task) => total + task.totalCost, 0);

  const scrollToTop = () => contentRef.current && contentRef.current.scrollToTop(500);

  const onClose = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    if (!menuRef.current) {
      return;
    }

    event.stopPropagation();
    menuRef.current.close();
  };

  useEffect(() => {
    if (assetError) addMessage(assetError, 'danger');
  }, [assetError, addMessage]);

  return (
    <AuthorizeRequired required={[AccountAction.GetWorkOrder]}>
      <IonPage id="details-page" ref={pageRef}>
        <IonHeader>
          <IonToolbar onClick={scrollToTop} style={{ cursor: 'pointer' }}>
            <IonButtons slot="start">
              <BackButton defaultHref={'/workorders'} />
            </IonButtons>
            <IonTitle>{workorder?.titleDisplay}</IonTitle>
            <IonButtons slot="end">
              {workorder && (
                <IonButton onClick={() => setShowPdfOptions(true)}>
                  {downloading ? (
                    <IonSpinner color="primary" name="crescent" />
                  ) : (
                    <IonIcon slot="icon-only" icon={downloadIcon} />
                  )}
                </IonButton>
              )}
              <IonMenuButton menu={`chat-menu-${workorderId}`} onClick={(e) => e.stopPropagation()}>
                <IonIcon icon={chatIcon} />
              </IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={contentRef} style={{ '--padding-bottom': 'var(--ion-margin, 16px)' }}>
          <PullToRefresh onRefresh={refetch} />

          {workorder && (
            <>
              <StatusBanner
                status={workorder.status}
                reason={workorder.reason}
                hasAlert={workorder.hasAlert}
              />

              <ListSeparator />

              <DetailsPhoto
                pageRef={pageRef}
                item={{
                  attachments: [...workorder.attachments].reverse(),
                  location: workorder.location,
                }}
                mapStyles={mapStyles}
              />

              <ListSeparator height="0" />

              <IonItem className={classes.header} lines="none">
                <DetailsHeader
                  pageRef={pageRef}
                  workOrder={workorder}
                  mapStyles={mapStyles}
                  onSelectLocation={(location) => assignLocation(location)}
                  onSelectStatus={(status, reason, date) => changeStatus(status, reason, date)}
                />
              </IonItem>

              <IonItem className={classes.header} lines="full">
                <WorkOrderAssignments
                  assignees={assignees}
                  categories={categories}
                  organizations={organizations}
                  pageRef={pageRef}
                  teams={teams}
                  workOrder={workorder}
                  onSelectAssignee={(assignee) => assignAssignee(assignee.id)}
                  onSelectCategory={(category) => assignCategory(category.id)}
                  onSelectOrganization={(organization) => assignOrganization(organization.id)}
                  onSelectTeam={(team) => assignTeam(team.id)}
                />
              </IonItem>

              <ListSeparator />

              <WorkOrderReporter
                pageRef={pageRef}
                workOrder={workorder}
                onSelectReporter={(reporter) => assignReporter(reporter)}
              />

              <ListSeparator />

              <IonList className="ion-no-padding">
                <IonItemDivider
                  mode="md"
                  sticky={true}
                  style={{
                    '--padding-end': 0,
                    '--inner-padding-end': 'calc(var(--ion-margin, 16px) * 0.25)',
                  }}
                >
                  <IonIcon icon={descriptionIcon} slot="start" />
                  <IonLabel>
                    <h2>Description</h2>
                  </IonLabel>
                  <AuthorizeRequired required={[AccountAction.EditWorkOrderDescription]}>
                    <DescriptionModal workOrder={workorder} pageRef={pageRef} />
                  </AuthorizeRequired>
                </IonItemDivider>
                {!workorder.description.startsWith(workorder.title) && (
                  <IonItem style={{ '--border-style': 'dashed' }}>
                    <IonLabel>
                      <h3>{workorder.title}</h3>
                    </IonLabel>
                  </IonItem>
                )}
                <IonItem lines="full">
                  <IonLabel color="dark">
                    <p style={{ whiteSpace: 'pre-line' }}>{workorder.description}</p>
                  </IonLabel>
                </IonItem>
              </IonList>

              <ListSeparator />

              <IonList className="ion-no-padding">
                <IonItemDivider
                  mode="md"
                  sticky={true}
                  style={{
                    '--padding-end': 0,
                    '--inner-padding-end': 'var(--ion-margin, 16px)',
                  }}
                >
                  <IonIcon icon={tasksIcon} slot="start" />
                  <IonLabel>
                    <h2>Worklog</h2>
                  </IonLabel>
                  {workorder.worklog.length > 0 && (
                    <IonLabel slot="end" color="secondary">
                      {formatCurrency(totalCost)}
                    </IonLabel>
                  )}
                </IonItemDivider>
                {workorder.worklog.map((task, index) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    lines={index === workorder.worklog.length - 1 ? 'full' : 'inset'}
                    routerLink={`/worklog/tasks/details/${task.id}`}
                    onRemove={() =>
                      setWorkOrder((prev: any) => ({
                        ...prev,
                        worklog: prev.worklog.filter((t: { id: string }) => t.id !== task.id),
                      }))
                    }
                  />
                ))}
                {workorder.worklog.length === 0 && (
                  <IonItem lines="full">
                    <IonLabel>
                      <p>No work has been logged for this work order</p>
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
              <AuthorizeRequired required={[AccountAction.CreateWorklogTask]}>
                <AddNewItemButton
                  title="Log Work"
                  routerLink={`/worklog/tasks/create/${workorderId}`}
                />
              </AuthorizeRequired>

              <ListSeparator />

              <IonList className="ion-no-padding">
                <IonItemDivider mode="md" sticky={true}>
                  <IonIcon icon={invoicesIcon} slot="start" />
                  <IonLabel>
                    <h2>Invoices</h2>
                  </IonLabel>
                </IonItemDivider>
                {workorder.invoices.map((invoice, index) => (
                  <InvoiceItem
                    key={index}
                    invoice={invoice}
                    lines={index === workorder.invoices.length - 1 ? 'full' : 'inset'}
                  />
                ))}
                {workorder.invoices.length === 0 && (
                  <IonItem lines="full">
                    <IonLabel>
                      <p className="ion-text-wrap">
                        No invoices have been created for this work order
                      </p>
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
              <AuthorizeRequired required={[AccountAction.CreateWorklogInvoice]}>
                <AddNewItemButton
                  title="Create an Invoice"
                  routerLink={`/worklog/invoices/create/${workorderId}`}
                />
              </AuthorizeRequired>

              <ListSeparator />

              {(workorderAssets.length || orgHasGISAssetURLs) && (
                <WorkOrderDetailsAssets
                  assets={workorderAssets}
                  loading={assetsLoading}
                  onUntag={untagAssets}
                  setAddAssetOpen={setAddAssetOpen}
                  mapStyles={mapStyles}
                />
              )}

              {addAssetOpen && (
                <TagAssetsModal
                  pageRef={pageRef}
                  open={addAssetOpen}
                  setOpen={setAddAssetOpen}
                  workorderId={workorder.id}
                  mapStyles={mapStyles}
                  onTag={tagAsset}
                />
              )}

              <ListSeparator />

              <ActivityList
                activity={workorder.activity}
                attachments={workorder.attachments}
                comments={workorder.comments}
                pageRef={pageRef}
                onEditComment={editComment}
                onDeleteComment={deleteComment}
                onDetachAttachment={detachAttachment}
              />
            </>
          )}
        </IonContent>

        <AuthorizeRequired
          required={[AccountAction.AddWorkOrderComment, AccountAction.AttachWorkOrderAttachment]}
        >
          <CommentBar
            uploadDirectory={workorderId}
            onAddComment={addComment}
            onUpload={attachAttachment}
            renderBrowseFiles={({ onBrowse }) => (
              <IonAlert
                isOpen={params.new === 'true'}
                header="Work Order Created"
                message="Would you like to add a photo?"
                buttons={[
                  {
                    text: 'No',
                    handler: () => history.replace(`/workorders/details/${workorderId}`),
                  },
                  {
                    text: 'Yes',
                    handler: () => {
                      history.replace(`/workorders/details/${workorderId}`);
                      onBrowse(true);
                    },
                  },
                ]}
              />
            )}
          />
        </AuthorizeRequired>
      </IonPage>

      <PdfGenerator
        isOpen={showPdfOptions}
        pageRef={pageRef}
        workorder={workorder}
        onDismiss={() => setShowPdfOptions(false)}
        onDownload={setDownloading}
        onFailure={(error) => addMessage(error, 'danger')}
      />

      <AuthorizeRequired required={[AccountAction.GetChatChannel]}>
        {channelQuery?.chatChannelByTopicId && (
          <IonMenu
            className={classes.menu}
            contentId="details-page"
            menuId={`chat-menu-${workorderId}`}
            ref={menuRef}
            side="end"
          >
            <IonHeader>
              <IonToolbar>
                {isPlatform('mobile') && (
                  <IonButtons className={classes.menuCloseButton} slot="start">
                    <IonButton onClick={onClose}>
                      <IonIcon icon={closeMenuIcon} size="large" />
                    </IonButton>
                  </IonButtons>
                )}
                <IonTitle>Resident Chat</IonTitle>
              </IonToolbar>
            </IonHeader>

            <ChatDialogue topicId={workorderId} />
          </IonMenu>
        )}
      </AuthorizeRequired>
    </AuthorizeRequired>
  );
};

export default WorkOrderDetails;
