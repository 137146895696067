import { gql, useMutation, useQuery } from '@apollo/client';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import InfiniteList from 'components/InfiniteList';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import { TableHeaderItem, TableItem } from 'components/TaskTable';
import UnsafeArea from 'components/UnsafeArea';
import AuthorizeRequired from 'containers/AuthorizeRequired';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import { AccountKind } from 'interfaces/AccountKind';
import { AccountStatus } from 'interfaces/AccountStatus';
import { AccountUtilization } from 'interfaces/AccountUtilization';
import {
  banOutline as deactivatedIcon,
  pricetagsOutline as utilizationSummaryIcon,
} from 'ionicons/icons';
import moment from 'moment';
import {
  ATTACH_PERMISSIONS,
  DEACTIVATE_ACCOUNT,
  DETACH_PERMISSIONS,
  GET_ACCOUNT,
  GetAccountResponse,
  REACTIVATE_ACCOUNT,
  REINVITE_ACCOUNT,
} from 'pages/Accounts/graphql';
import React, { useMemo, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { logException } from 'services/logger';
import { formatCurrency } from 'utils/formatCurrency';

import { SET_ACCOUNT_RATE } from '../graphql';
import AccountSummary from './components/AccountSummary';
import ActionManager from './components/AccountSummary/ActionManager';

const GET_ORGANIZATION_ACCOUNTS = gql`
  query GetOrganizationAccounts($accountId: ID!, $statuses: [AccountStatus!]) {
    account(id: $accountId) {
      id
      organization {
        id
        accounts(query: { statuses: $statuses }) {
          id
        }
      }
    }
  }
`;

const useStyles = createUseStyles({
  banner: {
    '--background': 'var(--ion-color-danger-tint)',
    '--color': 'var(--ion-color-danger-contrast)',

    '& > ion-button, ion-icon, p': {
      color: 'inherit',
    },

    '& > ion-icon': {
      '@media screen and (max-width: 450px)': {
        display: 'none',
      },
    },
  },
  actionButton: {
    marginBottom: 'var(--ion-margin, 8px)',
  },
});

const ManageAccount: React.FC = () => {
  const classes = useStyles();

  const { id: accountId } = useParams<{ id: string }>();

  const pageRef = useRef<HTMLElement>(null); // Bind to modals for card style display

  const { addMessage } = useMessages();

  const { workspace } = useWorkspace();

  const { data, loading, refetch } = useQuery<GetAccountResponse>(GET_ACCOUNT, {
    displayName: 'ManageAccount',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    partialRefetch: true,
    variables: { accountId },
    onError: () => addMessage('Unable to load account details.', 'danger'),
  });
  const account = data?.account;

  const fromStatus = useMemo(
    () =>
      account?.kind === AccountKind.User
        ? account.pastIdentity
          ? AccountStatus.Activated
          : AccountStatus.Invited
        : 'Unknown',
    [account]
  );

  const [attachPermissions] = useMutation(ATTACH_PERMISSIONS, {
    onError: ({ graphQLErrors }) => {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to attach permissions', ...messages].join('. ')}.`, 'danger');
    },
  });

  const [detachPermissions] = useMutation(DETACH_PERMISSIONS, {
    onError: ({ graphQLErrors }) => {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to detach permissions', ...messages].join('. ')}.`, 'danger');
    },
  });

  const [setRate] = useMutation(SET_ACCOUNT_RATE, {
    onError: ({ graphQLErrors }) => {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to set rate', ...messages].join('. ')}.`, 'danger');
    },
  });

  const [deactivateAccount] = useMutation(DEACTIVATE_ACCOUNT, {
    update: (cache, mutationResult) => {
      try {
        const { account: updatedAccount } = mutationResult.data.deactivateAccount;

        const queryResult: any = cache.readQuery({
          query: GET_ORGANIZATION_ACCOUNTS,
          variables: {
            accountId: updatedAccount.id,
            statuses: [AccountStatus.Invited, AccountStatus.Activated],
          },
        });

        const updatedQueryResult: any = {
          account: {
            ...queryResult.account,
            organization: {
              ...queryResult.account.organization,
              accounts: queryResult.account.organization.accounts.filter(
                (target: any) => updatedAccount.id !== target.id
              ),
            },
          },
        };

        cache.writeQuery({
          query: GET_ORGANIZATION_ACCOUNTS,
          variables: {
            accountId: updatedAccount.id,
            statuses: [AccountStatus.Invited, AccountStatus.Activated],
          },
          data: updatedQueryResult,
        });
      } catch (err) {
        logException(err as Error);
      }
    },
    onError: ({ graphQLErrors }) => {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to deactivate account', ...messages].join('. ')}.`, 'danger');
    },
  });

  const [reactivateAccount] = useMutation(REACTIVATE_ACCOUNT, {
    update: (cache, mutationResult) => {
      try {
        const { account: updatedAccount } = mutationResult.data.reactivateAccount;

        const queryResult: any = cache.readQuery({
          query: GET_ORGANIZATION_ACCOUNTS,
          variables: {
            accountId: updatedAccount.id,
            statuses: [AccountStatus.Invited, AccountStatus.Activated],
          },
        });

        const updatedQueryResult: any = {
          account: {
            ...queryResult.account,
            organization: {
              ...queryResult.account.organization,
              accounts: [...queryResult.account.organization.accounts, account],
            },
          },
        };

        cache.writeQuery({
          query: GET_ORGANIZATION_ACCOUNTS,
          variables: {
            accountId: updatedAccount.id,
            statuses: [AccountStatus.Invited, AccountStatus.Activated],
          },
          data: updatedQueryResult,
        });
      } catch (err) {
        logException(err as Error);
      }
    },

    onError: ({ graphQLErrors }) => {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to reactivate account', ...messages].join('. ')}.`, 'danger');
    },
  });

  const [reinviteAccount] = useMutation(REINVITE_ACCOUNT, {
    onCompleted: () => {
      addMessage(`We have resent ${account?.name} an invitation to join ${account?.team.name}`);
    },
    onError: ({ graphQLErrors }) => {
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to resend invite', ...messages].join('. ')}.`, 'danger');
    },
  });

  const handleUpdatePermissions = async (actions: AccountAction[], isDetach = false) => {
    if (!account) {
      return;
    }

    const variables = { input: { accountId: account.id, actions } };

    if (!isDetach) {
      await attachPermissions({ variables });
    } else {
      await detachPermissions({ variables });
    }
  };

  const { accountUtilizations, totalAccountUtilizationCost } = useMemo(() => {
    let utilizations: AccountUtilization[] = [];
    let totalCost = 0;

    if (account?.utilization.nodes.length) {
      utilizations = account.utilization.nodes;
      totalCost = account.utilization.nodes.reduce((total, item) => total + item.itemCost, 0);
    }

    return {
      accountUtilizations: utilizations,
      totalAccountUtilizationCost: totalCost,
    };
  }, [account]);

  const [confirmResendInvite] = useConfirmAction({
    header: 'Resend Invite',
    message: `Resend an email invitation for ${account?.name} to ${account?.email}?`,
    confirmText: 'Resend',
    handler: () => reinviteAccount({ variables: { accountId } }),
  });

  const [confirmDeactivate] = useConfirmAction({
    header: 'Deactivate Account',
    message:
      'Are you sure you want to deactivate this account? They will no longer have access to your organization or receive notifications and you will no longer be able to assign them work orders.',
    confirmText: 'Deactivate',
    destructive: true,
    handler: () => deactivateAccount({ variables: { accountId } }),
  });

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton defaultHref={'/accounts'} />
          </IonButtons>
          {!loading && account && <IonTitle>{account.name}</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PullToRefresh onRefresh={refetch} />

        <AuthorizeRequired required={[AccountAction.GetAccount]}>
          {!loading && account && (
            <>
              {account.status === AccountStatus.Deactivated && (
                <IonItem className={classes.banner} lines="full">
                  <IonIcon icon={deactivatedIcon} size="small" slot="start" />

                  <IonLabel>
                    <h2>Deactivated</h2>
                    <p>This account has been deactivated</p>
                  </IonLabel>

                  {(fromStatus === AccountStatus.Activated || fromStatus === 'Unknown') && (
                    <AuthorizeRequired required={[AccountAction.ReactivateAccount]}>
                      <IonButton
                        fill="clear"
                        slot="end"
                        strong={true}
                        onClick={() => reactivateAccount({ variables: { accountId } })}
                      >
                        Reactivate
                      </IonButton>
                    </AuthorizeRequired>
                  )}

                  {fromStatus === AccountStatus.Invited && (
                    <AuthorizeRequired required={[AccountAction.ReinviteAccount]}>
                      <IonButton
                        fill="clear"
                        slot="end"
                        strong={true}
                        onClick={() => reinviteAccount({ variables: { accountId } })}
                      >
                        Resend Invite
                      </IonButton>
                    </AuthorizeRequired>
                  )}
                </IonItem>
              )}

              <ListSeparator />

              <AccountSummary
                account={account}
                disabled={account.status === AccountStatus.Deactivated}
                pageRef={pageRef}
                onSetRate={(value) => setRate({ variables: { accountId, rate: value } })}
              />

              {account.kind === AccountKind.User && (
                <>
                  <ListSeparator />
                  <ActionManager
                    selected={account.actions}
                    allowed={workspace.actions}
                    disabled={account.status === AccountStatus.Deactivated}
                    onAttachPermission={handleUpdatePermissions}
                    onDetachPermission={(actions) => handleUpdatePermissions(actions, true)}
                  />
                </>
              )}

              {accountUtilizations?.length > 0 && (
                <React.Fragment>
                  <ListSeparator />

                  <IonItemDivider mode="md" sticky={true}>
                    <IonIcon icon={utilizationSummaryIcon} slot="start" />
                    <IonLabel>
                      <h2>{isPlatform('mobile') ? 'Utilization' : 'Utilization Summary'}</h2>
                    </IonLabel>
                    <IonLabel color="secondary" slot="end">
                      {formatCurrency(totalAccountUtilizationCost)}
                    </IonLabel>
                  </IonItemDivider>

                  <InfiniteList
                    title="Utilization Summary"
                    pageRef={pageRef}
                    searchKeys={[
                      'task.name',
                      'task.workorder.workorderNumber',
                      'task.workorder.title',
                    ]}
                    items={accountUtilizations}
                    itemSize={isPlatform('ios') ? 63 : 65}
                    renderListHeader={() => <TableHeaderItem quantityLabel="Hours" />}
                    renderListItem={({ task, rate, hours, itemCost }, index, items) => (
                      <TableItem
                        lines={index === items.length - 1 ? 'full' : 'inset'}
                        name={`${moment(task.taskDate).format('l')} ・ ${task.name}`}
                        note={`Work Order - ${task.workorder.titleDisplay}`}
                        rate={rate}
                        quantity={hours}
                        total={itemCost}
                        routerLink={`/worklog/tasks/details/${task.id}`}
                      />
                    )}
                  />
                </React.Fragment>
              )}

              {account.status === AccountStatus.Invited && (
                <AuthorizeRequired required={[AccountAction.ReinviteAccount]}>
                  <React.Fragment>
                    <ListSeparator />

                    <IonItem
                      button={true}
                      className={classes.actionButton}
                      detail={false}
                      lines="full"
                      onClick={confirmResendInvite}
                    >
                      <IonLabel className="ion-text-center">Resend Invite</IonLabel>
                    </IonItem>
                  </React.Fragment>
                </AuthorizeRequired>
              )}

              {(account.status === AccountStatus.Invited ||
                account.status === AccountStatus.Activated) && (
                <AuthorizeRequired required={[AccountAction.DeactivateAccount]}>
                  <React.Fragment>
                    <ListSeparator />

                    <IonItem
                      className={classes.actionButton}
                      button={true}
                      detail={false}
                      lines="full"
                      onClick={confirmDeactivate}
                    >
                      <IonLabel className="ion-text-center" color="danger">
                        Deactivate
                      </IonLabel>
                    </IonItem>
                  </React.Fragment>
                </AuthorizeRequired>
              )}
            </>
          )}
        </AuthorizeRequired>
        <UnsafeArea position="bottom" />
      </IonContent>
    </IonPage>
  );
};

export default ManageAccount;
