import { useMutation, useQuery } from '@apollo/client';
import { IonBadge, IonIcon } from '@ionic/react';
import { useWorkspace } from 'hooks/useWorkspace';
import { notificationsOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { logEvent } from 'services/logger';

import {
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  NotificationQueryResult,
  NotificationsQueryVariables,
  READ_SUBSCRIBER_NOTIFICATIONS,
  ReadSubscriberNotificationsInput,
  ReadSubscriberNotificationsResponse,
  UnreadNotificationQueryResult,
  UnreadNotificationsQueryVariables,
} from './graphql';
import NotificationItem from './NotificationItem';
import NotificationModal from './NotificationModal';
import NotificationPopover from './NotificationPopover';

const useStyles = createUseStyles({
  icon: {
    fontSize: '24px',
    cursor: 'pointer',
  },
  badge: {
    position: 'absolute',
    left: 12,
    top: -8,
    opacity: 0.9,
    fontSize: '11px',
    paddingRight: '6px',
    paddingLeft: '6px',
    cursor: 'pointer',
  },
});

interface NotificationsProps {
  className?: string;
}

const Notifications: React.FC<NotificationsProps> = ({ className }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { workspace } = useWorkspace();

  const [unread, setUnread] = useState(0);
  const [popupState, setPopupState] = useState<{ showPopup: boolean; event: any }>({
    showPopup: false,
    event: undefined,
  });

  const { loading: unreadLoading, data: unreadNotifications, refetch: refetchUnread } = useQuery<
    UnreadNotificationQueryResult,
    UnreadNotificationsQueryVariables
  >(GET_UNREAD_NOTIFICATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: workspace.operatorId,
      first: 100,
    },
  });

  const { loading, data, fetchMore, refetch } = useQuery<
    NotificationQueryResult,
    NotificationsQueryVariables
  >(GET_NOTIFICATIONS, {
    skip: !popupState.showPopup,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      accountId: workspace.operatorId,
      unread: undefined,
      after: undefined,
      first: 20,
    },
  });

  const [readSubscriberNotifications] = useMutation<
    ReadSubscriberNotificationsResponse,
    ReadSubscriberNotificationsInput
  >(READ_SUBSCRIBER_NOTIFICATIONS, {
    onCompleted: () => {
      // Refetch unread notifications
      // Ideally, we would directly update the cache with the mutation response,
      // but cache may be overwritten with notification list query.
      // Instead just allow time for projections to update and refetch.
      setTimeout(() => {
        refetchUnread();
      }, 1000);
    },
    onError: (err) =>
      // No need to notify user. Notif will just remain unread. Log to Sentry.
      logEvent('[useInAppNotifications] Read notification failed', {
        accountId: workspace.operatorId,
        error: err,
      }),
  });

  const notifications = data?.notificationSubscriber?.notifications?.nodes || [];
  const pageInfo = data?.notificationSubscriber?.notifications?.pageInfo;

  const loadMoreRows = () => {
    return fetchMore({
      variables: {
        notifyOnNetworkStatusChange: true,
        after: data?.notificationSubscriber?.notifications.pageInfo.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult?.notificationSubscriber.notifications.nodes;
        const newPageInfo = fetchMoreResult?.notificationSubscriber.notifications.pageInfo;

        if (!fetchMoreResult) {
          return previousResult;
        }

        return {
          notificationSubscriber: {
            notifications: {
              nodes: [...previousResult.notificationSubscriber.notifications.nodes, ...newNodes],
              pageInfo: newPageInfo,
            },
          },
        };
      },
    });
  };

  /** Render a single notification item */
  const row = ({ index, style }: { index: number; style: any }) => {
    const notification = {
      ...notifications[index],
      url: notifications[index]?.url === pathname ? undefined : notifications[index]?.url,
    };

    return <NotificationItem data={notification} style={style} />;
  };

  /**
   * All items are loaded if there is no next page.
   * If there is an next page, our item count is total notifications + 1,
   * so the "+ 1" is not not loaded.
   */
  const isItemLoaded = (index: number) => !pageInfo?.hasNextPage || index < notifications.length;
  const itemCount = pageInfo?.hasNextPage ? notifications?.length + 1 : notifications?.length;

  /** Only load more if not already fetching */
  const loadMoreItems = loading ? null : loadMoreRows;

  const openPopup = (event: any) => {
    event.persist();
    setPopupState({ showPopup: true, event });
  };

  const onCloseModal = () => {
    setPopupState({ showPopup: false, event: undefined });

    // Mark all unread as read on notifications window close
    const nodes = unreadNotifications?.notificationSubscriber?.notifications.nodes;

    if (!nodes) return;

    // Construct dict of channel IDs to get a unique list.
    // Value of `true` only to assign something. Not used.
    const channelMap = nodes.reduce(
      (acc, notification) => ({
        ...acc,
        [notification.channel.id]: true,
      }),
      {}
    );
    const channelIds = Object.keys(channelMap);

    for (const channelId of channelIds) {
      readSubscriberNotifications({
        variables: {
          channelId,
          accountId: workspace.operatorId,
        },
      });
    }
  };

  // Refetch notification list when notifications window is opened
  useEffect(() => {
    if (!popupState.showPopup) return;
    refetch();
  }, [popupState, refetch]);

  // Close notifications window on page navigations
  useEffect(() => {
    setPopupState({ showPopup: false, event: undefined });
  }, [pathname]);

  // Update unread count (badge) on updates from unread query
  useEffect(() => {
    if (unreadLoading || !unreadNotifications) return;
    setUnread(unreadNotifications?.notificationSubscriber.notifications.nodes.length);
  }, [unreadLoading, unreadNotifications]);

  const renderBadge = () => {
    if (unread > 0) {
      return (
        <IonBadge mode="ios" className={classes.badge} onClick={openPopup}>
          {unread > 99 ? '99+' : unread}
        </IonBadge>
      );
    } else {
      return null;
    }
  };

  const renderPopup = () => {
    const size = window.matchMedia('(min-width: 768px)').matches ? true : false;

    if (popupState) {
      if (size) {
        return (
          <NotificationPopover
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            popupState={popupState}
            notifications={notifications}
            row={row}
            onClose={onCloseModal}
          />
        );
      } else {
        return (
          <NotificationModal
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            popupState={popupState}
            notifications={notifications}
            row={row}
            onClose={onCloseModal}
          />
        );
      }
    }
  };

  return (
    <div className={className} style={{ position: 'relative' }}>
      <IonIcon icon={notificationsOutline} onClick={openPopup} className={classes.icon} />
      {renderBadge()}
      {renderPopup()}
    </div>
  );
};

export default Notifications;
