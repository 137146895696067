import { gql, useMutation } from '@apollo/client';
import * as React from 'react';
import { logException } from 'services/logger';

import { useMessages } from './useMessages';

export const EDIT_DESCRIPTION = gql`
  mutation editWorkOrderDescription($workorderId: ID!, $title: String!, $description: String!) {
    editWorkOrderDescription(
      input: { workorderId: $workorderId, title: $title, description: $description }
    ) {
      workOrder {
        id
        title
        description
        titleDisplay @client
      }
    }
  }
`;

export const useWorkOrderDescription = (workorderId: string) => {
  const { addMessage } = useMessages();
  const [error, setError] = React.useState('');
  const [edit, { loading: editDescriptionLoading }] = useMutation(EDIT_DESCRIPTION);

  const editDescription = async (title: string, description: string): Promise<any> => {
    try {
      const r = await edit({ variables: { workorderId, title, description } });
      return r;
    } catch (err) {
      onError(err as Error, 'Unable to edit description');
    }
  };

  const onError = (err: Error, alertMessage: string) => {
    addMessage(alertMessage, 'danger');
    setError(err.message);
    logException(err);
  };

  return {
    error,
    editDescription,
    editDescriptionLoading,
  };
};
