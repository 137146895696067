import { parseAddress } from 'hooks/useLocation';
import { Account } from 'interfaces/Account';
import { Category } from 'interfaces/Category';
import { WorkOrder } from 'interfaces/WorkOrder';
import moment from 'moment';
import { Content } from 'pdfmake/interfaces';

export interface WorkOrderProps
  extends Pick<WorkOrder, 'createdAt' | 'titleDisplay' | 'location' | 'status'> {
  category: Pick<Category, 'name'>;
  assignee: Pick<Account, 'name'>;
  reporter: { name: string };
}

export interface WorkOrdersListConfig {
  workorders: WorkOrderProps[];
}

export const workOrdersList = ({ workorders }: WorkOrdersListConfig): Content[] => {
  return [
    { text: 'Work Orders', style: 'sectionHeader' },
    {
      style: 'verticalSpacingMd',
      fontSize: 8,
      table: {
        headerRows: 1,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Date', style: 'tableHeader' },
            { text: 'Title', style: 'tableHeader' },
            { text: 'Address', style: 'tableHeader' },
            { text: 'Resident', style: 'tableHeader' },
            { text: 'Assignee', style: 'tableHeader' },
            { text: 'Status', style: 'tableHeader' },
            { text: 'Category', style: 'tableHeader' },
          ],
          ...workorders.map((workorder): Content[] => [
            moment(workorder.createdAt).format('MM/DD/YYYY'),
            workorder.titleDisplay,
            parseAddress(workorder.location?.address) || '',
            { text: workorder.reporter?.name || '', alignment: 'center' },
            { text: workorder.assignee?.name || '', alignment: 'center' },
            { text: workorder.status, alignment: 'center' },
            { text: workorder.category?.name || '', alignment: 'center' },
          ]),
        ],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: (i, node) =>
          i === 0 || i === (node.table.headerRows || 1) || i === node.table.body.length
            ? '#000000'
            : '#CCCCCC',
        vLineWidth: (i, node) => (i === 0 || i === node.table.body[0].length ? 0.5 : 0),
        // Text is slightly high in cell if top and bottom match. Maybe from lineHeight.
        // Small offset to compensate and vertically center.
        paddingTop: () => 9,
        paddingBottom: () => 7,
        paddingLeft: () => 10,
        paddingRight: (i) => (i === 0 ? 0 : 10),
      },
    },
  ];
};
